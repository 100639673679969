.Map {
  background-color: $color-bg;
  overflow: hidden;
  height: 100%;
  @media $verticalTablet {
    overflow: visible;
    height: auto;
  }
  @media $verticalTablet {
    margin-left: $tablet-margin-x * -1;
    margin-right: $tablet-margin-x * -1;
  }

  @media $phone {
    margin-bottom: 32px;
  }

  &__searchPopupHidden ymaps[class*='popup'] {
    display: none;
  }

  &__preloader {
    spinner(48px, 3px, $color-brand-green);
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -24px;
    margin-left: -24px;
  }

  &__inner {
    position: relative;
    padding-top: 46.357616%;
    background-color: $color-bg;
    height: 100%;

    @media $verticalTablet {
      height: auto;
      padding-top: 60%;
    }

    @media $phone {
      background-color: white;
      padding-top: 50px;
    }


  }

  &__layout {
    &, & > div:not(.Map__preloader) {
      fullsize();
      height: 100%;
      @media $phone {
        position: relative;
        height: 90vh;
        min-height: 280px;
      }

      @media $verticalPhone {
        height: 410px;
      }
    }
  }

}
