.ApplePay {
  position: relative;

  &__button {
    height: 32px;
    padding: 0 17px;
    font-size: $font-size-s;
    font-weight: 400;
    background-color: #000;
    color: #fff;
    text-transform: none;

    svg {
      width: 62px;
      height: 23px;
      display: inline-block;
      vertical-align: middle;

      * {
        fill: currentColor;
      }
    }

    &_disabled {
      &:before {
        background-color: inherit;
      }
    }

    &:active:not(&_disabled):not(&_link):before {
      transform: scale(1);
      background-color: #000;
      transition-duration: $halfduration;;
    }

    svg {
      width: 46px;
      height: 16px;
    }
  }

  &__tooltip {
    position: absolute;
    width: 200px;
    left: 50%;
    top: 100%;
    margin-left: -(@width / 2);
    margin-top: 16px;
    border-radius: $border-radius;
    background-color: #fff;
    box-shadow: $box-shadow;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    transition: opacity (($duration / 2)), visibility (($duration / 2));

    &_visible {
      opacity: 1;
      visibility: visible;
    }

    &:before {
      content: '';
      position: absolute;
      height: 20px;
      width: @height;
      top: -(@height / 2);
      left: 50%;
      margin-left: -(@width / 2);
      background-color: inherit;
      box-shadow: inherit;
      transform: rotate(45deg);
      z-index: -1;
    }
  }

  &__tooltipContent {
    position: relative;
    padding: 35px 25px;
    background-color: inherit;
    border-radius: inherit;
    z-index: 1;
  }

  &__tooltipText {
    margin-bottom: 20px;
    line-height: 20px;
  }

  &__tooltipButton {
    width: 100%;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
