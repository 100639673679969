.SocialButton {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color $duration;

  @media $phone {
    width: 48px;
    height: 48px;
  }

  &:hover {
    color: rgba(80, 166, 132, 1);
    transition-duration: $halfduration;;
  }

  &_google {
    color: $color-google-plus;

    &:hover {
      background-color: $color-google-plus;
      border-color: $color-google-plus;
    }
  }

  &_facebook {
    color: rgba(191, 183, 182, 1);
  }

  &_vk {
    color: rgba(191, 183, 182, 1);
  }

  &_telegram {
    color: $color-telegram;

    &:hover {
      background-color: $color-telegram;
      border-color: $color-telegram;
    }
  }

  &_share {
    color: $color-brand-green;

    &:hover {
      background-color: $color-brand-green;
      border-color: $color-brand-green;
    }
  }

  &_twitter {
    color: $color-twitter;

    &:hover {
      background-color: $color-twitter;
      border-color: $color-twitter;
    }
  }

  &_ok {
    color: $color-ok;

    &:hover {
      background-color: $color-ok;
      border-color: $color-ok;
    }
  }

  &_instagram {
    color: rgba(191, 183, 182, 1);
  }
}
