.BonusesSwitcher {
  width: 100%;
  height: $checkout-mobile-button-height;
  margin-bottom: $checkout-mobile-margin-15;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-radius: $checkout-mobile-button-radius;
  background: white;

  &__selector {
    width: 50%;
    height: 100%;
    padding: $checkout-mobile-button-padding;
    background: $color-brand-green;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: $checkout-mobile-button-radius;
    transition: transform $duration;
    transform: translateX(0);

    &_pickup {
      transform: translateX(100%);
    }
  }

  &__item {
    width: 50%;
    height: 100%;
    padding: $checkout-mobile-button-padding;
    position: relative;
    color: $color-brand-brown;

    &_active {
      color: white;
    }
  }

  &__subtitle {
    font-family: $font-primary;
    font-size: $font-size-s;
    font-weight: 500;
    line-height: 1;
    color: inherit;
  }
}
