.SuggestionsPapajohns {
  $padding-left = 48px;
  $box-shadow = 0 2px 8px 0 rgba(41, 44, 51, 0.08);

  &__textarea {
    padding: 23px 30px;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
  }

  &__suggestions {
    width: 100%;
    max-height: 200px;
    scrollable();
    custom-scrollbar(2px, #e6e2e1);
    box-sizing: border-box;
    position: absolute;
    top: 100%;
    padding-right: 5px;
    border-right: 4px solid transparent;
    border-radius: 5px;
    background-color: #FFF;
    box-shadow: $box-shadow;
    z-index: 1000;
  }

  &__suggestion {
    display: flex;
    align-items: center;
    word-wrap: break-word;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    max-width: 100%;
    padding-left: $padding-left;
    padding-right: 15px;
    cursor: pointer;
    line-height: 1.15;
    height: 40px;
    //font: $font-size-s / 40px $font-primary;
    color: $color-brand-brown;
    background-color: #fff;
    transition: background-color $duration;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 24px;
      top: 50%;
      opacity: 0;
      transform: translateY(-50%);
      background: url('../../assets/check.svg') no-repeat 50% 50% / contain;
      width: 16px;
      height: @width;
      transition: opacity $duration;
    }

    @media $phone {
      height: auto;
      padding-top: 8px;
      padding-bottom: 8px;
      line-height: 1.66;
      font-size: $font-size-xs;

      &:before {
        display: none;
      }
    }

    &_focused, &:hover {
      background-color: $color-bg;
      transition-duration: $halfduration;

      &:before {
        transition-duration: $halfduration;
        opacity: 1;
      }
    }
  }
}
