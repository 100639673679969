.CreatePizzaCard {
  width: 100%;
  display: flex;
  flex-direction column;
  justify-content: space-between;
  align-items: center;
  gap 8px
  padding: 16px $phone-margin-x 24px;
  background-color: white;
  transition: transform $duration;

  &:active {
    transform: scale(0.98);
    transition-duration: $halfduration;
  }

  &__heading {
    font-family: $font-secondary;
    font-weight: bold;
    color: $color-brand-brown;
    line-height: 1.33;
    font-size: $font-size-m;
    text-align center
  }

  &__image {
    width: 50%;
  }

  &__button {
    width 100%
    margin-top 12px
  }
}
