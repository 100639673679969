.Profile {
  $sidebarMargin = 44px;
  padding: 40px 0;

  &__container {
    display: flex;
    flex-direction: row;

    @media $verticalTablet {
      display: block;
      width: 584px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__sidebar {
    flex-shrink: 0;
    width: 350px;
    margin-top: $sidebarMargin;
    align-self: flex-start;
    margin-right: 32px;
    padding: 28px 28px 0;

    @media $verticalTablet {
      width: 100%;
      margin-top: 0;
      margin-bottom: 8px;
      margin-right: 0;
    }
  }

  &__content {
    flex: 0 1 auto;
    overflow: hidden;
    width: 100%;
    padding-top: $sidebarMargin;
  }

  &__title {
    margin-bottom: 10px;
  }

  &__modal {
    paper();
    width: 700px;
    padding: $checkout-desktop-margin-40;
  }

  &__bonuses-history {
    height: auto;
    margin-top: 24px;
    display: flex;
    align-items: center;
  }

  &__back-icon {
    width: auto;
    height: 11px;
    margin-left: 4px;
    transform: scale(-1, 1);
  }
}
