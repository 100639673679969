.DeliveryAlert {
  width: 314px;
  max-width: 94%;
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 24px;
  top: 24px;
  z-index: 9999;
  box-shadow: 0 0 8px 0px rgba(41, 44, 51, 0.2);
  border-radius: $border-radius;
  background-color: white;
  opacity: 0;
  transition: opacity $halfduration;

  &_entered {
    opacity: 1;
  }

  @media $phone {
    margin: auto;
    bottom: 24px;
    top: auto;
    right: 0;
    left: 0;
  }

  &__close {
    align-self: flex-end;
    transition: opacity $halfduration;

    &:hover {
      opacity: 0.5;
    }

    svg {
      width: 10px;
      height: 10px;
      color: $color-brand-brown;
    }
  }

  &__block {
    display: flex;
    justify-content: space-between;
  }

  &__pic {
    width: auto;
    height: 70px;
    margin-right: 20px;
    flex-shrink: 0;
  }

  &__container {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__title {
    margin-bottom: 8px;
    font-family: $font-secondary;
    font-weight: 500;
    font-size: $font-size-xs;
    line-height: 1.33;
    color: $color-brand-brown;
  }

  &__text {
    margin-bottom: 12px;
    color: $color-brand-brown;
    font-size: $font-size-xs;
    font-family: $font-primary;
    font-weight: 400;
    line-height: 1.33;
  }
}
