

.Superpapa {
  //$papa-color-1 = #03592D;
  //$papa-color-2 = #BDD330;
  //$papa-color-3 = #50A684;
  //$papa-color-4 = #072918;
  //$papa-color-5 = #C21313;
  :root {
    --papa-color-1: #03592D;
    --papa-color-2: #BDD330;
    --papa-color-3: #50A684;
    --papa-color-4: #072918;
    --papa-color-5: #C21313;
  }
}
