.ModalBlock {
  padding-top 20px
  background-color white
  text-align center;
  text-wrap: balance;
  white-space: break-spaces;
  max-height 90svh
  overflow auto
  position relative
  //@media $phone {
  //  padding-inline 12px
  //}

  &_desktop {
    border-radius 15px
    padding-top 24px
  }

  &_withLogo {
    padding-top 84px
    overflow visible
  }

  &__logo {
    position absolute
    width 286px
    left 50%
    top 0
    transform translate(-50%, -50%)
  }

  &__content {
    custom-scrollbar(2px, #e6e2e1);
    overflow auto
    overflow-x clip
    max-height calc(90vh - 200px)
    padding-inline 44px
    padding-bottom 24px

    @media $phone {
      max-height unset
      padding-inline 12px
      padding-bottom 20px
    }
  }

  &__cross {
    position absolute
    top 16px
    right 16px
    width 24px
    fill #50A684
    cursor pointer
    @media $tablet {
      width 16px
    }
    @media $phone {
      width 16px
    }
  }
}
