.Nav {
  columns: 3;

  @media $tablet {
    columns: 2;
  }

  @media $phone {
    columns: 1;
  }

  &__col {
    padding-right: 50px;
    display: inline-block;
  }

  &__item {
    flex: 1 1 30%;
    margin-right: 20px;

    @media $tablet {
      flex: 1 1 40%;
    }
  }

  &__link {
    font-weight: 500;
    font-family: $font-secondary;
    color: $color-brand-brown;
    font-size: $font-size-s;
    line-height: 14px;
    padding: 10px 0;
    display: inline-block;
    transition: color $duration;

    &:hover {
      color: $color-brand-green;
      transition-duration: $halfduration;
    }
  }
}
