.CancelOrderModal {
  padding: $checkout-mobile-block-padding;
  display: flex;
  flex-direction: column;

  &_cancelled {
    align-items: center;
  }

  &__title {
    margin-bottom: 30px;
    text-align: center;
  }

  &__checkboxes {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
  }

  &__checkbox {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  &__toggle {
    display: flex;
    align-items: center;
    color: $color-brand-green;
    transition: color $halfduration;
    font-size: $font-size-s;
    font-family: $font-primary;
    font-weight: 400;
    line-height: 1;
    cursor: pointer;

    &:hover {
      color: $color-brand-green-hover;
    }
  }

  &__arrow {
    height: auto;
  }

  &__textarea {
    width: 100%;
    margin-top: 15px;
  }

  &__actions {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__cancel {
    padding: 0 25px;
  }

  &__img {
    width: 45px;
    height: 45px;
    margin-bottom: 20px;
  }

  &__subtitle {
    margin-bottom: 12px;
  }

  &__success {
    font-family: $font-primary;
    font-weight: 500;
    font-size: $font-size-m;
    line-height: 1.33;
    text-align: center;
    color: $color-brand-brown;
    opacity: 0.8;
  }

  &__okey {
    margin-top: 40px;
  }
}