.Collage {
  position relative
  aspect-ratio 4 / 3

  border-radius 12px

  background url("./assets/bg.png")
  background-size cover

  &__floor {
    position absolute
    background url("./assets/floor.png")
    background-position center
    width 100vw
    height 100vh
    background-repeat repeat-y
    background-size cover

    top 80%

    &::before {
      position absolute
      content ""
      top -2px
      width calc(var(--offset) + 3%)
      aspect-ratio: 1;
      background #241932
      clip-path polygon(0 0%, 100% 0%, 0 100%)
    }
    &::after {
      position absolute
      content ""
      right 0
      top -2px
      width calc(var(--offset) + 10%)
      aspect-ratio: 1;
      background #241932
      clip-path polygon(0 0%, 100% 0%, 100% 100%)

      @media $tablet {
        display none
      }
    }

    left : calc(var(--offset)*-1)
  }

  &__Tomat {
    position absolute
    left 7%
    bottom 10%
    width 25%

  }
}
