.FlagsDecor {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  z-index: 10;
  background-repeat: repeat-x;
  background-size: auto 100%;

  &_foolsDay {
    background-image: url('/src/assets/FoolDay_images/flags_high.png');
    height: 19px;
    @media $tablet {
      height: 15px;
    }
  }

  &_foolsDay_big {
    height: 35px;
    background-image: url('/src/assets/FoolDay_images/flags_high.png');
    background-repeat: no-repeat;
    background-size: cover;
  }

  &_hw {
    border-top: 2px solid #048656
    background-image: url('/src/assets/Halloween_quest/decor/flags.png');
    height: 46px;
    background-position-y: -2px;
  }

  &_ny_24 {
    background-image: url('/src/assets/NY24_images/ny_decor_high.png');
    height: 19px;
    background-position-x : center;
    @media $tablet {
      height: 15px;
    }
  }

  img {
    width: 100%
  }
}
