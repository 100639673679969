.CartItem {
  & {
    position relative
    padding-top 16px
    padding-bottom 16px
    border-bottom 1px solid $color-border
  }

  &:last-child {
    margin-bottom 0
    border-bottom none
  }

  &__body {
    position relative
    display flex
    padding-right 16px
  }

  &__image {
    flex-shrink 0
    margin-right 5px
  }

  &__info {
    width 100%
  }

  &__name {
    margin-bottom 2px
  }

  &__description {
    font-size $font-size-xs
    line-height 1.33
  }

  &__remove {
    position absolute
    top 0
    right 0
  }

  &__layer {
    fullsize()
  }
}
