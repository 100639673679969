.Valentine25 {
  min-height 100vh

  padding 40px 0 60px

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  * {
    color #FFFFFF
  }

  &__title {
    font-size: 90px;
    font-weight: 700;
    text-align: center;

    @media $tablet {
      font-size: 44px;

    }

    @media $phone {
      font-size: 32px;

    }
  }

  &__subtitle {
    font-size: 32px;
    font-weight: 600;
    text-align: center;
    @media $tablet {
      font-size: 20px;
    }
    @media $phone {
      font-size: 16px;
    }
  }


  &__grid {
    display grid
    grid-template-columns 24% 1fr 24%
    align-items center
    gap 32px

    margin-top 48px

    @media $tablet {
      grid-template-columns 1fr
      gap 28px
      margin-top 40px
    }
  }

  &__card {
    position relative
    display flex
    justify-content center
    align-items center
    aspect-ratio 1
    @media $tablet {
      aspect-ratio unset
    }
    &_small {
      position relative
      padding 60px 24px 24px
      font-size: 20px;
      text-align: center;

      @media $tablet {
        font-size: 16px;
        padding 40px 20px 24px
      }

      @media $phone {
        font-size: 14px;
        padding 40px 20px 20px
      }
    }
    &_big {
      display flex
      flex-direction column
      gap 12px
      padding 40px 72px
      font-size: 24px;
      font-weight: 800;

      @media $tablet {
        padding 24px 72px
      }

      @media $phone {
        padding 12px 16px
      }

    }
    a, b {
      font-weight: 800;
      background unset
    }

    &_decor {
      &_1:after, &_3:after {
        position absolute
        content ''
        width 40%
        aspect-ratio 1
        top 0
        left 50%
        transform translate(-50%, -50%)

        @media $tablet {
          width 64px
        }

      }
      &_1:after {
        background url("./assets/card1.png") center center no-repeat
        background-size cover
      }

      &_3:after {
        background url("./assets/card3.png") center center no-repeat
        background-size contain
      }
    }
  }

  &__button {

    display block
    min-width 248px
    margin 20px auto 0
    background: #620D2F;

    @media $tablet {
      position sticky
      bottom 40px
    }

    &:active:before {
      background inherit
    }

    &_disabled {
      pointer-events none
      background #AA3664
      * {
        color #F197BB
      }
    }
  }
}
