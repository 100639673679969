.ProductList {
  position relative
  margin-bottom 40px

  @media $phone {
    margin-bottom 32px
  }

  &:last-child {
    margin-bottom 0
  }

  &__heading {
    margin-bottom 16px

    @media $phone {
      margin-bottom 10px
    }

  }

  &__list {
    display flex
    flex-direction row
    flex-wrap wrap
    margin-left -($card-margin)
    margin-right -($card-margin)

    @media $phone {
      display block
      margin-left -20px
      margin-right -20px
    }
  }

  &__item {
    display flex
    width 'calc(33.3% - %s)' % ($card-margin * 2)
    margin $card-margin

    @media $verticalTablet {
      width 'calc(50% - %s)' % ($card-margin * 2)
    }

    @media $phone {
      flex-basis auto
      width auto
      margin 0
      margin-bottom 2px

      &:last-child {
        margin-bottom 0
      }
    }

    &_closed {
      display none
    }
  }

  &__closedView {
    width 100%
    margin $card-margin

    @media $phone {
      margin 0
    }
  }
}
