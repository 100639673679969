.Header {
  width: 100%;
  height: $header-height;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1001;
  background-color: white;
  border-bottom: 3px solid $color-bg;

  &_home {
    position: absolute;
  }

  @media $tablet {
    position: static;
  }

  &__container {
    display: flex;
    align-items: center;
  }

  &__logo {
    height: 60%;

    &_regular {
      flex-shrink: 0;
      margin-right: auto;
    }

    img {
      height: 100%;
    }

    @media $tablet {
      height: 50%;
    }
  }

  &__hamburger {
    width: 50px;
    height: 50px;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .Header__hamburger-line, svg path {
      transition-duration: $duration;
    }

    &:hover {
      svg path {
        fill: $color-brand-green
      }

      .Header__hamburger-line {
        background: $color-brand-green;
      }
    }

    &-line {
      width: 20px;
      height: 3px;
      margin: 1px 0;
      display: block;
      background: $color-grey;

      &:first-child {
        margin-top: 0px;
      }

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  &__city-selector {
    flex-shrink: 0;
    margin-right: auto;
    margin-left: 50px;
  }
  &__address-section {
   margin-right: 8px
    //@media $tablet {
    //  width: 400px;
    //  left: calc(50% + 220px);
    //}
  }
}

.Header_cosmos {
  background-image: url("../../../assets/Astronaut_images/desktop.jpg")
  background-position center
  background-repeat no-repeat
  background-size cover

  @media $tablet {
    background-image: url("../../../assets/Astronaut_images/tablet.jpg")
  }


  .Header__logo {
    height auto
  }

  .Header__LangSwitcher {
    button {
      color white
    }
  }

  .Header__hamburger-line {
    background white
  }

  .Header__Nav {
    a {
      color white
    }
  }
}
