.SearchBar {
  margin-bottom: 24px;
  position: relative;

  &__field {
    width: 100%;
    height: 40px;
    padding: 8px 50px 10px 25px;
    display: block;
    border: none;
    border-radius: 20px;
    background-color: $color-bg;
    color: $color-brand-brown;
    placeholder($color-text);
    transition: background-color $duration;

    @media $phone {
      font-size: 16px;
    }

    &:focus {
      background-color: darken($color-bg, 3%);
    }
  }

  &__icon {
    margin-top: -12px;
    position: absolute;
    top: 50%;
    right: 15px;
    color: $color-grey;

    svg {
      display: block;
    }
  }
}