.SignUpForm {
  position: relative;
  overflow: scroll;
  max-height: 60vh;

  &__field-group {
    display: flex
    justify-content: space-between
    margin-top: 20px

    & input {
      border-radius: 12px

      @media $verticalTablet {
        width: 200px
      }
    }
  }

  &__field {
    margin-top: 10px

    & input {
      border-radius: 12px
    }
  }

  &__checkbox-group {
    display flex
    flex-direction column
    gap: 12px
    padding-top 12px
  }

  &__checkbox {
    display: flex
    align-items flex-start
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.02em;
    text-align: left;

  }

  &__footer {
    position: sticky;
    bottom: 0;
    background: white;
    margin-top: 20px
    display: flex
    flex-direction column
    gap: 8px

    &_button {
    }

    &_text {
      font-size: 11px
      line-height 15px
      color: rgba(189, 189, 189, 1);

      a {
        display: inline
        text-decoration underline
        color: $color-brand-green;
        font-weight 500

        //opacity 0.8

        //&:hover {
        //  opacity 1
        //}
      }
    }
  }

  &__error {
    font-size: $font-size-xs;
    line-height: 1.33;
    font-weight: 400;
    color: $color-brand-red;

    &_hidden {
      visibility: hidden
    }
  }
}
