.NavBar {
  position: relative;
  display: flex;
  align-items: center;
  height: $phone-navbar-height;
  padding-left: 20px;
  padding-right: 56px;
  border-bottom: 1px solid $color-border;

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    width: 56px;
    height: 100%;
    color: $color-grey;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -8px;
      margin-left: -8px;
    }
  }
}
