
.AlcoholWarning {
  width: 100%;
  height: 100%;
  padding: $paper-pad-x;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background-color: white;

  &__title {
    margin: auto 0;
  }
}
