.GenderPromo {
  display flex
  flex-direction column

  align-items center

  padding 32px 0 56px;

  @media $tablet {
    padding 24px 0 ;
  }

  @media $phone {
    padding 20px 0 ;
  }

  &_demo {
    filter: blur(10px)
    user-select none
  }

  &__title {
    text-align center
  }

  &__grid {
    display grid
    grid-template-areas 'left pic right'
    grid-template-columns 1fr 2fr 1fr
    max-width 100%
    column-gap 24px
    padding-top 32px

    @media $tablet {
      grid-template-areas 'pic pic' 'left right'
      grid-template-columns 1fr 1fr
      column-gap 20px
      row-gap 40px
    }

    @media $phone {
      grid-template-areas 'pic' 'left' 'right'
      grid-template-columns 1fr
      row-gap 20px

    }
  }

  &__left {
    @media $tablet {
      grid-area left
    }
  }

  &__right {
    @media $tablet {
      grid-area right
    }
  }

  &__pic {
    position: relative;
    width fit-content
    margin auto

    @media $tablet {
      grid-area pic
    }

    &_main, &_cover {
      width 100%

    }

    &_cover {
      position absolute
      top 0
      left 0
      right 0
      bottom 0

      &_0 {
        clip-path polygon(0 0)
      }

      &_1 {
        clip-path polygon(50% 0, 100% 0, 100% 50%, 50% 50%)
      }

      &_2 {
        clip-path polygon(50% 0, 100% 0, 100% 100%, 50% 100%)
      }

      &_3 {
        clip-path polygon(50% 0, 100% 0, 100% 100%, 0% 100%, 0 51%, 50% 51%)
      }

      &_4 {
        clip-path polygon(0 0, 100% 0%, 100% 100%, 0 100%)
      }
    }
  }
}
