input[type="checkbox"] {
  --active: $color-brand-green;
  --active-inner: #fff;
  --focus: 3px #fff;
  --border: #fff;
  --background: #BFB7B6;
  --disabled: #BFB7B6;
  --disabled-inner: #BFB7B6;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 28px;
  outline: none;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0;
  cursor: pointer;
  border: 1px solid var(--bc, var(--border));
  background: var(--b, var(--background));
  transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;

  &:after {
    content: "";
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
      opacity var(--d-o, 0.2s);
  }

  &:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: 0.3s;
    --d-t: 0.6s;
    --d-t-e: cubic-bezier(0.5, 0.85, 0.32, 1.2);
  }

  &:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: 0.9;

    &:checked {
      --b: var(--disabled-inner);
      --bc: var(--border);
    }
  }

  &:hover {
    &:not(:checked) {
      &:not(:disabled) {
        --bc: var(--border-hover);
      }
    }
  }

  &:focus {
    box-shadow: 0 0 0 var(--focus);
  }
}

input[type="checkbox"] {
  &.switch {
    width: 48px;
    border-radius: 50px;

    &:after {
      left: 4px;
      top: 3px;
      border-radius: 50px;
      width: 20px;
      height: 20px;
      background: var(--ab, var(--border));
      transform: translateX(var(--x, 0));
    }

    &:checked {
      --ab: var(--active-inner);
      --x: 19px;
    }

    &:disabled {
      &:not(:checked) {
        &:after {
          opacity: 0.6;
        }
      }
    }
  }
}

.CheckboxToggle {
  font-size: $font-size-m;
  font-weight: 400;
  font-family: $font-secondary;
  line-height: 20px;
  color: $color-brand-brown;
  transition: color $halfduration;
  cursor: pointer;
  user-select: none;

  &__container {
    display: flex;
    width: 333px;
    justify-content: space-between;
    align-items: center;
  }

  &_checked {
    color: $color-brand-green;
    border: 0;
  }

  &_disabled {
    pointer-events: none;
    opacity: 0.75;
  }
}







