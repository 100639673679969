.StockGoods {
  padding-top: 60px;

  &:last-of-type {
    padding-bottom: 60px;
  }

  &__heading {
    margin-bottom: 16px;
  }

  &__list {
    margin: 0 -20px;
    display: flex;
    flex-direction: column;
  }

  &__item {
    width: 100%;
    margin-bottom: 2px;

    &:last-child {
      margin-bottom: 0;
    }

    &_constructor, &_halves {
      margin: 12px 0;
    }
  }
}
