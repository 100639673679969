.PhoneField {
  position: relative;

  &__wrapper {
    position: relative;
  }

  &__flag {
    width: 16px;
    height: 16px;
    margin: auto 0;
    position: absolute;
    top: 2px;
    bottom: 0;
    left: 15px;
  }

  &__label {
    margin-bottom: 5px;
    display: block;
  }

  &__input {
    width: 100%;
    height: $inputHeight;
    padding: 12px 16px 12px 46px;
    border-radius: $input-border-radius;
    border: none;
    background-color: $color-bg;
    font-size: $font-size-m;
    color: $color-brand-brown;
    placeholder($color-grey);
    transition: background-color $duration;
  }

  &__description {
    padding-top: 8px;
    font-size: $font-size-xs;
    color: $color-text;
  }

  &_disabled {
    pointer-events: none;
    opacity: 0.75;
  }
}