.Layout {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: $color-bg;
  position: relative;

  &__header {
    width: 100%;
  }

  &__main {
    width: 100%;
    padding-top: $header-height;

    @media $tablet {
      padding-top: 0;
    }
  }

  &__footer {
    width: 100%;
    margin-top: auto;
  }

  &_theme_secondary {
    background-color: white;
  }

  &_theme_advent24 {
    background-color #241932;

    //Выбираем main, нужен только для обрезки фона пола
    & > div:first-of-type {
      overflow hidden
    }
  }

  &_theme_valentine25 {
    background url("../../scenes/Valentine25/assets/bg_val.jpg");
    background-size cover
    background-position top right
    background-repeat no-repeat
    background-attachment fixed

    @media $phone {
      background: linear-gradient(294.34deg, #FD7870 24.7%, #FF5CC3 83.95%);
      background-attachment fixed
    }
  }

  &_simple {
    .Layout__header, .Layout__footer {
      display: none;
    }
  }

  &__FlagsDecor {
    position absolute
    top: 80px;

    @media $phone {
      top: 48px;
    }
  }
}
