.OrderHistoryWidget {
  &__header {
    position: relative;
    padding: 24px 50px 24px 55px;
    cursor: pointer;
    user-select: none;
  }

  &__toggle {
    position: absolute;
    top: 50%;
    right: 15px;
    margin-top: -15px;
  }

  &__icon {
    position: absolute;
    top: 20px;
    left: 24px;

    svg {
      display: block;
    }
  }

  &__list {
    padding: 0 24px 0;

    &:last-child {
      padding-bottom: 24px;
    }
  }

  &__item {
    position: relative;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid $color-border;

    &:last-child {
      margin-bottom: 0;
      border-bottom: 0;
    }
  }

  &__header:hover &__toggle {
    color: $color-brand-green;
    transition-duration: $halfduration;;
  }

  &__empty {
    text-align: center;
  }

  &__footer {
    text-align: center;
    padding-bottom: 24px;
  }
}
