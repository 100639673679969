.BankCardList {
  display: flex;
  flex-direction: column;

  &__cards {
    display: flex;
    justify-content: center;
    overflow: auto;
    flex-wrap:wrap;
    gap: 10px;
    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  &__item {
    position: relative;

    &_editable {
      display: flex;
      flex-direction: column;
    }

    &-body {
      width: 118px;
      height: 74px;
      padding: 8px;
      flex-shrink: 0;
      position: relative;
      border-radius: 3px;
      background-color: $color-bg;
      cursor: pointer;
      user-select: none;
      transition: background-color $halfduration;

      &_selected {
        background-color: $color-brand-green-translucent;

        .BankCardList__item-check {
          opacity: 1;
        }
      }
    }

    &-delete {
      margin-top: 3px;
      align-self: flex-end;
      cursor: pointer;
      user-select: none;
      transition: color $halfduration;
    }

    &-check {
      width: 18px;
      height: 18px;
      position: absolute;
      top: 6px;
      right: 6px;
      border-radius: 2px;
      background-color: $color-brand-green;
      color: $color-brand-green-translucent;
      opacity: 0;
      transition: opacity $duration;

      svg {
        width: 18px;
        height: auto;
      }
    }

    &-type {
      position: absolute;
      top: 8px;
      left: 8px;

      &_MasterCard, &_Maestro, &_JCB, &_Visa {
        width: 39px;
      }

      &_MIR {
        width: 47px;
      }

      img {
        width: 100%;
        display: block;
      }
    }

    &-number {
      position: absolute;
      bottom: 8px;
      right: 8px;
      font-weight: 500;
      font-size: $font-size-m;
      line-height: 1;
      font-family: $font-secondary;
      color: $color-brand-brown;
    }

    &-plus {
      width: 16px;
      height: 16px;
      position: relative;
      margin: 0 auto 6px auto;
      border-radius: 100%;
      background-color: $color-grey;

      &::after, &::before {
        width: 8px;
        height: 2px;
        content: '';
        display: block;
        margin: 0 auto;
        background-color: #fff;
      }

      &::after {
        transform: translateY(5px) rotate(90deg);
      }

      &::before {
        transform: translateY(7px);
      }
    }

    &-text {
      font-family: $font-primary;
      color: $color-brand-brown;
      font-size: $font-size-xs;
      line-height: 1;
      text-align: center;
    }
  }

  &__add {
    margin-top: 20px;
    display: none;
    flex-direction: column;
    align-items: center;

    &_visible {
      display: flex;
    }
  }

  &__adder {
    margin-bottom: 10px;
  }

  &__info {
    margin-bottom: 20px;
    text-align: center;
    font-family: $font-primary;
    line-height: 1.33;
    font-size: $font-size-xs;
    font-weight: 400;
    color: $color-text;
  }
}
