.CopyCode {
  display: flex;
  align-self: flex-start;
  align-items: center;
  padding-top: 8px;
  color: $color-brand-green;
  font: bold $font-size-l / 24px $font-primary;
  text-transform: uppercase;
  cursor: pointer;

  &__copy-btn {
    margin-left: 6px;

    svg {
      width: 16px;
      height: 16px;

      path {
        fill: $color-brand-green;
      }

    }
  }
}
