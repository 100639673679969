.Wallet {

  padding 16px 35px
  background: #C2D830;
  background-image url("../assets/wallet_bg.png")
  background-size cover
  border-radius 48px
  box-shadow: 0 16px 20px 0 #00000029
  transition all $duration

  &:hover {
    transform scale(1.1)
  }


  &__collected {
    display flex
    justify-content space-between
    align-items center
    gap 8px


    &_decor {
      width 35px
      @media $phone {
        width: 25px;
      }
    }

    &_x {
      width 18px
      @media $phone {
        width: 13px;
      }
    }

    &_value {
      font-size: 40px;
      font-weight: 800;
      line-height: 1.1;
      letter-spacing: 0.02em;
      color: #D03B3B;

      @media $phone {
        font-size: 32px;
      }
    }
  }
}

.Cart {
  position relative
  overflow hidden
  border-radius 12px
  padding 24px

  &__description {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4;
    color $color-brand-brown

    @media $phone {
      font-size: 16px
    }
  }

  &__container {
    position relative
    z-index 1
    height 100%
    display flex
    flex-direction column
    gap 16px

  }

  &__cart1 {
    background: linear-gradient(353.18deg, #005531 58.99%, #009471 88.14%);


    &:before {
      content ''
      position absolute
      left -30px
      top -50px
      bottom -60px
      width 60%
      background: url("../assets/p_c_1.png");
      background-position: left center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    &__container {
      justify-content end
    }

    &__description {
      color white
      padding 40px 40px 0 50%
      margin auto

      @media $tablet {
        padding 0 0 0 50%
      }
    }

    &__button {
      @media $tablet {
        width 50%
        margin-left 50%
      }
      @media $phone {
        width 100%
        margin-left unset
      }
    }
  }

  &__cart2 {
    background #D3E764

    &:before {
      content ''
      position absolute
      left 0
      bottom 0
      transform translate(-30%, 60%)
      height 150%
      aspect-ratio 1.66
      background: url("../assets/p_c_2.png");
      background-position: left center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    &:after {
      content ''
      position absolute
      right 0
      bottom 0
      transform translate(50%, 20%)
      height 100%
      aspect-ratio 1
      background: url("../assets/p_c_2_2.png");
      background-position: left center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    &__container {
      justify-content space-between
    }
  }

  &__cart3 {
    background #A9DEE8

    &:before {
      content ''
      position absolute
      top 0
      left 50%
      transform translate(-50%, -60%)
      width 200%
      aspect-ratio 1
      background: url("../assets/p_c_3.png");
      background-position: left center;
      background-repeat: no-repeat;
      background-size: contain;

      @media $tablet {
        width unset
        left 0
        top 0
        bottom 0

        transform scale(2.5)
      }
      @media $phone {
        transform translateX(-50%) scale(1.5)
      }
    }

    &__container {
      justify-content space-between
      padding-top 50%

      @media $tablet {
        padding-top unset
        padding-left 50%
      }

      @media $phone {
        padding-left unset
      }
    }

    &__description {
      text-align center
      @media $phone {
        padding-left 50%
      }
    }
  }

  &__cart4 {
    background #FEBB3C

    &:before {
      content ''
      position absolute
      aspect-ratio 1
      background: url("../assets/p_c_4.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      top 0
      right 0
      transform translate(35%, -50%)
      height 100%

      @media $phone {
        display none
      }
    }

    &:after {
      content ''
      position absolute
      aspect-ratio 1
      background: url("../assets/p_c_4_2.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      bottom 0
      left 0
      transform translate(-35%, 50%)
      height 100%
    }


    &__container {
      justify-content end

    }

    &__description {
      margin auto
      text-align center

      @media $phone {
        text-align left
      }
    }
  }

  &__cart5 {
    background #8CC380

    &:before {
      content ''
      position absolute
      background: url("../assets/p_c_5.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      top 0
      left 16px
      right 16px
      aspect-ratio 1.78

      @media $tablet {
        height 100%
        right unset
        transform translateX(-35px)
      }

      @media $phone {
        height 40%
        right -15px
        bottom 65px
        left unset
        top unset
        transform unset
      }
    }

    &__container {
      justify-content space-between
      padding-top 50%

      @media $tablet {
        padding-top unset
        padding-left 50%
      }

      @media $phone {
        padding-left unset
      }
    }

    &__description {
      text-align center
      font-size 16px
      br {
        display none
      }
      @media $tablet {
        text-align left
        font-size 20px
      }
      @media $phone {
        font-size 16px
        white-space pre-line

        br {
          display block
        }
      }
    }
  }

  &__button {
    height 69px
    font-size: 32px;
    font-weight: 500;
    line-height: 37.5px;

    border-radius 35px

    color white

    @media $tablet {
      height: 60px;
    }
    &_disabled {
      pointer-events none
    }
  }

  &__sock {
    margin-left 19px
    width 30px
  }

}
