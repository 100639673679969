.CitySelector {
  $popupPadding-x = 40px;
  position: relative;

  &__toggle {
    display: flex;
    align-items: center;
    font-family: $font-secondary;
    color: $color-brand-brown;
    font-weight: 500;
    cursor: pointer;
    user-select: none;
    transition: color $duration;

    &:hover {
      color: $color-brand-green;
      transition-duration: $halfduration;;
    }
  }

  &__city {
    margin-right: 5px;
    text-overflow();
  }

  &__toggle-arrow {
    margin-top: -2px;
    border-style: solid;
    border-width: 6px 5px 0 5px;
    border-color: $color-brand-red transparent transparent transparent;
  }

  &__popup {
    width: 620px;
    padding: 24px $popupPadding-x 30px;
    z-index: 1001;
    display: flex;
    flex-direction: column;
  }

  &__cities {
    scrollable();
    custom-scrollbar(2px, #e6e2e1);
    margin-left: -($popupPadding-x);
    margin-right: @margin-left;
    padding-left: $popupPadding-x;
    padding-right: @padding-left - 2px;
    border-right: 4px solid transparent;
  }

}
