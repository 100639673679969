.CartPlaceholder{
  &{
    padding 0
  }

  &__img{
    width 48px
    margin-right 9px

    @media $tablet {
      width 120px
    }

    @media $phone {
      width @width
    }

    &:nth-child(4n){
      margin-right 0
    }
  }
}