.CovidField {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__button {
    position: relative;
    color: $color-grey;

    svg {
      width: 18px;
      height: auto;
      vertical-align: middle;
    }
  }

  &__tooltip {
    paper();
    width: 280px;
    padding: 20px;
    margin-bottom: $checkout-mobile-margin-10;
    position: absolute;
    bottom: 100%;
    right: -13px;
    opacity: 0;
    transition: opacity $duration;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    font-family: $font-primary;
    font-weight: 400;
    line-height: 1.33;
    color: $color-brand-brown;
    text-align: left;

    &:after {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      top: 100%;
      right: 16px;
      border-style: solid;
      border-width: 6px 6px 0 6px;
      border-color: white transparent transparent transparent;
    }

    &_entered {
      opacity: 1;
    }
  }
}
