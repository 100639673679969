.Lang {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-family: $font-secondary;
  color: $color-grey;
  transition: color $duration;
  text-transform: capitalize;

  @media $phone {
    width: 40px;
    height: @width;
    margin: 0 5px;
    border-radius: 50%;
  }

  &_active {
    color: $color-brand-green;
    cursor: default;
    order: -1;

    @media $phone {
      color: $color-brand-brown;
      background-color: $color-bg;
    }
  }

  &:not(&_active):hover {
    color: $color-brand-green;
    transition-duration: $halfduration;
  }
}
