.MobileField {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 16px;
  border-radius: 4px;
  background-color: white;

  &__label {
    flex-shrink: 0;
    margin-right: 8px;
    color: $color-text;
  }

  &__field {
    position: relative;
    flex-grow: 1;
    flex-basis: 100%;
    border: 0;
    padding: 0;
    background-color: transparent;
    font-size: $font-size-m;
    font-family: $font-secondary;
    font-weight: 500;
    text-align: right;
    color: $color-brand-brown;
  }

  /* Select */
  & select {
    text-align-last: right;
    direction: rtl;
  }
}
