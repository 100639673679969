.Popup {
  margin-top: 15px
  padding: 0 16px 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 78px;
  background: #FFFFFF;
  box-shadow: 0 0 15px rgba(37, 37, 37, 0.06);
  border-radius: 20px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #70544F;
  opacity: 0.95;

  &__left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__link {
    display: flex;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #70544F;
    opacity: 0.95;
  }

  &__icon {
    min-width: 38px;
    min-height: 38px;
    width: 38px;
    height: 38px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__text {
    margin-left: 16px;
    width: 220px;
  }

  &__button {
    justify-self: flex-end;
  }
}
