.ComboBox {
  padding: 50px 0 50px;

  &__header {
    margin-bottom: 40px;
  }

  &__body {
    position: relative;
  }

  &__subheading {
    position: relative;
    font-size: $font-size-m;
    line-height: 24px;
  }

  &__content {
    margin-right: $main-sidebar-width + 32px;

    @media $tablet {
      margin-right: 0;
    }
  }

  &__sidebar {
    width: $main-sidebar-width;
    max-height: 'calc(100vh - %s - 10px)' % $header-height;
    padding-top: 15px;
    float: right;
    display: flex;
    position: sticky;
    top: $header-height;
  }

  &__cart {
    display: flex;
    flex-basis: 100%;
    flex-grow: 1;

    @media $tablet {
      max-height: 90%;
    }
  }

  &__modalCart {
    max-width: 586px;
    display: flex;
    align-items: center;
  }
}
