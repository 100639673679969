.PizzaList {
  &__item {
    margin-bottom 8px

    @media $phone {
      margin-bottom 2px
    }

    &:last-child {
      margin-bottom 0
    }
  }
}
