.Constructor {
  $card-margin = 5px;
  padding: 50px 64px;

  &:after {
    display: block;
    content: '';
    height: 0;
    overflow: hidden;
    clear: both;
  }

  &__header {
    margin-bottom: 40px;
  }

  &__title {
    margin-bottom: 8px;
  }

  &__note {
    font-size: $font-size-xs;
  }

  &__info {
    width: 325px;
    float: right;
  }

  &__pizza-card, &__composition {
    margin-bottom: 32px;
  }

  &__main {
    margin-right: 365px;
  }

  &__ingredients {
    margin: 0 - $card-margin;
    display: flex;
    flex-wrap: wrap;
  }

  &__ingredient {
    width: 'calc(25% - %s)' % ($card-margin * 2);
    flex-shrink: 0;
  }

  &__warning {
    font-size: $font-size-xs;
    color: $color-brand-red;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__price-label {
    margin-bottom: 5px;
    color: $color-brand-brown;
    font-size: $font-size-xs;
    text-align: right;
  }
}
