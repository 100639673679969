.OrderItemAddedSauce {
  & {
    position relative
    padding-right 40px
    margin-bottom 8px
  }

  &:last-child {
    margin-bottom 0
  }

  &__gift {
    position absolute
    top -1px
    right 20px
    color $color-brand-red
  }
}
