.PasswordField {
  position relative

  input {
    padding-right 50px
  }

  &__icon {
    position absolute
    bottom 0
    right 0
    width 50px
    height $inputHeight
    cursor pointer
    user-select none
    color $color-grey
    transition color $duration

    svg {
      position absolute
      top 50%
      left 50%
      margin-top -12px
      margin-left -12px
    }

    &:hover {
      color darken($color-grey, 20%)
      transition-duration: $halfduration;
    }
  }
}
