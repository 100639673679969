.GiftsSliderFourZeroFour {
  width: calc(100% - 100px);
  max-height: 165px;
  @media $tablet{
    width: calc(100% - 32px);
  }

  &_disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &__slide {
    //margin: 0 8px;
    margin-top: 11px;
    //max-width: 345px;
    min-height: 150px;
    max-height: 161px;
    height: auto;
    padding: $checkout-desktop-margin-20 16px;
    display: flex;
    border: 4px solid $color-bg;
    border-radius: 15px;
    background-color: white;
    @media $tablet{
      margin: 0;
      margin-top: 15px;
      height: 127px !important;
      max-width: 100%;
    }

    &-image {
      width: 120px;
      height: auto;
      margin-right: 16px;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
      }
    }
  }

  :global {
    .swiper-container {
      @media $tablet{
        max-height 165px !important
      }
    }

    .swiper-button-prev,
    .swiper-button-next {

      height: 40px;
      width: 40px;
      top: calc(50% + 11px);


      &:before {
        border-color: #50a684;
      }
    }

    .swiper-button-prev-outside_disabled,
    .swiper-button-next-outside_disabled {
      &:before {
        border-color: #FFFFFF;
      }
    }
  }
}


