.NavBar {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: $phone-navbar-height;
  padding-left: $phone-margin-x;
  padding-right: $phone-margin-x;
  background-color: $color-bg;
  z-index: 1000;

  &__back {
    display: flex;
  }

  &__back-label, &__back-icon {
    display: inline-block;
  }

  &__back-icon {
    margin-right: 5px;
    color: $color-grey;

    svg {
      display: block;
    }
  }

  &__back-label {
    font-weight: 500;
    color: $color-text;
  }

  &__total {
    font-family: $font-secondary;
    font-weight: 500;
    color: $color-brand-brown;
  }
}
