.OrderPreview {
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;

  &_big {
    height: 35px;

    .OrderPreview__image {
      width: 60px;
    }

    .OrderPreview__more {
      width: 32px;
      height: 32px;
    }
  }

  &_clickable {
    cursor: pointer;
  }

  &__image {
    width: 40px;
    position: relative;
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 15px;
    }

    img {
      max-width: 100%;
      vertical-align: middle;
    }

    &_disabled {
      img {
        filter: grayscale(100%);
      }
    }
  }

  &__count {
    width: 22px;
    height: 22px;
    position: absolute;
    top: -10px;
    right: -10px;
    border-radius: 50%;
    background-color: $color-brand-green;
    border: 2px solid white;
    font-weight: bold;
    font-size: 10px;
    line-height: 16px;
    color: white;
    text-align: center;
  }

  &__gift {
    width: 18px;
    height: 18px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    color: $color-brand-red;
  }

  &__more {
    flex-shrink: 0;
    align-self: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #e6e2e1;
    color: $color-text;
    line-height: 30px;
    text-align: center;
  }
}
