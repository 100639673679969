.DeliveryAddresses {
  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__preloader {
    spinner(48px, 3px, $color-brand-green);
    margin: 0 auto;
    display: block;
  }
}
