.Checkout {
  padding: $checkout-desktop-margin-20 0 50px;
  display: flex;
  flex-direction: column;
}

.CheckoutTypeSwitcher {
  margin-bottom: $checkout-desktop-margin-20;
  display: flex;
  align-items: center;

  @media $tablet {
    flex-direction: column;
    align-items: flex-start;
  }

  &__inner {
    paper();
    margin-right: $checkout-desktop-margin-30;
    padding: $checkout-desktop-margin-30 $checkout-desktop-margin-40;

    @media $tablet {
      margin-right: 0;
      margin-bottom: $checkout-desktop-margin-20;
    }
  }

  &__types {
    width: 362px;
  }
}

.CheckoutAddress {
  paper();
  padding: $checkout-desktop-margin-40;
  margin-bottom: $checkout-desktop-margin-20;
}