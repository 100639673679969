.CreatePizzaCard {
  padding: 32px $paper-pad-x 35px;
  background-color: #fff;
  display flex
  justify-content center
  align-items center
  border-radius: 6px;

  &__container {
    display: flex;
    flex-direction: column;
    gap 8px;
    position: relative;
    text-align: center;
  }

  &__picture {
    width: 100%;
  }

  &__heading {
    flex-wrap: nowrap;
    font-family: $font-secondary;
    font-weight: bold;
    color: $color-brand-brown;
    line-height: 1.33;
    font-size: $font-size-l;
  }

  &__button {
    margin-top: 20px;
  }

  &__note {
    font-size: $font-size-m;
    line-height: 1.5;
  }

  &__PromoBorder {
    width 50%
    min-width 190px
    bottom 0
    left 50%
    transform translate(-50%, 0%)
  }
}
