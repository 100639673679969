.FeedbackForm {
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    width: 100%;
    margin-bottom: 20px;
    font-size: 18px;
    text-align: center;

    @media $phone {
      margin-bottom: 16px;
      font-size: $font-size-l;
    }
  }

  &__field {
    width: 100%;
    margin-bottom: 20px;
  }

  &__custom-field {

    div:last-child {
      border-radius: 12px;
      overflow: hidden;
      @media $phone {
        border-radius: 0;
      }
    }

    label {
      margin-bottom: 15px;
      @media $phone{
        margin-bottom: 5px;
      }
    }

    &--textarea {
      textarea:last-child {
        border-radius: 12px;
        overflow: hidden;
        @media $phone {
          border-radius: 0;
        }
      }

      label {
        margin-bottom: 15px;
        @media $phone{
          margin-bottom: 5px;
        }
      }
    }

  }

  &__error {
    font-family: $font-primary;
    font-size: $font-size-xs;
    line-height: 1.33;
    font-weight: 400;
    color: $color-brand-red;
  }

  &__image-loader {
    align-self: flex-start;
    width 100%
  }

  &__privacy {
    margin: 20px 0;
    text-align: center;
  }

  &__submit {
    align-self: flex-start;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    padding 30px 40px
    border-radius 40px

    @media $tablet {
      align-self: center;
    }
    @media $phone {
      align-self: center;
    }
  }

  &__recaptcha {
    margin-bottom 30px
    margin-top 10px
    align-self flex-start
  }
}
