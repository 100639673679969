.DeleteAddressForm {
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    font-size: $font-size-s;
    font-weight: 500;
  }

  &__actions {
    display flex
    flex-shrink 0
    align-items center
    justify-content flex-end
    margin-left -10px
    margin-right -10px

    @media $phone {
      flex-direction column
      align-items flex-start
      justify-content center
      margin-left 0
      margin-right 0
    }


    &__item {
      margin 0 10px

      @media $phone {
        display block
        width 50%
        margin-left 0
        margin-right 0
        margin-bottom 10px

        &:last-child {
          margin-bottom 0
        }
      }

    }
  }

  &__error {
    margin-top: 15px;
    text-align: center;
  }

  @media $phone {
    &__inner {
      display: block;
    }

    &__title {
      margin-bottom: 24px;
      font-size: $font-size-l;
      font-weight: bold;
      text-align: center;
    }

    &__actions {
      display: flex;
      flex-direction: row;
    }
  }
}
