.PizzaFilter__new-year {
  .PizzaFilter {
    &__wrapper {
      padding: 20px;
      background-color: white;
      border-radius: 22px;
    }

    &__express {
      margin: 0px
    }

    &__heading-wrapper {
      display: flex;
      justify-content: flex-start;
    }

    &__heading {
      margin-left: 0;
      @media screen and (max-width: 1700px) {
        margin: 0px;
        padding-top: 25px;
        padding-bottom: 20px;
      }
    }

    &__heading-img {
      @media screen and (max-width: 1700px) {
        flex-grow: 1;
        background-image: url($colorNY-filter-tablet_image);
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: top;
        background-position-x: right;
      }
    }
  }
}

.PizzaFilter {
  position: relative;

  &__heading {
    margin: 20px auto 25px;
    text-align: left;
  }

  &__tags-list {
    position relative
    display flex
    flex-wrap wrap
    align-items flex-start
    max-height auto
  }

  &__additional-wrapper {
    position: relative;
  }

  &__promoGame {
    top: -40px;
    left: 15px;
  }

  &__express {
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    align-items flex-start
    position relative


    &-button {
      margin: 6px;
      padding: 13px 22px;
      background-color: $color-bg;
      border: 1px solid #E6E2E1;
      border-radius: 20px;
      color: $color-text;
      cursor: pointer;
      transition: color $duration, border-color $duration;
      white-space: nowrap;


      &_selected {
        background-color: $color-brand-green;
        border-color: $color-brand-green;
        color: white;
      }

      &:not(&_selected):hover {
        color: $color-brand-green;
        border-color: rgba($color-brand-green, 0.2);
        transition-duration: $halfduration;
      }

      &-refresh {
        padding 11px 22px 7px
      }
    }

    &-select {
      position relative
      margin: 6px;
      padding: 13px 25px;
      padding-right 50px
      border: 1px solid #E6E2E1;
      border-radius: 20px;
      color: $color-text;
      cursor: pointer;
      transition: color $duration, border-color $duration;
      white-space: nowrap;
      background-color: rgba(255, 255, 255, 0);


      svg {
        position: absolute;
        top: 21px;
        height: 5px;
        width: 10px;
        right: 20px;
      }

      &_open {
        padding-right 25px
        border: 1px solid #50A684;
        border-radius 20px 20px 0 0;
        background white

        svg {
          position: absolute;
          top: 17px;
          height: 10px;
          width: 5px;
          right: 20px;
        }

        div:first-child {
          padding: 0;
        }

        div > div {
          padding 6px 25px

          &:first-child {
            padding 6px 25px
          }

          &:first-letter {
            text-transform: uppercase;
          }

          &:hover {
            opacity 0.7
          }
        }

      }

      &_removed {
        text-decoration: line-through;
        color: #e6e2e1;
        cursor: default;
      }

      &_select-block {
        position: absolute;
        z-index 1000
        width: auto;
        top: 44px;
        left: -1px;
        padding: 8px 0;
        border-radius: 0 0 20px 20px;
        border: 1px solid #50A684;
        color: $color-text;
        cursor: pointer;
        transition: color $duration;
        white-space: nowrap;
        background-color: white;
        opacity: 0;
        animation: animateBlock .1s linear forwards;
      }

      &_selected {
        opacity: 1;
        color: $color-brand-green;
      }
    }
  }

  &__action {
    margin-top 20px

    &_active {
      svg {
        fill $color-brand-green
      }
    }

    svg:hover {
      opacity 0.7
      fill $color-brand-green
    }

    &_invisible {
      display none
    }
  }

  &__filter {
    margin-right 20px
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #BDBDBD;
    padding-top 20px;
  }

  &__list {
    paper();
    margin-bottom: 24px;
    display: flex;
    padding: 20px 48px;
    margin-left: -15px;
    margin-right: -15px;
  }

  &__group {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
    margin-left: 15px;
    margin-right: 15px;
  }

  &__group-title {
    margin-bottom: 10px;
    font-weight: 500;
  }

  &__group-list {
    margin-left: -30px;
  }

  &__link {
    margin: 5px 0;
  }

  &__ModalBody {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
  }

  &__ModalTabs {
    width: 100%;
    overflow: hidden;
    min-height: 47px;
    max-height: 47px;
    border-bottom: 1px solid #ededed;
  }

  &__ModalTabsSwipe {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    position: relative;
    padding-bottom: 32px;
    overflow-x: auto;
  }

  &__ModalLink {
    height: 46px;
    padding: 0 12px;
    font-family: $font-secondary;
    font-weight: 500;
    line-height: 1;
    color: $color-grey;
    cursor: pointer;
    flex: 0 0 auto;

    &:first-child {
      padding-left: $phone-margin-x;
    }

    span {
      position: relative;
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;

      &:after {
        content: '';
        width: 100%;
        height: 3px;
        border-radius: 5px;
        position: absolute;
        left: 0;
        bottom: -1px;
        opacity: 0;
        background-color: $color-brand-green;
      }
    }

    &_active span {
      color: $color-brand-green;
    }

    &_active span:after {
      opacity: 1;
    }
  }

  &__ModalContainer {
    flex: 1 1 auto;
  }

  &__ModalCheckboxGroups {
    padding: 30px $phone-margin-x;
  }

  &__ModalCheckboxList {
    display: none;
    padding: 0 4px;

    &_active {
      display: inline-block;
    }
  }

  &__ModalCheckboxItem {
    display: block;
    margin-bottom: 8px;
  }

  &__ModalFooter {
    height: 48px + 50px;
    padding: 0px 20px 30px 20px;
    text-align: center;
  }

  &__priceSort {
    display: flex;
    align-items: center;
    margin-left: 47px;
    color: $color-text

    &-text {
      color: $color-text
    }

    svg {
      margin-right: 8px
    }

    &_active {
      background-color: $color-brand-green;

      span {
        color: white

      }

      &_rotate {
        svg {
          transform: rotateX(180deg);
        }
      }
    }

    &:hover {
      color: inherit
    }

    &-icon {
      padding-right: 8px;
    }
  }
}

@keyframes animateBlock {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
