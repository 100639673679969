.Halloween {
  min-height: calc(100vh - 80px);
  background-image url("./assets/bg.png")
  background-position bottom right
  background-size cover
  background-repeat no-repeat

  color white

  @media $tablet {
    background-image url("./assets/bg_tablet.png")
  }

  @media $phone {
    min-height calc(100vh - 48px)
    background-position top right
    background-size cover
    background-attachment fixed
    background-image url("./assets/bg_mobile.png")
  }

  b {
    color #FEC856
    background transparent
  }

  @media $tablet {
  }

  @media $phone {
    min-height calc(100vh - 48px)
  }

  &_blured {
    filter blur(10px)
  }

  &__container {
    padding-top 32px
    padding-bottom 32px

    @media $phone {
      padding-top 20px
      padding-bottom 20px
    }
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 32.68px;
    text-align: center;

    @media $phone {
      font-size: 20px;
      line-height: 24px;
    }
  }

  &__grid {
    display grid
    grid-template-columns 3fr 1fr
    column-gap 70px
    row-gap 28px

    margin-top 24px

    @media $tablet {
      grid-template-columns 2fr 1fr
      column-gap 20px
      row-gap 32px
      margin-top 20px
    }

    @media $phone {
      grid-template-columns 1fr
      row-gap 24px
      margin-top 16px
    }
  }

  &__grid2 {
    grid-column 1 / 3
    display grid
    grid-template-columns 3fr 1fr
    column-gap 70px
    @media $tablet {
      grid-template-columns 1fr 1fr
      column-gap 20px
      row-gap 32px
    }

    @media $phone {
      grid-column 1
      grid-template-columns 1fr
      row-gap 24px
    }
  }

  &__description, &__additionalText {
    align-self flex-end

    display flex
    flex-direction column
    gap 12px

    font-size: 16px;
    font-weight: 400;
    line-height: 20px;

    @media $tablet {
      grid-column 1 / 3
      text-align center
    }

    @media $phone {
      grid-column unset
      align-items center

      font-size: 14px;
      line-height: 20px;
    }
  }

  &__Rewards {
    @media $tablet {
      grid-column: 2;
      grid-row: 2;
    }
    @media $phone {
      grid-column unset
    }
  }

  &__Progress {
    @media $tablet {
      grid-column 1 / 3
      grid-row 3
    }
    @media $phone {
      grid-column 1
    }
  }

  &__CurrentTask {
    @media $tablet {
    }
  }

  &__additionalText {
    align-self flex-start
    @media $tablet {
      grid-column 1
      align-self center
    }
  }

  &__Collage {
  }

  &__Modal {
  }
}
