.CartItem {
  position: relative;
  padding: 16px 0;
  border-bottom: 1px solid $color-border;

  &:last-child {
    border-bottom: none;
  }

  &__body {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__image {
    width: 50px;
    margin-right: 5px;
    flex-shrink: 0;

    img {
      max-width: 100%;
    }

    &_preview {
      &:before {
        content: '';
        display: block;
        width: 40px;
        height: 40px;
        margin-right: auto;
        border-radius: 50%;
        background-color: #ededed;
      }
    }

    &_disabled {
      img {
        filter: grayscale(100%);
      }
    }
  }

  &__info {
    flex: 1;
  }

  &__name {
    margin-bottom: 2px;
  }

  &__description {
    font-size: $font-size-xs;
    line-height: 1.33;
  }

  &__sauces, &__add_sauce {
    margin: 8px -10px 0 0;
  }

  &__composition-toggle {
    display: flex;
    align-items: center;
    font-size: $font-size-xs;
    user-select: none;
  }

  &__composition-item {
    padding: 8px 0;
  }

  &__remove {
    flex-shrink: 0;
    color: $color-grey;
    transition: color $duration;
    cursor: pointer;

    svg {
      width: 10px;
      height: auto;
    }

    &:hover {
      color: $color-brand-red;
      transition-duration: $halfduration;
    }
  }

  // 
  &__footer {
    margin-top: 12px;
    display: flex;
    align-items: center;
  }

  &__amount {
    margin-left: auto;
  }

  // 
  &_gift:first-child {
    margin-top: 20px;
    border-top: 1px solid $color-border;
  }

  &__gift {
    margin-left: -17px;
    padding: 0 5px;
    position: absolute;
    top: -14px;
    left: 50%;
    background-color: white;
  }

  &_gift ~ &_gift &__gift {
    display: none;
  }

  // 
  &__loader {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
  }

  &__spinner {
    spinner(48px, 3px, $color-brand-green);
  }
}
