.ReplacementSlider {
  padding: $checkout-mobile-margin-20 0;

  &__empty {
    width: 100%;
    height: $checkout-mobile-margin-20;
  }

  &__slide {
    width: 368px;
    height: auto;
    min-height: 130px;
    display: flex;
  }

  &__wrapper {
    width: 100%;
    padding: 20px;
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 25px;
    border: 4px solid $color-bg;
    background-color: white;
  }

  &__image {
    width: 90px;
    height: auto;
    flex-shrink: 0;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;

    img {
      width: 100%;
    }
  }

  &__group {
    margin-right: 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__heading {
    margin-bottom: 10px;
    display: flex;
    align-items: flex-end;
  }

  &__name {
    display: inline-block;
    word-break: break-word;
  }

  &__description {
    display: inline;
    font-size: $font-size-s;
    color: $color-text;
    word-break: normal;
  }

  &__amount {
    font-family: $font-secondary;
    font-weight: 500;
    font-size: $font-size-m;
    line-height: 1;
  }

  &__price {
    display: inline-block;
    color: $color-brand-green-dark;
  }

  &__oldprice {
    display: inline-block;
    color: $color-brand-red-light;
    text-decoration: line-through;
  }

  &__button {
    width: 40px;
    height: 40px;
    padding: 0px;
    flex-shrink: 0;
    margin-left: auto;
    border-radius: 50%;
  }

  &__plus {
    width: 16px;
    height: auto;
    fill: white;
  }
}
