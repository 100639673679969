.Contacts {
  paper();
  margin-bottom: $checkout-desktop-margin-20;
  padding: $checkout-desktop-margin-40;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__title {
    margin-bottom: $checkout-desktop-margin-30;
  }

  &__field {
    width: 402px;

    &:not(:last-child) {
      margin-bottom: $checkout-desktop-margin-20;
    }
  }

  &__input {
    width: 100%;
  }

  &__error {
    width: 100%;
    font-family: $font-primary;
    font-size: $font-size-xs;
    line-height: 1.33;
    font-weight: 400;
    color: $color-brand-red;
  }

}

.Notifications {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}
