.ModalPhone {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  //height: calc(var(--vh, 1vh) * 100);
  z-index: 2000;
  overflow-x: hidden;
  overflow-y: scroll;
  transition: transform 700ms;
  will-change: transform;
  transform: translateY(100%);
  transition-timing-function: $easeInCubic;
  -webkit-overflow-scrolling: touch

  &::-webkit-scrollbar {
    display: none;
  }

  &__icon {
    width 50px
    height 6px
    border-radius 3px
    background white
    margin 0 auto 7px
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  &_entered {
    transition-timing-function: $easeOutCubic;
    transform: translateY(0%);
  }

  &__overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2000;
    transition: opacity 200ms;
    background: rgba(53, 53, 53, 0.6);

  }

  &__empty {
    width: 100%;
    height: 100vh;
  }

  &__content {
    border-radius: 25px 25px 0 0;
    background-color: white;
  }

  &__padding {
    padding-bottom: env(safe-area-inset-bottom, 0px);
    background-color: inherit;
  }
}


//.ModalPhone {
//  position: fixed;
//  display: flex;
//  flex-direction: column;
//  justify-content: flex-end;
//  top: 0;
//  bottom: 0;
//  left: 0;
//  right: 0;
//  z-index: 2000;
//  overflow-x: hidden;
//  overflow-y: scroll;
//  transition: transform 700ms;
//  will-change: transform;
//  transform: translateY(100%);
//  transition-timing-function: $easeInCubic;
//  -webkit-overflow-scrolling: touch;
//  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
//  //height: calc(var(--vh, 1vh) * 100);
//
//  &::-webkit-scrollbar {
//    display: none;
//  }
//
//  -ms-overflow-style: none;
//  scrollbar-width: none;
//
//  &_entered {
//    transition-timing-function: $easeOutCubic;
//    transform: translateY(0%);
//  }
//
//  &__overlay {
//    position: fixed;
//    left: 0;
//    right: 0;
//    top: 0;
//    bottom: 0;
//    z-index: 2000;
//    background-color: black;
//    opacity: 0;
//    transition: opacity 700ms;
//    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
//    height: calc(var(--vh, 1vh) * 100);
//
//    &_entered {
//      opacity: 0.5;
//    }
//  }
//
//  &__empty {
//    width: 100%;
//    height: 100vh;
//    position: absolute;
//    top: 0;
//    z-index: 100;
//  }
//
//  &__content {
//    border-radius: 25px 25px 0 0;
//    background-color: white;
//    position: relative;
//    z-index: 500;
//  }
//
//  &__padding {
//    // отступ с сейфзоны для айфонов
//    padding-bottom: 20px;
//    padding-bottom: constant(safe-area-inset-bottom, 0px);
//    padding-bottom: env(safe-area-inset-bottom, 0px);
//    background-color: inherit;
//  }
//}
