.Group {
  margin-bottom 30px

  @media $phone {
    margin-bottom 22px
  }

  &:last-child {
    margin-bottom 0
  }

  &__title {
    margin-bottom 16px

    @media $phone {
      margin-bottom 16px
      font-size $font-size-l
    }
  }

  &__list {
    column-count 3
    column-gap 20px
    margin-left -12px

    @media $phone {
      columns auto
      margin-left (- $phone-margin-x)
      margin-right (- $phone-margin-x)
    }
  }

  &__column {
    flex-grow 1
    flex-shrink 0
    flex-basis 33.3%
    padding-right 20px
  }

  &__item {
   display block
   font-size 16px
   line-height 1.33
   padding-top: 10px;
   padding-bottom: 10px;
   padding-left: calc(20px * 2);

   &:hover {
      background #e1faf4
      color #50a684
    }
  }
}
