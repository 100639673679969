.OkkoLink {
  z-index 100
  position: fixed;
  bottom: 40px;
  left: 40px;

  border-radius: 50%;
  //transition: bottom $duration;
}

.OkkoLink_mobile {
  left: 0;
}
