.PizzaCard {
  position relative

  &__image {
    margin-bottom 20px
    text-align center

    @media $phone {
      margin-bottom 10px
    }

    img {
      max-width 100%

      @media $phone {
        max-width 70%
      }

    }
  }

  &__field {
    position relative
    margin 0 20px 30px

    @media $phone {
      margin-left 0
      margin-right @margin-left
      margin-bottom 24px
    }

    input {
      width 100%
      padding-left 30px
      padding-right @padding-left
      border none
      font bold $font-size-l / 1.33 $font-secondary
      color $color-brand-brown
      text-align center
      placeholder(#d8d8d8)
    }
  }

  &__field-edit {
    position absolute
    top 0
    right 0
    color #ededed

    svg {
      display block
    }
  }

  &__selector {
    margin 0 20px

    @media $phone {
      margin-left 0
      margin-right @margin-left
    }
  }

  &__empty {
    text-align center
    font-size $font-size-xs
  }
}
