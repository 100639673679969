.ChoosePizza {
  height: auto;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 64px 0 76px;

    @media $tablet {
      padding: 36px 0 32px;
    }

    @media $phone {
      padding: 24px 0 24px;
    }

  }

  &__heading {
    text-align: center;
  }

  &__container {
    display: flex;
    align-items center;
    padding-top: 28px;

    @media $tablet {
      padding-top: 16px;
    }

    @media $phone {
      padding-top: 12px;
    }
  }


}

.sprite {
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url(./assets/choose_pizza.png);
  background-size: 300% 100%;
  aspect-ratio: 1 / 1

  &__pizza_1 {
    background-position: 50% 0
  }

  &__pizza_2 {
    background-position: 0 0
  }

  &__pizza_3 {
    background-position: 100% 0
  }

  &__size_m {
    height: 300px

    @media $tablet {
      height: 130px
    }

    @media $phone {
      height: 84px;
    }
  }

  &__size_l {
    height: 394px

    @media $tablet {
      height: 186px
    }

    @media $phone {
      height: 120px;
    }
  }
}
