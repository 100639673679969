.CommentField {
  margin-bottom: $checkout-desktop-margin-40;

  &__button {
    display: flex;
    align-items: center;
    color: $color-brand-green;
    transition: color $halfduration;
    font-size: $font-size-s;
    font-family: $font-primary;
    font-weight: 400;
    line-height: 1;
    cursor: pointer;

    &:hover {
      color: $color-brand-green-hover;
    }
  }

  &__textarea {
    width: 473px;
    margin-top: $checkout-desktop-margin-20;
  }
}
