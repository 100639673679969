.PromotionFootball {
  &__trigger {
    position fixed
    z-index 100
    left: 40px
    bottom 40px

    width 100px
    height 100px
    border-radius 50%
    cursor pointer

    transition $duration;

    @media $tablet {
      left: 20px
      bottom 4%

      width 70px
      height 70px

    }
    @media $phone {
      left: 20px
      bottom 20px

    }

    &:hover {
      background: $color-brand-green
    }

    &_hidden {
      bottom: -100px
    }

    &-image {
      width 100%
      height: 100%;
      object-fit contain
    }
  }


  &__dialog {
    position relative
    padding 78px 32px 7px;
    border-radius 15px;

    //overflow hidden
    background-image: linear-gradient(#fff 0%, #fff 70%, #12B0F3 100%);

    @media $tablet {
      max-width 100vw
      padding 124px 32px 72px;

    }
    @media $phone {
      padding 60px 20px 10px;
    }

    @media (max-height: 700px) {
      padding 60px 20px 5px;
    }

    &_content {
      display grid
      grid-template-columns repeat(2, 1fr)
      gap 20px
      position relative

      // НАВЕРНОЕ УДАЛИТЬ
      max-height 72svh;
      overflow-y auto;

      @media $tablet {
        max-height 74svh
        gap 16px
      }

      @media $phone {
        max-height: calc(100vh - 120px);
        grid-template-columns repeat(1, 1fr)
        gap 6px
      }
    }

    &_decor {
      position absolute
      bottom 0
      left 0
      right 0

      &-img {
        width 100%
        transform translateY(10px)
        object-fit cover
      }
    }
  }

  &__cart {
    padding 28px
    border-radius 12px
    background: linear-gradient(150deg, #01438A 50%, #FFFFFF 50.1%, #FFFFFF 55%, #12B0F3 55.1%);

    &-item {
      max-width 340px
    }
  }

  &__title_decor {
    position absolute
    top 0
    left 50%
    transform translate(-50%, -50%)
    height 140px

    img {
      width 100%
      height 100%
      object-fit contain
    }

    @media $tablet {
      height 175px
    }

    @media $phone {
      height 150px
      width 70vw
    }
  }

  &__mobile-modal {
    overflow-y unset !important
    overflow unset !important
  }

  &__vs {
    position absolute
    top 50%
    right 0
    transform translateX(calc(50% + 10px))
    width 90px;
    height: auto;
    pointer-events none
    z-index 20

    @media $tablet {
      width 70px
    }

    @media $phone {
      top unset
      right unset
      bottom 0
      left 0
      transform translate(50%, 90%)
    }
  }
}



