.NotAllowedCityModal {

  &__button {
    position: fixed;
    bottom: 40px;
    right: 40px;
    cursor: pointer;

    &-wrapper {
      display: flex;
      gap: 8px;
    }
  }

  &__Modal {
    background: white;
    max-width: 576px;
    border-radius: 15px;
    padding: 38px 32px 32px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    &_heading {
      font-family: Manrope;
      font-size: 22px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0.02em;
      text-align: center;
      margin-bottom: 16px;
    }

    &_text {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
      color: $color-text-gray;
      text-align: center;
      padding: 0 12px;

      &-marginTop {
        margin-top: 8px;
      }
    }
  }
}
