.BankCardList {
  display: flex;
  flex-direction: column;

  &__cards {
    display: flex;
    flex-wrap: wrap;
    margin-top: -10px;
  }

  &__item {
    margin-right: 10px;
    position: relative;

    &_editable {
      display: flex;
      align-items: flex-start;
    }

    &-body {
      width: 118px;
      height: 74px;
      padding: 8px;
      margin-top: 10px;
      position: relative;
      border-radius: 3px;
      background-color: $color-bg;
      cursor: pointer;
      user-select: none;
      transition: background-color $halfduration;

      &_selected {
        background-color: $color-brand-green-translucent;

        .BankCardList__item-check {
          opacity: 1;
        }
      }

      &:not(&_selected):hover {
        background-color: $color-brand-green-translucent;
      }
    }

    &-delete {
      margin-top: 10px;
      color: $color-border;
      cursor: pointer;
      user-select: none;
      transition: color $halfduration;

      svg {
        width: 24px;
        height: auto;
      }

      &:hover {
        color: $color-brand-red;
      }
    }

    &-check {
      width: 18px;
      height: 18px;
      position: absolute;
      top: 6px;
      right: 6px;
      border-radius: 2px;
      background-color: $color-brand-green;
      color: $color-brand-green-translucent;
      opacity: 0;
      transition: opacity $duration;

      svg {
        width: 18px;
        height: auto;
      }
    }

    &-type {
      position: absolute;
      top: 8px;
      left: 8px;

      &_MasterCard, &_Maestro, &_JCB, &_Visa {
        width: 39px;
      }

      &_MIR {
        width: 47px;
      }

      img {
        width: 100%;
        display: block;
      }
    }

    &-number {
      position: absolute;
      bottom: 8px;
      right: 8px;
      font-weight: 500;
      font-size: $font-size-m;
      line-height: 1;
      font-family: $font-secondary;
      color: $color-brand-brown;
    }

    &-plus {
      width: 16px;
      height: 16px;
      position: relative;
      margin: 0 auto 6px auto;
      border-radius: 100%;
      background-color: $color-grey;

      &::after, &::before {
        width: 8px;
        height: 2px;
        content: '';
        display: block;
        margin: 0 auto;
        background-color: #fff;
      }

      &::after {
        transform: translateY(5px) rotate(90deg);
      }

      &::before {
        transform: translateY(7px);
      }
    }

    &-text {
      font-family: $font-primary;
      color: $color-brand-brown;
      font-size: $font-size-xs;
      line-height: 1;
      text-align: center;
    }
  }

  &__add {
    margin-top: 20px;
    display: none;
    flex-direction: column;

    &_visible {
      display: flex;
    }
  }

  &__adder {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__info {
    margin-bottom: 20px;
    font-family: $font-primary;
    line-height: 1.33;
    font-size: $font-size-s;
    font-weight: 400;
    color: $color-text;
  }

  &__actions {
    align-self: flex-end;
  }

  &__cancel {
    margin-right: 20px;
  }
}
