.NavBar {
  width: 100%;
  height: $phone-navbar-height;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;

  &_border {
    box-shadow: 0px 0px 5px rgba(32, 38, 55, 0.08);
    border-radius: 0px 0px 20px 20px;
  }

  &__title {
    text-align: center;
  }

  &__back {
    width: 15%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &-icon {
      width: auto;
      height: 15px;
    }
  }

  &__children {
    width: 15%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
