.BonusesSwitcher {
  paper();
  padding: 30px $checkout-desktop-margin-40;
  align-self: flex-start;

  &__inner {
    height: $checkout-desktop-button-height;
    display: flex;
    justify-content: space-between;
    position: relative;
    background: $color-bg;
    border-radius: $checkout-desktop-button-radius;
  }

  &__selector {
    width: 50%;
    height: 100%;
    padding: $checkout-desktop-button-padding;
    background: $color-brand-green;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: $checkout-desktop-button-radius;
    transition: transform $duration;
    transform: translateX(0);

    &_pickup {
      transform: translateX(100%);
    }
  }

  &__item {
    flex: 1;
    height: 100%;
    padding: $checkout-desktop-button-padding;
    position: relative;
    color: $color-brand-brown;

    &_active {
      color: white;
    }
  }

  &__subtitle {
    font-family: $font-primary;
    font-weight: 500;
    font-size: $font-size-m;
    line-height: 1.33;
    color: inherit;
  }
}
