.OrderItem {

  &__inCompetition {
    position: absolute;
    top: 0;
    padding: 4px 18px 4px 8px;
    border-top-right-radius: 4px
    border-bottom-right-radius: 4px
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: white;
    background-color: $color-brand-red-light

    &:after {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      border-radius: 2px;
      height: 100%;
      aspect-ratio: 1;
      background-color: #fff;
      transform: translateX(17px) rotate(45deg);
    }
  }

  &__preview {
    width: 100%;
    margin-bottom: 16px;
  }

  &__info {
    width: 100%;
    margin-bottom: 16px;
    font-size: $font-size-xs;
  }

  &__header {
    width: 100%;
    margin-bottom: 2px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__date {
    font-family: $font-secondary;
    font-weight: bold;
  }

  &__description {
    color: $color-brand-brown;
  }

  &__actions {
    width: 100%;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__status {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: $font-size-xs;
    font-weight: bold;
    text-align: center;
    color: $color-brand-green;

    & svg {
      margin-bottom: 2px;
    }
  }

  &__rating {
    height: 24px;
    display: flex;
    align-items: flex-start;
  }

  &__rating-button {
    font-size: $font-size-xs;
    color: $color-brand-green;
  }

  &__star {
    width: 14px;
    height: 14px;
    margin: 0 2px;
    fill: rgb(203, 211, 227);
    transition: fill 0.2s ease-in-out;

    &_active {
      fill: rgb(253, 215, 164);
    }

    &_hovered {
      cursor: pointer;

      &:hover {
        fill: rgb(253, 215, 164);
      }
    }
  }

}
