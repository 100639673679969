.CVForm {
  width 100%
  padding-right 12px;
  padding-left 12px;
  &__groupTitle {
    grid-column 1 / 4
    font-size: 22px;
    font-weight: 400;
    line-height: 28px;
    padding-top 32px;
    padding-bottom 20px;
    color $color-brand-brown
    @media $tablet{
      grid-column 1
      padding-top 28px;
      padding-bottom 16px;
    }
    @media $phone{
      padding-top 24px;
      padding-bottom 12px;
      padding-left 12px;
      padding-right 12px;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
    }
  }
  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align center
    @media $phone{
      font-size: 18px;
      line-height: 24px;
    }
  }
  &__subtitle {
    padding-top -12px
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align center
  }
  &__pleaseLogin {
    font-weight: 700;
    font-size: 18px;
    text-align center
  }
  &__formContainer {
    display flex
    align-items center
    justify-content center
    flex-direction column
    padding-left 12px
    padding-right 12px
  }
  &__inputsContainer {
    width: 100%;
    display flex
    //align-items center
    justify-content center
    gap 16px
    @media $tablet{
      flex-direction column
    }
    @media $phone{
      flex-direction column
    }
  }
  &__label {
    flex-grow 1
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    padding-bottom 15px;
    color $color-brand-brown
    &_secondary {
      font-size: 16px;
      line-height: 28px;
      padding-bottom 20px
    }
    &_error {
      color $color-brand-red
    }
  }
  &__formItem {
    display flex
    flex-direction column
  }
  &__button {
    display block
    margin-top 24px
    margin-inline auto
    width: 365px
    @media $tablet{
      width: 100%
    }
    @media $phone{
      width: 100%
    }
  }
  &__field {
    border-radius 20px
    background: white
    width: 402px
    @media $tablet{
      width: 100%
    }
    @media $phone{
      width: 100%
    }
    label {
      padding-left 10px
    }
    &_error {
      color $color-brand-red
      width: 400px;
      white-space: normal;
      word-wrap: break-word;
      @media $phone{
        width: 300px;
      }
    }
  }
}
