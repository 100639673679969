.Actions {
  display flex
  align-items center
  justify-content space-between
  margin-left -10px
  margin-right -10px

  @media $phone {
    flex-direction column
    align-items flex-start
    justify-content center
    margin-left 0
    margin-right 0
  }

  &__item {
    margin 0 10px
    flex-grow 1

    @media $phone {
      display block
      width 100%
      margin-left 0
      margin-right 0
      margin-bottom 10px

      &:last-child {
        margin-bottom 0
      }
    }

  }

}
