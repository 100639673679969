.HomePage__new-year {
  padding-bottom: 0 !important;
  background-image: url($colorNY-bg);
  background-repeat: repeat;
  background-size: contain;
  position: relative;

  .HomePage {

    &__header {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: calc(70px + 25 * ((100vw - 1500px) / 920));
      z-index: 10;
      background-image: url($colorNY-slider-header);
      background-repeat: no-repeat;
      background-size: cover;

      @media $phone {
        height: calc(100px + 25 * ((100vw - 1500px) / 920));
        background-image: url($colorNY-slider-header_mobile);
        background-size: contain;
      }
    }

    &__container {
      @media screen and (min-width: 1701px) {
        min-height: 100vh;
        padding-bottom: 80px;

        background-image: url($colorNY-bg-pictures);
        background-repeat: repeat-y;
        background-size: contain;
      }
    }
  }
}

.HomePage {
  padding-bottom: 80px;
  position: relative;

  &__content {
    margin-right: $main-sidebar-width + 32px;
    transition: opacity 0.3s;
    opacity: 1;

    &_hidden {
      opacity: 0;
    }

    &_disableMainSideBar {
      margin-right: 0;
    }
  }

  &__PopupPromoGame {
    position: fixed;
    left: 96px;
    bottom: 30px;
    @media $tablet {
      left: 19px;
    }
  }

  &__promoGame-artefact {
    right: 7px;
    top: 20%;
    transform: rotate(-90deg);
    height: 90px;

    &_mm {
      position: fixed
      height: 159px
      top: 85%;
      transform: rotate(-45deg) translate(32px)
    }
  }
}

.ModalVpn {
  &__popupMobileGeneral {
    margin-top: 15px;
    padding: 0 20px;
    position: fixed;
    z-index: 1000;
    top: 0;
    width: 100%;
    height: 78px;

    &_desktop {
      padding: 0 20px;
      position: fixed;
      z-index: 1000;
      bottom: 30px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 361px;
      height: 84px;
    }

    &_icon {
      min-width: 40px;
      min-height: 40px;
      heigth: 40px;
      width: 40px;
    }
  }
}
