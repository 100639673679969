.QuizCard {

  display flex
  flex-direction column
  justify-content space-between
  gap 16px

  padding 24px
  border-radius 24px
  background-image url("./assets/desktop_bg.png")
  background-repeat no-repeat
  background-position bottom right
  background-size cover

  color white

  @media $tablet {
    background-image url("./assets/tablet_bg.png")
  }

  @media $phone {
    padding 12px
    background-image url("./assets/mobile_bg.png")
    background-position top right
    background-size unset
    border-radius 20px

  }

  &__text_1 {
    width calc(100% - 80px)

    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    @media $tablet {
      width calc(100% - 55px)

      font-size: 16px;
      line-height: 20px;
    }

    @media $phone {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__text_2 {
    font-family: Roboto;
    font-size: 44px;
    font-weight: 900;
    line-height: 48px;
    text-align: left;
    @media $tablet {
      font-size: 32px;
      line-height: 36px;
    }

    @media $phone {
      font-size: 20px;
      line-height: 22px;
    }
  }

  &__button {
    background #FF055C
    width calc(100% - 80px)
    @media $tablet {
      width 100%
    }

    &:hover, &:active:before {
      background #FF055C

    }
  }


  &__tablet_visible {
    display none
    @media $tablet {
      display block

    }
  }
}
