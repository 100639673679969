.HalfSelect {
  & {
    position: relative;
  }

  &__side {
    margin-bottom: 8px;
    font-size: $font-size-s;
    font-family: $font-secondary
    font-weight: 400;

    @media (orientation: landscape) and (max-width: 1024px) {
      margin-bottom: 0;
    }

    @media $verticalTablet {
      margin-bottom: 0;
    }
  }

  &__name {
    font-size: $font-size-m;
    font-family: $font-secondary
    font-weight: 500;
    max-width: 180px;
    color: #e6e2e1;

    @media (orientation: landscape) and (max-width: 1024px) {
      font-size: $font-size-xs;
      line-height: 24px;
    }

    @media $verticalTablet {
      font-size: $font-size-xs;
      line-height: 24px;
      max-width: 280px;
      text-overflow();
    }
  }

  &_side_left {
    padding-right: 10px;
  }

  &_side_right {
    padding-left: 10px;
    text-align: right;
  }

  &_selected &__name {
    color: $color-brand-brown;
  }
}
