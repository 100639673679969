.MobileAppButton {
  width: auto;
  height: 40px;
  flex-shrink: 0;

  img {
    width: auto;
    height: 100%;
    display: block;
  }
}
