.HalvesCard {
  padding: 32px $paper-pad-x 35px;
  background-color: #fff;
  display flex
  justify-content center
  align-items center
  border-radius: 6px;

  &__heart {
    position absolute;
    bottom 0;
    left 0;
  }

  &__vk {
    position absolute;
    top 12px;
    z-index 100;
  }

  &__imageContainer {
    position: relative;

    &_match {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap 8px;
    position: relative;
    text-align: center;
    justify-content: space-between;
    align-items: center;
  }

  &__picture {
    width: calc(100% + 42px);
    margin-left: -18px;
    margin-right: -24px;


  }

  &__heading {
    flex-wrap: nowrap;
    font-family: $font-secondary;
    font-weight: bold;
    color: $color-brand-brown;
    line-height: 1.33;
    font-size: $font-size-l;
    text-align center;
  }

  &__button {
    margin-top: 20px;
  }

  &__note {
    font-size: $font-size-m;
    line-height: 1.5;
  }

  &__cosmo_decor {
    position absolute
    right 0
    top -45px
  }

  &__PromoBorder {
    width 50%
    min-width 190px
    top 0
    left 50%
    transform translate(-50%, 0%)
  }

  &__PaddingtonDecor {
    height 80%
    bottom 0
    right 0
  }
}
