.PopupPromoGame.PopupPromoGame_mm {
  background: linear-gradient(104.04deg, rgba(173, 117, 76, 1) 3.51%, rgba(112, 84, 79, 1) 85.65%);
  height: 152px
  opacity 1

  @media $phone {
    height: 124px
  }

  * {
    color: white

    @media (max-width: 365px) {
      font-size: 12px
    }
  }

  .PopupPromoGame {
    &__decor_mm {
      background-position: top left;
      background-repeat: no-repeat
    }

    &__img {
      &_mm {
        align-self: flex-end
        height: 100%;
        width: 27%;
        min-width: 92px
        object-fit: cover;

        &_small {
          object-fit: contain;
        }
      }
    }

    &__right {
      gap: 16px;
      @media $phone {
        padding 20px 16px 30px 8px;
      }

      @media (max-width: 365px) {
        gap: 8px;
      }
    }

    &__text {
      margin-right 30px
      @media $phone {
        margin-right 20px
      }
    }

    &__progress {

      & > div {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-gap: 24px;

        @media (max-width: 365px) {
          grid-template-columns: 1fr;
          grid-gap: 8px;
        }
      }

      &-bar {
        @media (max-width: 365px) {
          grid-row: 2 !important
        }

      }

      &-count {
        grid-column: 2;

        @media $phone {
          font-size: 14px
        }
        @media (max-width: 365px) {
          grid-column: unset;
          grid-row: 1
          text-align end
        }

        &_passed {
          color: #CFA86D

        }
      }

      &-bar {
        overflow visible;
        position: relative;
        grid-column: 1;
        grid-row: 1;
        //margin-top: unset;
        background-color: #452B25

        &::before {
          position: absolute;
          content: '';
          height: 2px;
          top: 50%;
          transform: translateY(-50%);
          width: 100%;
          background-color: #67502E
        }


        &__hw-decor {
          position absolute
          left -10px
          //top 0
          right -10px
          height: 100%;
          display: flex;
          justify-content: space-between;
          align-items center

          &_item {
            position relative
            width calc(20% - 10px)
            min-width 35px
            aspect-ratio 1
            background-size cover
            background-position bottom center
            background-repeat no-repeat
            //@media $phone {
            //  width 35px
            //  height 35px
            //}

            &_done {
              &::after {
                position absolute;
                right: 0;
                top: 0;
                content: '';
                width 50%;
                height 50%;
                background-size: cover;
                background-image: url("../assets/mm_game/checkpoint_done.png")
                //@media $phone {
                //  //width 18px;
                //  //height 18px;
                //}
              }
            }
          }
        }
      }
    }

    &__content {
      &_mm {
        flex-direction: column;
        justify-content: space-around;
      }
    }

    &__copy-btn {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(201%) contrast(120%) //*{
    }
  }

}

.PopupPromoGame.PopupPromoGame_hw {
  background: #0F4934;
  height: 152px
  opacity 1

  * {
    color: white
  }

  .PopupPromoGame {
    &__decor_hw {
      background: url('/src/assets/Halloween_quest/decor/flags_modal.png')
      background-position: top left;
      background-repeat: no-repeat

    }

    &__content_hw {
      flex-direction: column;
      justify-content: space-around;
      padding-left: 160px
      @media $phone {
        padding-left: 130px;
      }
    }

    &__text {
      flex-grow unset
    }

    &__button {
      svg, path {
        fill white
      }
    }

    &__progress {

      & > div {
        flex-direction row-reverse
        align-items baseline
        gap 24px
      }


      &-count {
        flex-shrink 0

        &_passed {
          color: #FFCE00;
        }
      }
    }

    &__progress-bar {
      position relative
      background-color: #FFCE00;
      overflow visible

      &_success {
        background-color: #FFCE00
      }

      &__hw-decor {
        position absolute
        left -10px
        //top 0
        right -10px
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items center

        &_item {
          position relative
          width 50px
          height 50px
          background-image url("/src/assets/Halloween_quest/icons/checkpoint.png")
          background-size cover
          @media $phone {
            width 35px
            height 35px
          }

          &_done {
            background-image url("/src/assets/Halloween_quest/icons/checkpoint_done.png")
          }
        }
      }
    }
  }
}

.PopupPromoGame {
  z-index: 10000;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 120px;
  width: 600px;

  background: $color-green-light;
  box-shadow: 0 0 15px rgba(37, 37, 37, 0.15);
  border-radius: 8px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #70544F;
  opacity: 0.95;
  transition: all 1s ease-in-out;

  &_hidden {
    transform: translateY(200px)
  }

  @media $phone {
    width: calc(100% - 2 * 19px);
    height: 96px;

  }

  &__wrapper {
    position: relative
    height: 100%;
    width: 100%;
  }

  &__content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width 100%;
  }

  &__container {
    display: flex;
    flex-grow 1;
    justify-content: flex-start;
    align-items: center;
    height 100%;

    &_column {
      flex-direction column;
    }
  }

  &__img {
    height 100%
  }

  &__right {
    padding 20px 24px 24px 15px;
    @media $phone {
      padding 20px 16px 16px 8px;
    }
  }

  &__text {
    flex-grow: 1;
    align-self: flex-start
    margin-right: 15px
    color: $color-brand-brown
    font: bold $font-size-l / 24px $font-secondary
    white-space: pre-line

    @media $phone {
      font-size: $font-size-s
      line-height: 16px
    }

    svg {
      width: 20px;
      height: auto;
      margin-left: 15px;
      transform: translateY(2px);

      @media $phone {
        width: 12px;
        margin-left: 5px;
      }
    }
  }


  &__progress {
    width 100%
  }

  &__progress-count {
    align-self: flex-end;
    color: $color-brand-brown
    font: bold $font-size-l / 28px $font-secondary;
    @media $phone {
      font-size $font-size-m
      line-height 20px
    }

    span {
      color: $color-brand-green
    }

  }

  &__progress-bar {
    margin-top: 3px;
    display flex;
    width 100%
    overflow hidden
    height 8px
    border-radius 30px
    background-color $color-border

    &_success {
      background-color: $color-brand-green;
      transition: all 1s ease-in;
    }
  }

  &__button {
    position absolute;
    top 13px;
    right 13px;
    padding: 20px;
    margin: -20px;
  }

  &__success {
    display: flex;
    align-self: flex-start;
    align-items: center;
    padding-top: 8px;
    color: $color-brand-brown;
    font: bold $font-size-l / 24px $font-primary;
    text-transform: uppercase;
    cursor: pointer;
  }

  &__copy-btn {
    margin-left: 6px;

    svg {
      width: 16px;
      height: 16px;
      filter: invert(61%) sepia(52%) saturate(362%) hue-rotate(105deg) brightness(86%) contrast(86%);
    }
  }
}
.PopupPromoGame_hw24{
  height 86px
  z-index 10
}

.PopupPromoGame.PopupPromoGame_ky {
  background: linear-gradient(0deg, #70544F, #70544F),
    linear-gradient(104.04deg, rgba(83, 41, 30, 0.7) 3.51%, rgba(149, 97, 86, 0) 85.65%);

}
