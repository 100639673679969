.PapaBonus {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: $color-brand-green;

  &__info {
    width: 18px;
    height: 18px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__white {
    padding: 10px 16px 20px 16px;
    display: flex;
    flex-direction: column;
    background-color: white;

    &_borders {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }

  &__subtitle {
    margin-bottom: 15px;
    font-family: $font-secondary;
    font-weight: 500;
    font-size: $font-size-s;
    line-height: 1.33;
    color: $color-brand-brown;

    &_greenTitle {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #50A684;
      opacity: 0.9;
    }

    &_container2 {
      margin-top: 30px;
    }
  }

  &__articles {
    display: flex;

    &-line {
      width: 4px;
      height: auto;
      flex-shrink: 0;
      margin: 0.5em 20px 0.5em 0;
      background: $color-brand-green;
      border-radius: 10px;
    }

    &-content {
      display: flex;
      flex-direction: column;
    }
  }

  &__article {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &-title {
      margin-bottom: 5px;
      position: relative;
      font-family: $font-primary;
      font-weight: 500;
      font-size: $font-size-s;
      line-height: 1.33;
      color: $color-brand-brown;

      &:before {
        content: '';
        width: 10px;
        height: 10px;
        background-color: $color-brand-green;
        position: absolute;
        top: calc(50% - 5px);
        left: -27px;
        border-radius: 50%;
      }
    }

    &-description {
      &-title {
        font-family: $font-primary;
        font-weight: 500;
        font-size: $font-size-s;
        line-height: 1.33;
        color: $color-text;
      }
    }
  }

  &__bonuses {
    height: 42px;
    margin-top: 20px;
    padding: 9px 20px;
    background: $color-bg;
    border-radius: 20px;
    align-self: center;
    font-size: $font-size-s;

    svg {
      margin-right: 10px;
    }
  }

  &__gifts {
    padding: 20px 16px;
  }

  &__slider {
    align-items: center;
  }

  &__accordion {
    height: 61px;
    width: 343px;
  }

  &__workContainer {
    display: flex;
    flex-direction: column;
    margin-top: -25px;
    width: 100%;
    padding: 35px 40px 40px 30px;
    background: #f8f8f8;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  &__itemContainer {
    display: flex;
    margin-top: 14px;
  }

  &__greenText {
    font-family: 'Roboto';
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.02em;
    font-weight: 500;
    color: #50A684;
  }

  &__itemText {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #70544F;
  }

  &__greenText2 {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #50A684;
  }

  &__greenText3 {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #50A684;
  }
  &__programmContainer {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #70544F;

    &_text {
      margin-top: 14px;
    }
  }
}
