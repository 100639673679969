.AdultModal {
  max-width 400px

  &__wrapper {
    display flex
    flex-direction column
    align-items center
    justify-content center
  }

  &__title {
    margin-top 26px
  }
  &__subtitle {
    margin-top 12px
  }

  &__buttons {
    margin-top 26px
    display flex
    justify-content space-between
    gap 12px
  }
}
