.TextareaField {
  position: relative;

  &__label {
    display: block;
    margin-bottom: 5px;
  }

  &__field {
    display: block;
    width: 100%;
    min-height: 142px;
    padding: 12px 16px;
    border-radius: $input-border-radius;
    border: none;
    border-right: 2px solid transparent;
    background-color: $color-bg;
    font-size: $font-size-m;
    color: $color-brand-brown;
    placeholder($color-grey);
    scrollable();
    custom-scrollbar(2px, #e6e2e1);
    transition: background-color $duration;

    &:focus {
      background-color: $color-bg-hover;
    }
  }
}
