.AddressButton {
  width: 100%;

  &_active {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  &_inactive {
    width: 100%;
    padding: 13px 26px;
    margin-bottom: 15px;
  }

  &__line {
    margin-right: 15px;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-brand-brown;
    white-space: nowrap;

    &:last-child {
      margin-right: 0px;
    }

    &_medium {
      width: 100%;
      padding: 15px;
      margin-right: 0;
      border-radius: 35px;
      font-weight: 500;
      background-color: white;
    }
  }

  &__span {
    text-overflow();
    text-overflow: ellipsis;
    direction: rtl;
    text-align: end;
  }

  &__icon {
    width: 20px;
    height: auto;
    flex-shrink: 0;

    &_whitepin, &_redpin {
      margin-right: 10px;
    }
  }

  &__border {
    padding: 9px;
    margin-right: 10px;
    display: flex;
    background-color: white;
    border-radius: 50%;
  }
}
