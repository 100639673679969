.SuccessMessageModal {
  paper();
  width: 258px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;

  &__img {
    width: 30px;
    height: 30px;
    margin-bottom: 15px;
  }



  &__header {
    font-size: 14px
    font-weight: 500
    font-family: $font-secondary
    color: rgba(112, 84, 79, 1)
    text-align: center

  }


  &__subtitle {
    margin-bottom: 20px;
    text-align: center;
  }


  &__button {
    padding: 13px 30px;
  }

}
