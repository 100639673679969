.promotion {
  position: relative;
  color: white;
  background: linear-gradient(294.34deg, #000000 2.22%, #1D044C 12.93%, #648AD2 24.56%, #76AAF5 28.09%, #709FE9 31.38%, #648AD2 33.68%, #4409B2 44.68%, #29066C 54.51%, #150337 62.03%, #000000 76.24%);
  padding-left: 24px;
  padding-top: 24px;
  overflow: hidden;
}

.box {
  position: absolute;
  bottom: 70px;
  right: 350px;
}

.pizza {
  position: absolute;
  left: 0;
  bottom: 300px;
}

.boxContainer {
  padding-top: 24px;
  padding-right: 24px;
}

.pic {
  width: 600px;
  height: 600px;
  position: absolute;
  bottom: 0
  right: -10px;
}

.pizza1 {
  width: 300px;
  height: 300px;
  position: absolute;
  right: -50px;
  bottom: 300px;
}

.pizza2 {
  width: 300px;
  height: 300px;
  position: absolute;
  right: 50px;
  bottom: 50px;
}

.pizza3 {
  width: 300px;
  height: 70px;
  position: absolute;
  right: 50px;
  top: 0;
}
