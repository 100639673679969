.PizzaContainer {
  display flex
  flex-direction column
  align-items center
  gap 26px

  &__button {
    min-width 248px

    @media $phone {
      width 100%
    }

    &_wait {
      pointer-events: none;
      background-color: #70544F;
      color: white;

      span {
        padding-inline 8px
        color #E2475A
      }
    }
  }
}

.Pizza {
  width 100%
  position relative
  display flex
  align-items center
  justify-content center

  aspect-ratio 1
  background url("../assets/turn_arrows.svg") no-repeat center center
  background-size contain

  &::before {
    content ''
    background url("../assets/turn_word.png")
    background-size contain
    background-repeat: no-repeat;

    position absolute
    left 0
    top 50%
    height: 25%;
    width: 100%;
    transform translate(-24%, 84%) rotate(45deg)

    @media $tablet {
      transform translate(-1%, -50%)

    }
  }

  &__image {
    width 93%
    aspect-ratio 1

    background url("../assets/pizza.png") no-repeat center center
    background-size contain
  }

}


.Rewards {

  &__title {

  }

  &__container {

    margin-top 12px

    display grid
    grid-template-columns 1fr
    gap 8px

    @media $tablet {
      grid-template-columns repeat(2, 1fr)
      column-gap 16px
    }

    @media $phone {
      grid-template-columns 1fr

    }
  }


}

.RewardItem {
  display: flex;
  flex-direction column;
  gap 12px

  padding 12px

  background: #5F312AB2;
  backdrop-filter: blur(4px)

  border-radius 12px


  &__date {
    font-weight: 400;
    font-size: 12px;
  }

  &__text {

    font-weight: 700;
    font-size: 16px;

    span {
      display block
      padding-top 4px
      font-weight: 400;
      @media $phone {
        display inline
        padding-left 8px
      }
    }

    b {
      font-weight: 700;
      background unset
      color: #1AB779;

    }
  }

  &__more {
    cursor pointer
    font-weight: 400;
    font-size: 16px;
    color: #1AB779;
  }


}


.Modal {
  max-width 400px
  background #FFDE72 !important

  &__wrapper {
    display flex
    flex-direction column
    align-items center
    justify-content center
    gap 12px

    padding-top 30px
  }

  &__image {
    width 228px
    height 164px
    border 5px solid white
    background-repeat: no-repeat;
    background-position: center center;
    background-size contain

  }

  &__img {
    width 228px
    height auto

    margin-top -50%
    transform rotate(-8deg)

    object-fit contain
    border 5px solid white
  }

  &__content {
    color #70544F

    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    text-align: center;

  }

  &__code {
    background: white;
    padding: 4px 12px !important;
    border-radius: 40px;
    align-self: unset !important;
  }
}
