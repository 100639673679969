.ModalCityRedirect {
  position: relative;
  padding: 72px 30px 60px;
  max-width: 576px;
  display: flex;
  flex-direction column
  align-items: center;
  background #FFFFFF
  border-radius 15px;

  @media $phone {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  @media (max-width: 370px) {
    padding: 52px 20px 40px;
  }

  &__open {
    height: auto;
    min-height: 351px;
  }

  &__title {
    font-weight: 400;
    font-size: 22px;
    line-height: 1.3;
    width 70%;
    text-align: center;
    padding-bottom: 28px;

    @media $phone {
      width 100%;
    }

  }

  &__description {
    font-family: $font-primary;
    font-size: $font-size-s;
    color: $color-grey;
    padding: 0 20px 36px;
    text-align: center;

  @media (max-width: 370px) {
      padding: 0 0px 18px;
    }
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    gap: 15px;

  @media (max-width: 370px) {
      grid-template-columns: 1fr;
    }
  }

  &__button {

    &-secondary {
      background-color: $color-grey;
      color: white;
      transition: background-color $halfduration, border-color $halfduration, color $halfduration;

    }
  }

  &__modal-icon {
    margin: 0 auto;
    height: 6px;
    border-radius: 3px;
    background: #fff;
    width: 70px;
    position: absolute;
    top: -15px;
  }
}
