.RestRegion {
  display flex
  flex-direction column
  gap 20px
  padding 20px 0

  &_buttons {
    display flex
    justify-content space-between
    align-items center
  }

  &_back {
    cursor pointer


    &:hover > * {
      color: $color-brand-green;
      transition: color .2s;
    }
  }

  &_title {
    align-self center
  }

  &_decription {
    font-size: $font-size-m;
    font-weight normal
    color $color-brand-brown
  }

  &_upsale {
    margin-bottom 0 !important
  }

  &_subtitle {
    margin-bottom 24px
    @media $tablet {
      margin-bottom 12px
    }
  }

  &_restList {
    display grid
    grid-template-columns repeat(3, 1fr)
    column-gap 20px
    row-gap 24px

    @media $tablet {
      grid-template-columns repeat(2, 1fr)
    }
    @media $phone {
      grid-template-columns repeat(1, 1fr)
    }
  }

  &_Map {
    border-radius 25px
    overflow hidden

    @media $tablet {
      border-radius unset
      overflow unset
    }
  }
}
