.Landing {

  &__header {
    font-size: 24px
    font-weight: 700
    font-family: $font-secondary
    color: rgba(112, 84, 79, 1)
    text-align: center
    margin-top: 37px
  }

  &__content {
    display: flex
    justify-content: space-between
    margin-top: 50px
    margin-bottom: 50px
  }

  &__content-left {
    width: 534px
    height: 623px

    @media $verticalTablet {
        width: 450px
    }

    &_text {
      font-size: 16px
      font-weight: 500
      font-family: $font-secondary
      color: rgba(112, 84, 79, 1)
      padding-bottom: 20px
    }
  }

  &__content-picture {

    & img {
      width: 623px
      height: 623px
      border-radius: 100px
      filter: drop-shadow(0px 0px 25px rgba(53, 53, 53, 0.15));
    
      @media $tablet {
        width: 300px
        height: 300px
        border-radius: 50px
        filter: drop-shadow(0px 0px 12px rgba(53, 53, 53, 0.15));
      }

      @media $verticalTablet {
        width: 250px
        height: 250px
        border-radius: 40px
        filter: drop-shadow(0px 0px 12px rgba(53, 53, 53, 0.15));
      }
    }
  }

  &__field-group {
    display: flex
    justify-content: space-between
    margin-top: 20px

    & input {
      border-radius: 12px
    }
  }

  &__field {
    margin-top: 10px

    & input {
      border-radius: 12px
    }
  }

  &__checkbox-group {
    padding-top: 10px
  }

  &__checkbox {
    margin-top: 20px
    display: flex
  }

  &__footer {
    margin-top: 20px
    display: flex

    &_text {
      font-size: 12px
      padding-left: 40px
    }
  }

  &__error {
    font-size: $font-size-xs;
    line-height: 1.33;
    font-weight: 400;
    color: $color-brand-red;

    &_hidden {
      visibility: hidden
    }
  }

}
