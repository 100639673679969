.ModalAddress {
  position: relative;
  padding: 30px 16px 16px;
  height: 316px;
  display: flex;
  flex-direction column
  align-items: center;

  &__open {
    height: auto;
    min-height: 351px;
  }

  &__big {
    padding: 30px 0 16px;
    min-height: 635px;
    height auto
    height: -webkit-fill-available;
  }

  &__height-inherit {
    height: inherit;
  }

  &__height-calc {
    height: calc(var(--vh, 1vh) * 107);
  }

  &__modal-icon {
    margin: 0 auto;
    height: 6px
    border-radius: 3px
    background #FFFFFF
    width: 70px
    position: absolute;
    top -15px
  }

  &__title {
    font-family: Manrope
    font-weight: 500;
    font-size: 18px;
    line-height: 17px;
    text-align: center;
    color: #70544F;
  }

  &__button-wrapper {
    position: fixed;
    bottom: 0;
  }

  &__button-wrapper {
    background white
    width: 100%;
    position: fixed;
    bottom 0;
  }
}

@media (max-height: 650px) {
  .ModalAddress {
    &__big {
      min-height: 312px;
      height: auto;
    }
  }
}
