.Paygateway {
  @media $phone {
    & > div:first-child {
      margin-bottom: 0;
    }
  }

  &__modal {
    box-shadow: none;
  }

  &__iframe {
    opacity: 1;
    transition: opacity $duration;
    background-color: white; 

    &_isLoaded {
      opacity: 1;
    }
  }

  &__loader {
    width: 100px;
    height: @width;
    margin-top: -(@width / 2);
    margin-left: -(@width / 2);
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }

  &__spinner {
    spinner(48px, 3px, $color-brand-green);
  }
}

:global(#Paygatewayiframe) {
  width: 700px;
  max-width: 100%;
  height: 500px;
  max-height: 100%;
  position: relative;
  z-index: 2;

  @media $phone {
    height: 90vh;
  }
}
