.Benefits {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr;
  margin-top: 24px;
  @media $verticalTablet{
    display: flex;
    flex-direction: column;
    margin-top: 0;
  }

  &__title {
    font-family: Manrope;
    font-size: $font-size-l;
    font-weight: 700;
    grid-column: 1 / 4;
    margin-bottom: 24px;
    @media $verticalTablet{
      order: -1;
      margin-bottom: 17px;
    }
  }

  &__img {
    align-self: center;
    width: 100%;
    @media $verticalTablet{
      order: -2;
    }
  }

  &__list:first-of-type {
    @media $verticalTablet{
      margin-bottom: 28px
    }
  }

  &__item {
    display: flex;
    align-items: center;

    img {
      padding-right: 16px;
    }

    span {
      opacity: .8;
      letter-spacing: 0.02em;
    }
  }

  &__item:not(:last-child) {
    margin-bottom: 28px
  }
}
