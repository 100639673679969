.FormLink {
  width 436px
  max-width 100%

  @media $tablet {
    width 100%
  }

  &__button {
    background var(--papa-color-5)
    width 100%

    &:hover, &:active:before {
      background var(--papa-color-5)

    }
  }
}
