input[id='desktop'] {
  --active: $color-brand-green;
  --active-inner: #F2F2F2;
  --focus: 1px #F2F2F2;
  --border: #F2F2F2;
  --background: #F2F2F2;
  --disabled: #F2F2F2;
  --disabled-inner: #F2F2F2;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0;
  cursor: pointer;
  border: 1px solid var(--bc, var(--border));
  background: var(--b, var(--background));
  transition: background .3s, border-color .3s, box-shadow .2s;

  &:after {
    content: '';
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
  }

  &:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: .3s;
    --d-t: .6s;
    --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
  }

  &:disabled {
    background: #F2F2F2;
    cursor: not-allowed;
    opacity: .9;

    &:checked {
      --b: var(--disabled-inner);
      --bc: var(--border);
    }
  }

  &:hover {
    &:not(:checked) {
      &:not(:disabled) {
        --bc: var(--border-hover);
      }
    }
  }

  &:focus {
    box-shadow: 0 0 0 var(--focus);
  }

  & {
    width: 22px;
    height: 22px;
    &:after {
      opacity: var(--o, 0);
    }

    &:checked {
      --o: 1;
    }
  }
}

input[id='desktop'] {
  & {
    border-radius: 5px;

    &:after {
      width: 8px;
      height: 12px;
      border: 3px solid var(--active-inner);
      border-top: 0;
      border-left: 0;
      left: 7px;
      top: 2px;
      transform: rotate(var(--r, 20deg));
    }

    &:checked {
      --r: 43deg;
    }
  }
}

.RadioInput {
  &__container {
    display: flex;
    padding: 0 40px 0 40px;
    width: inherit;
    flex-direction: column;
    overflow-y: auto;
  }

  &__itemContainer {
    margin-bottom: 18px;
    width: 100%;
    display: flex;
    align-items: center;

    div {
      margin-left: 20px;
    }
  }

  &__city {
    font-family: $font-secondary;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #70544F;
  }

  &__bonuses {
    color: #50A684;
    text-transform: uppercase;
  }
}
