.Rewards {
  display flex
  flex-direction column
  gap 12px
  color black
  &__title {
    font-family: Roboto;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;

  }

  &__subtitle {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;

  }

  &__list {
    display flex
    flex-direction column
    gap 8px
  }

  &__item {
    min-width: 335px;
    padding: 12px 48px 12px 47px;
    border-radius: 12px;
    background: #EDEDED;
    display flex
    flex-direction column
    gap 4px


    @media $phone {
      min-width unset
    }


    &_text {
      font-family: Roboto;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      text-align: center;

    }

    &_code {
      text-transform: unset;
      margin auto
    }
  }
}
