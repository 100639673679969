.Notifications {
  @media $phone {

  }

  &__row {
    @media $tablet {
      flex-wrap: wrap;
    }
  }

  &__field {
    margin-bottom: 25px;

    ^[0]__row > & {
      @media $tablet {
        width: 100%;
        margin-bottom: 24px;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    &_description {
      padding-top: 8px;
      font-size: $font-size-xs;
      color: $color-text;
    }
  }
}
