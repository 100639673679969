.promotion {
  position: relative;
  display: flex;
  justify-content: space-between;
  color: white;
  background: linear-gradient(294.34deg, #000000 2.22%, #1D044C 12.93%, #648AD2 24.56%, #76AAF5 28.09%, #709FE9 31.38%, #648AD2 33.68%, #4409B2 44.68%, #29066C 54.51%, #150337 62.03%, #000000 76.24%);
  padding-left: 20%;
  padding-top: 32px;
  max-width: 100vw;
  overflow: hidden;
}
.box {
  position: absolute;
  bottom: 70px;
  right: 15%;
}
.pizza {
  position: absolute;
  left: 0;
  bottom: 300px;
}
.pizza1 {
  position: absolute;
  bottom: 300px;
  right: 50px;
}

.pizza2 {
  position: absolute;
  bottom: 100px;
  right: 300px;
}

.pizza3 {
  position: absolute;
  top: -32px;
  right: 400px;
}
