.ComboBox {
  &__inner {
    padding-left: $phone-margin-x;
    padding-right: $phone-margin-x;
  }

  &__title {
    margin-bottom: 8px;
  }

  &__header {
    margin-bottom: 24px;
  }

  &__cart {
    display: flex;
    flex-direction: column;

    @media $phone {
      max-height: 85vh;
    }

    @media $verticalPhone {
      max-height: 80vh;
    }
  }
}
