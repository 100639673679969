.ProductCardHatikoBanner {
  position: absolute;

  z-index: 1000;

  transition: all .4s ease-in-out;
  width: 82%
  aspect-ratio: 0.93
  left: 10%;
  top: 10%;
  overflow: hidden;

  &_hidden {
    opacity : 0;

    * {
      cursor: default;
    }
  }

  @media $phone {
    height: 90%
    width: unset;
    left: 0;
    bottom: 0;
    top : auto;
  }

  &__image {
    width: 100%;
    height: 100%;
    background-image: url(./assets/hatico_success.png);
    background-repeat: no-repeat;
    background-size: contain;

    @media $phone {
      transform: 'translatex(-10%)'
    }
  }

  &__close {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 10px;
    margin: -10px;
    cursor: pointer;

    svg > path {
      fill: $color-brand-brown;
    }
  }
}
