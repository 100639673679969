.Checkout {
  width: 100%;
  position: relative;
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  &__clear {
    width: auto;
    height: 24px;

    svg {
      width: auto;
      height: 24px;
      color: $color-grey;
    }
  }

  &__green {
    margin-bottom: 25px;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: $color-brand-green;
  }

  &__user {
    padding: $checkout-mobile-block-padding;
    margin-bottom: -25px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 5px rgba(32, 38, 55, 0.08);
    border-radius: 25px;
    background-color: white;
  }

  &__types {
    width: 100%;
    margin-bottom: $checkout-mobile-margin-15;
  }
}
