.PizzaSelector {
  position: relative;

  &__dough {
    margin-bottom: 8px;
  }

  &__variations {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__border {
    margin-left: 8px;
  }
}
