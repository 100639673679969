.RestaurantSelector {
  display: flex;
  flex-direction: column;

  &__title {
    margin-bottom: $checkout-desktop-margin-25;
    color: $color-text;

    &-span {
      color: $color-brand-brown;
      cursor: pointer;
      transition: color $halfduration;

      &:hover, &_active {
        color: $color-brand-green;
      }
    }
  }

  &__subtitle {
    margin-bottom: $checkout-desktop-margin-20;
    font-family: $font-primary;
    font-weight: 400;
    font-size: $font-size-s;
    line-height: 1;
    color: $color-brand-green;
  }

  &__search {
    margin-bottom: $checkout-desktop-margin-25;
    display: flex;
    align-items: center;

    &-input {
      max-width: 500px;
      flex: 1;
      margin-right: $checkout-desktop-margin-20;
    }

    &-button {
      flex-shrink: 0;
    }
  }

  &__list {
    margin-bottom: 10px; // 25px - 15px;
    display: flex;
    flex-wrap: wrap;

    &-button {
      margin-bottom: 25px;
      display: flex;
      align-items: center;
      color: $color-brand-green;
      transition: color $halfduration;
      font-size: $font-size-s;
      font-family: $font-primary;
      font-weight: 400;
      line-height: 1;
      cursor: pointer;
      align-self: flex-start;

      &:hover {
        color: $color-brand-green-hover;
      }
    }

    &-arrow {
      height: auto;
    }
  }

  &__restaurant {
    width: 30%;
    display: flex;
    margin-right: 1%;
    margin-bottom: 15px;
    text-align: left;
    color: $color-brand-brown;
    font-family: $font-secondary;
    font-weight: 500;
    font-size: $font-size-m;
    line-height: 1.33;
    transition: color $halfduration;

    &:hover:not(&_disabled), &_active {
      color: $color-brand-green;

      .RestaurantSelector__restaurant-icon g path {
        fill: $color-brand-green;
      }
    }

    &_disabled {
      cursor: default;
      opacity: 0.5;
    }

    &-icon {
      width: auto;
      height: 21px;
      flex-shrink: 0;

      g path {
        transition: fill $halfduration;
        fill: $color-brand-red-light;
      }
    }

    @media $tablet {
      width: 49%;
    }
  }

  &__map {
    width: 100%;
    height: 435px;
    margin-bottom: $checkout-desktop-margin-25;
    position: relative;
  }

  &__preloader {
    spinner(48px, 3px, $color-brand-green);
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -24px 0 0 -24px;
  }

  &__info {
    display: flex;
    flex-direction: column;

    &-icon {
      width: auto;
      height: 21px;
      margin-right: 6px;
      flex-shrink: 0;
    }
  }

  &__address {
    display: flex;
    font-family: $font-secondary;
    font-size: $font-size-m;
    font-weight: 500;
    line-height: 1.33;
    color: $color-brand-green;
    opacity: 0.8;

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  &__metro {
    display: flex;
    font-family: $font-secondary;
    font-size: $font-size-m;
    font-weight: 400;
    line-height: 1.33;
    color: $color-brand-brown;

    &:not(:last-child) {
      margin-bottom: $checkout-desktop-margin-25;
    }
  }

  &__schedule {
    display: flex;

    &-wrapper {
      padding: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: $color-bg;
      border-radius: 15px;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }

    &-day, &-time1 {
      margin-bottom: 4px;
    }

    &-day {
      font-family: $font-secondary;
      font-weight: 500;
      font-size: $font-size-s;
      line-height: 1;
      color: $color-brand-brown;
    }

    &-time1, &-time2 {
      font-family: $font-secondary;
      font-weight: 500;
      font-size: $font-size-xs;
      line-height: 1;
      color: $color-grey;
    }
  }

  &__accept {
    margin-top: $checkout-desktop-margin-25;
    align-self: flex-end;
  }
}
