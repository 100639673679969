.Address {
  background-color: white;

  &__body {
    padding: $checkout-mobile-block-padding;
    display: flex;
    flex-direction: column;
  }

  &__types {
    margin-bottom: 25px;
  }
}
