.PayTypeSwitcher {
  margin-bottom: $checkout-desktop-margin-40;
  display: flex;
  flex-direction: column;

  &__title {
    margin-bottom: $checkout-desktop-margin-25;
  }

  &__row {
    display: flex;

    &:not(:last-child) {
      margin-bottom: $checkout-desktop-margin-20;
    }
  }

  &__button {
    &:not(:last-child) {
      margin-right: $checkout-desktop-margin-20;
    }
  }

  &__change {
    width: 307px;
  }
}