.CookiePolicyPopup {
  width: 518px;
  padding: 40px 20px 30px 20px;
  transform: translateY(100%);
  z-index: 10001;
  background: white;
  border-radius: 25px;
  transition: transform 500ms;


  &-background {
    width: 100%;
    position: fixed;
    height: 100vh;
    background: rgba(53, 53, 53, 0.35);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000;
  }

  &_entered {
    transform: translateY(0);
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-title {
      color: $color-brand-brown;
      font-weight: bold;
      font-size: 18px;
      line-height: 17px;
      font-family: $font-secondary;
      margin-top: 30px;
    }

    &-text {
      font-family: $font-primary;
      color: $color-brand-brown;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      opacity: 0.8;
      margin-top: 12px;
    }

    &-checkbox {
      color: $color-brand-green;
      margin-top: 22px;
      align-self: flex-start;
    }

    &-checkbox-description {
      padding-left: 30px;
      margin-top: 10px;
      align-self: flex-start;
    }

    &-checkboxs-group {
      padding-bottom: 22px;
      border-bottom: 3px solid #F4F4F8;
      border-top: 3px solid #F4F4F8;
      margin-top: 20px;
      margin-bottom: 30px;
    }

    &-button {
      background-color: $color-brand-green;
      color: white;
      height: 60px;
      width: 180px;
      border-radius: 40px;
    }

    &-footer {
      margin-top: 20px;

      & a {
        font-family: $font-primary;
        font-size: 12px;
        line-height: 16px;
        color: rgba(189, 189, 189, 1);
        opacity 0.8

        &:hover {
          opacity 1
        }

        &:first-child {
          &:after {
            content: '|'
            margin-left: 1em;
          }
        }

        &:last-child {
          margin-left: 1em;
        }
      }
    }
  }

  &__button {
    align-self: flex-end;
  }
}
