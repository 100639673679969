.EntryBlock {
  height: 100vh;
  padding-top: $header-height;
  //height : calc(100vh - $phone-navbar-height)
  display: grid;
  grid-template-rows: minmax(auto,300px) 1fr minmax(auto,300px);
  flex-direction: column;
  gap: 15px;

  @media $tablet {
    gap: 6px
  }

  &_row {
    display: flex;
    gap: 15px;
    //height: 300px;

    @media $tablet {
      gap: 6px
    }

    &-r1, &-r3 {
      padding-inline: 73px


      @media $tablet {
        padding-inline: 24px
      }
    }
  }

  &_imageWrapper {
    position: relative;
  }

  &_image {
    object-fit: cover;
    border-radius: 16px;
    height: 100%;
    max-width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;


    @media $tablet {
      border-radius: 8px;
      //height: 100px
    }

    &-shrink0 {
      width: 100%;
      height: auto;
      margin: auto;
    }
  }

}
