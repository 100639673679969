.PrimaryView {
  padding-right: @padding-left;

  &__back {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__content {
    width: 100%;
    height: @width;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
