.MobileDateField {
  & {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 16px;
    border-radius: 4px;
    background-color: white;
  }

  &__label {
    flex-shrink: 0;
    margin-right: 8px;
    color: $color-text;
  }

  &__field {
    flex-grow: 1;
    flex-basis: 100%;
    font-family: $font-secondary;
    font-weight: 500;
    text-align: right;
    color: $color-brand-brown;
    font-size: $font-size-m;
  }

  &__fieldButton {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    font-size: $font-size-m;

    /* Removes the clear buttons from date inputs */
    &[type='date']::-webkit-clear-button, &[type='date']::-webkit-outer-spin-button, &[type='date']::-webkit-inner-spin-button {
      -webkit-appearance: none;
      display: none;
    }

    &::-webkit-calendar-picker-indicator {
      position: absolute;
      opacity: 0;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }
}
