.Sidebar {
  &__settings {
    padding-top: 32px;
    padding-bottom: @padding-top;
    border-bottom: none;

    @media $tablet {
      &:last-of-type {
        padding-bottom: @padding-bottom;
      }
    }

    @media $verticalTablet {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }

  &__settings-item {
    position: relative;

    @media $verticalTablet {
      flex-shrink: 0;
      width: calc(50% - 16px);
    }
  }

  &__field > label, &__field-label {
    font-size: $font-size-xs;
  }

  &__field {
    &-content, & input, & textarea, & :global(.react-tel-input input[type='tel']), & :global(.react-date-picker__button), & :global(.Select-placeholder), & :global(.Select-control) {
      font-family: $font-secondary;
      font-size: $font-size-s;
      font-weight: 500;
    }

    & input:not(:global(.Select-input)), & textarea, & :global(.react-tel-input input[type='tel']), & :global(.react-date-picker), & :global(.Select) {
      width: calc(100% + 16px * 2);
      margin: 0 -16px;

      & + :global(.flag-dropdown) {
        display: none;
      }
    }

    & :global(.react-tel-input .flag-dropdown) {
      display: none;
    }

    & :global(.react-tel-input input[type='tel']) {
      padding-left: 16px;
    }
  }

  &__change-button {
    margin: -10px 0 30px 0;
  }

  &__success-message {
    margin-bottom: 20px;
    text-align: center;

    &:last-child {
      margin-bottom: 0;
    }

    @media $tablet {
      margin-bottom: @margin-bottom;
    }
  }

  &__recovery-password {
    margin-bottom: 40px;
  }
}