.ExpireInformationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 24px 13px 22px;
  background-color: white;
  border-radius: 15px;
}

.ExpireInformation {
  &__item {
    padding: 0 16px;
  }

  &__text {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: rgba(112, 84, 79, 0.9);

    &_bonuses {
      color: #F2994A;
    }
    &_papabonuses {
      color: #50A684;
    }

    &_expire {
      color: red;
    }

    &_date {
      color: red;
      text-decoration-line: underline;
    }
  }
}
