.Stock {
  padding: 20px 0 50px 0;
  display: flex;
  flex-direction: column;

  &__back {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    align-self: flex-start;
    font-family: $font-primary;
    font-weight: 400;
    font-size: $font-size-s;
    line-height: 1.33;

    svg {
      width: auto;
      height: 10px;
      margin-right: 10px;
    }
  }

  &__image {
    position: relative;
    margin-bottom: 40px;
    width: 100%;
    height: auto;
    &-caption {
      position: absolute;
      left : 0;
      bottom : 0;
      margin : 15px;
      border-radius : 15px;
      padding : 6px 10px;
      background-color: rgba(255, 255, 255, 0.5);
      font-size: 10px;
      color: $color-brand-brown;
    }
    img {
      width: 100%;
      height: auto;
      border-radius: 25px;
    }
  }

  &__title {
    margin-bottom: 25px;
    text-align: center;
    font-family: $font-secondary;
    font-weight: 500;
    font-size: $font-size-xl;
    line-height: 1.33;
    color: $color-brand-brown;
  }

  &__description {
    font-family: $font-secondary;
    font-weight: 400;
    font-size: $font-size-m;
    line-height: 1.33;
    color: $color-brand-brown;
    opacity: 0.8;
  }

  &__button {
    height: 60px;
    margin-top: 50px;
    align-self: center;
  }

  &__spinner {
    spinner(48px, 3px, $color-brand-green);
    align-self: center;
    margin-left: 12px;
  }
}
