.Group {
  margin-bottom 30px

  @media $phone {
    margin-bottom 22px
  }

  &:last-child {
    margin-bottom 0
  }

  &__title {
    margin-bottom 16px

    @media $phone {
      margin-bottom 16px
      font-size $font-size-l
    }
  }

  &__list {
    column-count 3
    column-gap 20px
    margin-left -12px

    @media $phone {
      columns auto
      margin-left (- $phone-margin-x)
      margin-right (- $phone-margin-x)
    }
  }

  &__column {
    flex-grow 1
    flex-shrink 0
    flex-basis 33.3%
    padding-right 20px
  }

  &__item {
    display:inline-block;  
    width 100%
    margin 2px 0
    page-break-inside avoid
    break-inside avoid

    @media $phone {
      float none
      margin 0
    }
  }

  &__link {
    @media $phone {
      padding-top 10px
      padding-bottom 10px
      padding-left 'calc(%s * 2)' % $phone-margin-x
      padding-right $phone-margin-x
      font-size $font-size-m
    }
  }
}