.Flipper {
  display: flex;
  flex-direction: column;
  position: relative;

  &__paper {
    border-radius: $border-radius;
    background-color: white;
  }

  &__front, &__back {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    left: 0;
    top: 0;
    border-radius: $border-radius;
    background-color: white;
    backface-visibility: hidden;
    transition: transform 800ms;
  }

  &__front {
    &_exited {
      position: relative;
      transform: none;
    }

    &_exiting {
      position: relative;
      transform: none;
    }

    &_entering {
      position: absolute;
      transform: rotateY(180deg);
    }

    &_entered {
      position: absolute;
      transform: rotateY(180deg);
    }
  }

  &__back {
    &_exited {
      position: absolute;
      transform: rotateY(-180deg);
      z-index: -1;
    }

    &_exiting {
      position: absolute;
      transform: rotateY(-180deg);
    }

    &_entering {
      position: relative;
      transform: none;
    }

    &_entered {
      position: relative;
      transform: none;
      z-index: 0;
    }
  }
}
