.Tabs {
  position relative
  display flex
  height 100%

  &__link {
    padding 0 12px
    font-family $font-secondary
    font-weight 500
    line-height 1
    color $color-grey

    &:first-child {
      padding-left $phone-margin-x
    }

    span {
      position relative
      display flex
      height 100%
      justify-content center
      align-items center

      &:after {
        content ''
        width 100%
        height 3px
        border-radius 5px
        position absolute
        left 0
        bottom -1px
        opacity 0
        background-color $color-brand-green
      }
    }

    &_active span {
      color $color-brand-green
    }

    &_active span:after {
      opacity 1
    }
  }

}
