html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, img, ins, kbd, q, s, samp, small, strike, sub, sup, tt, var, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

th, td {
  text-align: left;
}

textarea {
  resize: none;
}

address {
  font-style: normal;
}

input, textarea, button, select {
  outline: none;
  margin: 0;
  font: inherit;
  -webkit-font-smoothing: inherit;
  -webkit-appearance: none;
}

button {
  display: inline-block;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  outline: none;
  border: 0;
}

&:focus {
  outline: 0px;
}

*, *:before, *:after {
  box-sizing: border-box;
}