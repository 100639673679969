.ModalContainer {
  &__modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 390px;
    background-color: #FFFFFF;
    border-radius: 25px;
  }

  &__picture {
    margin-top: 50px;
    margin-bottom: 15px;
  }

  &__header {
    display: block;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    color: #50A684;
    padding-bottom: 12px;
  }

  &__text {
    display: block;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    margin: 0 40px;
    color: #70544F;
  }

  &__order-button {
    margin: 40px;
    width: 310px;
  }
}
