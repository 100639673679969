.Popup {
  paper();
  position: absolute;
  width: 340px;
  padding: 40px $paper-pad-x $paper-pad-x;
  box-shadow: $box-shadow;
  transition: opacity 200ms;
  opacity: 0;

  &_landing {
    top: 50px;
    max-height: 80vh;
  }

  &_regular {
    top: 150%;
    right: 0;
    max-height: 50vh;
  }

  &_auth {
    top: 100%;
    right: 0;
  }

  &_entered {
    opacity: 1;
  }

  &__close {
    position: absolute;
    top: $paper-pad-x;
    right: $paper-pad-x;
    color: rgba($color-grey, 0.6);
    cursor: pointer;
    transition: color $duration;

    svg {
      display: block;
    }

    &:after {
      content: '';
      position: absolute;
      top: -7px;
      right: @top;
      bottom: @top;
      left: @top;
    }

    &:hover {
      color: $color-grey;
      transition-duration: $halfduration;;
    }
  }
}
