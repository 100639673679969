.FootballProductCardDesktop {
  &__wrapper {
    height 100%
    display flex
    flex-direction column
    padding 24px 24px 24px
    align-items center
    justify-content center
    max-width 440px

    @media $tablet {
      max-width unset
      padding 32px 32px 32px
    }
  }

  &__score {
    display flex
    flex-direction column
    gap 5px
    color: white;

    &_title {
      font-family: Manrope;
      font-size: 16px;
      font-weight: 400;
      line-height: 18px;
      text-align: center;
    }

    &_content {
      display flex
      align-items center
      justify-content center
      gap 8px
      font-family: Manrope;
      font-size: 36px;
      font-weight: 500;
      line-height: 1.2;
      letter-spacing: 0.02em;
      text-align: center;

    }
  }

  &__cart {
    height 100%
    width 100%
    margin-top 5px
    z-index 10
  }
}

