.GoodCard {
  display flex
  flex-direction column
  justify-content space-between
  gap 24px
  padding 30px
  background var(--papa-color-3)
  border-radius 24px
  @media $tablet {
    padding 24px
  }
  @media $phone {
    padding 12px
    gap 16px

  }

  &__text {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    color black
    @media $tablet {
      font-size: 16px;
      line-height: 20px;
    }

    @media $phone {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__mark {
    color var(--papa-color-5)
  }

  &__image {
    width 100%
    border-radius 16px
    object-fit cover
  }
}
