.Loyalty {
  &__newPl {
    margin-left: 28px;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content center;
    width: 218px;
    height: 55px;
    gap: 12px;
    border-radius: 40px;
    background: #50A684;
    font-weight: 500;
    font-size: 16px
    color: rgba(255, 255, 255, 1)

    &_gradient {
      background: url('../../../../../../icons/backgrounds/gradientForButtonProfile.png') no-repeat center center / cover
    }
      &_black {
        background: url('./blackButton.png') no-repeat center center / cover
      }

  }

  &__modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 403px;
    height: 514px;
    background-color: white
    border-radius: 25px;
  }

  &__lollipop {
    margin-top: 5px;
    //margin-left: 10px;
    //margin-right: 15px
  }

  &__picture {
    margin-top: 50px;
    margin-bottom: 10px;
  }

  &__header {
    display: block;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    color: rgba(80, 166, 132, 1);
    padding-bottom: 12px;
  }

  &__text {
    display: block;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    margin: 0 40px;
    color: rgba(112, 84, 79, 1);
  }

  &__orderButton {
    margin: 40px 40px 0 40px
    width: 293px;
    background: #50A684;

    &_gradient {
      background: url('../../../../../../icons/backgrounds/backgroundForButtonInModal.png') no-repeat center center / cover
    }
    &_black {
      background: url('./blackButton.png') no-repeat center center / cover
    }
  }

  &__about {
    display: block;
    margin-top: 15px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #A6A5A5;
  }

  &__status {
    margin-bottom: 4px;
    font-family: $font-primary;
    font-weight: 400;
    font-size: $font-size-m;
    line-height: 1.33;
  }

  &__label {
    color: $color-brand-brown;
  }

  &__name {
    display: inline-flex;
    align-items: center;
    color: $color-brand-green;

    svg {
      width: 14px;
      height: 14px;
      margin-left: 4px;
      color: $color-grey;
    }
  }

  &__cashback {
    font-family: $font-primary;
    font-weight: 400;
    font-size: $font-size-s;
    line-height: 1.33;
  }
}
