.TotalBlock {
  background var(--papa-color-2)


  &__container {
    display grid
    grid-template-columns repeat(2, 1fr)
    gap 16px
    padding 36px 0


    @media $phone {
      grid-template-columns 1fr
      padding 24px 0
    }
  }

  &__wrapper {

    display flex
    align-items center
    gap 16px
  }

  &__content {
    font-size 68px
    font-weight bolder
    @media $tablet {
      font-size 52px
    }
  }

  &__thin {
    font-size 24px
    transform translateY(11px)
    @media $tablet {
      transform translateY(6px)
    }
  }

  &__greenSvg {
    //filter invert(100%) sepia(0/%) saturate(0%) hue-rotate(196deg) brightness(104%) contrast(100%)
  }

  .TotalItem {
    display flex
    flex-direction column
    gap 30px
    background var(--bg)
    border-radius 24px
    padding 24px 36px

    @media $tablet {
      padding 16px 24px

    }

    @media $phone {
      gap 12px

    }

    &__text {
      color var(--text)
    }

    &__content {
      font-size 68px
      @media $tablet {
        font-size 52px
      }
    }
  }
}
