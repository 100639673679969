.CurrentTask {
  position relative
  overflow hidden

  display flex
  flex-direction column
  gap 12px

  padding 16px 12px 12px

  border-radius: 12px;
  background-color: #FEBB3C;


  &__Tree {
    position absolute
    width 232px
    height auto
    bottom -40px
    right -40px
    @media $phone {
      width 180px
    }
  }

  &__title {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: center;
    color: $color-brand-brown;
  }

  &__description {

    min-height 220px

    display flex
    justify-content center
    align-items center
    padding: 8px;
    border-radius: 8px;

    border: 5px solid #E78312CC
    backdrop-filter: blur(3px)
    background: #FFF4A4B2;
    color #70544F

    font-size: clamp(10px, 14px, 14px);
    line-height: 16px;
    text-align center

    b {
      font inherit
      background unset
      color inherit
      padding unset
    }

    @media $tablet {
      min-height 100px
    }
  }

  &__button {
    width 100%

    &_hidden {
      visibility hidden
    }

    &_disabled {
      pointer-events none
    }

    &_completed {
      width 23px
      aspect-ratio 1
      margin-right 5px
      background url("./chevron.png") center center no-repeat
      background-size cover

    }
  }
}

.Modal {
  max-width 640px
  position relative
  overflow unset !important
  padding-top 32px !important
  background-color #FEBB3C !important
  color $color-brand-brown
  &__text {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
  }

  &__subtext {
    padding-top 12px
    font-size: 16px;
    text-align: center;
    color $color-brand-brown
  }
  &:before {
    position absolute
    content ''
    top 0
    left 50%
    width 111px
    height 81px
    background url("./decor.png") no-repeat center center
    background-size contain
    transform translate(-50%, -60%)
  }

  &__content {
    display flex
    flex-direction column
    gap 16px
  }
}
