.MainSidebar {
  width: $main-sidebar-width;
  float: right;
  margin-top: 85px;
  position: sticky;
  transition: top 500ms ease-in-out;
  display: flex;
  flex-direction: column;

  &__cart, &__history {
    margin-bottom: 10px;
  }

  &__promocode {
    padding: $paper-pad-y $paper-pad-x;
  }
}
