.ModalContainer {

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #FFFFFF

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 50px 16px 40px 16px;
  }

  &__modal-icon {
    margin: 0 auto;
    height: 6px;
    border-radius: 3px;
    background: #fff;
    width: 70px;
    position: relative;
    top: -15px;
  }

  &__picture {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  &__header {
    display: block;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    color: #50A684;
    padding-bottom: 12px;
  }

  &__text {
    display: block;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    margin: 0 40px;
    color: #70544F;
  }

  &__order-button {
    margin: 0 auto;
    width: 100%;
    height: 51px;
    left: 804px;
    top: 755px;
    background: #50A684;
    border: 1px solid #50A684;
    border-radius: 25px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #FFFFFF;
  }
}
