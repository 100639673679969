.Card {
  & {
    position relative
    padding 16px 20px
    border-radius 4px
    background-color white
    cursor pointer
    transition opacity $duration
  }

  @media $phone {
    border-radius 0
  }

  &:last-child {
    margin-bottom 0
  }

  &:before {
    content ''
    width 6px
    height 100%
    position absolute
    top 0
    left 0
    background-color $color-brand-green
    border-top-left-radius 4px
    border-bottom-left-radius @border-top-left-radius
    opacity 0
    transition opacity $duration

    @media $phone {
      display none
    }
  }

  &__header {
    display flex
    justify-content space-between
    margin-bottom 8px
  }

  &__name {
    margin-right 10px

    @media $phone {
      font-size $font-size-m
    }
  }

  &__data {
    font-size: $font-size-xs

    @media $phone {
      font-size: $font-size-s
    }

  }

  &__markers {
    display flex
    flex-shrink 0
  }

  &__marker {
    margin-right 5px

    &:last-child {
      margin-right 0
    }

    svg {
      display block
    }
  }

  &_selected:before {
    opacity 1
  }

  &_disabled {
    opacity 0.5
    cursor default
  }
}
