.ReplacementGoods {
  position absolute
  bottom 100%
  left: 50%;
  width 248px
  padding 16px
  margin-bottom 16px
  border-radius $border-radius
  background-color white
  z-index 1000
  box-shadow: 0 6px 52px 0 rgba(41, 44, 51, 0.2)
  transform: translateX(-50%);

  @media $phone {
    position static
    width auto
    margin-bottom 0
    box-shadow none
    transform none
  }

  &:after {
    content ''
    position absolute
    top 100%
    left 50%
    transform: translateX(-50%);
    width 0
    height 0
    border-style solid
    border-width 16px 16px 0 16px
    border-color white transparent transparent transparent

    @media $phone {
      display none
    }
  }

  &__cartItem {
    user-select none
    cursor pointer
    transition opacity $duration

    &:hover {
      transition-duration: $halfduration;
      opacity .7
    }
  }
}
