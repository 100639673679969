.PromoLinks {
  z-index 100
  position: fixed;
  bottom: 40px;
  left: 40px;
  transition: bottom $duration;

  display flex
  flex-direction column
  gap 12px

}
