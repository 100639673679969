.PizzaFilter__new-year {
  .PizzaFilter {
    &__image {
      position: absolute;
      width: 100%;
      top: 0;
      bottom: 0;
      right: -20px;
      background-image: url($colorNY-filter-mobile_image);
      background-position-x: right;
      background-position-y: bottom;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &__actions {
      margin-bottom: 0;
      padding-bottom: 16px;
    }
    &__action_toggle {
      z-index: 1;
    }
  }
}

.PizzaFilter {
  position: relative;

  &__tags {
    margin 0px 20px
  }

  &__heading {
    margin: 0px auto 16px;
    text-align: center;
  }

  &__actions {
    display flex
    align-items center
    margin-bottom 16px
  }

  &__wrapper {
    display flex
    flex-grow 1
  }

  &__head {
    margin-right 16px
  }

  &__tags-list {
    display flex
    flex-wrap wrap
    align-items flex-start
    padding 0px 12px

    &:nth-child(2n) {
      padding 0px 12px 88px
    }
  }

  &__express {
    width: 100%;
    margin: 0 -13px 15px;
    display: flex;
    align-items flex-start
    position relative


    &-button {
      margin: 6px;
      padding: 12px 20px;
      background-color: rgba(255, 255, 255, 1);
      border: 1px solid #E6E2E1;
      border-radius: 20px;
      cursor: pointer;
      transition: color $duration, border-color $duration;
      white-space: nowrap;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.02em;
      color: #70544F;


      &_selected {
        background-color: $color-brand-green;
        border-color: $color-brand-green;
        color: white;
      }

      &_refresh {
        padding: 10px 20px 14px
        color: $color-brand-green
      }
    }

    &-select {
      position relative
      z-index 1000
      margin: 6px;
      padding: 13px 25px;
      padding-right 50px
      border: 1px solid #E6E2E1;
      border-radius: 20px;
      color: $color-text;
      cursor: pointer;
      transition: color $duration, border-color $duration;
      white-space: nowrap;
      background-color: rgba(255, 255, 255, 0);


      svg {
        position: absolute;
        top: 21px;
        height: 5px;
        width: 10px;
        right: 20px;
      }

      &_open {

        padding-right 25px
        border: 1px solid #50A684;
        background white

        svg {
          position: absolute;
          top: 22px;
          height: 10px;
          width: 5px;
          right: 20px;
        }

        div:first-child {
          border-bottom 1px solid #50A684;
          width calc(100% + 50px)
          color: #50A684
          padding-bottom 13px
          margin-left -25px
          margin-bottom 8px
        }

        div {
          padding 6px 0px

          &:first-letter {
            text-transform: uppercase;
          }

          &:hover {
            opacity 0.7
          }
        }

      }

      &_removed {
        text-decoration: line-through;
        color: #e6e2e1;
        cursor: default;
      }

      &_selected {
        opacity: 1;
        color: $color-brand-green;
      }

    }
  }

  &__action {

    &_active {
      svg {
        fill $color-brand-green
      }
    }

    svg:hover {
      opacity 0.7
      fill $color-brand-green
    }

    &_invisible {
      display none
    }
  }

  &__filter {
    margin-right 20px
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #BDBDBD;
    padding-top 20px;
  }

  &__list {
    paper();
    margin-bottom: 24px;
    display: flex;
    padding: 20px 48px;
    margin-left: -15px;
    margin-right: -15px;
  }

  &__group {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
    margin-left: 15px;
    margin-right: 15px;
  }

  &__group-title {
    margin-bottom: 10px;
    font-weight: 500;
  }

  &__group-list {
    margin-left: -30px;
  }

  &__link {
    margin: 5px 0;
  }

  &__Modal {
    padding-top: 25px

    &-content {
      overflow: scroll
      max-height: 410px
      height: 410px;
      position: relative
    }

    &-icon {
      margin: 0 auto;
      height: 6px;
      border-radius: 3px;
      background: #fff;
      width: 50px;
      position: relative;
      top: -40px;
    }
  }

  &__ModalFooter {
    display flex
    justify-content space-between
    position sticky
    position -webkit-sticky
    bottom 0
    padding 16px 16px 25px

    &-all-tags {
      border-radius: 20px 20px 0px 0px;
      box-shadow: 0px 0px 10px rgba(32, 38, 55, 0.15)
      padding 20px 16px
      background white
      width 100vw
    }


    &-choose-button {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.02em;
      color: #FFFFFF;
      padding 13px 0px
      width 100%
      border-radius 20px
    }

    &-refresh-button {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.02em;
      background: #BFB7B6;
      border-radius: 20px;
      color: #FFFFFF;
      padding 13px 0px
      width 100%
      margin-right 14px
    }
  }

  &__tags-heading {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #70544F;

    margin 16px 6px 6px
  }

  &__priceSort {
    display: flex;
    align-items: center;
    margin-left: 47px;
    color: $color-text

    &-text {
      color: $color-text
    }

    svg {
      margin-right: 8px
    }

    &_active {
      background-color: $color-brand-green;

      span {
        color: white

      }

      &_rotate {
        svg {
          transform: rotateX(180deg);
        }
      }
    }

    &:hover {
      color: inherit
    }

    &-icon {
      padding-right: 8px;
    }
  }
}
