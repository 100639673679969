.PasswordWrapperSpecify {
  position: relative;

  &__layer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 100%;
    display: flex;
    align-items: center;
    color: $color-brand-brown;

    &Inner {
      position: relative;
      display: inline;
      max-width: 100%;
    }
  }

  &__text {
    text-overflow: inherit;
    white-space: inherit;
  }

  &__buttonSpecify {
    align-self: center;
    font-size: $font-size-xs;
    color: $color-brand-green;
    cursor: pointer;

    &:hover {
      color: $color-brand-green-hover;
    }
  }

  &__buttonEdit {
    position: absolute;
    display: inline-block;
    bottom: 1px;
    left: 100%;
    line-height: 1;
    font-size: 0;
    color: #ededed;
    cursor: pointer;
    transition: color $duration;

    &:hover {
      color: $color-text;
    }
  }

  &__togglePasswordVisible {
    position: absolute;
    display: flex;
    align-items: center;
    height: 100%;
    right: 0;
    color: $color-grey;
    cursor: pointer;
    transform: translateX(0);
    transition: color $duration, transform $duration;
    z-index: 1;

    &:hover {
      color: $color-text;
    }
  }

  &__repeat-button {
    height: 24px;
    display: inline-block;
    font-size: $font-size-xs;
    color: $color-brand-green-dark;
    cursor: pointer;

    &_activated {
      color: $color-brand-green-green-dark;
      pointer-events: none;
    }
  }

  &__notCall {
    margin-top: 15px;
    color: #6d6d6d;
    text-decoration: underline;
    cursor: pointer;
    font-size: 10px;

    &_condition {
      color: #306c59;
    }
  }

  &__validIcon {
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    right: 0;
    top: 0;
    pointer-events: none;
    opacity: 0;
    color: $color-brand-green;
    animation: showIcon $duration linear 0s forwards;
    z-index: 1;
  }

  & input {
    padding-right: 70px;
  }

  & :global(.react-date-picker__button__input input) {
    padding: 0;
  }

  & input, & textarea, & :global(.react-date-picker), & :global(.Select) {
    position: relative;
  }

  & :global(.Select > .Select-control .Select-value) {
    padding-left: 16px;
  }

  & :global(.react-date-picker__button input) {
    margin-left: 0;
    margin-right: 0;
  }

  &__field {
    & :global(.react-date-picker--disabled) {
      background-color: inherit;
    }

    & input, & textarea, & :global(.react-tel-input), & :global(.react-date-picker__button), & :global(.Select) {
      opacity: 0;
      z-index: -1;
    }

    &_visible {
      & input, & :global(.react-tel-input), & :global(.Select) {
        opacity: 1;
        z-index: 1;
      }
    }

    &_blur {
      & input, & :global(.react-tel-input), & :global(.react-date-picker__button), & :global(.Select-placeholder), & :global(.Select-control) {
        background-color: inherit;
      }
    }

    &_valid {
      & .PasswordWrapperSpecify__togglePasswordVisible {
        transform: translateX(-22px);
      }
    }

    &_password {
      & input {
        padding-right: 70px;
      }
    }
  }

  &__preloader {
    $spinnerSize = 20px;
    $spinnerWidth = 2px;
    z-index: 1;
    width: $spinnerSize;
    height: $spinnerSize;
    border-width: $spinnerWidth;
    margin-left: 4px;

    &:before, &:after {
      left: -($spinnerWidth);
      top: -($spinnerWidth);
    }
  }
}

// keyframe
@keyframes showIcon {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
