.ModalInCompetition {
  paper();
  width: 370px;
  padding: 40px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 25px;

  &__img {
    width: 45px;
    height: 45px;
    margin-bottom: 20px;
  }

  &__title {
    margin-bottom: 12px;
  }

  &__subtitle {
    margin-bottom: 40px;
    font-family: $font-primary;
    font-weight: 500;
    font-size: $font-size-m;
    line-height: 1.33;
    text-align: center;
    color: $color-brand-brown;
    opacity: 0.8;
  }
}
