.Nutrition {
  &__title {
    margin-bottom: $paper-pad-y;
    padding-top: 32px;
    padding-left: $phone-margin-x;
    padding-right: @padding-left;
  }

  &__listItem {
    position: relative;
    display: block;
    margin-bottom: 4px;
    padding: 24px 45px 24px $phone-margin-x;
    color: $color-brand-brown;
    background-color: white;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 4px;
      height: 100%;
      border-top-left-radius: 4px;
      border-bottom-left-radius: @border-top-left-radius;
      background-color: $color-brand-green;
      opacity: 0;
    }
  }

  &__listItemArrow {
    position: absolute;
    top: 50%;
    right: $phone-margin-x;
    margin-top: -8px;
    color: $color-grey;

    svg {
      display: block;
      transform: rotate(180deg);
    }
  }
}
