.NotAllowedCityModal {

  &__button {
    z-index: 9000;
    height: 60px;
    width 60px;
    border-radius: 50%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 40px;
    right: 40px;
    background-color: rgba(80, 166, 132, 1);
    box-shadow: $box-shadow;
    opacity : 1
    transition : all .3s

    &_hidden {
      opacity : 0
    }
  }

  &__Modal {
    background: white;
    max-width: 576px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 23px 16px 18px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    &_heading {
      font-family: Roboto;
      font-size: 18px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
    }

    &_text {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
      margin-top: 12px;
    }
  }
}
