.RestaurantSelector {
  display: flex;
  flex-direction: column;


  &__title {
    margin-bottom: 24px;
    color: $color-brand-brown;
    text-align: center;
  }


  &__subtitle {
    font-family: $font-primary;
    font-weight: 400;
    font-size: $font-size-s;
    line-height: 1;
    color: $color-brand-green;
  }

  &__search {
    margin-bottom: $checkout-desktop-margin-25;
    display: flex;
    align-items: center;
    gap: 10px;
    width: 70%;


    &-input {
      flex: 2 0 0;
    }

    &-button {
      flex: 1 0 0;
      height: 60px;
      padding: 20px 25px
      border-radius: 60px;

      &:hover:before {
        transform: none !important;
      }

    }

    @media $tablet {
      width: 100%;
    }

    @media $phone {
      flex-direction: column;
      &-input {
        width 100%;
      }

      &-button {
        width 100%;
      }
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;

    @media $phone {
      padding-bottom: 12px;

    }
  }

  &__subtitle {
    font-family: $font-secondary;
    font-weight: 500;
    font-size: $font-size-xl;
    line-height: 1.33;
    color: $color-brand-brown;
  }

  &__toggle {
    display: flex;
    gap: 12px;

    &-item {
      position: relative;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: $color-border;
      cursor: pointer;

      svg {
        position: absolute;
        top: 50%;
        left: 50%
        transform: translate(-50%, -50%)
        width: 20px;
        height: 20px;
        filter: invert(58%) sepia(19%) saturate(904%) hue-rotate(105deg) brightness(96%) contrast(89%);
      }

      &_active {
        background: $color-brand-green;

        svg {
          filter: invert(0);
        }
      }
    }
  }


  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 80px;
    row-gap: 36px;

    @media $tablet {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 20px;
      row-gap: 24px;

    }

    @media $phone {
      grid-template-columns: 1fr;
    }
  }

  &__restaurant {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media $tablet {
      gap: 16px;
    }

    &-wrapper {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    &-title {
      font-weight: 500;

      &:hover {
        color: $color-brand-green;
      }
    }

    &-container {
      display: flex;
      gap: 15px;
    }

    &-icon {
      width: auto;
      flex-shrink: 0;
      height: 17px;
      filter: invert(57%) sepia(20%) saturate(865%) hue-rotate(105deg) brightness(97%) contrast(88%);


    }

    &-description {
      font-family: $font-primary;
      font-weight: 400;
      font-size: $font-size-s;
      line-height: 1.33;
      color: $color-brand-brown;
    }
  }

  &__map {
    width: 100%;
    height: 435px;
    margin-bottom: $checkout-desktop-margin-25;
    position: relative;
    border-radius: 25px;
    overflow hidden;

    @media $tablet {
      margin-left: -20px;
      margin-right: -20px;
      overflow: visible;
      width: 100vw;

    }
  }

  &__preloader {
    spinner(48px, 3px, $color-brand-green);
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -24px 0 0 -24px;
  }

  &__info {
    display: flex;
    flex-direction: column;

    &-icon {
      width: auto;
      height: 21px;
      margin-right: 6px;
      flex-shrink: 0;
    }
  }

  &__name {
    display: flex;
    font-family: $font-secondary;
    font-size: $font-size-m;
    font-weight: 500;
    line-height: 1.33;
    color: $color-brand-brown;
    opacity: 1;
    margin-bottom: 15px;

    &:hover {
      color: $color-brand-green;
    }

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  &__address {
    display: flex;
    font-family: $font-secondary;
    font-size: $font-size-m;
    font-weight: 500;
    line-height: 1.33;
    color: $color-brand-green;
    opacity: 0.8;

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  &__metro {
    display: flex;
    font-family: $font-secondary;
    font-size: $font-size-m;
    font-weight: 400;
    line-height: 1.33;
    color: $color-brand-brown;

    &:not(:last-child) {
      margin-bottom: $checkout-desktop-margin-25;
    }
  }

  &__schedule {
    display: flex;
    flex-wrap : wrap;
    gap : 10px

    &-wrapper {
      padding: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: $color-bg;
      border-radius: 15px;
    }

    &-day, &-time1 {
      margin-bottom: 4px;
    }

    &-day {
      font-family: $font-secondary;
      font-weight: 500;
      font-size: $font-size-s;
      line-height: 1;
      color: $color-brand-brown;
    }

    &-time1, &-time2 {
      font-family: $font-secondary;
      font-weight: 500;
      font-size: $font-size-xs;
      line-height: 1;
      color: $color-grey;
    }
  }

  &__accept {
    margin-top: $checkout-desktop-margin-25;
    align-self: flex-end;
  }

}
