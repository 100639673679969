.SuccessMessage {
  position: relative;
  text-align: center;

  &__picture, &__title, &__text {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__picture {
    img {
      max-width: 100%;
    }
  }

  &__title {
    @media $phone {
      font-size: $font-size-xxl;
    }
  }

  &__text {
    font-family: $font-secondary;
    font-weight: 500;
    color: $color-brand-brown;
  }
}
