.DoughSelector__halloween {
  background-color: $colorHW-bg-select;
  .DoughSelector {
    &__overlay, &__type_threeselected  {
      background-color: $colorHW-bg-select-selected;
    }
    &__type{
      color: $colorHW-text-select;
      &:not(&_selected):hover {
        color: $colorHW-text-hover!important
      }
    }
    &__type_selected {
      color: $colorHW-text-select-selected;
    }
  }
}

.DoughSelector__new-year {
  background-color: $colorNY-bg-select;
  .DoughSelector {
    &__overlay, &__type_threeselected  {
      background-color: $colorNY-bg-select-selected;
    }
    &__type{
      color: $colorNY-text-select;
      &:not(&_selected):hover {
        color: $colorNY-text-hover!important
      }
    }
    &__type_selected {
      color: $colorNY-text-select-selected;
    }
  }
}

.DoughSelector {
  width: 100%;
  height: 36px;
  display: flex;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  border-radius: 100px;
  border: solid 1px $color-border;

  &__overlay {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #ededed;
    border-radius: inherit;
    transition: transform $duration $easeOutCubic;
  }

  &__type {
    flex: 1;
    padding: 0 10px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    cursor: pointer;
    user-select: none;
    transition: color $duration;
    white-space: nowrap;
    border-radius: inherit;

    &_selected {
      cursor: default;
      color: $color-brand-brown;
    }

    &_threeselected {
      background-color: #ededed;
    }

    &_heart {
      width: 40px;
      flex: initial;
    }

    &:not(&_selected):hover {
      color: $color-brand-green;
      transition-duration: $halfduration;
    }
  }

  &__heart {
    width: 16px;
    height: auto;
  }
}
