.PromoArtefact {
  position: absolute;
  overflow: hidden;
  height: 45px;
  cursor: pointer
  transition: all 1s ease-in-out


  img {
    max-height: 100%;
    max-width: 100%;
    transition: all 1s ease-in-out
  }

  &__toLeft {
    img {
      transform: translateX(50%);
    }

    &_active {
      img {
        transform: translateX(0%);
      }
    }
  }

  &__toRight {
    img {
      transform: translateX(-40%);

    }

    &_active {
      img {
        transform: translateX(10%);

      }

    }
  }

  &__toTop {
    img {
      transform: translateY(50%);

    }

    &_active {
      img {
        transform: translateY(0%);

      }
    }
  }

  &__toBottom {
    img {
      transform: translateY(-50%);

    }

    &_active {
      img {
        transform: translateY(0%);

      }
    }
  }

  &__opacity {

    &_active {
      z-index: 10 !important;
    }
  }

  &__active {
    transform: translateY(50px)
  }
}
