.RecoveryPasswordSecondStepForm {
  &__note {
    padding-top 10px
    margin-bottom 24px
    color $color-brand-brown
  }

  &__actions {
    display flex
    align-items center
    justify-content space-between
    margin-left -10px
    margin-right -10px

    @media $phone {
      flex-direction column
      align-items flex-start
      justify-content center
      margin-left 0
      margin-right 0
    }

    &__item {
      margin 0 10px
      flex-grow 1

      @media $phone {
        display block
        width 100%
        margin-left 0
        margin-right 0
        margin-bottom 10px

        &:last-child {
          margin-bottom 0
        }
      }
    }
  }

  &__repeat {
    margin-top: 15px;
    margin-bottom: 15px;
    color: #50a684;

    &_again {
      cursor: pointer
    }

    &_again:hover {
      color: #306c59;
    }
  }

  &__notCall {
    margin-bottom: 15px;
    text-decoration: underline;
    cursor: pointer;

    &_condition {
      color: #306c59;
    }
  }
}
