.SignUpPage {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
  background-color: white;

  &__body {
    padding-top: $phone-navbar-height + 24px;
    padding-bottom: 50px;
  }

  &__navbar {
    padding-left: 0;
  }
}
