.flex {
  display flex
}

.flex-col {
  flex-direction column
  justify-content space-between
}

.gap-12 {
  gap 12px
}

.placeholder {
  opacity 0.6
}

img {
  width fit-content
  object-fit scale-down
}

b {
  background-color $color-brand-green
  color white
  padding: 2px 5px;
}

em {
  font-style normal
  color $color-brand-green
}

.Partial {

  &__left {
    gap 30px

    &_promo {
      width fit-content
      min-width: 200px
      padding: 7px 13px
      border-radius: 8px
      border: 1px dashed $color-brand-brown

      div {
        padding 0
      }
    }

    &_block1 {
      flex-direction: column;
      @media $phone {
        flex-direction row
      }

      &_arrowBg {
        background-repeat: no-repeat
        background-position: 205px calc(100% - 10px)

        @media $phone {
          background-position: 315px calc(100% - 10px)
        }

      }
    }

    &_block2 {
      @media $phone {
        flex-direction row-reverse
      }
    }

    &_block1, &_block2 {
      @media $phone {
        align-items center
        gap: 15px
        & > div, & > p {
          flex-grow 1
        }
      }
    }

    &_image {
      @media $tablet {
        max-width 150px
      }

      @media $phone {
        max-width 25%
      }
    }

  }


  &__right {
    display flex
    flex-direction column
    gap 12px

    &_title {
      color $color-brand-green
    }

    &_conditions {
      flex-grow 1
      list-style circle
      margin-left 20px
      justify-content normal

      li::marker {
        color: $color-brand-green;
      }
    }

    &_decor {
      display: block;
      @media $phone {
        display none
      }
    }

    &_CopyCode {
      & * {
        display inline-block
        font-size 14px
      }
    }
  }

}
