.TextareaField {
  position: relative;

  &__label {
    mobileinputlabel();
  }

  &__field {
    mobileinputfield();
    height: auto;
    min-height: 142px;
    line-height: 1.33;
    scrollable();
    custom-scrollbar(2px, #e6e2e1);
  }

  &__symbolsCount {
    padding: 5px;
    border-radius: 5px;
    position: absolute;
    bottom: 5px;
    right: 0;
    background-color #e6e2e1;
  }
}
