.Sauces {
  position: relative;

  &__toggle {
    position: relative;
    display: inline-block;
    padding-right: 30px;
    color: $color-brand-green;
    cursor: pointer;
    user-select: none;
  }

  &__toggle-arrow {
    position: absolute;
    top: -8px;
    right: 0;
  }
}
