.AddedSauce {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &_outOfStock {
    .AddedSauce__text {
      color: $color-brand-red;
    }

    .AddedSauce__remove {
      color: $color-brand-green;
    }
  }

  &__text {
    margin-right: auto;
  }

  &__gift {
    width: 16px;
    height: 16px;
    margin-left: 20px;
    flex-shrink: 0;
    color: $color-brand-red;
  }

  &__remove {
    margin-left: 20px;
    display: flex;
    align-items: center;
    color: $color-grey;
    font-size: $font-size-xs;
    font-weight: bold;
    transition: color $duration;

    svg {
      width: 10px;
      height: 10px;
    }

    &:hover {
      color: $color-brand-red;
    }
  }
}
