.AddButton {
  display: inline-block;
  cursor: pointer;
  font-family: $font-secondary;
  font-size: $font-size-s;
  font-weight: 500;
  color: $color-brand-brown;
  transition: color $halfduration;
  padding-left:12px;

  &__icon {
    width: 24px;
    height: auto;
    margin-right: 8px;
    vertical-align: middle;
    color: $color-grey;
    transition: color $halfduration;
  }

  &__text {
    vertical-align: middle;
  }

  &:hover {
    color: $color-brand-green;

    .AddButton__icon {
      color: inherit;
    }
  }
}
