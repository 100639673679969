.container {
  margin-top: 64px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: left;
  gap: 20px;
  cursor: pointer
}

.container_mobile {
  margin-top: 12px;
}

.main {
  font-family: sans-serif Manrope;
  font-size: 90px;
  font-weight: 700;
  line-height: 92px;
}

.main_mobile {
  font-family: sans-serif Manrope;
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
}

.description {
  font-family: sans-serif Manrope;
  font-size: 40px;
  font-weight: 400;
  line-height: 52px;
  letter-spacing: 0.02em;
}

.description_mobile {
  font-family: sans-serif Manrope;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
}

.date {
  margin-top: 32px;
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
  letter-spacing: 0.02em;
  color: #66F8BE;
}

.date_mobile {
  margin-top: 12px;
  margin-bottom: 12px;
  font-family: sans-serif Manrope;
  font-size: 16px;
  font-weight: 800;
  line-height: 28px;
  letter-spacing: 0.02em;
}

.steps {
  margin-top: 24px;
  width: 600px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  color: #FFFFFF;
}

.steps_mobile {
  margin-top: 12px;
  width: 100%;
}

.stepBlock {
  display: flex;
  align-items: center;
  gap: 24px
}

.number {
  font-family: sans-serif Manrope;
  font-size: 72px;
  font-weight: 800;
  line-height: 72px;
  letter-spacing: 0.02em;
  color: #66F8BE;
}

.number_mobile {
  font-size: 56px;
}

.text {
  font-family: sans-serif Roboto;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.02em;
  z-index: 200;
}

.text_mobile {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
}

.green {
  color: #66F8BE;
  font-weight: 700;
}

.small {
  font-family: sans-serif Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
}

.button {
  width: 250px;
  margin-top: 24px;
  font-family: sans-serif Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  background: #1AB779;
}

.buttonBlock {
  padding-right: 24px;
}

.button_mobile {
  width: 100%
  z-index: 200;
}

.link_mobile {
  color: #FFFFFF
  width:100%
}

.logos {
  margin-top: 84px;
  display: flex
  gap: 46px
}

.logos_mobile {
  display: flex;
  flex-direction: column;
  gap: 24px
  margin-top: 24px;
  z-index: 200;
}
