.Partners {
  background: var(--papa-color-1);
  padding 74px 0

  @media $tablet {
    padding 36px 0
  }

  @media $phone {
    padding 28px 0
  }

  &__container {
  }

  &__title {
    color white
    text-align center
    margin-bottom 48px

    @media $tablet {
      margin-bottom 20px
    }
  }


  &__list {
    width 100%
    display grid
    grid-template-columns repeat(4, 1fr);
    gap 8px

    @media $tablet {
      grid-template-columns repeat(2, 1fr);
      column-gap 20px
      row-gap 16px
    }
    @media $phone {
      grid-template-columns unset;
      gap 8px
    }
  }


  &__item {
    display flex
    justify-content center
    align-items center
    background white
    border-radius 12px
    padding 35px

    img {
      max-width 100%
    }
    @media $tablet {
      padding 24px

    }

    &:nth-child(n+5) {
      transform translateX(50%)

      @media $tablet {
        transform none
      }
    }

    &:nth-child(n+7) {

      @media $tablet {
        transform translateX(50%)
      }
      @media $phone {
        transform none
      }
    }
  }
}
