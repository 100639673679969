.FloatingCart {
  width: auto;
  height: 56px;
  padding: 0 15px;
  position: fixed;
  bottom: 4%;
  right: 64px;
  z-index: 999;
  display: flex;
  align-items: center;
  opacity: 0;
  border-radius: 100px;
  background-color: $color-brand-red;
  box-shadow: 0 4px 5px 0 rgba(41, 44, 51, 0.2);
  color: white;
  transition: transform $duration, opacity $duration;
  transform: scale(0.85);
  pointer-events: none;

  @media $verticalTablet {
    right: $phone-margin-x;
  }

  &_visible {
    opacity: 1;
    transform: scale(1);
    pointer-events: auto;
  }

  &:active {
    transform: scale(0.95);
    transition-duration: $halfduration;
  }

  &__icon {
    width: auto;
    height: 25px;
  }

  &__amount {
    margin-left: 10px;
    color: inherit;
  }

  &__promoGame-decor {
    position: absolute
    bottom: 0;
    transform: translateY(-86%);
    left: 15px;
    right : 15px;

    img {
      width: 100%
    }
  }

  &__promoArtefact {
    top: 0;
    right: 20px;
    transform:  translateY(-100%);
  }

  &__cosmo-decor {
    position absolute
    top -26px
    left 17px
  }

  &__movie-decor {
    width: 140px;
    top: -92px;
    left: -15px;
  }

  &__promoBorder {
    width: 140px;
    top: 0;
    right: 0;
    transform translateY(-100%)
  }

  &__PaddingtonDecor {
    width: 96px;
    top: 0;
    left: 50%;
    transform translate(-50%, -88%)

    @media $phone {
      width: 53px;
    }
  }
}
