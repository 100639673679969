.IncludeIngredient {
  & {
    position relative
    display flex
    padding-top: $phone-margin-y
    padding-bottom: $phone-margin-y
    border-bottom 1px solid #f5f5f5
  }

  &__cell {
    &_remove {
      position relative
      width 52px
      padding-top 5px
      padding-left $phone-margin-x
    }

    &_info {
      flex-grow 1
      padding-right 10px
    }

    &_portion {
      width 94px
      padding-right $phone-margin-x
    }
  }

  &__remove {
    color $color-grey

    svg {
      display block
    }
  }

  &__name {
    margin-bottom 10px
    font-size $font-size-m

    &:first-letter {
      text-transform: capitalize
    }
  }
}
