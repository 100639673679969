.Accordion {
  margin-top: 20px;
  &__item {
    background: #F8F8F8;
    border-radius: 20px;
    width:619px;
    height:77px;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 17px;
    color: #70544F;
    padding: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media $phone {
      height:61px;
      width: 100%;
      font-weight: 400;
      font-size: 14px;
      line-height: 13px;
      color: #70544F;
    }
  }
}
