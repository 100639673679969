.CheckoutFooter {
  &__agree {
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: $font-size-xs;

    &-title {
      margin-bottom: 12px;
      text-align: center;
    }

    &-checkboxes {
      display: flex;
      justify-content: center;
    }

    &-checkbox {
      color: $color-text;
      font-size: $font-size-xs;
      font-family: $font-primary;
      font-weight: 400;
      line-height: 1;

      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }

  &__legal {
    padding: 16px;
    font-size: $font-size-xs;
    text-align: center;
  }

  &__footer {
    padding: 25px 16px;
    position: sticky;
    left: 0;
    bottom: 0;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 5px rgba(32, 38, 55, 0.08);
    border-radius: 25px 25px 0px 0px;
    background-color: white;
  }

  &__price {
    padding-left: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__amount {
    color: $color-brand-green-dark;
  }

  &__delivery {
    margin-top: 10px;
    font-size: $font-size-xs;
    text-transform: lowercase;
    text-align: right;
  }

  &__captcha {
    margin-bottom: 16px;
    display: none;
    align-self: center;

    &_visible {
      display: block;
    }
  }

  &__privacy {
    &-title {
      display: flex;
      align-items: center;
    }

    &-description {
      padding-top: 8px;
      font-size: $font-size-xs;
      color: $color-text;
      line-height: 1.33;
      font-weight: 400;
      font-family: $font-primary;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    &-subtitle {
      font-size: $font-size-s;
      font-weight: 500;
      font-family: $font-secondary;
      line-height: 1;
      color: $color-brand-brown;
    }
  }
}