.StockGoods {
  padding-top: 60px;

  &:last-of-type {
    padding-bottom: 60px;
  }

  &__heading {
    margin-bottom: 16px;
  }

  &__list {
    margin: -($card-margin);
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
  }

  &__item {
    width: 'calc(50% - %s)' % ($card-margin * 2);
    margin: $card-margin;
  }
}
