.Scene {
  position: relative;
  display: flex;
  flex-direction: column;

  &__navbar {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1000;
  }

  &__body {
    position: relative;
  }

  &__footer {
    position: sticky;
    bottom: 0;
    left: 0;
  }

  &_tablet {
    width: 584px;
    margin-left: auto;
    margin-right: auto;
    background-color: transparent !important;
  }
}
