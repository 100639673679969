.Contacts {
  display: flex;
  flex-direction: column;

  &__modal {
    padding: $checkout-mobile-block-padding;
  }

  &__field {
    margin-bottom: $checkout-mobile-margin-15;
    padding-bottom: $checkout-mobile-margin-15;
    border-bottom: 2px solid $color-bg;
  }

  &__input {
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  &__address {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: $checkout-mobile-margin-15;
    }

    &-left {
      margin-right: $checkout-mobile-margin-15;
      display: flex;
      flex-direction: column;
      text-align: left;
    }

    &-top {
      mobileinputlabel();
    }

    &-bottom {
      color: $color-brand-brown;
      font-size: $font-size-m;
      line-height: 1.33;
      font-family: $font-primary;
      font-weight: 500;

      &_red {
        color: $color-brand-red-light;
      }
    }

    &-right {
      width: 20px;
      height: auto;
      flex-shrink: 0;
    }
  }
}