.Gift {
  display: flex;
  align-items: center;
  position: relative;
  height: 24px;
  color: $color-brand-red;

  &__icon {
    svg {
      display: block;
    }
  }

  &__count {
    margin-top: 3px;
    margin-left: 3px;
    font-family: $font-secondary;
    font-weight: 500;
    color: $color-brand-brown;
    font-size: $font-size-xs;
  }
}
