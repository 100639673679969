.Characteristics {
  & {
    position absolute
    top 3px
    right 0
  }

  &__icon {
    color $color-grey
    cursor pointer
    user-select none
    transition: color $duration

    &:after {
      content: ''
      position: absolute
      top: -9px
      right: -9px
      bottom: -9px
      left: -9px
    }

    &:hover {
      color: #807573

      transition-duration: $halfduration;
    }

    svg {
      display: block
    }

  }

  &__tooltip {
    position absolute
    bottom 100%
    left -13px
    width 248px
    padding 16px
    margin-bottom 13px
    border-radius $border-radius
    background-color $color-brand-brown
    color white
    z-index 1000
    opacity 0
    visibility hidden
    transition opacity ($duration / 2), visibility ($duration / 2)

    @media $tablet {
      right @left
      left auto
    }

    &:after {
      content ''
      position absolute
      top 100%
      left 16px
      width 0
      height 0
      border-style solid
      border-width 6px 6px 0 6px
      border-color $color-brand-brown transparent transparent transparent

      @media $tablet {
        right @left
        left auto
      }
    }

    &_visible {
      opacity 1
      visibility visible
    }
  }

  &__tooltip-title {
    margin-bottom 10px
    color white
  }
}
