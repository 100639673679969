.Preloader {
  display: flex;
  align-items: center;

  &__animation {
    display: flex;
    justify-content space-between
    margin-left: 10px
    width: 50px;

    div {
      height: 10px;
      width: 10px;
      border-radius 50%;
      background: #a69895;

      &:first-child {
        animation: animate3 2s linear infinite;
      }

      &:nth-child(2n) {
        animation: animate2 2s linear infinite;
      }

      &:nth-child(3n) {
        animation: animate1 2s linear infinite;
      }
    }
  }
}

@keyframes animate1 {
  30% {
    transform: scale(1);
  }

  65% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes animate2 {
  20% {
    transform: scale(1);
  }

  50% {
    transform: scale(0);
  }

  80% {
    transform: scale(1);
  }
}

@keyframes animate3 {
  10% {
    transform: scale(1);
  }

  35% {
    transform: scale(0);
  }

  60% {
    transform: scale(1);
  }
}
