.SignUpForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  &__step {
    width: 100%;
    margin-bottom: 20px;
    display: none;
    flex-direction: column;

    &_visible {
      display: flex;
    }
  }

  &__field {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__privacy {
    &-title {
      display: flex;
      align-items: center;
    }

    &-description {
      margin-bottom: 20px;
      padding-top: 8px;
      font-size: $font-size-xs;
      color: $color-text;
      line-height: 1.33;
      font-weight: 400;
      font-family: $font-primary;
    }

    &-subtitle {
      font-size: $font-size-s;
      font-weight: 500;
      font-family: $font-secondary;
      line-height: 1;
      color: $color-brand-brown;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__button {
    &_left {
      margin-right: 40px;
    }
  }

  &__error {
    font-family: $font-primary;
    font-size: $font-size-xs;
    line-height: 1.33;
    font-weight: 400;
    color: $color-brand-red;
  }

  &__errors {
    margin-top: 20px;
  }

  &__success {
    width: calc(100% + 40px);
    margin-left: -20px;
  }
}
