.Image {
  display block
  width 50px

  &_preview {
    &:before {
      $size = 40px
      content ''
      display block
      width $size
      height $size
      margin-left auto
      margin-right auto
      border-radius 50%
      background-color #ededed
    }
  }
  img {
    max-width 100%
  }
}
