.Footer {
  padding-top: 20px;
  padding-bottom: 40px;
  background-color: white;

  &__body {
    margin-bottom: 24px;
  }

  &__phone {
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid $color-border;
  }

  &__row {
    width: 100%;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    &:last-child {
      margin-bottom: 0;
    }

    &_second {
      flex-direction: column-reverse;
    }
  }

  &__nav {
    width: 100%;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__apps {
    position relative
    display: flex;
    margin-left: auto;
    margin-left: 0;
  }

  &__promoGame {
    top: -45px;
    left: 0;
  }

  &__app {
    margin-right: 10px;
    z-index: 1;

    &:last-child {
      margin-right: 0px;
    }
  }

  &__socials {
    display: flex;
    width: 100%;
    margin-bottom: 32px;
    display: flex;
    justify-content: center;
  }

  &__social {
    flex-shrink: 0;
    margin-right: 10px;

    &:last-child {
      margin-right: 0px;
    }
  }

  &__copyright {
    font-size: $font-size-xs;

    &-line {
      display: block;
    }
  }

  &__legal {
    text-align: left;
  }

  &__legal-link {
    display: inline-block;
    padding: 5px 0;
    font-size: $font-size-xs;
    color: $color-text;
    transition: color $duration;

    &:hover {
      color: $color-brand-green;
      transition-duration: $halfduration;
    }
  }
}
