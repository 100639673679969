.CommentField {
  margin-bottom: $checkout-mobile-margin-15;

  &__button {
    display: flex;
    align-items: center;
    font-family: $font-secondary;
    font-weight: 500;
    font-size: $font-size-s;
    color: $color-grey;
    line-height: 1.33;
    cursor: pointer;
  }

  &__icon {
    width: 10px;
    height: auto;
    margin-right: $checkout-mobile-margin-10;
    margin-bottom: 2px;
    fill: $color-grey;
  }

  &__modal {
    padding: $checkout-mobile-block-padding;
    display: flex;
    flex-direction: column;
    background white
    border-radius: 25px 25px 0px 0px;
  }

  &__subtitle {
    margin-bottom: $checkout-mobile-margin-15;
    text-align: center;
  }

  &__textarea {
    margin-bottom: $checkout-mobile-margin-15;
  }

  &__accept {
    align-self: center;
  }
}
