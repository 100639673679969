.ResultsBlock {
  background var(--papa-color-4);
  padding 74px 0
  overflow-x clip

  @media $tablet {
    padding 36px 0
  }

  @media $phone {
    padding 28px 0
  }

  &__title {
    color white
    text-align center
  }

  &__group {
    padding-top 46px
    @media $tablet {
      padding-top 24px
    }
    @media $phone {
      padding-top 30px
    }
  }

  &__group_title {
    color white
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;

    @media $tablet {
      font-size: 24px;
      line-height: 32px;
    }

    @media $phone {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &__group_leadersContainer {
    display grid
    grid-template-columns 1fr 1fr
    column-gap 10px
    row-gap 18px
    padding-top 32px

    @media $tablet {
      grid-template-columns 1fr
      padding-top 24px
    }

    @media $phone {
      padding-top 16px
    }
  }

  &__group_leader {
    display flex
    flex-direction column
    border-radius 20px
    padding 22px 20px
    color white

    @media $phone {
      border-radius 12px
      padding 12px
    }

    &_1 {
      grid-row 1 / 3
      background var(--papa-color-2);
      color black
      @media $tablet {
        grid-row unset
      }
    }

    &_2 {
      background $color-brand-red;
    }

    &_3 {
      background var(--papa-color-1);
    }

    &_place {
      font-size: 28px;
      font-weight: 700;
      line-height: 32px;
      text-align: center;
      @media $tablet {
        font-size: 24px;
        line-height: 32px;
      }

      @media $phone {
        font-size: 20px;
        line-height: 28px;
      }
    }

    &_content {
      display grid
      grid-template-columns 2fr 3fr
      column-gap 20px
      padding-top 12px
      margin-bottom 24px

      @media $phone {
        grid-template-columns 1fr
        padding-top 8px
        margin-bottom 12px
      }

      &_1 {
        height 100%
        grid-template-columns 1fr
      }
    }

    &_photo {
      display block
      margin auto
      object-fit cover
      width auto
      height 100%
      max-width 100%
      max-height 200px
      border-radius 12px
      @media $phone {
        max-height 300px
      }

      img {
      }

      &_1 {
        max-height 500px
        @media $phone {
          max-height 300px
        }
      }
    }

    &_text {
      align-self center
      display flex
      flex-direction column
      gap 16px

      margin-top 20px
      @media $phone {
        margin-top 12px
        align-items center
      }

      &_1 {
        align-items center
      }


      &_name {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
      }

      &_descr {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        line-clamp: 5;
        -webkit-box-orient: vertical
        white-space unset

        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;

        @media $phone {
          text-align center
        }
        &_1 {
          -webkit-line-clamp: 2;
          line-clamp: 2;
          text-align center
          @media $tablet {
            -webkit-line-clamp: 3;
            line-clamp: 3;
          }
          @media $phone {
            -webkit-line-clamp: 5;
            line-clamp: 5;
          }
        }
      }
    }

    &_button {
      margin-top auto
      &:hover&:before {
        background: $color-brand-green-hover
        transform none !important
      }
    }
  }

  &__slider {
    overflow visible
    margin-top 46px
    @media $phone {
      margin-top 24px
    }

    div:first-of-type {
      overflow visible
    }

    &_item {
      max-height 280px
      width auto
      border-radius 16px
      cursor pointer
      @media $tablet {
        max-height 180px
      }
    }

    &_position {
      position absolute
      top 10px
      right 10px
      width 48px
      height 48px
      border-radius 50%
      display flex
      align-items center
      justify-content center
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
      text-align: center;
      background white
      color black

      @media $tablet {
        width 36px
        height 36px
        font-size: 16px;
      }
    }
  }
}
