.HomePage__new-year {
  padding-bottom: 0 !important;
  background-image: url($colorNY-bg);
  background-repeat: repeat;
  background-size: contain;
  position: relative;

  .HomePage {
    &__header {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: calc(70px + 25 * ((100vw - 1500px) / 920));
      z-index: 10;
      background-image: url($colorNY-slider-header);
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}

.HomePage {
  padding-bottom: 80px;
  position: relative;

  &__content {
    transition: opacity 0.3s;
    opacity: 1;

    &_hidden {
      opacity: 0;
    }
  }

  &__promocode {
    padding: $paper-pad-y $paper-pad-x;
  }

  &__modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 390px;
    background-color: #FFFFFF;
    border-radius: 25px;
  }

  &__modal-auth {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 318px;
    background-color: #FFFFFF;
    border-radius: 25px;
  }

  &__picture {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  &__header {
    display: block;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    color: #50A684;
    padding-bottom: 12px;
  }

  &__text {
    display: block;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    margin: 0 40px;
    color: #70544F;
  }

  &__order-button {
    margin: 40px;
    width: 310px;
  }

  &__order-button-auth {
    margin: 40px;
    width: 238px;
  }

  &__PopupPromoGame {
    position: fixed;
    left: 19px;
    bottom: 20px;
  }

  &__promoGame-artefact {
    z-index: 10;
    right: 7px;
    top: 20%;
    transform: rotate(-90deg);
    height: 90px;

    &_mm {
      position: fixed
      height: 159px
      top: 65%;
      transform: rotate(-45deg) translate(32px)
    }
  }
}


