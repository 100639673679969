.BonusesHistory {
  display: flex;
  flex-direction: column;

  &__navbar {
    display: none;

    @media $phone {
      display: flex;
    }
  }

  &__selector {
    margin-bottom: 30px;
    background-color: #FFFFFF;

    @media $phone {
      margin-bottom: 15px;
      padding: 10px 16px 20px 16px;
      border-radius: 0 0 $checkout-mobile-button-radius $checkout-mobile-button-radius;
      box-shadow: 0px 5px 5px rgba(32, 38, 55, 0.02);
    }
  }

  &__container {
    background-color: white;

    @media $phone {
      padding: 0 16px 20px 16px;
      background-color: inherit;
    }
  }

  &__list {
    margin: -7.5px -10px;
    display: flex;
    flex-wrap: wrap;
  }
}

.Selector {
  width: 100%;
  height: $checkout-desktop-button-height;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-radius: $checkout-desktop-button-radius;
  background-color: $color-bg;

  @media $phone {
    margin-bottom: 20px;
    height: $checkout-mobile-button-height;
    border-radius: $checkout-mobile-button-radius;
  }

  &__background {
    width: calc((100% / 3));
    height: 100%;
    padding: $checkout-desktop-button-padding;
    background-color: $color-brand-green;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: inherit;
    transition: transform $duration;
    transform: translateX(0);

    &_spent {
      transform: translateX(100%);
    }

    &_expired {
      transform: translateX(200%);
    }

    @media $phone {
      padding: $checkout-mobile-button-padding;
    }
  }

  &__view {
    width: calc((100% / 3));
    height: 100%;
    position: relative;
    font-family: $font-primary;
    font-weight: 500;
    font-size: $font-size-m;
    line-height: 1.33;
    color: $color-brand-brown;

    &_active {
      color: white;
    }

    @media $phone {
      font-size: $font-size-s;
    }
  }
}

.Info {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &_confirmed {
    color: $color-brand-green;
  }

  &_spent {
    color: #EB5757;
  }

  &_expired {
    color: #F2994A;
  }

  &__confirmed {
    font-family: $font-primary;
    font-weight: 500;
    font-size: $font-size-s;
    line-height: 1.33;
    color: #828282;
  }

  &__title {
    font-family: $font-secondary;
    font-weight: 700;
    font-size: $font-size-l;
    line-height: 1;
    color: $color-brand-brown;

    @media $phone {
      display: none;
    }
  }

  &__credited {
    font-family: $font-primary;
    font-weight: 500;
    font-size: $font-size-s;
    line-height: 1.33;
    color: #828282;
  }
}

.HistoryItem {
  width: calc(50% - 20px);
  margin: 7.5px 10px;
  padding: 20px;
  display: flex;
  align-items: center;
  background-color: $color-bg;
  border-radius: 25px;

  @media $phone {
    width: 100%;
    background-color: white;
  }

  &_brown {
    color: $color-brand-brown;
  }

  &__left {
    width: 81px;
    height: 66px;
    margin-right: 20px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 15px rgba(32, 38, 55, 0.15);
    border-radius: 25px;

    &_confirmed {
      background-color: $color-brand-green;
    }

    &_spent {
      background-color: #EB5757;
    }

    &_expired {
      background-color: #F2994A;
    }
  }

  &__amount {
    font-family: $font-primary;
    font-weight: 500;
    font-size: $font-size-m;
    line-height: 1;
    color: white;
  }

  &__right {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__title {
    margin-bottom: 5px;
    font-family: $font-primary;
    font-weight: 400;
    font-size: $font-size-m;
    line-height: 1.33;
    color: #828282;
  }

  &__description {
    font-family: $font-primary;
    font-weight: 400;
    font-size: $font-size-s;
    line-height: 1.33;
    color: $color-grey;
  }
}
