.Coloring {
  position: relative;
  height: auto;
  overflow-x: hidden;
  background-color: $color-brand-green;
  padding: 60px 0 80px;

  @media $tablet {
    padding: 28px 0 32px;
  }

  &__bg {
    position: absolute;
    height: auto;
    width 15vw
    @media $tablet {
      display: none;
    }

    &_1 {
      position: absolute;
      left: 0;
      bottom: 10vw;
    }

    &_2 {
      right: 0;
      top: 0;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 !important;
    @media $tablet {
      padding: 28px 0 32px;
    }

    @media $phone {
      padding: 20px 0 28px;
    }


  }

  &__heading, &__description {
    color: white
  }

  &__description {
    white-space: break-spaces;
    text-align center;
    padding-top: 32px;
    @media $tablet {
      padding-top: 16px;
    }

    @media $phone {
      padding-top: 8px;
    }
  }


  &__slider {
    position: relative;
    width: 100%;

    &_wrapper {
      width: 100%;
      position: relative;
      padding-top: 40px

      @media $tablet {
        padding-top: 20px;
      }

      @media $phone {
        padding-top: 32px;
      }

    }

    &_item {
      position relative
      display flex
      justify-content center
      align-items center
      aspect-ratio: 0.7;
      border-radius: 16px;
      overflow hidden
      background-color white


      &:hover > a {
        z-index 5
      }

      &:hover > .overlay {
        background-color: rgba(46, 44, 44, 0.8)
      }
    }

    &_overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0);
      transition: all .5s

    }

    &_coloring {
      width 100%
      height 100%
      object-fit scale-down
    }

    &_button {
      z-index -5
      position absolute
    }
  }
}

:global {
  .Brand_colors {
    .swiper-button-next, .swiper-button-prev {
      @media $tablet {
        display: none;
      }
    }
    .swiper-container {
      @media $tablet {
        overflow: unset;
      }
    }
  }
}

