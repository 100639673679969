.Cart {
  position relative
  padding-top 40px

  @media $tablet {
    padding-top 0
  }

  &__line {
    position absolute
    top calc(40px - 3px)
    left 0
    width 100%
    height 3px
    background-color $color-brand-red
    border-radius 2px

    @media $tablet {
      display none
    }
  }

  &__decor {
    box-sizing border-box
    position absolute
    top 0
    left 50%
    width 96px
    height @width
    margin-left -(@width / 2)
    border-radius 50%
    background-color white
    border 3px solid $color-brand-red

    @media $tablet {
      display none
    }
  }

  &__icon {
    position absolute
    top -20px
    left 50%
    margin-left -16px
    color $color-brand-red

    @media $tablet {
      display none
    }

    svg {
      display block
    }
  }

  &__inner {
    position relative
    flex-grow 1
    flex-basis 100%
    display flex
    flex-direction column
    padding $paper-pad-x
    border-top-left-radius 2px
    border-top-right-radius @border-top-left-radius
  }

  &__header {
    flex-shrink 0
    display flex
    justify-content space-between
    align-items baseline
    margin-bottom 10px

    @media $tablet {
      margin-bottom 16px
    }

    @media $phone {
      margin-bottom @margin-bottom
    }
  }

  &__heading {
    @media $tablet {
      font-size $font-size-l
    }
  }

  &__headline {
    position relative
    padding-right 30px
  }

  &__title {
    font-size $font-size-l
  }

  &__list-wrapper {
    margin: 0 (-($paper-pad-x))
  }

  &__list {
    padding 0 $paper-pad-x
  }

  &__actions {
    flex-shrink 0
    padding-top 15px
    margin-bottom 8px
    text-align center
  }

  &__content {
    overflow hidden
    scrollable()
    custom-scrollbar(3px, $color-brand-red )
    margin-left -($paper-pad-x)
    margin-right -($paper-pad-x)
    padding 0 $paper-pad-x
  }

  &__section {
    margin-bottom 10px
    padding-bottom @margin-bottom
    border-bottom 1px solid $color-border

    &:last-child{
      margin-bottom 0
    }

    &-title{
      margin-bottom 12px
      font-size $font-size-xs

      ^[1]_done & {
        color $color-brand-green
      }
    }
  }

  &__total {
    display flex
    justify-content space-between
    align-items baseline
    padding-top 15px
    padding-bottom 10px
  }

  &__total-label {
    font-weight 500
  }

  &__total-value {
    flex-shrink 0
    flex-grow 1
    text-align right
  }

  &__error {
    text-align center
  }

  &__error > div {
    padding-bottom 10px
    font-size 11px
  }

  &__error > div:nth-last-child(1) {
    padding 0
  }
}
