.SaucesList {
  width: 200px;
  max-height: 200px;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  border-radius: 2px;
  border-right: 2px solid transparent;
  background-color: white;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: $box-shadow;
  z-index: 100;
  custom-scrollbar(2px, #e6e2e1, 0);

  &__item {
    padding: 10px 0;
    display: flex;
    align-items: center;
    color: $color-brand-brown;
    font-size: $font-size-s;
    cursor: pointer;
    transition: background-color $duration;

    &:hover {
      background-color: $color-bg;
      transition-duration: $halfduration;

      .SaucesList__check {
        opacity: 1;
      }
    }
  }

  &__check {
    width: 16px;
    height: 16px;
    margin: 0 8px;
    flex-shrink: 0;
    color: $color-brand-green;
    opacity: 0;
    transition: opacity $halfduration;
  }
}
