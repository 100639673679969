.Header {
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  background-color: white;
  box-shadow: 0 2px 24px 0 rgba(83, 92, 90, 0.38);
  opacity: 0;
  transform: translateY(-60px);
  transition: transform $duration, opacity $duration;

  @media $tablet {
    display: none;
  }

  &_entered {
    transform: translateY(0);
    opacity: 1;
  }

  &__container {
    display: flex;
    align-items: center;
  }

  &__logo {
    //height: 60%;
    flex-shrink: 0;
    margin-right: auto;

    img {
      height: 100%;
    }
  }

  &__categories {
    height: 100%;
    display: flex;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
  }

  &__category {
    padding: 0 10px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $font-size-s;
    color: $color-brand-brown;
    font-weight: bold;
    font-family: $font-secondary;
    text-transform: uppercase;
    white-space: nowrap;
    cursor: pointer;

    &:after {
      content: '';
      height: 4px;
      position: absolute;
      bottom: 0;
      left: 9px;
      right: 9px;
      border-radius: 2px;
      background-color: $color-brand-red;
      opacity: 0;
      transition: opacity $duration;
    }

    &_active, &:hover {
      &:after {
        opacity: 1;
        transition-duration: $halfduration;
      }
    }
  }

  &__hamburger {
    width: 50px;
    height: 50px;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .Header__hamburger-line, svg path {
      transition-duration: $duration;
    }

    &:hover {
      svg path {
        fill: $color-brand-green;
      }

      .Header__hamburger-line {
        background: $color-brand-green;
      }
    }

    &-line {
      width: 20px;
      height: 3px;
      margin: 1px 0;
      display: block;
      background: $color-grey;

      &:first-child {
        margin-top: 0px;
      }

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}
