.Map {
  background-color: $color-bg;
  overflow: hidden;
  height: 100%;
  @media $verticalTablet {
    overflow: visible;
    height: auto;
  }
  @media $verticalTablet {
    margin-left: $tablet-margin-x * -1;
    margin-right: $tablet-margin-x * -1;
  }

  @media $phone {
    margin-bottom: 32px;
  }

  &__searchPopupHidden ymaps[class*='popup'] {
    display: none;
  }

  &__preloader {
    spinner(48px, 3px, $color-brand-green);
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -24px;
    margin-left: -24px;
  }

  &__inner {
    position: relative;
    padding-top: 46.357616%;
    background-color: $color-bg;
    height: 100%;

    @media $verticalTablet {
      height: auto;
      padding-top: 60%;
    }

    @media $phone {
      background-color: white;
      padding-top: 50px;
    }


  }

  &__layout {
    &, & > div:not(.Map__preloader) {
      fullsize();
      height: 100%;
      @media $phone {
        position: relative;
        height: 90vh;
        min-height: 280px;
      }

      @media $verticalPhone {
        height: 410px;
      }
    }
  }

  &__toolbar {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 30px;
    left: 30px;
    width: 360px;
    max-height: 'calc(100% - %s * 2)' % @top;
    padding: 20px 14px;
    box-shadow: 0 2px 8px 0 rgba(41, 44, 51, 0.08);
    border-radius: 20px;

    @media $phone {
      min-height: 50px;
      top: 0;
      left: @top;
      right: @top;
      width: auto;
      padding: 0;
      background-color: white;
      border-top: 1px solid #ededed;
      border-bottom: 1px solid #ededed;
      border-radius: 0;
    }
  }

  &__legend {
    position: absolute;
    top: 30px;
    right: 30px;
    max-width: 200px;
    min-height: 120px;
    background-color: #fff;
    box-shadow: $box-shadow;
    border-radius: 20px;

    @media $phone {
      position: static;
      max-width: 100%;
      min-height: 0;
      box-shadow: none;
      border-radius: 4px;

      & ^[0]__legendTabs {
        display: none;
      }

      & ^[0]__legendGroup {
        padding-left: $phone-margin-x;
        padding-right: $phone-margin-x;
        padding-bottom: 0;
      }

      & ^[0]__legendText {
        margin-bottom: 6px;
        font-weight: normal;
        text-align: left;
        color: $color-text;
      }

      & ^[0]__legendItems {
        justify-content: flex-start;
      }

      & ^[0]__legendItem {
        flex-basis: auto;
      }
    }
  }

  &__legendGroup {
    padding: 20px;
    font-size: $font-size-xs;

    @media $phone {
      padding: 12px 32px 16px;
    }
  }

  &__legendText {
    flex-grow: 1;
    margin-bottom: 8px;
    color: $color-brand-brown;
    font-family: $font-secondary;

    @media $phone {
      font-weight: bold;
      text-align: center;
    }
  }

  &__legendItems {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__legendItem {
    display: flex;
    align-items: baseline;
    margin-right: 16px;
    margin-bottom: 5px;
    flex: 0 0 50%;
    line-height: 18px;

    &:last-child {
      margin-bottom: 0px;
    }

    @media $phone {
      margin-bottom: 0px;
    }

    &:before {
      content: '';
      display: block;
      width: 10px;
      height: @width;
      margin-right: 3px;
      border-radius: 50%;
      background-color: currentColor;
      top: 1px;
      position: relative;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__legendLabel {
    color: $color-brand-brown;
    font-size: $font-size-xs;
  }

  &__legendTabs {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;

    @media $phone {
      margin-bottom: 16px;
      margin-left: -8px;
      margin-right: @margin-left;
    }
  }

  &__legendTab {
    width: 100%;
    text-align: center;
    padding-bottom: 10px;
    position: relative;
    transition: color $duration;
    font-family: $font-secondary;
    cursor: pointer;
    font-weight: 500;

    @media $phone {
      padding-bottom: 4px;
      font-weight: bold;
      flex: 0 0 50%;
      &:first-child:after {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

      &:last-child:after {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #ededed;
      transition: background-color $duration;
    }


    &:only-child {
      cursor: default;
    }

    &_active {
      color: $color-brand-green;
      cursor: default;
      transition-duration: $halfduration;

      &:after {
        background-color: $color-brand-green;
        transition-duration: $halfduration;
      }
    }

    &:hover {
      color: $color-brand-green-hover;
    }
  }

  &__searchAddress {
    &_invisible {
      display: none;
    }
  }

  &__search {
    $padding-left = 25px;
    position: relative;
    transition: opacity $duration;

    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      height: 24px;
      width: 100%;
      background-image: linear-gradient(to bottom, white 20%, rgba(255, 255, 255, 0));
      z-index: 2;

      @media $phone {
        display: none;
      }
    }

    &_disabled {
      pointer-events: none;

      & ^[0]__searchIcon {
        visibility: hidden;
      }

      & ^[0]__searchInput {
        placeholder(transparent);
      }
    }

    &Icon {
      position: absolute;
      top: 50%;
      right: 16px;
      margin-top: -12px;
      color: $color-grey;
      pointer-events: none;
      transition: transform $duration ($duration / 2);
    }

    &Cleaner {
      $height = 30px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 50%;
      right: 5px;
      height: $height;
      margin-top: (- $height / 2);
      padding-left: 10px;
      padding-right: @padding-left;
      color: $color-grey;
      visibility: hidden;
      opacity: 0;
      cursor: pointer;
      z-index: 1;
      transition: visibility 0s $duration, opacity $duration, color $duration;

      &:hover {
        color: $color-text;
      }
    }

    &Field {
      position: relative;

      &_dirty {
        & .Map__searchIcon {
          transform: translateX(-100%);
          transition: transform $duration 0s;
        }

        & .Map__searchCleaner {
          visibility: visible;
          opacity: 1;
          transition: visibility 0s 0s, opacity $duration, color $duration;
        }
      }
    }

    &Input {
      display: block;
      width: 100%;
      height: 62px;
      border: none;
      padding: 0 70px 0 36px;
      font-size: $font-size-m;
      color: $color-text;
      border-radius: 15px;
      background-color: $color-bg;
      font-family: $font-primary;

      &::placeholder {
        color: $color-grey
      }
      @media $phone {
        border-radius: $input-border-radius;
        background-color: white;
        padding: 0 70px 0 $padding-left;
        height: 48px;
      }
    }

    & :global(ymaps[class*='popup']) {
      custom-scrollbar(2px, #e6e2e1);
      overflow: hidden;
      overflow-y: auto;
      max-height: 368px;
      margin-top: 24px;
      padding-right: 15px;
      border: none;
      box-shadow: $box-shadow !important;
      margin-left: -16px;
      margin-right: -16px;

      @media $phone {
        margin-top: 6px;
        padding-right: 0;
      }
    }

    &Field, & :global(ymaps[class*='popup']) {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        display: none;
        width: 32px;
        height: 100%;
        top: 0;
        right: 0;
        background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 1));
        pointer-events: none;
      }
    }

    & :global(ymaps[class*='suggest-item']) {
      min-height: 20px;
      white-space: normal;
      font: $font-size-s / 40px $font-primary;
      color: $color-brand-brown;
      background-color: #fff;
      transition: background-color $duration;

      &:global([class*='__suggest-item_selected_yes']), &:hover {
        background-color: $color-bg;
        transition-duration: $halfduration;
      }
    }

    & :global(ymaps[class*='search__suggest-item']) {
      padding-left: $padding-left;
      padding-right: 0;
      line-height: 20px;
      padding-top: 10px;
      padding-bottom: 10px;

      @media $phone {
        padding-right: 15px;
      }
    }

    &Error {
      @media $phone {
        margin-bottom: 10px;
        padding-left: $padding-left;
        padding-right: $padding-left;
        font-size: $font-size-xs;
      }
    }
  }
}

:global {
  .Map__hint {
    width: 172px;
    padding: 8px 8px 10px 30px;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    background-color: #fff;
  }

  .Map__hintContent {
    position: relative;
  }

  .Map__hintIcon {
    position: absolute;
    left: -20px;
    top: 2px;
    width: 16px;
  }

  .Map__hintTitle {
    margin-bottom: 4px;
    font: $font-size-s / 1.33 $font-secondary;
    font-weight: bold;
    color: $color-brand-brown;
  }

  .Map__hintTimework {
    margin-bottom: 4px;
    font: $font-size-xs / 1.33 $font-secondary;
    font-weight: bold;
    color: $color-brand-brown;
  }

  .Map__hintText {
    font: $font-size-xs / 1.33 $font-primary;
    color: $color-text;
  }
}

.InfoWindow {
  overflow: hidden;
  overflow-y: auto;
  max-height: 100%;

  @media $phone {
    overflow: hidden;
    position: fixed;
    max-height: 168px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 9999;
    box-shadow: 0 -2px 8px 0 rgba(41, 44, 51, 0.08);
    transition: transform $duration;

    &:after {
      content: '';
      position: absolute;
      height: 34px;
      bottom: 0;
      left: 0;
      right: 0;
      background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 1));
      background-image: none;
      background-color: #fff;
      pointer-events: none;
    }

    &_isOpen {
      transition-duration: $halfduration;
      max-height: 100%;

      & ^[0]__inner {
        overflow-y: auto;
      }
    }

    &_isAnimated {
      display: flex;
    }
  }

  &__container {
    @media $phone {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-bottom: 20px;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 24px;

    @media $phone {
      overflow: hidden;
      padding-top: 0;
      padding-left: $phone-margin-x;
      padding-right: $phone-margin-x;
    }
  }

  &__actions {
    position: relative;
    display: flex;
    flex-shrink: 0;
    margin-bottom: 15px;
  }

  &__toggleButton {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    flex-basis: 100%;
    padding-top: 18px;
    padding-bottom: 9px;

    &:before {
      chevron-right();
      position: static;
      transform: rotate(-135deg);
      transition: background-color $duration;

      ^[0]_isOpen & {
        transform: rotate(45deg);
      }
    }

    &:active {
      &:before {
        color: $color-brand-green-hover;
      }
    }
  }

  &__header, &__footer {
    flex-shrink: 0;
  }

  &__header {
    margin-bottom: 18px;
  }

  &__close {
    position: absolute;
    right: 20px;
    top: 47px;
    display: none;

    @media $phone {
      display: block;
    }
  }

  &__content {
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 16px + 48px;
    overflow-y: auto;
    max-height: 228px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__footer {
    position: absolute;
    z-index: 1;
    right: 0;
    width: 100%;
    height: 48px + 16px + 16px;
    bottom: 0;
    padding: 0 32px 0 16px;
  }

  &__footerGradient {
    background: linear-gradient(to bottom, rgba(30, 87, 153, 0) 0%, rgba(255, 255, 255, 1) 100%);
    height: 16px;
  }

  &__footerInner {
    height: 48px + 16px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-family: $font-secondary;
    font-weight: 500;
    color: $color-grey;
  }

  &__contentItem {
    position: relative;
    padding-top: 4px;
    padding-bottom: 16px;

    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
    }

    &_toggle {
      margin-bottom: 16px;
      border-bottom: 1px solid $color-border;

      &:last-child {
        margin-bottom: 0;
      }

      & .InfoWindow__label {
        position: relative;
        padding-right: 20px;
        cursor: pointer;
        user-select: none;

        &:hover {
          & .InfoWindow__toggleIcon {
            color: $color-brand-green;
            transition-duration: $halfduration;
          }
        }
      }
    }
  }

  &__contentIcon {
    position: absolute;
    width: 24px;
    top: -2px;
    right: 100%;
    margin-right: 8px;
  }

  &__label {
    width: max-content;
    max-width: 100%;
    font-weight: bold;
    color: $color-brand-brown;
    font-family: $font-secondary;
    font-size: $font-size-xs;
    margin-bottom: 6px;

    &_link {
      transition: color $duration;

      &:hover {
        color: $color-brand-green;
        transition-duration: $halfduration;
      }
    }
  }

  &__worktime {
    max-height: 145px;
    overflow: auto;
    scrollable();
    custom-scrollbar();
  }

  &__worktimeRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__worktimeItem {
    flex-shrink: 0;
    width: 50%;
  }

  &__characteristics {
    display: flex;
    flex-wrap: wrap;
    margin-right: -20px;
  }

  &__characteristicsItem {
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 12px;
    line-height: 1;

    & img {
      width: 24px;
      height: auto;
      margin-right: 8px;
    }
  }

  &__toggleIcon {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: @width;
    margin-top: -2px;
  }

  &__PlacemarkIcon {
    background: #112;
    position: relative;
    z-index: 50;
    width: 48px;
    height: @width;

    & img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__buttonRoute {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: @width;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      border: 1px solid $color-brand-green;
      transition: border-color $duration;

      & svg path {
        fill: $color-brand-green;
        transition: fill $duration;
      }
    }
  }
}
