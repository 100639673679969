.GenderLink {
  z-index 100
  position: fixed;
  bottom: 40px;
  left: 40px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  transition: bottom $duration;

  &_hidden {
    bottom: -100px;
  }
}
