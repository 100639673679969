.Button {
  height: 48px;
  padding: 15px 25px;
  position: relative;
  display: inline-block;
  border-radius: 24px;
  border: none;
  background-color: $color-brand-green;
  vertical-align: middle;
  color: white;
  font-family: $font-primary;
  font-size: $font-size-m;
  font-weight: 500;
  line-height: 1.33;
  text-align: center;
  cursor: pointer;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: inherit;
    background-color: inherit;
    transition: transform $halfduration;
  }

  &__text {
    width: 100%;
    height: 100%;
    position: relative;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__spinner {
    spinner(24px, 2px, currentColor);
    margin-top: -12px;
    margin-left: -12px;
    position: absolute;
    top: 50%;
    left: 50%;
  }

  &_isLoading &__text {
    opacity: 0;
  }

  &:hover:not(&_disabled):not(&_link):not(&_dark):before {
    transform: scale((52 / 48));

    @media $tablet {
      transform: none;
    }
  }

  &:active:before {
    transform: scale(1) !important;
    background-color: $color-brand-green-hover;
  }

  &_link, &_link:before {
    padding: 0px;
    background-color: transparent !important;
    color: $color-brand-green;
    font-size: $font-size-s;
    font-weight: 400;
    transition: color $halfduration;
  }

  &_link {
    &:hover {
      color: $color-brand-green-hover;
    }
  }

  &_secondary, &_secondary:before {
    background-color: $color-brand-green-translucent !important;
    color: $color-brand-green;
    font-family: $font-secondary;
    font-size: $font-size-s;
    font-weight: 700;
    text-transform: uppercase;
  }

  &_danger, &_danger:before {
    background-color: $color-brand-red !important;
  }

  &_dark, &_dark:before {
    background-color: #000 !important;
    color: #fff;
  }

  &_disabled, &_disabled:before {
    background-color: $color-bg !important;
    color: #BFB7B6;
    cursor: default;
  }

  &_small {
    height: 36px;
    padding: 8px 18px;
    font-family: $font-primary;
    font-size: $font-size-s;
    font-weight: 500;
    text-transform: none;
  }
}
