.Nav {
  display: flex;

  &_phone {
    flex-direction: column;

    .Nav__link {
      padding: 7px $phone-margin-x;
      font-size: $font-size-l;
      font-weight: 700;
    }
  }

  &_desktop {
    .Nav__link {
      padding: 0 14px;
      font-size: $font-size-s;
      font-weight: 500;

      @media $tablet {
        padding: 0 10px;
      }
    }
  }

  &__link {
    transition: color $duration;
    font-family: $font-secondary;
    color: $color-brand-brown;
    white-space: nowrap;

    &_active {
      color: $color-grey;
      cursor: default;
    }

    &:not(&_active):hover {
      color: $color-brand-green;
      transition-duration: $halfduration;
    }
  }
}
