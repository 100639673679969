.FoolsDay25 {
  min-height 100vh

  display flex
  flex-direction column

  background: url("./assets/bg.jpg") no-repeat center center
  background-size cover

  * {
    color white
  }

  @media $tablet {
    background-attachment fixed
  }

  &__container {
    flex 1
    display flex
    flex-direction column
    padding 40px 0
    @media $tablet {
      padding 40px 0
    }
  }

  &__BackToMain {
    //* {
    //  color white
    //}
  }

  &__content {
    flex 1
    display flex
    flex-direction column
  }

  &__title {
    margin-top 52px
    font-weight: 700;
    font-size: 90px;
    text-align: center;

    @media $tablet {
      margin-top 52px
      font-size: 52px;
    }

    @media $phone {
      margin-top 28px
      font-size: 36px;
    }
  }

  &__subtitle {
    margin-top 16px

    font-weight: 600;
    font-size: 40px;
    text-align: center;

    @media $tablet {
      font-size: 24px;
    }

    @media $phone {
      margin-top 8px
      font-size: 18px;
    }
  }

  &__grid {
    flex 1

    margin-top 40px

    display grid
    grid-template-columns 293px 1fr 293px

    @media $tablet {
      grid-template-columns 1fr
      row-gap 24px
      margin-top 20px
    }

    @media $phone {
      margin-top 12px
    }
  }
}
