.RadioField {
  $paddingX = 12px
  $color = $color-brand-brown
  $disabled-color = $color-grey;

  margin-left (- $paddingX )
  margin-right (- $paddingX )
  padding-left $paddingX
  padding-right $paddingX
  border-radius 3px
  color $color
  background-color #fff

  &__field {
    display flex
    align-items center
    justify-content space-between
    min-height $inputHeight
    margin-left (- $paddingX)
    margin-right (- $paddingX)
    padding 4px $paddingX
    font-family $font-secondary
    font-size $font-size-s
    font-weight 500
    color $color
    cursor pointer
    transition color $duration

    ^[0]_disabled & {
      color $disabled-color
      cursor inherit
    }
    ^[0]_checked & {
      cursor default
    }
  }

  &__caption {
    padding-right 20px
  }

  &__input {
    $size = 16px
    flex-shrink 0
    border-radius 50%
    width $size
    height $size
    background-color #fff
    border 2px solid $color-grey
    box-shadow inset 0 0 0 2px #fff
    cursor pointer
    transition background-color $duration, border-color $duration

    ^[0]_checked & {
      background-color $color-brand-green
      border-color $color-brand-green
    }
    ^[0]_disabled & {
      cursor inherit
      border-color $disabled-color
    }
  }

  &__content {
    padding-top $phone-margin-y
    padding-bottom $phone-margin-y

    & label {
      color $color-text
    }
  }

  &:not(&_disabled):not(&_checked) &__field:hover {
    color $color-grey
  }

  &_disabled {
    cursor not-allowed
    color $disabled-color
  }
}
