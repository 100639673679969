.Advent {
  padding 20px 0 24px

  &_hidden {
    filter blur(10px)
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 32.68px;
    text-align: center;
    color white

  }
  &__Rules {
    margin-top 12px
  }

  &__Collage {
    margin-inline -20px
  }


  &__container {
    margin-top 8px
    display grid
    grid-template-columns 1fr minmax(min-content, 172px)
    gap 10px
  }

  &__Tasks {
    margin-inline auto
    margin-top 40px
    grid-template-columns repeat(11, 1fr)!important
    gap 8px!important
    padding-top 12px!important

    img {
      top -57px
    }


    &>div:nth-child(n+13) {
      transform translateX(50%)
    }
  }

}
