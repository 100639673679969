.GiftBlock {
  overflow-x clip
  margin 50px auto

  @media $tablet {
    margin 36px auto 24px

  }

  @media $phone {
    margin 24px auto 16px


  }

  &__title {
    color black
    margin-bottom 48px

    @media $tablet {
      margin-bottom 20px
      text-align center
    }

    @media $phone {
      margin-bottom 48px

    }
  }

  &__first {
    display grid
    grid-template-columns 2fr 1fr 1fr
    gap 10px
    margin-bottom 24px
    @media $tablet {
      grid-template-columns 1fr
      gap 8px
      margin-bottom 8px

    }
  }

  &__slider {
    div:first-of-type {
      overflow visible
    }

    //.swiper-slide {
    //  height auto !important
    //
    //}

  }

}

.GiftItem {
  display grid
  background var(--papa-color-1)
  color white
  padding 28px 28px 40px
  border-radius 24px
  grid-template-rows min-content 1fr min-content auto

  @media $tablet {
    padding 16px 16px 24px
  }

  &_1 {
    grid-template-columns repeat(2, 1fr)
    column-gap 24px

    @media $tablet {
      column-gap 30px
    }

    @media $phone {
      grid-template-columns 1fr
    }
  }

  &_2, &_3 {

    @media $tablet {
      grid-template-columns repeat(2, 1fr)
      column-gap 30px
    }

    @media $phone {
      grid-template-columns 1fr
    }

  }



  &_rest {
    grid-template-columns 2fr 3fr
    column-gap 30px
    @media $tablet {
      grid-template-columns 1fr
    }

    @media $phone {
      grid-template-columns 1fr 2fr
      column-gap 10px

    }
  }

  &_5 {
    height 100%
    @media $tablet {
      grid-template-columns 1fr
    }
  }

  &__image {
    width 100%
    border-radius 24px
    object-fit cover

    @media $tablet {
      border-radius 20px
    }

    @media $phone {
      border-radius 16px
    }

    &_1 {
      grid-row 1 / 3
      @media $tablet {
        grid-row 1 / 4
      }
    }


    &_2, &_3 {
      @media $tablet {
        grid-row 1 / 4
      }
    }

    &_5 {
      @media $tablet {
        grid-row 1 / 4
      }
    }

    &_rest {
      grid-row 1 / 4
      @media $phone {
        grid-row 1 / 3
        min-width 100px
      }
    }

  }


  &__title {
    font-family: Roboto;
    font-size: 28px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0.02em;


    @media $tablet {
      font-size: 24px;
      line-height: 28px;
    }

    &_1 {

      grid-column 2
      padding-top 24px
      @media $phone {
        grid-column unset
        padding-top 16px
        margin auto

      }
    }


    &_2, &_3 {
      padding-top 16px
      margin auto

      @media $tablet {
        grid-column 2
        padding-top 24px
        margin unset

      }

      @media $phone {
        grid-column unset
        padding-top 16px
        margin auto

      }

    }

    &_5{
      padding-top 16px
      margin auto
      text-align center
      @media $tablet {
        grid-column unset
        padding-top 12px
      }
      @media $phone {
        grid-column unset
        padding-top 16px
        margin auto

      }
    }

    &_rest {
      grid-column 2
      @media $tablet {
        grid-column unset
        padding-top 12px
      }
      @media $phone {
        grid-column 2
      }
    }
  }

  &__description {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    @media $tablet {
      font-size: 16px;
      line-height: 24px;

    }

    &_1 {
      grid-column 2
      padding-top 24px
      @media $phone {
        grid-column unset
        padding-top 12px
        margin auto

      }
    }


    &_2, &_3 {
      margin auto

      @media $tablet {
        grid-column 2
        margin unset
        padding-top 24px


      }
      @media $phone {
        grid-column unset
        padding-top 12px
        margin auto

      }
    }

    &_5{
      text-align center
      @media $tablet {
        grid-column unset
      }
      @media $phone {
        grid-column unset
        padding-top 12px
        margin auto

      }
    }

    &_rest {
      grid-column 2
      align-self end
      @media $tablet {
        grid-column unset
      }
      @media $phone {
        grid-column 2
      }
    }
  }

  &__logo {

    max-height 83px
    width auto
    max-width 100%

    &_1 {
      @media $tablet {
        grid-column 2
        padding-top 12px

      }
      @media $phone {
        grid-column unset
        margin auto

      }
    }


    &_2, &_3 {
      padding-top 24px
      margin auto

      @media $tablet {
        grid-column 2
        padding-top 12px
        margin unset

      }

      @media $phone {
        grid-column unset
        margin auto
      }

    }

    &_5 {
      //padding-top 24px
      margin auto
      @media $tablet {
        max-height 56px
        grid-column unset
        //padding-top 24px

      }
      @media $phone {
        grid-column unset
        margin auto
      }
    }

    &_rest {
      grid-column 2
      padding-top 16px

      @media $tablet {
        grid-column unset
        padding-top 24px

      }
      @media $phone {
        grid-column 2
        max-height 45px
        padding-top 12px

      }
    }
  }
}
