.RestaurantSelector {
  display: flex;
  flex-direction: column;

  &__title {
    margin-bottom: $checkout-mobile-margin-15;
    text-align: center;
  }

  &__search {
    margin-bottom: $checkout-mobile-margin-20;
    display: flex;
    align-items: center;

    &-input {
      max-width: 500px;
      flex: 1;
      margin-right: $checkout-mobile-margin-15;
    }

    &-button {
      width: 46px;
      height: 42px;
      padding: 8px;
      flex-shrink: 0;
    }
  }

  &__map {
    width: calc(100% + 32px);
    margin-left: -16px;
    height: 280px;
    margin-bottom: $checkout-mobile-margin-20;
    position: relative;
  }

  &__preloader {
    spinner(48px, 3px, $color-brand-green);
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -24px 0 0 -24px;
  }

  &__info {
    display: flex;
    flex-direction: column;

    &-icon {
      width: auto;
      height: 1.33em;
      margin-right: 6px;
      flex-shrink: 0;
    }
  }

  &__address {
    display: flex;
    font-family: $font-secondary;
    font-size: $font-size-s;
    font-weight: 500;
    line-height: 1.33;
    color: $color-brand-green;
    opacity: 0.8;

    &:not(:last-child) {
      margin-bottom: $checkout-mobile-margin-10;
    }
  }

  &__metro {
    display: flex;
    font-family: $font-secondary;
    font-size: $font-size-s;
    font-weight: 400;
    line-height: 1.33;
    color: $color-brand-brown;

    &:not(:last-child) {
      margin-bottom: $checkout-mobile-margin-10;
    }
  }

  &__schedule {
    display: flex;

    &-wrapper {
      padding: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: $color-bg;
      border-radius: 15px;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }

    &-day, &-time1 {
      margin-bottom: 4px;
    }

    &-day {
      font-family: $font-secondary;
      font-weight: 500;
      font-size: $font-size-s;
      line-height: 1;
      color: $color-brand-brown;
    }

    &-time1, &-time2 {
      font-family: $font-secondary;
      font-weight: 500;
      font-size: $font-size-xs;
      line-height: 1;
      color: $color-grey;
    }
  }

  &__accept {
    margin-top: $checkout-mobile-margin-20;
    z-index: 0;
    align-self: center;
  }
}
