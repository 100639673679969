.SEO {
  &__title {
    margin-bottom: 8px;
  }

  &__more {
    user-select: none;
  }

  &_opened {
    .SEO__content:after {
      display: none;
    }
  }

  &__content {
    position: relative;
    margin-bottom: 15px;

    &:after {
      content: '';
      width: 100%;
      height: 30px;
      position: absolute;
      left: 0;
      bottom: 0;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, $color-bg 100%);
    }

    p {
      margin-bottom: 0.8em;
    }

    h2 {
      margin-top: 1.5em;
      margin-bottom: 0.5em;
      font-family: $font-secondary;
      font-size: $font-size-m;
      font-weight: bold;
      color: $color-brand-brown;

      &:first-child {
        margin-top: 0;
      }
    }

    ol {
      counter-reset: list;
    }

    li {
      margin-bottom: 0.8em;
    }

    li:before {
      counter-increment: list;
      content: counter(list) '. ';
    }

    p:last-child, ol:last-child, ul:last-child, li:last-child {
      margin-bottom: 0;
    }
  }
}
