.RadioField {
  $color = $color-brand-brown
  $disabled-color = $color-grey

  position relative
  display inline-block
  padding-left 32px

  &__field {
    margin-left - @padding-left
    padding-left @padding-left
    font-family $font-secondary
    font-size $font-size-s
    font-weight 500
    vertical-align text-top
    color $color
    cursor pointer
    transition color $duration

    ^[0]_disabled & {
      color $disabled-color
      cursor inherit
    }
    ^[0]_checked & {
      cursor default
    }
  }

  &__input {
    $size = 16px
    position absolute
    left 1px
    top -1px
    border-radius 50%
    width $size
    height $size
    background-color #fff
    border 2px solid $color
    box-shadow inset 0 0 0 2px #fff
    cursor pointer
    transition background-color $duration

    ^[0]_checked & {
      background-color $color
    }
    ^[0]_disabled & {
      cursor inherit
      border-color $disabled-color
    }
  }

  &:not(&_disabled):not(&_checked) &__field:hover {
    color $color-brand-green
  }

  &_disabled {
    cursor not-allowed
    color $disabled-color
  }

  &__description {
    padding-top 4px
    font-size $font-size-xs
    color $color-text
  }
}
