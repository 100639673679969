.CheckoutFooter {
  paper();
  padding: $checkout-desktop-margin-40;
  display: flex;
  flex-direction: column;

  &__block {
    margin-right: $checkout-desktop-margin-40;
    display: flex;
    flex-direction: column;
    color: $color-text;
    font-size: $font-size-xs;
    font-family: $font-primary;
    font-weight: 400;
    line-height: 1.33;
  }

  &__agree {
    margin-bottom: $checkout-desktop-margin-20;
    display: flex;
    align-items: center;

    &-title, &-checkbox {
      margin-right: $checkout-desktop-margin-20;
    }

    &-checkbox {
      color: $color-text;
      font-size: $font-size-xs;
      font-family: $font-primary;
      font-weight: 400;
      line-height: 1;

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  &__privacy {
    &-title {
      display: flex;
      align-items: center;
    }

    &-description {
      padding-top: 8px;
      font-size: $font-size-xs;
      color: $color-text;
      line-height: 1.33;
      font-weight: 400;
      font-family: $font-primary;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    &-subtitle {
      font-size: $font-size-s;
      font-weight: 500;
      font-family: $font-secondary;
      line-height: 1;
      color: $color-brand-brown;
    }
  }

  &__bottom {
    padding: $checkout-desktop-margin-40 $checkout-desktop-margin-40 0 0;
    align-self: flex-end;
    display: flex;
    align-items: center;
  }

  &__captcha {
    margin-right: $checkout-desktop-margin-40;
    display: none;

    &_visible {
      display: block;
    }
  }

  &__pay {
    padding: $checkout-desktop-button-padding;
    height: $checkout-desktop-button-height;
    border-radius: $checkout-desktop-button-radius;
  }
}
