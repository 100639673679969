.Rules {
  position relative
  padding 25px 20px

  border-radius: 12px;
  background: #D3E764;

  @media $phone{
    padding 16px 16px 12px
  }

  &_hidden {
    opacity 0
  }

  &__text {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
    color: $color-brand-brown;
    @media $phone {
      font-size: 14px;
    }
  }

  &__withPadding {
    padding-right 120px
    @media $phone {
      padding-right 85px
    }
  }

  &__decor {
    position absolute
    top 50%
    right 0
    transform translateY(-50%)
    width 127px
    height auto
    @media $phone {
      top unset
      transform unset
      bottom -10px
      right -5px
      width 105px
    }
  }

}
