.Header {
  width: 100%;
  height: $phone-navbar-height;
  padding-left: $phone-margin-x;
  padding-right: 60px;
  position: relative;
  display: flex;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid $color-border;
  z-index 1000;

  &__logo {
    width: 73px;
    flex-shrink: 0;

    img {
      width: 100%;
      display: block;
    }
  }

  &__city {
    padding-left: 20px;
    padding-top: 2px;
    position: relative;
    overflow: hidden;
  }

  &__city-pin {
    position: absolute;
    top: 0;
    left: 0;

    svg {
      display: block;
    }
  }

  &__city-name {
    text-overflow();
  }

  &__hamburger {
    width: 60px;
    height: 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
  }

  &__hamburger-line {
    width: 20px;
    height: 2px;
    margin-bottom: 4px;
    border-radius: 2px;
    background-color: $color-brand-green;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.Header_cosmos {
  background-image: url("../../../assets/Astronaut_images/mobile.jpg")
  background-position right top
  background-repeat no-repeat
  background-size cover

  .Header__CitySelector {
    * {
      color white
    }
  }

  .Header__hamburger-line {
    background white
  }

}
