.PortionSwitcher {
  position: relative;
  display: flex;
  width: 56px;
  height: 24px;
  border-radius: 2px;
  border: solid 1px #e6e2e1;
  overflow: hidden;

  @media $tablet {
    width: 74px;
    height: 32px;
  }

  &__disabled {
    border: solid 1px #ffffff;
  }

  &__item {
    width: 50%;
    font-weight: bold;
    font-family: $font-secondary;
    font-size: $font-size-xs;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
    user-select: none;
    transition: color $duration, background-color $duration;

    @media $tablet {
      line-height: 32px;
    }

    &_active {
      color: $color-brand-brown;
      background-color: #ffd8a6;
      cursor: default;
      pointer-events: none;
    }

    

    &:not(&_active):hover {
      color: $color-brand-brown;
      transition-duration: $halfduration;;
    }
  }
}
