.AddressFieldGroup {
  @media $phone {
    margin-bottom: 0;
  }

  &__error {
    margin-top: 15px;
    text-align: center;
  }

  &__AddressMap {
    padding-top: 12px;
  }

  &__linkAction {
    &_AddressMap {
      margin-bottom: 24px;
    }
  }

  &__linkActionLabel {
    width: max-content;
    height: 30px;
    display: flex;
    align-items: center;
    line-height: 30px;
    color: $color-brand-green;
    cursor: pointer;
    transition: color $duration;

    &:hover {
      color: $color-brand-green-hover;
      duration();
    }
  }

  &__linkActionIcon {
    color: inherit;
    transition: inherit;
  }

  &__linkGpsIcon {
    margin-right: 5px;
  }

  &__toggleFields {
    margin-bottom: 0;
    padding-top: 20px;
  }

  &__actions {
    display flex
    align-items center
    justify-content flex-end
    margin-left -10px
    margin-right -10px

    @media $phone {
      flex-direction column
      align-items flex-start
      justify-content center
      margin-left 0
      margin-right 0
    }

    &__item {
      margin 0 10px

      @media $phone {
        display block
        width 100%
        margin-left 0
        margin-right 0
        margin-bottom 10px

        &:last-child {
          margin-bottom 0
        }
      }

    }

  }
}
