.Steps {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.Step {
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 0.5;
  color: $color-brand-brown;

  &_previous {
    opacity: 1;
    color: $color-brand-green;
  }

  &_active {
    opacity: 1;

    .Step__line_left {
      background-color: $color-brand-green;
    }

    .Step__line_right {
      opacity: 0.5;
    }

    &:last-child {
      color: $color-brand-green;

      .Step__loader {
        display: none;
      }
    }
  }

  &:first-child {
    .Step__line_left {
      visibility: hidden;
    }
  }

  &:last-child {
    .Step__line_right {
      visibility: hidden;
    }
  }

  &__icon {
    width: auto;
    height: 65px;
    margin-bottom: 10px;
  }

  &__name {
    color: inherit;
    display: flex;
    align-items: center;
  }

  &__check {
    width: auto;
    height: 16px;
    margin-left: 5px;
  }

  &__loader {
    spinner(10px, 2px, $color-brand-green);
    margin-left: 5px;
  }

  &__line {
    width: 12px;
    height: 1px;
    background: currentColor;

    &_left {
      margin-right: 10px;
    }

    &_right {
      margin-left: 10px;
    }
  }
}
