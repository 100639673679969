.BaseInfo {
  border-radius: 4px;
  background-color: #F8F8F6;

  &__inner {
    padding: 0 8px 8px;
  }

  &__preloader {
    width: 100%;
    padding: 12px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__spinner {
    spinner(48px, 3px, $color-brand-green);
  }

  &__container {
    padding: 24px 16px 21px 16px;
    background-color: white;
    border-radius: 0 0 25px 25px;
    box-sizing: border-box;
  }

  &__row {
    display: flex;
    justify-content: center;
  }

  &__main {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__username-container {
    display: flex;
    margin-left: 15px;
    font-family: $font-primary;
    font-weight: 500;
    font-size: $font-size-l;
    line-height: 1.33;
    color: $color-brand-brown;
  }

  &__username {
    position: relative;
    margin-top: 10px;
  }

  &__phone {
    margin-top: 10px;
    margin-left: 15px;
    font-family: $font-primary;
    font-weight: 500;
    font-size: $font-size-m;
    line-height: 19px;
    color: $color-brand-brown;
  }

  &__edit {
    position: absolute;
    top: -5px;
    right: -20px;
    color: $color-grey;
    width: 15px;
    height: 20px;

    svg {
      width: 15px;
      height: 20px;
    }
  }

  &__avatar {
    width: 72px;
    height: 72px;
    flex-shrink: 0;
  }

  &__button {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content center;
    width: 100%;
    height: 44px;
    border-radius: 25px;
    background: #50A684;
    font-weight: 500;
    font-size: 16px
    color: rgba(255, 255, 255, 1)
    letter-spacing: 0.02em;

    &_gradient {
      background: url('../../../../icons/backgrounds/backgroundForButtonInModal.png') no-repeat center center / cover
    }
    &_black {
      background: url('./blackButton.png') no-repeat center center / cover
    }
  }

  &__lollipop {
    margin-top: 5px;
    //margin-left: 84px;
    margin-right: 10px
  }
}

.Loyalty {
  &__oldPl {
    margin-top: 15px;
  }

  &__status {
    margin-bottom: 4px;
    font-family: $font-primary;
    font-weight: 400;
    font-size: $font-size-m;
    line-height: 1.33;
  }

  &__label {
    color: $color-brand-brown;
  }

  &__name {
    display: inline-flex;
    align-items: center;
    color: $color-brand-green;

    svg {
      width: 14px;
      height: 14px;
      margin-left: 4px;
      color: $color-grey;
    }
  }

  &__cashback {
    font-family: $font-primary;
    font-weight: 400;
    font-size: $font-size-s;
    line-height: 1.33;
  }
}

.ModalLoyaltyLow {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 542px;
  background-color: #FFFFFF;
}

.ModalLoyalty {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #FFFFFF;

  &__modal-icon {
    margin: 0 auto;
    height: 6px;
    border-radius: 3px;
    background: #fff;
    width: 70px;
    position: relative;
    top: -15px;
  }

  &__picture {
    margin-top: 40px;
    //margin-bottom: 40px;
  }

  &__header {
    font-family: $font-secondary;
    display: block;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    color: rgba(80, 166, 132, 1);
    padding-bottom: 12px;
  }

  &__text {
    font-family: $font-secondary;
    display: block;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    margin: 0 40px;
    color: rgba(112, 84, 79, 1);
  }

  &__orderButton {
    margin: 40px 40px 0 40px
    width: 343px;
    height: 51px;
    background: #50A684;

    &_gradient {
      background: url('../../../../icons/backgrounds/backgroundForButtonInModal.png') no-repeat center center / cover
    }
    &_black {
      background: url('./blackButton.png') no-repeat center center / cover
    }
  }

  &__about {
    font-family: $font-secondary;
    display: block;
    margin-top: 15px;
    margin-bottom: 30px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #A6A5A5;
  }
}
.StatusesInfo {
  padding: $checkout-mobile-block-padding;
  display: flex;
  flex-direction: column;

  &__header {
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    margin-bottom: 15px;
    font-family: $font-secondary;
    font-weight: 500;
    font-size: $font-size-l;
    line-height: 1;
    color: $color-brand-brown;
  }

  &__current {
    padding: 11px 30px;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color-bg;
    border-radius: 25px;
  }

  &__award {
    flex-shrink: 0;
    margin-right: 15px;
  }

  &__name {
    font-family: $font-primary;
    font-weight: 500;
    font-size: $font-size-m;
    line-height: 1;
  }

  &__note {
    font-family: $font-primary;
    font-weight: 400;
    font-size: $font-size-m;
    line-height: 1.33;
    color: $color-grey;
  }

  &__accept {
    margin-top: 30px;
    align-self: center;
  }
}

.StatusesInfoItem {
  padding: 15px 0;
  display: flex;
  border-top: 2px solid #EBEEF5;

  &:last-child {
    padding-bottom: 0;
  }

  &__list {
    display: flex;
    flex-direction: column;
  }

  &__award {
    margin-right: 15px;
    flex-shrink: 0;
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__name {
    padding-top: 5px;
    margin-bottom: 10px;
    font-family: $font-secondary;
    font-weight: 500;
    font-size: $font-size-l;
    line-height: 1;
  }

  &__description {
    font-family: $font-primary;
    font-weight: 400;
    font-size: $font-size-m;
    line-height: 1.33;
    color: $color-brand-brown;
    opacity: 0.8;
  }
}
