.ModalContainer {
  font-family: 'Roboto';

  &__icon {
    margin: 0 auto;
  }

  &__header {
    margin-top: 50px;
    text-align:center;
    font-weight: 500;
    font-size: 18px;
    color: #50A684;
  }

  &__text {
    margin-top: 20px;
    text-align:left;
    font-size: 16px;
    color: #70544F;
    font-style: normal;
    line-height: 21px;
    opacity: 0.8;
  }

  &__greenText {
    color: #50A684;
  }

  &__link {
    margin-top:10px;
    margin-bottom: 40px;
    text-align: left;
    font-family: Manrope;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-decoration-line: underline;
    color: #2D9CDB;
    opacity: 0.8;
  }
}
