.ProductList {
  position relative
  padding-top: 70px;

  section {
    padding-top: 70px

    &:nth-child(2) {
      padding-top: 0
    }
  }

  &:last-of-type {
    padding-bottom: 70px;
  }

  &__heading {
    margin-bottom: 16px;
  }

  &__list {
    margin: -($card-margin);
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
  }

  &__item {
    width: 'calc(33.3% - %s)' % ($card-margin * 2);
    margin: $card-margin;
    position: relative

    // при ресайзе окна на десктопе
    @media $tablet {
      width: 'calc(50% - %s)' % ($card-margin * 2);
    }

    @media $verticalTablet {
      width: 100%;
    }

    & > div {
      height 100%
      width 100%
    }

    &_Football_promo {
      border 4px solid $color-brand-green;
      border-radius 14px
    }

    &_halves, &_constructor {
      min-height 600px
      @media $tablet {
        min-height unset
      }
    }
  }

  &__PromoArtefact {
    transform translate(100px, -57px)
    height: 60px;
  }

  &__FootballDecor {
    position absolute
    top 30px
    left 100px !important
    width 70px !important
    height 70px !important

    @media $tablet {
      top 35px
    }
  }

  &__MovieDecor {
    &_constructor {
      width 120px
      bottom 0
      left 0

    }
    &_halves {
      width 159px
      top 210px
      right 0
    }

    &_filter {
      width 230px
      top -36px
      left -160px
    }
  }

  &__PaddingtonDecor {
    top 5px
    left 5px
    max-width 80%
  }
}
