.Heading {
  position: relative;
  font-family: $font-secondary;
  font-weight: bold;
  color: $color-brand-brown;
  line-height: 1.33;
  white-space: pre-wrap;

  &_level_1 {
    font-size: $font-size-xxxl;

    @media $phone {
      font-size: $font-size-xxl;
    }
  }

  &_level_2 {
    font-size: $font-size-xxl;

    @media $phone {
      font-size: $font-size-l;
    }
  }

  &_level_3 {
    font-size: $font-size-l;

    @media $phone {
      font-size: $font-size-m;
    }
  }

  &_level_4 {
    font-size: $font-size-s;
  }

  &_level_5 {
    font-size: $font-size-xs;
  }

  &_level_6 {
    font-size: $font-size-xxl;

    @media $tablet {
      font-size: $font-size-xl;
    }
  }

  &_level_7 {
    font-size: $font-size-xl;

    @media $tablet {
      font-size: $font-size-m;
    }
  }

  &_16_14 {
    font-size: $font-size-m;

    @media $tablet {
      font-size: $font-size-s;
    }
  }

  &_medium {
    font-weight: 500;
  }

  &_fontWeight {
    &_normal {
      font-weight: 400;
    }
  }

  &_medium {
    font-weight: 500;
  }

}
