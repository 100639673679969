.ModalPhone {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4000;
  overflow-x: hidden;
  overflow-y: scroll;
  transition: transform 700ms;
  will-change: transform;
  transform: translateY(100%);
  transition-timing-function: $easeInCubic;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  &_entered {
    transition-timing-function: $easeOutCubic;
    transform: translateY(0%);
  }

  &__overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 4000;
    background-color: black;
    opacity: 0;
    transition: opacity 700ms;

    &_entered {
      opacity: 0.5;
    }
  }

  &__empty {
    width: 100%;
    height: 100vh;
  }

  &__content {
    border-radius: 25px 25px 0 0;
    background-color: white;
  }

  &__padding {
    // отступ с сейфзоны для айфонов
    padding-bottom: 20px;
    padding-bottom: constant(safe-area-inset-bottom, 0px);
    padding-bottom: env(safe-area-inset-bottom, 0px);
    background-color: inherit;
  }
}
