.UnauthorizedModal {
  display: flex;
  flex-direction: column;
  align-items center;
  gap: 12px
  padding 20px 24px 32px
  background-color white
  text-align center;
  text-wrap: balance;
  white-space: break-spaces;

  &_desktop {
    max-width: 576px;
    border-radius 15px
    padding 50px 36px 36px
  }

  &_icon {
    display: inline-block;
    margin auto;
  }

  &_title {
    font-family: Manrope;
    font-size: 22px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #70544F;
  }

  &_caption {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #70544F;
  }


  &__form {
    width: 100%;

    &_submit {
      width: 100%;
      margin-top: 28px;
    }
  }

  &__button {
    width: 100%;

    &_1 {
      margin-top: 16px;
    }
  }
}
