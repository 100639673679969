.Ingredients {
  position absolute
  top 16px
  left 16px
  right 16px
  padding 24px 24px $paper-pad-y
  z-index 2

  &__title {
    margin-bottom 16px
  }

  &__body {
    padding-bottom 10px
  }

  &__item {
    display block
    position relative
    padding 7px 25px 7px 0
    font-size $font-size-m
    color $color-brand-brown
    cursor pointer
    user-select none

    &_removed {
      text-decoration line-through
      color #e6e2e1
    }

    &_disabled {
      color: $color-brand-green;
    }
  }

  &__item:hover &__item-remove {
    color $color-brand-red
    transition-duration: $halfduration;
  }

  &__item:hover &__item-return {
    color $color-brand-green
    transition-duration: $halfduration;
  }

  &__item-name {
    line-height 1.2

    &:first-letter {
      text-transform capitalize
    }
  }

  &__item-return {
    position: absolute
    top: 9px
    right: -2px
    color: #e6e2e1
    cursor: pointer
    transition: color $duration

    &:after {
      content: ''
      position: absolute
      top: -5px
      right: -5px
      bottom: -5px
      left: -5px
    }

    svg {
      display: block
    }

  }

  &__item-remove {
    position absolute
    top 11px
    right 0
    color $color-grey
    cursor pointer
    transition color $duration

    &:after {
      content ''
      position absolute
      top -5px
      right -5px
      bottom -5px
      left -5px
    }

    svg {
      display block
    }
  }
  &__actions {
    display flex
    align-items center
    justify-content space-between
    margin-left -10px
    margin-right -10px

    @media $phone {
      flex-direction column
      align-items flex-start
      justify-content center
      margin-left 0
      margin-right 0
    }

    &__item {
      margin 0 10px
      flex-grow 1

      &_cancel {
        color $color-grey
      }

      @media $phone {
        display block
        width 100%
        margin-left 0
        margin-right 0
        margin-bottom 10px

        &:last-child {
          margin-bottom 0
        }
      }
    }
  }

  &__footer {
    padding-top 16px
    border-top 1px solid #e6e2e1

  }

  &__cart-button {
    padding-left 15px
    padding-right 15px
  }
}
