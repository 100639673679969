.OrderSteps {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  background-color: white;
  padding: 0 35px 0 43px;

  &__icon {
    position: relative;
    top: 2px;
  }

  &__list {
    &-item {
      position: relative;
      display: inline-flex;
      align-items: center;
      font-size: $font-size-xs;
      line-height: 16px;
      font-weight: 600;
      color: $color-grey;

      &:not(:last-child) {
        margin-right: 38px;

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          right: -6px;
          display: block;
          width: 24px;
          height: 1px;
          background-color: $color-brand-green;
          transform: translateX(100%);
        }
      }

      &_active {
        color: $color-brand-brown;
      }

      &_completed {
        color: $color-brand-green;
      }
    }
  }

  &__total {
    font-weight: 600;
    color: $color-brand-brown;
  }
}