:global(.pac-container) {
  custom-scrollbar(2px, #e6e2e1);
  width: 100%;
  max-height: 368px;
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  border: none;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  z-index: 10000;

  &:after {
    display: none;
  }
}

:global(.pac-icon) {
  display: none;
}

:global(.pac-item) {
  width: 100%;
  height: 40px;
  padding: 0 0 0 16px;
  background-color: #fff;
  transition: background-color $halfduration;
  position: relative;
  line-height: 1.33;

  @media $phone {
    height: auto;
  }

  &:before {
    display: none;
  }
}

:global(.pac-item-query) {
  font: $font-size-s / 40px $font-primary;
  color: $color-brand-brown;

  @media $phone {
    font-size: $font-size-xs;
  }

  &:before {
    display: none;
  }
}

:global(.pac-item-selected) {
  transition-duration: $halfduration;

  &:before {
    transition-duration: $halfduration;
    opacity: 1;
  }
}

.AddressesField {
  $padding-left = 48px;
  $box-shadow = 0 2px 8px 0 rgba(41, 44, 51, 0.08);
  position: relative;

  &__mobile {
    height: 56px;
    border-radius: 35px;
  }

  &__desktop{
    border-radius: 12px;
    padding: 15px 30px;
  }

  &__fieldEnd{
    text-overflow: ellipsis;
    direction: rtl;
    text-align: end;
    height: 62px;
    &:focus{
      direction: ltr;
      text-align: start;
    }
  }

  &__search {
    position: relative;
    margin-bottom: 12px;
    transition: opacity $duration;

    @media $phone {
      z-index: 1;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &_hidden {
      opacity: 0;
    }
  }

  :global(ymaps[class*='popup']) {
    custom-scrollbar(2px, #e6e2e1);
    overflow: hidden;
    overflow-y: auto;
    max-height: 368px;
    margin-top: 12px;
    padding-right: 15px;
    border: none;
    border-radius: 2px;
    box-shadow: $box-shadow;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 32px;
      height: 100%;
      top: 0;
      right: 0;
      background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 1));
      pointer-events: none;
    }
  }

  :global(ymaps[class*='__suggest-item']) {
    height: 40px;
    font: $font-size-s / 40px $font-primary;
    color: $color-brand-brown;
    background-color: #fff;
    transition: background-color $duration;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 24px;
      top: 50%;
      opacity: 0;
      transform: translateY(-50%);
      background: url('../../assets/check.svg') no-repeat 50% 50% / contain;
      width: 16px;
      height: @width;
      transition: opacity $duration;
    }

    @media $phone {
      height: auto;
      padding-top: 8px;
      padding-bottom: 8px;
      line-height: 1.66;
      font-size: $font-size-xs;

      &:before {
        display: none;
      }
    }
  }

  :global(ymaps[class*='__suggest-item_selected_yes']) {
    background-color: $color-bg;
    transition-duration: $halfduration;

    &:before {
      transition-duration: $halfduration;
      opacity: 1;
    }
  }

  :global(ymaps[class*='search__suggest-item']) {
    padding-left: $padding-left;
    padding-right: 0;

    @media $phone {
      padding-left: 16px;
    }
  }
}
