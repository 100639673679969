.Ingredients__halloween {
  .Ingredients {
    &__button-toggle {
      background-color : $colorHW-ingredientsBtn-bg;
      color : $colorHW-ingredientsBtn-color;
      border-color : $colorHW-ingredientsBtn-border;
    }
  }
}

.Ingredients__new-year {
  .Ingredients {
    &__button-toggle {
      background-color : $colorNY-ingredientsBtn-bg;
      color : $colorNY-ingredientsBtn-color;
      border-color : $colorNY-ingredientsBtn-border;
    }
  }
}

.Ingredients {
  &__overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;
    background-color: rgba(#807573, 0.63);
    border-radius: $border-radius;

    @media $phone {
      border-radius: 0px;
    }
  }

  &__button {
    position: absolute;
    top: $paper-pad-y;
    right: $paper-pad-y;
    z-index: 1;
    cursor: pointer;
    user-select: none;

    &:after {
      content: '';
      position: absolute;
      top: -16px;
      right: @top;
      bottom: @top;
      left: @top;
    }

    &-toggle {
      width: 32px;
      height: 32px;
      padding: 0 7px;
      border: 1px solid rgba($color-brand-green, 0.2);
      border-radius: 50%;
      background-color: white;
      color: $color-brand-green;
      transition: border-color $duration;
      z-index: 1;
    }

    &-counter {
      position: absolute;
      top: -2px;
      right: -4px;
      width: 20px;
      height: @width;
      border-radius: 50%;
      background-color: $color-brand-red;
      border: 2px solid white;
      color: white;
      font-size: 10px;
      line-height: 15px;
      text-align: center;
    }

    &:hover &__toggle {
      border-color: rgba($color-brand-green, 0.6);
      transition-duration: $halfduration;
    }
  }

  &__popup {
    padding: 24px 24px $paper-pad-y;
    position: absolute;
    top: 16px;
    left: 16px;
    right: 16px;
    z-index: 101;
    border-radius: $border-radius;
    background-color: white;
  }

  &__title {
    margin-bottom: 16px;
  }

  &__body {
    padding-bottom: 10px;
  }

  &__list {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  &__add {
    height: auto;
    font-weight: 500;
    font-family: $font-secondary;
    color: $color-brand-green;
    transition: $halfduration;

    &:hover {
      color: $color-brand-green-hover;
    }
  }

  &__add-icon {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    position: relative;
    border-radius: 50%;
    background-color: currentColor;

    &:before, &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: white;
    }

    &:before {
      width: 8px;
      height: 2px;
      margin-top: -(@height / 2);
      margin-left: -(@width / 2);
    }

    &:after {
      width: 2px;
      height: 8px;
      margin-top: -(@height / 2);
      margin-left: -(@width / 2);
    }
  }

  &__item {
    display: block;
    position: relative;
    padding: 7px 25px 7px 0;
    font-size: $font-size-m;
    color: $color-brand-brown;
    cursor: pointer;
    user-select: none;

    &_removed {
      text-decoration: line-through;
      color: #e6e2e1;
    }

    &_disabled {
      color: $color-brand-green;
    }
  }

  &__item:hover &__item-remove {
    color: $color-brand-red;
    transition-duration: $halfduration;
  }

  &__item:hover &__item-return {
    color: $color-brand-green;
    transition-duration: $halfduration;
  }

  &__item-name {
    line-height: 1.2;

    &:first-letter {
      text-transform: capitalize;
    }
  }

  &__item-return {
    position: absolute;
    top: 9px;
    right: -2px;
    color: #e6e2e1;
    cursor: pointer;
    transition: color $duration;

    &:after {
      content: '';
      position: absolute;
      top: -5px;
      right: @top;
      bottom: @top;
      left: @top;
    }

    svg {
      display: block;
    }
  }

  &__item-remove {
    position: absolute;
    top: 11px;
    right: 0;
    color: $color-grey;
    cursor: pointer;
    transition: color $duration;

    &:after {
      content: '';
      position: absolute;
      top: -5px;
      right: @top;
      bottom: @top;
      left: @top;
    }

    svg {
      display: block;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 16px;
    border-top: 1px solid #e6e2e1;
  }

  &__cancel {
    padding-left: 0;
    padding-right: @padding-left;
    color: $color-grey;
  }

  &__cart-button {
    padding-left: 15px;
    padding-right: @padding-left;
  }
}
