.ExpireInformation {
  display: flex;
  width: 280px;
  height: 32px;
  margin-top: 16px;

  &__icon {
    margin-left: 17px;
    margin-top: 5px;
  }

  &__text {
    margin-left: 17px;
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: rgba(112, 84, 79, 0.9);

    &_superbonuses {
      color: #F2994A;
    }

    &_papabonuses {
      color: #50A684;
    }

    &_expire {
      color: red;
    }

    &_date {
      color: red;
      text-decoration-line: underline;
    }
  }
}
