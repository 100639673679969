.AddressButton {
  &_active {
    flex: 1;
    margin: 0 10%;
    padding: 10px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-bg;
    border-radius: 24px;
    cursor: pointer;
    transition: opacity $duration;
    opacity: 1;

    @media $tablet {
      margin: 0 2%;
    }

    &:hover {
      opacity: 0.75;
    }
  }

  &_inactive {
    width: 480px;
    height: 45px;
    padding: 10px;
    position: absolute;
    left: calc(50% - 240px);

    @media $tablet {
      width: 400px;
      left: calc(50% - 200px);
    }
  }

  &__line {
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-brand-brown;
    white-space: nowrap;

    &:last-child {
      margin-right: 0px;
    }

    &_medium {
      font-weight: 500;
    }
  }

  &__span {
    text-overflow: ellipsis;
    direction: rtl;
    text-align: left;

    max-width: 450px;
    text-overflow();
    text-overflow: ellipsis;
    direction: rtl;
    text-align: end;

    @media $tablet {
      max-width: 180px;
    }
  }

  &__icon {
    width: 20px;
    height: auto;
    flex-shrink: 0;

    &_whitepin, &_redpin {
      margin-right: 10px;
    }
  }

  &__border {
    padding: 6px;
    margin-right: 10px;
    display: flex;
    background-color: white;
    border-radius: 50%;
  }

  &__modal {
    paper();
    width: 1095px;
    padding: $checkout-desktop-margin-40;

    @media $tablet {
      width: 700px;
    }
  }

  &__types {
    width: 100%;
    margin-bottom: $checkout-desktop-margin-30;
  }
}
