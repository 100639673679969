.MobileMenu {
  position: relative;
  height: 100vh;
  background: white;
  overflow hidden;

  &__navbar {
    height: $phone-navbar-height;
  }

  &__nav {
    padding: 12px 0;
    justify-content: space-between;
  }

  &__footer {
    padding: 24px $phone-margin-x 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid $color-border;
  }

  &__phone {
    width: 100%;
    margin-bottom: 20px;
  }

  &__app {
    margin-bottom: 10px;
  }

  &__lang {
    margin-top: 20px;
  }
}

.Auth {
  border-bottom: 1px solid $color-border;

  &__login {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $phone-margin-y $phone-margin-x;
  }

  &__text {
    margin-right: 16px;
  }

  &__button {
    flex-shrink: 0;
  }
}
