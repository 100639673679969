.SecondaryView__item {
  height: 100%;
  width: 100%;
  margin-right: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: #70544F;
  svg {
    position: absolute;
    top: 15px;
    right:16px;
  }
}

