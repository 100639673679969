.ProductCard {
  $phone-border-radius = 12px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  // flex-grow: 1;
  border-radius: $border-radius;
  background-color: white;

  &__body {
    margin-bottom: 24px;
  }

  @media $phone {
    border-top-left-radius: $phone-border-radius;
    border-top-right-radius: $phone-border-radius;
    min-height: 'calc(100vh - %s)' % $phone-navbar-height;
    padding-bottom: 186px;
  }

  &__picture {
    overflow: hidden;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    margin-bottom: 10px;

    @media $phone {
      border-top-left-radius: $phone-border-radius;
      border-top-right-radius: $phone-border-radius;
    }

    img {
      display: block;
      max-width: 100%;
    }
  }

  &__content {
    padding: 0 $paper-pad-x;
  }

  &__meta {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: $paper-pad-x;
    margin-bottom: 10px;
  }

  &__meta-options {
    display: flex;
    align-items: center;
    min-height: 20px;
  }

  &__meta-option {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }

    &_person-count {
      display: flex;
      align-items: center;

      svg {
        margin-right: 5px;
      }
    }

    svg {
      display: block;
    }
  }

  &__badge {
    position: relative;
    height: 20px;
    padding-left: 12px;
    padding-right: 12px;
    background-color: $color-brand-red;
    color: white;
    font: bold $font-size-xs / 21px $font-secondary;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 4px 10px 0;
      border-color: transparent white transparent transparent;
    }

    &_vegan {
      background-color: $color-brand-green;
    }
  }

  &__header {
    position: relative;
    padding-right: 30px;
  }

  &__heading {
    margin-bottom: 5px;
  }

  &__footer {
    padding: 0 $paper-pad-x $paper-pad-x;

    @media $phone {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      padding-top: 10px;
      background-color: white;
    }
  }

  &__selector {
    margin-bottom: 24px;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media $phone {
      flex-direction: row-reverse;
    }
  }

  &__not-available {
    display: flex;
    height: 48px;
    align-items: center;
    justify-content: center;
  }

  &__overlay {
    fullsize();
    background-color: rgba(#807573, 0.63);
    border-radius: $border-radius;
  }
}
