.Crust__halloween {
  .Crust {
    &__text, &__done {
      color: $colorHW-crust;
    }

    &__plus {
      fill: $colorHW-crust;
    }
  }
}

.Crust__ew-year {
  .Crust {
    &__text, &__done {
      color: $colorNY-crust;
    }

    &__plus {
      fill: $colorNY-crust;
    }
  }
}

.Crust {
  display: flex;
  flex-direction: column;
  margin-bottom 10px;
  order 2;
  width 100%;
  @media $phone {
    order 0;
  }

  &__modal {
    order 0;
  }
  @media $phone {
    order 0;
  }

  &__item {
    margin-bottom 15px
    width 100%;
    display flex;
    justify-content: space-between;
    align-items center;
    @media $phone {
      margin-bottom 5px
    }
  }

  &__item-modal {
    margin-bottom 15px
    width 100%;
    display flex;
    align-items center;
    @media $phone {
      margin-bottom 5px
    }
  }

  &__name {
    @media $phone {
      margin-left 0
    }
  }

  &__text {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.02em;
    color: #50A684;
    @media $phone {
      font-weight 400;
    }
  }

  &__text-modal {
    margin-left: 12px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.02em;
    color: #50A684;
    @media $phone {
      font-weight 400;
    }
  }

  &__price-modal {
    margin-left auto;
  }

}
