.OrderMap {
  position: relative;

  &__preloader {
    spinner(48px, 3px, $color-brand-green);
    margin-top: -24px;
    margin-left: -24px;
    position: absolute;
    top: 50%;
    left: 50%;
  }
}
