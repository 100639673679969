.steps {
  background-color: $color-beige;
  padding 47px 20% 64px;
  font-family: $font-primary;
  @media $verticalTablet{
    padding 47px 10% 64px;
  }
  @media $phone{
    padding 28px 20px;
  }

  &__title {
    font-size: 22px;
    text-align: center;
    @media $phone{
      font-size: $font-size-l;
      text-align: left;
    }
  }

  .step {
    display: flex;
    padding-top: 44px;
    align-items: center;
    @media $phone{
      padding-top: 28px;
      flex-direction: column-reverse;
    }

    &_align-right {
      flex-direction: row-reverse;
      @media $phone{
        flex-direction: column-reverse;
      }
    }

    &__grid {
      flex: 1 0 60%;
      display: grid;
      grid-template-columns: 62px 1fr;
      grid-template-rows: auto 1fr;
      column-gap: 28px;
      opacity: .8;
      @media $phone{
        column-gap: 16px;
        padding-top: 8px;
      }
    }

    &__number {
      min-width: 62px;
      height: 62px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: inherit;
      font-weight: 700;
      font-size: $font-size-xxxl;
      color: $color-brand-green-dark;
      border: 2px solid $color-brand-green-dark;
      border-radius: 50%;
    }

    &__title {
      align-self center;
      font-size: $font-size-xl
      font-weight: 600;
      color: $color-brand-green-dark;
      letter-spacing: .02em;
    }

    &__text {
      grid-column: 2;
      font-size: $font-size-m;
      line-height: 1.75;
      color: $color-brand-brown;
      letter-spacing: 0.02em;
      @media $phone{
        grid-column: 1 / 3;
        padding-top: 16px;
      }
      a {
        text-decoration: underline;
        color: inherit;
      }

      ol {
        list-style: decimal;
        list-style-position: inside;

        li::marker {
          font-weight: 500;
          color: $color-brand-green-dark;
        }
      }
    }

    &__img-wrapper {
      width: 40%;
      @media $phone{
        width: 100%;
      }

      img {
        width: 100%;
      }
    }
  }
}
