.Image {
  & {
    display flex
    justify-content center
  }

  &__real {
    text-align center

    img {
      vertical-align top
      max-width 100%
    }
  }

  &__placeholder {
    display flex
  }

  &__placeholder-half {
    display flex
    align-items center

    img {
      display block
      width 100%
    }
  }
}
