.FreeSaucesPanel {
  &__overlay {
    fullsize();
    position: fixed;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1000;
  }

  &__panel {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 22px 40px 30px;
    background-color: white;
    box-shadow: $box-shadow;
    z-index: 1001;
  }

  &__title {
    margin-bottom: 16px;
  }

  &__list-wrapper {
    overflow-x: auto;
  }

  &__list {
    display: flex;
  }

  &__item {
  }
}

.Sauce {
  display: flex;
  width: 246px;
  min-height: 122px;
  border-radius: 5px;
  background-color: white;
  border: solid 1px $color-border;
  flex-shrink: 0;
  margin-right: 15px;

  &:last-child {
    margin-right: 0;
  }

  &:after {
    display: block;
    content: '';
    height: 0;
    overflow: hidden;
    clear: both;
  }

  &__image {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: 80px;
    margin-right: 10px;

    img {
      max-width: 100%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    padding: 16px 16px 16px 0;
  }

  &__info {
    margin-bottom: 10px;
    font-size: $font-size-xs;
  }

  &__name {
    margin-bottom: 4px;
    font-size: $font-size-xs;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__button {
    svg {
      width: auto;
      height: 20px;
    }
  }
}
