.AddCardFieldGroup {
  display: flex;
  flex-direction: column;

  &__row {
    display: flex;

    &:first-child {
      margin-bottom: 20px;
    }
  }

  &__field {
    &_long {
      max-width: 308px;
      margin-right: 15px;
      flex: 2;
    }

    &_short {
      max-width: 154px;
      flex: 1;
    }
  }

  &__error {
    margin-top: 5px;
    color: $color-brand-red;
  }

  &__spinner {
    spinner(48px, 3px, $color-brand-green);
  }
}
