.TaskImageClickOverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: contain;


  &_disabled {
    background-color: #f3bdbda6
    //@TODO УЗНАТЬ
    //pointer-events: none;
    //cursor: default;
  }

  &_completed {
    background-color: #ffffffa6
  }
}
