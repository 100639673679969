.Cart {
  paper();
  height: 97px;
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__count {
    color: $color-brand-green-dark;
    font-weight: 500;
  }

  &__total {
    font-family: $font-secondary;
    font-weight: 500;
    font-size: $font-size-l;
    line-height: 1.33;
    color: $color-grey;
  }

  &__amount {
    color: $color-brand-green-dark;
  }
}
