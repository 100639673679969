.RemovePortionModal {
  padding: $phone-margin-y $phone-margin-x 32px;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  &__name {
    &:first-letter {
      text-transform: capitalize;
    }
  }

  &__close {
    padding-left: 20px;
    flex-shrink: 0;
  }

  &__body {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
  }

  &__ingredient {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 136px;
    padding: 15px;
    border-radius: $border-radius;
    background-color: $color-bg;
    text-align: center;
  }

  &__ingredient-image {
    display: block;
    margin-bottom: 15px;

    img {
      max-width: 100%;
    }
  }

  &__ingredient-name {
    margin-bottom: 5px;

    &:first-letter {
      text-transform: capitalize;
    }
  }

  &__actions {
    display flex
    align-items center
    justify-content space-between
    margin-left -10px
    margin-right -10px

    @media $phone {
      flex-direction column
      align-items flex-start
      justify-content center
      margin-left 0
      margin-right 0
    }

    &__item {
      margin 0 10px
      flex-grow 1
      width: 100%;

      @media $phone {
        display block
        width 100%
        margin-left 0
        margin-right 0
        margin-bottom 10px

        &:last-child {
          margin-bottom 0
        }
      }

    }

  }
}


