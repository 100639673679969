.SberPaymentModal {
  @media $phone {
    & > div:first-child {
      margin-bottom: 0;
    }
  }

  &__modal {
    box-shadow: none;
  }

  &__iframe {
    opacity: 1;
    transition: opacity $duration;
  }
}

:global(#sberFrame) {
  width: 450px;
  max-width: 100%;
  height: 720px;
  max-height: 100%;
  position: relative;
  z-index: 2;

  @media $phone {
    height: 90vh;
  }
}
