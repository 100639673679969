.GoodBlock {
  background: var(--papa-color-1);
  padding 74px 0

  @media $tablet {
    padding 36px 0
  }

  @media $phone {
    padding 28px 0
  }

  &__title {
    color white
    margin-bottom 48px

    @media $tablet {
      margin-bottom 20px
      text-align center
    }
  }

  &__content {
    display grid
    grid-template-columns 1fr 1fr
    gap 43px
    @media $tablet {
      grid-template-columns 1fr
      gap 20px
    }
    @media $phone {
      gap 10px
    }
  }

  &__text {
    position relative
    background var(--papa-color-2)
    color black
    border-radius 24px
    border-bottom-left-radius  0
    padding 24px 36px 22px
    font-size 32px

    @media $tablet {
      font-size 24px
    }

    @media $phone {
      padding 16px
    }

    &:after {
      background var(--papa-color-2)
      display block
      position absolute
      left 0
      content " " url("../../assets/smile.svg")
      border-radius 24px
      border-top-left-radius  0
      border-top-right-radius  0
      padding 12px 36px 20px
      width 35%
      @media $tablet {
        width 45%

      }

      @media $phone {
        padding 16px
      }
    }
    &:before {
      background var(--papa-color-2)
      display block
      position absolute
      left 0
      bottom -20px
      content " "
      width calc(35% + 20px)
      height 20px

      @media $tablet {
        width calc(45% + 20px)

      }
    }

    &_corner {
      position absolute
      left 35%
      bottom -40px
      border-radius 24px
      width 40px
      height 40px
      background var(--papa-color-1)
      clip-path: polygon(100% 0, 0 0, 0 100%);
      @media $tablet {
        left 45%
      }
    }
  }

  &__grid {
    margin-top 40px
    display grid
    gap 43px
    grid-template-columns 35% 1fr
    @media $tablet {
      grid-template-columns 45% 1fr
      margin-top 20px
      gap 20px
    }
  }

  &__okko {
    margin-top 76px
  }

  &__link {
    margin 24px auto 0
    display block
  }

  &__disabled {
    pointer-events none
    filter grayscale(1)
  }
}
