.SecondScene {
  &__info {
    padding: 24px $phone-margin-x $phone-margin-y;
    border-bottom: 4px solid $color-bg;

    .SecondScene_tablet & {
      background-color: white;
      border-width: 8px;
    }
  }

  &__title {
    .SecondScene_tablet & {
      text-align: center;
    }
  }

  &__navbar {
    .SecondScene_tablet & {
      display: none;

      @media $phone {
        display: flex;
      }
    }
  }

  &__image {
    margin-bottom: 30px;
    text-align: center;

    img {
      max-width: 70%;
    }
  }

  &__note {
    color: $color-brand-brown;

    .SecondScene_tablet & {
      display: block;
      width: 100%;
      max-width: 348px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__ingredients {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: @margin-left;
  }

  &__ingredient {
    width: calc(50% - 10px);
  }

  &__footer {
    padding: 24px $phone-margin-x;
    background-color: $color-bg;
  }

  &__button {
    width: 100%;

    &_back {
      margin-bottom: 10px;
    }
  }
}
