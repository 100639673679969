.SelectButton {
  height: $checkout-mobile-button-height;
  padding: $checkout-mobile-button-padding;
  border-radius: $checkout-mobile-button-radius;
  background-color: $color-grey;
  font-family: $font-primary;
  font-size: $font-size-s;
  line-height: 1.33;
  font-weight: 500;
  color: white;
  transition: opacity $halfduration;
  white-space: nowrap;

  &:not(&_active):not(&_disabled):hover {
    opacity: 0.9;
  }

  &_active {
    background-color: $color-brand-green;
  }
}