.Cart {
  position: relative;
  padding-top: 40px;

  &__line {
    position: absolute;
    top: 'calc(%s - 3px)' % @padding-top;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: $color-brand-red;
    border-radius: 2px;
  }

  &__promoGame-decor {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -28px;
    width: 76px;
    img {
      width : 100%
    }

  }

  &__decor {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 50%;
    width: 96px;
    height: @width;
    margin-left: -(@width / 2);
    border-radius: 50%;
    background-color: white;
    border: 3px solid $color-brand-red;
  }

  &__icon {
    position: absolute;
    top: -20px;
    left: 50%;
    margin-left: -16px;
    color: $color-brand-red;

    svg {
      position relative
      display: block;
    }
  }

  &__cosmo-decor {
    position absolute
    top 50%
    left 50%
    transform translate(-50%,-50%)
  }

  &__inner {
    position: relative;
    padding: $paper-pad-x;
    border-top-left-radius: 2px;
    border-top-right-radius: @border-top-left-radius;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &__headline {
    position: relative;
    padding-right: 30px;
    cursor: pointer;
    user-select: none;
  }

  &__title {
    font-size: $font-size-l;
  }

  &__toggle {
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -15px;
    color: $color-brand-brown;
  }

  &__headline:hover &__toggle {
    color: $color-brand-green;
    transition-duration: $halfduration;
  }

  &__heading, &__count {
    display: inline-block;
  }

  &__clear {
    font-size: $font-size-xs;
  }

  &__empty {
    padding-top: 16px;
    text-align: center;
  }

  &__empty-pic {
    position relative
    display: block;
    margin-bottom: 8px;

    img {
      max-width: 100%;
      vertical-align: middle;
    }

    &-hatiko {
      max-width : 172px;
      margin: 0 auto;
    }

    &-cosmo {
      position absolute
      right 0
      bottom -10px
    }

    &__promoBorder {
      width 140px
      top 0
      transform translate(-50%,-98%)
      left 50%
    }
  }

  &__list-wrapper {
    margin: 0 (-($paper-pad-x));
  }

  &__total {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding-top: 15px;
    padding-bottom: 10px;
    border-top: 1px solid white;
    border-bottom: 1px solid $color-border;
  }

  &__total-label {
    font-weight: 500;
  }

  &__total-value {
    flex-shrink: 0;
    flex-grow: 1;
    text-align: right;
  }

  &__total-count {
    font-size: $font-size-xs;
  }

  &__pay {
    text-align: center;
    padding-top: 16px;
    margin-bottom: 10px;
  }

  &__actions {
    padding-top: 15px;
    margin-bottom: 8px;
    text-align: center;
  }

  &__cashback {
    text-align: center;
    font-size: $font-size-xs;
    color: $color-brand-green;
  }

  &_opened &__total {
    border-top-color: $color-border;
    border-bottom-color: white;
  }

  &_discount &__total {
    padding-top: 25px;
  }

  &__promoArtefact {
    top: -18px;
    right: 40px;
    height: 55px;

  }
}
