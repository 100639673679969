.NewPassword {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: white;

  &__success {
    margin: auto;
  }

  &__inner {
    padding: 32px $phone-margin-x 0;
  }

  &__field {
    display: none;
    flex-direction: column;

    &_visible {
      display: flex;
    }
  }

  &__repeat {
    margin-top: 20px;
  }

  &__error {
    font-family: $font-primary;
    font-size: $font-size-xs;
    line-height: 1.33;
    font-weight: 400;
    color: $color-brand-red;
  }

  &__note {
    margin-top: 20px;
    font-family: $font-primary;
    font-size: $font-size-xs;
    line-height: 1.33;
    font-weight: 400;
    color: $color-text;
  }

  &__actions, &__submit {
    margin-top: 40px;
  }

  &__actions {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__back {
    margin-right: 40px;
  }
  &__repeatRequest {
    margin-top: 15px;
    margin-bottom: 15px;
    color: #50a684;

    &_again {
      cursor: pointer
    }
  }
  &__notCall {
    text-decoration: underline;
    cursor: pointer;

    &_condition {
      color: #306c59;
    }
  }
}
