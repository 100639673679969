.Halves {
  position: relative;
  width: 1000px;
  margin-left: auto;
  margin-right: auto;


  &__vk {
    display flex;
    justify-content center;
    margin-top: 24px
    @media $verticalTablet {
      display: none;
    }
  }


  @media (orientation: landscape) and (max-width: 1279px) {
    width: 1000px;
  }

  @media $verticalTablet {
    width: 584px;
  }

  &__title {
    font-family: $font-secondary
    font-weight 700
    text-align: center

    @media (orientation: landscape) and (max-width: 1023px) {
      font-size: $font-size-xxl;
      line-height: 32px;
    }
  }

  &__header {
    margin-bottom: 32px;

    @media (orientation: landscape) and (max-width: 1023px) {
      margin-bottom: 12px;
    }

    &_match {
      top: 16px;
      left: 50%;
      position absolute;
      z-index 100;
    }
  }

  &__subtitle {
    font-family: $font-secondary
    font-size: $font-size-m;
    text-align: center
  }

  &__content {
    display: flex;
    height: 584px;

    @media (orientation: landscape) and (max-width: 1023px) {
      justify-content: space-between;
    }

    @media $verticalTablet {
      flex-wrap: wrap;
      justify-content: space-between;
      height: auto;
    }
  }

  &__side {
    flex-shrink: 0;
    flex-basis: 264px;
    padding-right: 5px;
    overflow-x: hidden;
    overflow-y: auto;

    @media (orientation: landscape) and (max-width: 1023px) {
      flex-basis: 285px;
    }

    @media $verticalTablet {
      flex-basis: 285px;
    }

    scrollbar-width: thin;
    scrollbar-color: $color-border white;

    &::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }

    &::-webkit-scrollbar-track {
      background-color: white;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-border;
      border-radius: 2px;
    }
  }

  &__body {
    flex-grow: 1;
    flex-shrink: 0;
    border-radius: 25px;
    padding: 24px 20px 32px;
    margin-left: 35px;
    margin-right: @margin-left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (orientation: landscape) and (max-width: 1023px) {
      flex-grow: 0;
      flex-basis: 280px;
      height: 400px;
      margin-left: 0;
      margin-right: @margin-left;
      justify-content: flex-start;
      order: -1;
    }

    @media $verticalTablet {
      flex-grow: 0;
      flex-basis: 100%;
      height: 194px;
      order: -1;
      margin-left: 0;
      margin-right: @margin-left;
      margin-bottom: 76px;
      position: relative;
    }
  }

  &__body-header {
    display: flex;
    justify-content: space-between;

    &_vkTablet {
      display: none;
      @media $verticalTablet {
        position: absolute;
        left: 40%;
        display flex;
        justify-content center;
      }
    }
    @media (orientation: landscape) and (max-width: 1023px) {
      position: absolute;
      width: 582px;
      bottom: calc(100% + 12px);
      left: calc(100% + 35px);
    }

    @media $verticalTablet {
      position: absolute;
      top: calc(100% + 26px);
      left: 0;
      width: 100%;
    }
  }

  &__body-content {
    position: relative;
    padding-bottom: 200px;

    @media $verticalTablet {
      padding-bottom: 0;
    }
  }

  &__selected {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
  }

  &__actions {
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 244px;
    margin-left: -122px;
    font-family: $font-secondary

    @media $verticalTablet {
      top: 50%;
      bottom: auto;
      left: auto;
      right: 12px;
      transform: translateY(-50%);
    }
  }

  &__image {
    @media $verticalTablet {
      padding-right: 50%;
    }
  }

  &__textPreview {
    font-family: $font-secondary
    font-size: $font-size-m;
    font-weight: 500;
    position: absolute;
    left: 50%;
    top: 240px;
    width: 320px;
    margin-left: -160px;
    text-align: center;

    @media $verticalTablet {
      top: 0;
      right: 0;
      left: auto;
      width: 260px;
      height: 100%;
      display: flex;
      align-items: center;
      margin: 0;
    }
  }
}
