.InputField {
  &_disabled {
    pointer-events: none;
    opacity: 0.75;
  }

  &__wrapper {
    position: relative;
    display: flex;
  }

  &__label {
    mobileinputlabel();
  }

  &__field {
    mobileinputfield();

    &_plain {
      mobileinputplainfield();
    }
  }

  &_error {
    .InputField__label {
      color: $color-brand-red;
    }
  }

  &__textBlock {
    justify-content: flex-start;
    padding-top: 14px!important;
    padding-bottom: 14px!important;
  }

  &__textInsideBlock {
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    justify-content: flex-end;

    &_placeholder{
      color: #bfb7b6;
    }
  }

  &__textPlaceholder{
    color: $color-grey;
  }
}
