.PopupNewYear {
  transform: translate3d(0,0,0);
  position: fixed;
  bottom: 0;
  z-index: 1;

  &__wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__close {
    position: absolute;

    svg {
      color: black;
    }
  }

  &__image {
    height: 100%;
    width: auto;
  }

  &_variant-1 {
    width: auto;
    height: 240px;
    left: 25%;
    @media $phone {
      left: 10%;
    }

    .PopupNewYear {
      &__close {
        left: -5%;
        top: 30%;
      }
    }
  }

  &_variant-3 {
  width: auto;
  height: 240px;
  left: 25%;
  @media $phone {
    left: 10%;
    height: 200px;
   }

    .PopupNewYear {
      &__close {
        left: -5%;
        top: 30%;
      }
    }
  }

  &_variant-2 {
    width: auto;
    height: 300px;
    right: 0;
    @media $phone {
      height: 240px;
     }

    .PopupNewYear {
      &__close {
        top: 40%;
        right: 5%;
      }
    }
  }
}
