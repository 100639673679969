.HomePage__new-year {
  padding-bottom: 0 !important;
  background-image: url($colorNY-bg);
  background-repeat: repeat;
  background-size: contain;
  position: relative;

  .HomePage {
    &__header {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: calc(100px + 25 * ((100vw - 1500px) / 920));
      z-index: 10;
      background-repeat: no-repeat;
      background-image: url($colorNY-slider-header_mobile);
      background-size: contain;
    }
  }
}

.HomePage {
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  position: relative;

  &__promocode {
    padding: 12px;
  }

  &__content {
    transition: opacity 0.3s;
    opacity: 1;

    &_hidden {
      opacity: 0;
    }
  }

  &__containerForBonusesButtons {
    padding: 0 !important;
    margin-bottom: 16px;
  }

  &__floatingMargin {
    bottom: 100px;
    transition: all .4s ease-in-out;

    &_to-up {
      bottom: 130px;
    }
  }

  &__PopupPromoGame {
    position: fixed;
    left: 19px;
    bottom: 20px;
  }

  &__promoGame-artefact {
    z-index: 10;
    right: 9px;
    top: 20%;
    transform: rotate(-90deg);
    height: 90px;

    &_mm {
      position: fixed
      height: 120px
      top: 45%;
      transform: rotate(-45deg) translate(32px)
    }
  }
}

.ModalVpn {
  &__popupMobileGeneral {
    margin-top: 15px
    width: 100%;
  }
}
