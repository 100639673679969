:global {
  .react-date-picker {
    display: inline-flex;
    position: relative;
  }

  .react-date-picker, .react-date-picker *, .react-date-picker *:before, .react-date-picker *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .react-date-picker--disabled {
    background-color: #f0f0f0;
    color: #6d6d6d;
  }

  .react-date-picker__button {
    display: flex;
    border: thin solid gray;
  }

  .react-date-picker__button__input {
    min-width: calc(4px + (4px * 3) + 0.54em * 8 + 0.217em * 2);
    flex-grow: 1;
    display: flex;
    padding: 0 2px;
    align-items: baseline;
  }

  .react-date-picker__button__input__divider {
    padding: 1px 0;
  }

  .react-date-picker__button__input__input {
    min-width: 0.54em;
    height: 100%;
    position: relative;
    padding: 1px;
    border: 0;
    background: none;
    font: inherit;
    box-sizing: content-box;
    -moz-appearance: textfield;
  }

  .react-date-picker__button__input__input::-webkit-outer-spin-button, .react-date-picker__button__input__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .react-date-picker__button__input__input:invalid {
    background: rgba(255, 0, 0, 0.1);
  }

  .react-date-picker__button__input__input--hasLeadingZero {
    margin-left: -0.54em;
    padding-left: calc(1px + 0.54em);
  }

  .react-date-picker__button__icon {
    border: 0;
    background: transparent;
    padding: 4px 6px;
  }

  .react-date-picker__button__icon:enabled {
    cursor: pointer;
  }

  .react-date-picker__button__icon:enabled:hover svg g, .react-date-picker__button__icon:enabled:focus svg g {
    stroke: #0078d7;
  }

  .react-date-picker__button__icon:disabled svg g {
    stroke: #6d6d6d;
  }

  .react-date-picker__button__icon svg {
    display: inherit;
  }

  .react-date-picker__calendar {
    width: 350px;
    max-width: 100vw;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
  }

  .react-date-picker__calendar--closed {
    display: none;
  }

  .react-date-picker__calendar--above-label {
    bottom: 100%;
    top: auto;
  }

  .react-date-picker__calendar .react-calendar {
    border-width: thin;
  }

  .react-calendar {
    width: 350px;
    max-width: 100%;
    background: white;
    border: 1px solid #a0a096;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
  }

  .react-calendar, .react-calendar *, .react-calendar *:before, .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }

  .react-calendar button:enabled:hover {
    cursor: pointer;
  }

  .react-calendar__navigation {
    height: 44px;
    margin-bottom: 1em;
  }

  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
  }

  .react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
  }

  .react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
  }

  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
  }

  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }

  .react-calendar__month-view__weekNumbers {
    font-weight: bold;
  }

  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    padding: calc((0.75em / 0.75)) calc((0.5em / 0.75));
  }

  .react-calendar__month-view__days__day--weekend {
    color: #d10000;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
  }

  .react-calendar__year-view .react-calendar__tile, .react-calendar__decade-view .react-calendar__tile, .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }

  .react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;
  }

  .react-calendar__tile:disabled {
    background-color: #f0f0f0;
  }

  .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
  }

  .react-calendar__tile--hasActive {
    background: #76baff;
  }

  .react-calendar__tile--hasActive:enabled:hover, .react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
  }

  .react-calendar__tile--active {
    background: #006edc;
    color: white;
  }

  .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
    background: #1087ff;
  }

  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }
}

.DatePickerField {
  $fieldColor = $color-bg;
  position: relative;

  &__label {
    display: block;
    margin-bottom: 5px;
    min-height: 19px;
  }

  &__field {
    width: 100%;
    flex: 1 1 100%;
  }

  &__field :global(.react-calendar), &__field :global(.react-date-picker__calendar) {
    width: 100%;
  }

  &__field :global(.react-calendar) {
    overflow: hidden;
    border: none;
    border-radius: 5px;
    box-shadow: $box-shadow;
  }

  &__field :global(.react-date-picker__calendar) {
    z-index: 10;
  }

  &__field :global(.react-date-picker__button__input__input:invalid) {
    background-color: transparent;
  }

  &__field :global(.react-date-picker__button__input__input) {
    color: $color-brand-brown;
  }

  &__field :global(.react-calendar__tile--active) {
    background: $color-brand-green !important;
  }

  &__field :global(button.react-calendar__tile:enabled:hover), &__field :global(button.react-calendar__tile:enabled:focus) {
    background: $fieldColor;
  }

  &__field :global(.react-calendar__navigation button:enabled:hover), &__field :global(.react-calendar__navigation button:enabled:focus), &__field :global(.react-calendar__tile--hasActive) {
    background: $fieldColor;
  }

  &__field :global(.react-date-picker__clear-button) {
    display: none;
  }

  &__field :global(.react-date-picker__button) {
    display: flex;
    align-items: center;
    width: 100%;
    height: $inputHeight;
    padding: 12px 10px 12px 16px;
    border-radius: 4px;
    border: none;
    background-color: $fieldColor;
    font-size: $font-size-m;
    color: $color-brand-brown;
    placeholder($color-grey);
    transition: background-color $duration;

    &:last-child {
      margin-bottom: 0;
    }

    &:focus {
      background-color: darken($fieldColor, 3%);
    }
  }

  &__inputWrapper {
    position: relative;
  }

  &__description {
    padding-top: 8px;
    font-size: $font-size-xs;
    color: $color-text;
  }

  &__fieldWrapper {
    position: relative;
    display: flex;
  }

  &_disabled {
    pointer-events: none;
    opacity: 0.75;
  }
}
