.CookiePolicyPopup {
  width: 100%;
  position: fixed;
  padding: 20px 0;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
  z-index: 10001;
  background: white;
  border-radius: 6px;
  box-shadow: 0 2px 12px 0 rgba(41, 44, 51, 0.2);
  transition: transform 500ms;

  &_entered {
    transform: translateY(0);
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__button {
    align-self: flex-end;
  }
}
