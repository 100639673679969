.PayFieldGroup {
  padding: 16px;
  display: flex;
  flex-direction: column;
  position: relative;

  &__inner {
    display: flex;
    flex-direction: column;
    position: relative;

    &_disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}