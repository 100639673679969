.WarningModal {
  padding: $phone-margin-y $phone-margin-x 32px;
  text-align: center;

  @media (min-width: 768px) and (max-width: 1279px) {
    top: auto;
    max-width: none;
    height: 160px;
    background-color: white;
    padding-top: 36px;
  }

  &__text {
    margin: 0 auto;
    margin-bottom: 24px;
    color: $color-brand-brown;
  }

  &__button {
    width: 100%;

    @media (min-width: 768px) and (max-width: 1279px) {
      width: 280px;
    }
  }
}
