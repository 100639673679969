.Profile {
  width: 100%;
  position: relative;
  display: flex;
  padding: 12px 0 12px $phone-margin-x;

  &__body {
    margin-right: auto;
    display: flex;
    align-items: center;
  }

  &__avatar {
    width: 52px;
    height: 52px;
    margin-right: 24px;
  }

  &__bonuses {
    color: $color-text;
  }

  &__logout {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: inherit;
  }
}
