.Characters {
  background-color: $color-border;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 0 148px;
    @media $tablet {
      padding: 28px 0 36px;
    }

    @media $phone {
      padding: 20px 0 28px;
    }
  }

  &__description {
    text-align: center;
    padding-top: 32px;
    @media $tablet {
      padding-top: 20px;
    }

    @media $phone {
      padding-top: 12px;
    }
  }

  &__wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    padding-top: 40px;

    @media $tablet {
      gap: 5px;
      padding-top: 24px;
    }

    @media $phone {
      grid-template-columns: repeat(2, 1fr);
      gap: 12px;
    }

    @media (max-width: 400px) {
      grid-template-columns:  1fr;
      gap: 12px;
    }
  }


  &__tile {
    background-color: transparent;
    perspective: 1000px;
    aspect-ratio: 0.56;
    //overflow : hidden;

    &:focus {
      outline: 0;
    }

    &:hover > div, &:focus > div {
      transform: rotateY(180deg);
    }

    &:hover  .back, &:focus .back {
      z-index: 3;
    }

    &-inner {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;
      transition: transform 0.6s;
      transform-style: preserve-3d;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      border-radius: 16px

      @media $tablet {
        border-radius: 8px
      }
    }

    &-front, &-back {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    &-front {
      z-index: 2;
      display: flex;
      justify-content: center;
      padding: 50px 30px 0;
      //align-items: center;
      border-radius: 16px

      @media $tablet {
        border-radius: 8px
        padding: 30px 12px 0;
      }
    }

    &-back {
      transform: rotateY(180deg);
      z-index: 1;
      display: flex;
      flex-direction: column;
      //justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 50px 30px 50px;
      gap 20px;
      //filter: brightness(0.5)

      @media $tablet {
        padding: 30px 12px 30px;
      }
    }


    &-back-img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      //background: rgba(46, 44, 44, 0.8);
      border-radius: 16px

      @media $tablet {
        border-radius: 8px
      }
    }

    &-heading, &-caption {
      color: white;
      white-space: break-spaces;

    }

    &-caption {
          flex-grow: 1;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
      //position: absolute
      //top: 50%
      //transform: translateY(-50%)
      //left: 30px;
      //right: 30px;

      @media $tablet {
        //transform: translateY(-35%)
        //left: 12px;
        //right: 12px;
      }

    }
  }

}

.sprite {
  //display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url(./assets/sprite.png);
  background-size: 300% 300%;


  &__tile_1 {

    background-position: 0 0
  }

  &__tile_1_back {

    background-position: 50% 0
  }

  &__tile_2 {

    background-position: 100% 0
  }

  &__tile_2_back {

    background-position: 0 50%
  }

  &__tile_3 {

    background-position: 50% 50%
  }

  &__tile_3_back {

    background-position: 100% 50%
  }

  &__tile_4 {

  }
  background-position: 0 100%

  &__tile_4_back {

    background-position: 50% 100%
  }
}


