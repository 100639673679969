.SizeSelector__halloween {
  background-color: $colorHW-bg-select;
  .SizeSelector {
    &__overlay {
      background-color: $colorHW-bg-select-selected;
    }
    &__size {
      color: $colorHW-text-select;
      &:not(&_selected):hover {
        color: $colorHW-text-hover!important
      }
    }
    &__size_selected {
      color: $colorHW-text-select-selected;
    }
  }
}

.SizeSelector__new-year {
  background-color: $colorNY-bg-select;
  .SizeSelector {
    &__overlay {
      background-color: $colorNY-bg-select-selected;
    }
    &__size {
      color: $colorNY-text-select;
      &:not(&_selected):hover {
        color: $colorNY-text-hover!important
      }
    }
    &__size_selected {
      color: $colorNY-text-select-selected;
    }
  }
}

.SizeSelector {
  width: 100%;
  height: 36px;
  display: flex;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  border-radius: 100px;
  border: solid 1px $color-border;

  &__size {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    line-height: 1.2;
    cursor: pointer;
    user-select: none;
    transition: color $duration;
    white-space: nowrap;

    &_selected {
      cursor: default;
      color: $color-brand-brown;
    }

    &:not(&_selected):hover {
      color: $color-brand-green;
      transition-duration: $halfduration;
    }
  }

  &__overlay {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #ededed;
    border-radius: 100px;
    transition: transform ($duration / 1.5) $easeOutCubic;
  }
}
