.CitySelector {
  position: relative;

  &__toggle {
    max-width: 160px;
    padding: 6px 15px 0;
    position: relative;
    display: flex;
    align-items: center;
    font-family: $font-secondary;
    color: $color-brand-brown;
    font-weight: 500;
    cursor: pointer;
    user-select: none;
    transition: color $duration;

    &:hover {
      color: $color-brand-green;
      transition-duration: $halfduration;;
    }
  }

  &__icon {
    width: 16px;
    height: auto;
    color: $color-brand-brown;
    flex-shrink: 0;
    margin-bottom: 3px;
  }

  &__city {
    margin-left: 6px;
    text-overflow();
    font-size: $font-size-xs;
  }

  &__navbar {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 10;
  }

  &__list {
    padding: 24px $phone-margin-x;
  }
}