.Success {
  width: 376px;
  margin: 0 auto;
  text-align: center;

  @media $verticalPhone {
    width: 100%;
  }

  &__title {
    width: 100%;
    margin-bottom: 8px;
    font-size: 28px;
    text-align: center;

    @media $phone {
      margin-bottom: 16px;
      font-size: $font-size-l;
    }
  }

  &__mark {
    width: 90px;
    margin: 0 auto 40px auto;
    display: block;
  }

  &__subtitle {
    margin-bottom: 48px;
    color: $color-brand-brown;

    @media $phone {
      margin-bottom: 40px;
    }
  }
}
