.SliderArrow {
  display flex
  align-items center
  gap 16px
  cursor pointer

  &_reversed {
    flex-direction row-reverse

    svg {
      transform rotate(180deg)
    }
  }

  svg {
    @media $phone {
      width 80px
    }
  }

  * {
    color black
    fill black
  }

  &__text {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;

  }
}
