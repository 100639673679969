.NewSignUpForm {
  display: flex;
  flex-direction: column;

  &__title {
    margin-bottom: 30px;
  }

  &__phone {
    margin-bottom: 20px;
  }

  &__code {
    margin-bottom: 20px;
  }

  &__privacy {
    &-title {
      display: flex;
      align-items: center;
    }

    &-description {
      margin-bottom: 20px;
      padding-top: 8px;
      font-size: $font-size-xs;
      color: $color-text;
      line-height: 1.33;
      font-weight: 400;
      font-family: $font-primary;
    }

    &-subtitle {
      font-size: $font-size-s;
      font-weight: 500;
      font-family: $font-secondary;
      line-height: 1;
      color: $color-brand-brown;
    }
  }

  &__actions {
    margin-top: 20px;
    display: flex;
    align-items: center;
  }

  &__action {
    margin-right: 20px;
    font-family: $font-primary;
    font-weight: 400;
    font-size: $font-size-s;
    line-height: 1.33;
    color: $color-brand-green;
    transition: color $duration;

    &:hover {
      color: $color-brand-green-hover;
    }

    &_disabled {
      color: $color-grey;
      pointer-events: none;
    }
  }

  &__submit {
    margin-left: auto;
    flex-shrink: 0;
  }

  &__error {
    font-family: $font-primary;
    font-size: $font-size-xs;
    line-height: 1.33;
    font-weight: 400;
    color: $color-brand-red;
  }
}
