.Meta-option {
  margin-right: 8px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: default;

  &:last-child {
    margin-right: 0;
  }

  &_person-count {
    svg {
      margin-right: 5px;
    }
  }

  &__tooltip {
    margin-bottom: 13px;
    padding: 16px;
    position: absolute;
    bottom: 100%;
    left: -13px;
    border-radius: $border-radius;
    background-color: $color-brand-brown;
    color: white;
    z-index: 1000;
    transition: opacity ($duration / 2);
    opacity: 0;
    white-space: nowrap;

    &:after {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      top: 100%;
      left: 16px;
      border-style: solid;
      border-width: 6px 6px 0 6px;
      border-color: $color-brand-brown transparent transparent transparent;
    }

    &_entered {
      opacity: 1;
    }
  }
}