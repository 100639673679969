

.RouteWindow {
  overflow hidden
  overflow-y auto
  max-height 100%


  @media $phone {
    overflow hidden
    height 48px
    padding 5px 20px
    display flex
    align-items stretch
    justify-content space-between
  }

  &__back {
    position relative
    display flex
    justify-content space-between
    align-items center
    top 0
    left 0
    width 100%
    height 20px
    margin-bottom 24px

    @media $phone {
      align-items left
      width auto
      flex 0 0 auto
      margin-bottom 0
      height 100%
    }
  }

  &__back-label,
  &__back-icon {
    display inline-block
  }

  &__back-icon {
    color $color-grey
    height 12px
    width 12px

    svg {
      display block
    }
  }

  &__back-label {
    padding 0 16px 0 0
    font-weight 500
    color $color-text
    flex 1 0 auto
    text-align  center

    @media $phone {
       padding 0  0  0 16px
    }
  }

  &__fieldIconPlace {
    input {
      padding-left 48px
    }
  }

  &__fieldIcon {
    position absolute
    left 12px
    top 12px
  }

  &__fieldRow {
    margin-bottom 12px

    @media $phone {
      display none
    }
  }

  &__routingModes {
    display flex

    height 40px
    margin-bottom 12px
    background-color #f5f5f5
    border-radius 20px
    overflow hidden
    white-space nowrap

    @media $phone {
      flex 0 0 auto
      margin-bottom 0
    }
  }

  &__routingModeBtn {
    display flex
    flex 0 0 50%
    align-items center
    justify-content center
    height 38px
    border 1px solid #f5f5f5
    padding 4px 16px
    min-width 38px
    border-radius 20px
    overflow hidden
    transition background-color $duration, border-color $duration


    @media $phone {
      flex 0 0 auto;
      position relative
    }

    &_active {
      border 1px solid #009471
      background-color white
    }
  }

  &__routingModeBtnText {
    display inline-block
    padding 0 0 0 8px
    color $color-grey
    font-size  12px
    font-weight bold

    ^[0]__routingModeBtn_active & {
      color $color-brand-green
    }

    ^[0]__routingModeBtn:not(^[0]__routingModeBtn_active):hover & {
      transition color $duration
      color alpha($color-brand-green, 70%)
    }

    @media $phone {
      display none
    }
  }

  &__routingModeBtnIcon {
    path {
      fill $color-grey

      ^[0]__routingModeBtn_active & {
        fill $color-brand-green
      }

      ^[0]__routingModeBtn:not(^[0]__routingModeBtn_active):hover & {
        transition fill $duration
        fill alpha($color-brand-green, 70%)
      }
    }

    @media $phone {
      position absolute
      width 24px
      height 24px
      left 6px
      top 6px
    }
  }
}
