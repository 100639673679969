.SignInForm {
  position: relative;

  &__phone {
    margin-bottom: 20px;
  }

  &__recovery {
    margin-top: 8px;
  }

  &__actions {
    margin-top: 20px;
    display: flex;
    align-items: center;
  }

  &__link {
    margin-right: auto;
  }

  &__submit {
    margin-left: $checkout-mobile-margin-20;
  }

  &__error {
    font-family: $font-primary;
    font-size: $font-size-xs;
    line-height: 1.33;
    font-weight: 400;
    color: $color-brand-red;
  }
}
