.RemoveButton {
  & {
    position relative
    display inline-block
    color rgba($color-grey, 0.6)
    background none
    border none
    transition color $duration
    cursor pointer
  }

  &:after {
    content ''
    position absolute
    top -6px
    right -6px
    bottom -6px
    left -6px
  }

  svg {
    display block
  }

  &:hover {
    color $color-brand-red
    transition-duration: $halfduration;
  }

}
