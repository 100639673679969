.ProductCardCompact__halloween {
  .ProductCardCompact {
    &__addContainer {
      background-image: url($colorHW-card-background)
      background-size: cover;
    }

    &__name, &__description, &__option, __sizes {
      color: $colorHW-text-halloween;
    }

    &__button {
      color: $colorHW-text-btn!important;

      &:before {
        background-color: $colorHW-bg-btn!important;
      }
    }
  }
}

.ProductCardCompact__new-year {
  .ProductCardCompact {
    &__addContainer {
      background-image: url($colorNY-card-background)
      background-size: cover;
    }

    &__name, &__description, &__option, __sizes {
      color: $colorNY-text-halloween;
    }

    &__button {
      color: $colorNY-text-btn!important;

      &:before {
        background-color: $colorNY-bg-btn!important;
      }
    }
  }
}

.ProductCardCompact {
  display: flex;
  position: relative;
  // @TODO это может чтото сломать
  //overflow: hidden;
  background-color: white;

  &__spinner {
    spinner(24px, 2px, currentColor);
    margin-top: -12px;
    margin-left: -12px;
    position: absolute;
    top: 50%;
    left: 50%;
  }

  &__points {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 55px;
    height: 36px;
    font-family: Roboto;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    border-radius: 25px;
    cursor: pointer;
    background: url('../../icons/backgrounds/BG.png') no-repeat center center / cover

    &_disabled {
      background: #BFB7B6;
    }
  }

  &__addContainer {
    display: flex;
    flex-grow: 1;
    position: relative;
  }

  &__promoGame {
    &_button {
      z-index: 1
      top: -29px;
      right: 14px;
      &_mm {
        max-width: 95px;
        top 0;
        right 5px
      }
    }

    &_side {
      z-index: 1

      top: 20%;
      right: 0;
    }

    &_sideLeft {
      z-index: 1

      bottom: 15%;
      left: 0;
    }

    &_top {
      z-index: 1
      left: 20%;
    }
    &_bottom {
      z-index: 10
      bottom : 0;
      right: 30%;
    }
    &_top_outside {
      top: -45px;
      left : 0;
    }
  }

  &__picture {
    width: 40%;
    padding-right: 8px;
    position: relative;
    display: flex;
    align-items: center;

    &_pizza {
      width: 80%;
      margin-left: -40%;
    }
  }

  &__image {
    width: 100%;
  }

  &__badge {
    height: 20px;
    padding: 0 8px;
    position: absolute;
    right: 12px;
    top: 0;
    z-index: 2;
    background-color: $color-brand-red;
    color: white;
    font: normal 10px / 21px $font-primary;

    &_vegan {
      background-color: $color-brand-green;
    }
  }

  &__content {
    width: 60%;
    padding: 16px 16px 16px 0;
    display: flex;
    flex-direction: column;
  }

  &__name {
    margin-bottom: 8px;
    font-family: $font-secondary;
    font-weight: bold;
    color: $color-brand-brown;
    line-height: 1.33;
    font-size: $font-size-l;
  }

  &__description {
    margin-bottom: 8px;
  }

  &__option {
    margin-bottom: 8px;
  }

  &__sizes {
    width: 100%;
    margin-top: 8px;
  }

  &__footer {
    position: relative;
    margin-top: auto;
    padding-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__cosmo_decor {
    position absolute
    transform translate(-50%, -57%)
  }

  &__price {
    user-select: none;
  }

  &__button {
    flex-shrink: 0;
    z-index: 2;
  }

  &__PromoBorder {
    &_pic {
      width 100px
      bottom 0
      left 0
    }
    &_button{
      z-index 10
      width 48px
      bottom 0
      right 0
    }
  }


  &__PaddingtonDecor {
      width 50px
      top 5px
      right 0
  }
}
