.SelectField {
  $height = 62px;
  $input-border-radius = 12px;
  $color = $color-brand-brown;

  &_disabled {
    pointer-events: none;
    opacity: 0.75;
  }

  &__label {
    display: block;
    max-width: 100%;
    min-height: 19px;
    margin-bottom: 5px;
  }

  :global {
    .Select-control {
      box-sizing: border-box;
      width: 100%;
      height: $height;
      position: relative;
      border: none;
      border-radius: $input-border-radius;
      background-color: $color-bg;
      color: $color;
      transition: background-color $duration;
      placeholder($color-grey);
      font-family: $font-primary;
      font-size: $font-size-s;
      font-weight: 400;
      line-height: 1.33;
    }

    .Select-control:hover {
      box-shadow: none;
    }

    .Select--single > .Select-control .Select-value, .Select-placeholder {
      padding-left: 30px;
      line-height: $height;
    }

    .Select.has-value.Select--single > .Select-control .Select-value .Select-value-label, .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
      font-weight: 500;
      font-family: $font-primary;
      font-size: $font-size-s;
      line-height: 1.33;
      color: $color;
    }

    .Select-control > *:last-child {
      padding-right: 28px;
    }

    .Select-arrow {
      border-color: $color-grey transparent transparent;
    }

    .Select.is-focused > .Select-control {
      background-color: $color-bg-hover;
    }

    .Select.is-open > .Select-control {
      box-shadow: none;
      border-radius: $input-border-radius;
    }

    .Select.is-focused:not(.is-open) > .Select-control {
      box-shadow: none;
    }

    .Select-input {
      width: 100%;
      height: 100%;
      padding: 0;
      margin-top: 10px;
    }

    .Select-input > input {
      padding: 0;
    }

    .Select-control .Select-input:focus {
      background-color: transparent;
    }

    .Select-menu-outer {
      box-sizing: border-box;
      overflow: hidden;
      position: absolute;
      width: 100%;
      max-height: 200px;
      top: 100%;
      padding-right: 4px;
      border: none;
      border-radius: $input-border-radius;
      box-shadow: $box-shadow;
      z-index: 1000;
    }

    .Select-menu {
      max-height: 200px;
      scrollable();
      custom-scrollbar(2px, #e6e2e1);
    }

    .Select-option {
      position: relative;
      padding: 15px 15px 15px 48px;
      border-radius: 0;
      line-height: 1.15;
      color: $color;

      &:before {
        $size = 16px;
        content: '';
        position: absolute;
        display: block;
        width: $size;
        height: $size;
        left: 24px;
        top: 50%;
        margin-top: ($size / -2);
        opacity: 0;
        background-image: url('../assets/tick.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        pointer-events: none;
        transition: opacity $duration;
      }
    }

    .Select-option.is-focused {
      background-color: $color-bg;
    }

    .Select-option.is-selected {
      cursor: default;

      &:before {
        transition-duration: $halfduration;
        opacity: 1;
      }
    }

    .Select.is-open > .Select-control .Select-arrow {
      border-color: transparent transparent #333;
    }
  }
}
