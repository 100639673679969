.Message {
  display: flex;
  flex-direction: column;

  &__container {
    display: flex;
    margin: 15px 15px 7px 15px; 
    height: auto;

    &_right {
      justify-content: flex-end
    }
  }

  &__text {
    display: inline-block;
    padding: 10px;
    height: 100%;
    background-color: rgba(255, 255, 255, 1);
    color: rgba(53, 53, 53, 1);
    font-weight: 400;

    &_right {
      border-radius: 10px 10px 0px 10px;
    }

    &_left {
      border-radius: 10px 10px 10px 0px;
    }
  }

  &__time {
    display: inline-block;
    padding: 0px 15px;
    color: rgba(189, 189, 189, 1);
    font-size: 11px;

    &_right {
      text-align: right ;
    }
  }

  &__image {
    width: 320px;
    height: 160px;
    margin: 7px 15px; 
    background-size: cover;
    cursor: pointer;

    &_right {
      border-radius: 10px 10px 0px 10px;
    }

    &_left {
      border-radius: 10px 10px 10px 0px;
    }
  }

}
