.Warning {
  max-width: 582px;
  padding: $checkout-desktop-margin-20 $checkout-desktop-margin-40;
  background: #FAF0E1;
  border-radius: 12px;
  font-family: $font-primary;
  font-size: $font-size-m;
  color: $color-brand-brown;
  font-weight: 400;
  line-height: 1.33;
}
