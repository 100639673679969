.SaucesList {
  &__list {
    background-color: $color-bg;
    display: flex;
    flex-direction: column;
  }

  &__item {
    min-height: 80px;
    margin-bottom: 2px;
    padding: 8px;
    display: flex;
    align-items: center;
    background-color: #fff;
    transition: transform $duration;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__image {
    width: 105px;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    img {
      max-width: 100%;
    }
  }

  &__info {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__name {
    margin-bottom: 8px;
  }

  &__description {
    font-size: $font-size-xs;
  }

  &__button {
    flex-shrink: 0;
    align-self: center;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}
