.GiftSlide {
  width: 100%;
  height: 100%
  display: flex;
  background: #FFFFFF;
  @media $tablet{
    width: calc(100% - 32px);
    border-radius: 25px;
    margin: 0 auto;
    min-height: 130px;
    margin-bottom: 31px;
    padding: 32px 16px;
    position: relative;
  }

  &_disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &__slide {
    &-image {
      width: 120px;
      height: auto;
      margin-right: 16px;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      @media $tablet{
        width: 90px;
      }

      img {
        width: 100%;
      }
    }

    &-group {
      height: 100px
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;

      @media $tablet{
        height: auto;
        max-width: 130px;
      }

        &-head{
        display: flex;
        flex-direction column
      }
    }

    &-name {
      margin-bottom: 6px;
      word-break: break-word;
    }

    &-description {
      margin-bottom: 15px;
      word-break: break-word;
      font-size: $font-size-s;
      color: $color-grey;
      font-family: $font-primary;
      line-height: 1.33;
      font-weight: 400;
    }

    &-footer {
      //margin-top: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-amount {
      color: $color-brand-green-dark;
    }

    &-button {
      width: 36px;
      height: 36px;
      padding: 1px;
      flex-shrink: 0;
      border-radius: 50%;

      @media $tablet{
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(-50%, -50%);
      }
    }

    &-plus {
      width: 14px;
      height: auto;
      vertical-align: middle;
      fill: white;
    }
  }

  &__slide-points,
  &__slide-percent {
    width: 69px;
    height: 27px;
    border: 3px solid #F4F4F8;
    background: url("../../icons/grad.png") no-repeat center center / cover;
    color: #FFFFFF;
    border-radius: 25px;
    position: absolute;
    top: -15px;
    right: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 13px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media $tablet{
      box-shadow: 0px 0px 10px rgba(32, 38, 55, 0.35);
    }
  }

  &__slide-percent {
    right: 99px;
    background: #EB5757;
  }

  &__slide-price {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #009471;
    letter-spacing -0.8px;

    @media $tablet{
      margin-top: 10px;
    }
  }

  &__slide-old-price {
    white-space: nowrap;
    font-weight: 400;
    color: #EB5757;
    text-decoration: line-through;
  }

  &__slide-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #BFB7B6;
  }

}


