.PromocodesAndBonuses {
  paper();
  margin-bottom: $checkout-desktop-margin-20;
  padding: $checkout-desktop-margin-40;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &:not(:last-child) {
      margin-bottom: $checkout-desktop-margin-25;
    }

    &-count {
      color: $color-brand-green-dark;
      font-weight: 500;
    }

    &-toggle {
      width: 10px;
      height: 10px;
      margin-left: 8px;
      color: $color-grey;
      transition: transform $halfduration;
      transform: rotate(-90deg);

      &_open {
        transform: rotate(0deg);
      }
    }

    &-delete {
      display: flex;
      align-items: center;
      font-family: $font-secondary;
      font-weight: 400;
      font-size: $font-size-s;
      line-height: 1;
      color: $color-grey;
      transition: color $halfduration;

      &:hover {
        color: $color-brand-red;
      }
    }

    &-basket {
      width: 24px;
      height: auto;
      margin: -1px 0 0 10px;
      color: inherit;
    }
  }

  &__price {
    display: flex;
    flex-direction: column;
  }

  &__total {
    font-family: $font-secondary;
    font-weight: 500;
    font-size: $font-size-l;
    line-height: 1.33;
    color: $color-grey;
  }

  &__amount {
    color: $color-brand-green-dark;
  }

  &__delivery {
    font-size: $font-size-xs;
    text-transform: lowercase;
  }

  &__list {
    margin-bottom: $checkout-desktop-margin-20;
  }

  &__item {
    padding: $checkout-desktop-margin-20 0;
    border-top: 3px solid $color-bg;

    &:last-child {
      border-bottom: 3px solid $color-bg;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__wrapper {
    display: flex;
    gap: 36px;
    position: relative;
  }

  &__footer-line {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__selector {
    width: 360px !important;
  }

  &__sale {
    font-family: $font-secondary;
    color: $color-brand-brown;
    font-weight: 500;
    line-height: 1.33;
    font-size: $font-size-xs;
  }

  &__promocode {
    position: relative;
    //max-width: 65%;

    &-input {
      width: 258px !important;
    }
  }

  &__bonus {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex-shrink: 0;
  }

  &__BonusInput {
      width: 258px !important;
  }

  &__hint {

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;

    &_absolute {
      position: absolute;
      left: 294px;
      width: max-content;
      padding-top: 14px;
    }
  }

  &__hint-item {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0px;
    color: $color-brand-brown;

    &_primary {
      color: $color-brand-green;
      font-weight: 500;
    }

  }

  &__preloader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.5);
  }

  &__spinner {
    spinner(48px, 3px, $color-brand-green);
  }

  &__notifications {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }
}
