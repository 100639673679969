.MiniPreloader{
  position: relative;
  width: 10px;
  height: 10px

  &__loader {
    position: absolute;
    spinner(10px, 2px, $color-brand-green);
    margin-left: 5px;
  }

  &__check {
    position: absolute;
    width: auto;
    height: 16px;
    margin-left: 5px;
  }
}
