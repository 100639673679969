.General {
  overflow unset
}

.LoginModal {
  position relative
  overflow unset !important
  padding-top 75px !important

  &:before {
    position absolute
    content ''
    top 0
    left 50%
    width 124px
    height 131px
    background url("./decor.png") no-repeat center center
    background-size contain
    transform translate(-50%, -50%)
  }

  &__content {

  }

  &__text {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    color $color-brand-brown
  }

  &__subtext {
    padding-top 12px
    font-size: 16px;
    //font-weight: 600;
    text-align: center;
    color $color-brand-brown

  }

  &__decor {
    position absolute
    width 124px
    top 0
    left 50%
    transform translate(-50%, -50%)
  }

  &__button {
    margin-top 16px
  }
}
