.SuperBonuses {
  margin-top: 30px;

  &__button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 280px;
    height: 50px;
    border-radius: 40px;
    background: url('../../../../../../icons/backgrounds/bgDesktopSuperButton.png') center center;
    font-weight: 500;
    font-size: 14px
    color: #FFFFFF
  }

  &__text {
    font-family: $font-secondary;
    font-style: normal;
    font-size: 14px;
    line-height: 13px;
    display: block;
    font-weight: 500;
    margin-left: 24px;
  }

  &__icon {
    margin-right: 18px;
  }

  &__modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 429px;
    height: 408px;
    background-color: #F4F4F8
    border-radius: 25px;
  }

  &__container-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFFFFF;
    width: 429px;
    height: 173px;
    border-radius: 25px;
  }

  &__title {
    font-family: $font-secondary;
    font-style: normal;
    font-size: 18px;
    font-weight: 500;
    color: #70544F;
    margin-top: 40px;
  }

  &__container-value {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 164px;
    height: 51px;
    background: url('../../../../../../icons/backgrounds/bgDesktopSuperButton.png') center center;
    border-radius: 25px;
    margin-top: 25px;
  }

  &__value {
    font-family: $font-primary;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin-left: 14px;
  }

  &__header {
    font-family: $font-primary;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #70544F;
    margin-top: 30px;
  }

  &__information {
    font-family: $font-primary;
    width: 265px;
    font-style: normal;
    font-size: 16px;
    display: block;
    text-align: center;
    font-weight: 500;
    color: #70544F;
    opacity: 0.8;
    margin-top: 12px;
  }

  &__understand-button {
    font-family: $font-primary;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 40px 0 40px
    width: 265px;
    height: 51px;
    background: url('../../../../../../icons/backgrounds/bgDesktopSuperButton.png') center center;
    border-radius: 25px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    cursor: pointer;
  }
}
