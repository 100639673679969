.CartItem {
  position: relative;
  display: flex;
  flex-direction: column;

  &__body {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media $phone {
      align-items: flex-start;
    }
  }

  &__image {
    width: 85px;
    height: 85px;
    margin-right: 35px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
    }

    &_preview {
      &:before {
        content: '';
        display: block;
        width: 40px;
        height: 40px;
        margin-left: auto;
        margin-right: auto;
        border-radius: 50%;
        background-color: #ededed;
      }
    }

    &_disabled {
      img {
        filter: grayscale(100%);
      }
    }

    @media $phone {
      display: none;
    }
  }

  &__info {
    flex: 1;
    margin-right: 35px;
  }

  &__name {
    margin-bottom: 10px;
  }

  &__upsale {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #EB5757;
    opacity: 0.8;

    &_value {
      font-family: $font-secondary;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #EB5757;
      opacity: 0.8;
      border-bottom: 0.5px solid #EB5757;
    }
    &_currency {
      font-family: $font-secondary;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #EB5757;
      opacity: 0.8;
      border-bottom: 1px solid #EB5757;
    }
    &_notApply {
      font-family: $font-secondary;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      opacity: 0.8;
      color: #828282;
    }
  }

  &__description {
    line-height: 1.33;
    font-size: $font-size-s;
    font-family: $font-secondary;
    color: $color-brand-brown;
    opacity: 0.8;
  }

  &__gift {
    width: 20px;
    margin-left: 20px;
    flex-shrink: 0;

    @media $phone {
      display: none;
    }
  }

  &__aside {
    width: 190px;
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media $phone {
      width: auto;
      padding: 5px 0;
      flex-direction: column-reverse;
      align-items: flex-end;
      align-self: stretch;
    }
  }

  &__amount {
    margin-left: auto;
    font-size: $font-size-m;
    color: $color-brand-green-dark;

    @media $phone {
      margin-bottom: 20px;
    }
  }

  &__remove {
    width: 20px;
    margin-left: 20px;
    flex-shrink: 0;
    color: $color-grey;
    transition: color $halfduration;
    cursor: pointer;

    @media $phone {
      display: none;
    }

    svg {
      width: 10px;
      height: auto;
    }

    &:hover {
      color: $color-brand-red;
    }
  }

  &__footer {
    margin-left: 120px;
    flex: 1;

    @media $phone {
      margin-left: 0;
    }
  }

  &__sauces, &__add-sauce {
    margin-top: 8px;
  }

  &__composition-toggle {
    display: flex;
    align-items: center;
    font-size: $font-size-xs;
  }

  &__composition-item {
    display: flex;
    padding: 8px 0;
  }

  &__composition-wrapper {
    flex-basis:  50%;
  }

  &__composition-name {
    margin-bottom: 2px;
  }

  &__composition-description {
    font-size: $font-size-xs;
    line-height: 1.33;
  }

  &_composition-count {
    flex-grow : 1;
  }

  &__loader {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
  }

  &__spinner {
    spinner(48px, 3px, $color-brand-green);
  }

  &__error {
    color: $color-brand-red;
    font-weight: bold;
  }
}
