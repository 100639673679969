.Page {
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  background-color: white;

  &__title {
    margin-bottom: 40px;
  }

  &__spinner {
    spinner(48px, 3px, $color-brand-green);
    align-self: center;
    margin-left: 12px;
  }

  &__composite {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media $phone {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &__menu-container {
    width: 30%;
    position: relative;

    @media $phone {
      width: 100%;
    }
  }

  &__menu {
    position: sticky;
    float: left;
    height: 60vh;
    left: 61px;
    right: 65%

    @media $phone {
      position: static;
      width: 100%;
      margin-bottom: 40px;
    }

    display: flex;
    flex-direction: column;
  }

  &__search {
    width: 270px;
    max-width: 100%;
    height: 48px;
    margin-bottom: 40px;
    position: relative;

    &-icon {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 15px;
      left: 15px;
      z-index: 1;
    }

    &-input {
      input {
        height: 48px;
        padding: 7px 16px 7px 45px;
        border: 1px solid #fff;
        background-color: $color-bg;
        placeholder($color-text);
        transition: border-color $duration, background-color $duration;

        &:focus {
          transition-duration: $halfduration;
        }
      }
    }
  }

  &__nav {
    position: relative;
    overflow-y: scroll;

    scrollbar-width: thin;
    scrollbar-color: $color-border white;

    &::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }

    &::-webkit-scrollbar-track {
      background-color: white;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-border;
      border-radius: 2px;
    }

    &-item {
      padding: 6px 0 6px 20px;
      position: relative;
      display: flex;
      align-items: center;
      color: $color-text;
      line-height: 1.2;
      cursor: pointer;
      font-size: $font-size-m;
      border-left: 2px solid $color-border;

      &_selected {
        border-left: 2px solid $color-brand-red;
      }

      &:hover {
        color: $color-brand-brown;
      }
    }
  }

  &__list {
    width: 65%;
    display: flex;
    flex-direction: column;

    @media $phone {
      width: 100%;
    }

    &-item {
      margin-bottom: 40px;
      display: flex;
      flex-direction: column;

      &-title {
        margin-bottom: 5px;
      }

      &-description {
        font-size: $font-size-m;
        color: $color-brand-brown;
      }
    }
  }
}
