.Landing {

  &__header {
    font-family: $font-primary
    text-align: center
    margin-top: 15px
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: $color-brand-brown;
  }

  &__content {
    display: flex
    flex-direction: column
    justify-content: center

    &-text {
      font-size: 16px
      font-weight: 500
      font-family: $font-secondary
      color: rgba(112, 84, 79, 1)
      padding-bottom: 15px
      text-align: center
    }

    &-signUp-button {
      display: flex
      justify-content: center
      margin-top: 10px;
      margin-bottom: 15px;
    }
  }


  &__content-picture {

    & img {
      width: 100%
      height: auto
      border-radius: 25px
      filter: drop-shadow(0px 0px 25px rgba(53, 53, 53, 0.15))
      margin-top: 15px
      margin-bottom: 20px
    }
  }

  &__form {
    background: white;
    box-shadow: 0px 0px 15px rgba(32, 38, 55, 0.15);
    border-radius: 20px 20px 0px 0px;
    padding: 25px 20px 30px 20px;

    &--city {
      height auto
      width 100%
      padding-bottom 1px
    }

    &-field {
      background-color: #F4F4F8;
      border-radius: 21px;
      margin-top: 15px;
      height: 42px;
      padding-left: 30px !important;
    }

    &-submit {
      display: flex
      justify-content: center
      margin-bottom: 20px
      margin-top 10px
    }

    &-text {
      padding: 0 17px;
      font-family: $font-primary;
      font-size: 10px;
      line-height: 13px;
      text-align: center;
      letter-spacing: 0.02em;
      color: $color-grey;
      opacity: 0.8

      a {
        font-family: $font-primary;
        font-size: 10px;
        line-height: 13px;
        letter-spacing: 0.02em;
        color: $color-grey;
        text-decoration: underline;

        &:hover {
          opacity 1
        }
      }
    }

    &-header {
      font-family: $font-secondary
      font-weight: bold;
      text-align: center
      font-size: 16px;
      line-height: 19px;
      color: $color-brand-brown;
    }
  }

  &__modal {
    z-index: 100;
    width: 100%;
    display flex
    align-items flex-end
    flex-wrap wrap
  }

  &__city-choice {

    display flex
    justify-content center
    align-items center
    margin-top 10px

    span {
      margin-right 5px
      font-family: Manrope;
      font-size: 14px;
      line-height: 20px;
      color: #70544F;
      font-weight bold
    }
  }

  &__field-group {
    display: flex
    justify-content: center
    margin-top: 20px

    & input {
      border-radius: 12px
    }
  }

  &__field {
    margin-top: 10px

    & input {
      border-radius: 12px
    }
  }

  &__checkbox-group {
    margin-bottom: 20px;
  }

  &__checkbox {
    margin-top: 20px
    display: flex
    font-weight: normal
  }

  &__footer-text {
    margin-top: 20px 20px 25px 20px;
    font-size: 10px;
    color: $color-text;
  }

  &__error {
    font-size: $font-size-xs;
    line-height: 1.33;
    font-weight: 400;
    color: $color-brand-red;
    margin-top: 5px;
    display: block
  }

  &__icon {
    height: 6px;
    width: 50px;
    border-radius: 3px;
    background-color: white;
    margin: 0 auto;
    margin-bottom: 7px;
  }

  &__city-input {
    display flex;
    justify-content space-between
    margin-top 5px
    margin-bottom 20px
  }

  &__pointer {
    background: rgba(80, 166, 132, 1);
    width 46px
    height 42px
    border-radius 21px
    display flex
    align-items center
    justify-content center
    margin-top 15px
  }

}
