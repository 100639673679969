.GiftsSlider {
  width: 100%;

  &_disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &_vertical {
    display: flex;
    flex-direction: column;

    .GiftsSlider__slide {
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }

  &__slide {
    width: 290px;
    height: auto;
    min-height: 82px;
    padding: 15px;
    display: flex;
    border: 4px solid $color-bg;
    border-radius: 20px;
    background: white;

    &-image {
      width: 66px;
      height: auto;
      flex-shrink: 0;
      margin-right: 15px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
      }
    }

    &-group {
      margin-right: 15px;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &-name {
      word-break: break-word;

      &:not(:last-child) {
        margin-bottom: 4px;
      }

      &:last-child {
        margin: auto 0;
      }
    }

    &-description {
      font-family: $font-secondary;
      font-size: $font-size-xs;
      font-weight: 400;
      line-height: 1.33;
      color: $color-brand-brown;
      opacity: 0.8;
    }

    &-end {
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
    }

    &-amount {
      color: $color-brand-green-dark;
      line-height: 1.33;

      &:not(:last-child) {
        margin-bottom: 15px;
      }

      &:last-child {
        margin: auto 0;
      }
    }

    &-button {
      width: 30px;
      height: 30px;
      padding: 1px;
      margin-top: auto;
      border-radius: 50%;
    }

    &-plus {
      width: 12px;
      height: auto;
      vertical-align: middle;
      fill: white;
    }
  }
}
