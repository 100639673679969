.ThirdScene {
  &__footer {
    padding: 24px $phone-margin-x;
    background-color: $color-bg;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }

  &__navbar {
    .ThirdScene_tablet & {
      display: none;

      @media $phone {
        display: flex;
      }
    }
  }

  &__count {
    font-size: $font-size-xs;
  }

  &__button {
    width: 100%;

    &_back {
      margin-bottom: 10px;
    }
  }
}
