.Referral {
  position relative
  overflow hidden
  border-radius 12px
  border 2px solid #009471
  display flex
  font-family: $font-secondary;
  @media $phone{
    display block
    text-align center
  }

  &_text {
    flex 3
    position relative
    display flex
    flex-direction column
    padding: 24px 64px 27px;
    color white
    background: linear-gradient(90deg, rgba(0, 148, 113, 1) 0%, rgba(0, 148, 113, 0.55) 100%);

    @media $tablet{
      padding: 20px 24px;
    }
    @media $phone{
      align-items center
      background: linear-gradient(180deg, rgba(0, 148, 113, 1) 0%, rgba(0, 148, 113, 0.55) 100%);
    }
  }

  &_code {
    flex 2
    display flex
    flex-direction column
    justify-content center

    align-items start
    padding: 24px 64px 27px;
    @media $tablet{
      padding: 20px 24px;
    }
    @media $phone{
      align-items center
    }

    &_title {
      color $color-brand-brown
      font-size 14px

    }

    &_code {
      color $color-brand-green
      font-weight 700
      font-size 14px
      cursor pointer
    }

    &_button {
      margin-top 8px
    }
  }

  &_divider {
    width 100%
    max-width 288px
    margin 12px 0;
    height 1px
    background white
  }


  &_city {
    margin-top 4px
    @media $phone{
      display flex
      flex-direction column
    }
  }


}

.fs_18 {
  font-size 18px
}

.fs_14 {
  font-size 14px
}

.fs_12 {
  font-size 12px
}

.decor {
  position absolute

  &_1 {
    width: 150px;
    bottom: -85px;
    left: -10px;
    @media $phone{
      width: 157px;
      bottom: -159px;
      left: -30px;
    }
  }

  &_2 {
    width: 100px;
    top: -15px;
    right: 35px;
    @media $tablet{
      width: 130px;
      top: -62px;
      right: -42px;
    }
  }

  &_3 {
    width: 230px;
    bottom: -78px;
    right: -100px;
    @media $tablet{
      width: 209px;
      bottom: -107px;
      right: -103px;
    }
    @media $phone{
      width: 233px;
    }
  }

  &_4 {
    width 215px
    top -100px
    right -100px
    @media $tablet{
      display none
      //padding: 20px 24px;
    }
  }
}
