.Notification {
  max-width: 465px;
  padding: 12px 44px 12px 24px;
  position: relative;
  top: 0;
  right: 0;
  border-radius: 5px;
  background-color: #ffd8a6;
  box-shadow: 0 2px 12px 0 rgba(112, 84, 79, 0.17);
  font-size: $font-size-xs;
  color: $color-brand-brown;
  opacity: 0;
  transition: opacity $halfduration;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &_entered {
    opacity: 1;
  }

  &__close {
    position: absolute;
    top: 11px;
    right: 11px;
    cursor: pointer;
    color: $color-brand-brown;
    transition: color $halfduration;

    &:hover {
      color: $color-brand-green;
    }

    svg {
      width: 11px;
      height: auto;
      color: inherit;
    }
  }
}
