.HalvesCard {
  display: flex;
  flex-direction column;
  justify-content: space-between;
  align-items: center;
  gap 8px
  padding: 16px $phone-margin-x 24px;
  background-color: white;
  transition: transform $duration;

  &__heart {
    position absolute;
    bottom 12px;
    left 0;
  }
  &__vk {
    position absolute;
    top 12px;
    left 12px;
    z-index 100
  }
  &__logo {
    position absolute;
    top 24px;
    right 12px;
    z-index 100
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap 8px;
    position: relative;
    text-align: center;
    justify-content: space-between;
    align-items: center;
  }
  &:active {
    transform: scale(0.98);
    transition-duration: $halfduration;
  }

  &__heading {
    font-family: $font-secondary;
    font-weight: bold;
    color: $color-brand-brown;
    line-height: 1.33;
    font-size: $font-size-m;
  }

  &__image-container {
    position relative
    width: 50%;
  }

  &__image {
    width: 100%;
  }

  &__button {
    margin-top 12px
    width 100%
    z-index 100
  }

  &__cosmo_decor {
    position absolute
    left 50%
    top -10px
  }

  &__PaddingtonDecor {
    height 100%
    bottom 0
    right -15px
  }
}
