.AdventPageLink {
  z-index 100
  position: fixed;
  bottom: 40px;
  left: 40px;
  width: 60px;
  height: 60px;
  background-color: #C2D830;
  border-radius: 50%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size 80%
  transition: bottom $duration;

  &_hidden {
    bottom: -100px;
  }
}
