.PhoneField {
  position: relative;

  &__wrapper {
    position: relative;
  }

  &__flag {
    width: 16px;
    height: 16px;
    margin: auto 0;
    position: absolute;
    top: 2px;
    bottom: 0;
    left: 15px;
  }

  &__label {
    desktopinputlabel();
  }

  &__input {
    desktopinputfield();
    padding-left: 40px;
  }

  &_disabled {
    pointer-events: none;
    opacity: 0.75;
  }
}