.Souvenirs {

  &__linkButton {
    padding-inline: 120px
    @media $phone {
      padding-inline: 30px
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    padding: 52px 0 68px
    @media $tablet {
      gap: 24px;
      padding: 32px 0 24px
    }

    @media $phone {
      gap: 12px;
      padding: 20px 0 24px
    }


  }

  &__grid {
    width: 100%;
    display: grid;

    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    @media $tablet {
      display flex
      flex-wrap: wrap
      justify-content space-between
    }

    @media $phone {
      flex-direction: column;
      //display: grid;
      //grid-template-rows: repeat(5, 1fr);
      gap: 12px;
      //padding: 20px 0 24px
    }


    &_1 {
      grid-column: 1 / 3;
      grid-row: 1 / 3;
    }

    &_1, &_2 {
      @media $tablet {
        width 40%
      }

    }

    &_3, &_4, &_5 {
      @media $tablet {
        width 30%
      }
    }

    &_1, &_2, &_3, &_4, &_5 {
      @media $phone {
        width 100%;
      }
    }
  }
}

.sprite {
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url(./assets/sprite.png);
  background-size: 300% 200%;
  aspect-ratio: 1.26;

  &__merch {
    flex-grow: 1;
    border-radius: 16px;
    border: 2px solid $color-border

    @media $tablet {
      border-radius: 12px;
    }

    @media $phone {
      border-radius: 8px;
    }
  }

  &__merch_1 {
    background-position: 0 0
  }

  &__merch_2 {
    background-position: 50% 0
  }

  &__merch_3 {
    background-position: 100% 0%
  }

  &__merch_4 {
    background-position: 0% 100%
  }

  &__merch_5 {
    background-position: 50% 100%
  }

}
