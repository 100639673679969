.AddressSelector {
  display: flex;
  flex-direction: column;

  &__header {
    margin-bottom: $checkout-desktop-margin-25;
    display: flex;
    justify-content: space-between;
  }

  &__hint {
    color: $color-text-gray;
    margin-bottom: $checkout-desktop-margin-20

    &_error {
      color: $color-brand-red
    }
  }

  &__map {
    height: 435px;
    margin-bottom: $checkout-desktop-margin-30;
  }

  &__addresses {
    margin-bottom: 10px;
    flex-wrap: wrap;
  }

  &__address {
    margin: 0 10px 10px 0;
    font-family: $font-secondary;
    font-weight: 500;
    line-height: 1;
    font-size: $font-size-s;
    color: $color-brand-brown;
    cursor: pointer;
    transition: color $halfduration;
    border-bottom: 1px dashed $color-brand-brown;
    text-align: left;

    &:hover, &_active {
      color: $color-brand-green;
      border-bottom: 1px dashed $color-brand-green;
    }
  }

  &__first {
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-wrap: wrap;
  }

  &__addresser {
    width: 50%;
    margin-right: $checkout-desktop-margin-25;
    flex-shrink: 0;

    @media $tablet {
      width: 100%;
      margin-bottom: $checkout-desktop-margin-20;
    }
  }

  &__flat, &__floor {
    flex: 1;

    @media $tablet {
      flex: initial;
      width: 220px;
    }
  }

  &__flat {
    margin-right: $checkout-desktop-margin-25;
  }

  &__toggle {
    display: flex;
    align-items: center;
    align-self: flex-start;
    color: $color-brand-green;
    font-family: $font-primary;
    font-weight: 400;
    line-height: 1.33;
    font-size: $font-size-s;
    cursor: pointer;
    transition: color $halfduration;

    &:hover {
      color: $color-brand-green-hover;
    }
  }

  &__arrow {
    height: auto;
  }

  &__second {
    margin-top: $checkout-desktop-margin-25;
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media $tablet {
      width: 100%;
    }
  }

  &__intercom, &__porch {
    flex: 1;

    @media $tablet {
      flex: initial;
      width: 220px;
    }
  }

  &__intercom {
    margin-right: $checkout-desktop-margin-25;
  }

  &__accept {
    margin-top: $checkout-desktop-margin-25;
    z-index: 0;
    align-self: flex-end;
  }
}
