.OrderItem {
  position: relative;
  padding-top: 10px;

  &__preview {
    width: 100%;
    margin-bottom: 16px;
  }

  &__info {
    width: 100%;
    margin-bottom: 16px;
    font-size: $font-size-xs;
  }

  &__header {
    width: 100%;
    margin-bottom: 2px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__date {
    font-family: $font-secondary;
    font-weight: bold;
  }

  &__description {
    color: $color-brand-brown;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__status {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: $font-size-xs;
    font-weight: bold;
    text-align: center;
    color: $color-brand-green;

    & svg {
      margin-bottom: 2px;
    }
  }
}
