
.EntryBlock {
  height: 90vh;
  display: grid;
  grid-template-rows: 1fr auto 1fr 1fr;
  padding-top: 5vh;
  gap: 15px;
  //width : 100%;
  overflow-x : hidden;

  &__logo-wrapper {
     display: flex
    justify-content: center
  }

    &__logo {
    height: auto;
    width: 100%;
  }

  &__row {

  }

  &__slider{
    //width: 100%
    //overflow hidden
    height 100%

    &_item {
      display flex;
      gap 4px
      height 100%
    }
  }
}


.sprite {
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url(../assets/sprite.png);
  height: 100%;
  width: 100%;

  &__logo {
    width: 1186px;
    height: 390px;
    background-position: -0px -600px
  }

  &__photo_1 {
    background-size: 342% 330%
    background-position: -0px -0px
  }

  &__photo_10 {
    width: 428px;
    height: 300px;
    background-position: -1307px -300px
  }

  &__photo_2 {
    background-size: 409% 330%
    background-position: 27% 0
  }

  &__photo_3 {
    width: 427px;
    height: 300px;
    background-position: -1039px -0px
  }

  &__photo_4 {
    width: 270px;
    height: 300px;
    background-position: -1466px -0px
  }

  &__photo_5 {
    width: 355px;
    height: 390px;
    background-position: -1186px -600px
  }

  &__photo_6 {
    width: 349px;
    height: 390px;
    background-position: -1541px -600px
  }

  &__photo_7 {
    width: 552px;
    height: 300px;
    background-position: -0px -300px
  }

  &__photo_8 {
    width: 487px;
    height: 300px;
    background-position: -552px -300px
  }

  &__photo_9 {
    width: 268px;
    height: 300px;
    background-position: -1039px -300px
  }
}
