.Amount__halloween {
  color: $colorHW-amount-color !important;
}

.Amount__new-year {
  color: $colorNY-amount-color !important;
}

.Amount {
  position: relative;
  display: inline-block;
  color: $color-brand-brown;
  font-family: $font-secondary;
  font-weight: 500;
  line-height: 1;
  white-space: nowrap;

  &_size {
    &_xs {
      font-size: $font-size-xs;
    }

    &_s {
      font-size: $font-size-s;
    }

    &_m {
      font-size: $font-size-m;
    }

    &_l {
      font-size: $font-size-l;
    }
  }

  &__price {
    display: inline-block;
  }

  &__oldPrice {
    position: absolute;
    bottom: calc(100% - 3px);
    right: 0;
    font-size: $font-size-s;
    line-height: 20px;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      height: 1px;
      margin-top: -1px;
      background-color: currentColor;
    }
  }

  &__oldPrice, &__price {
    white-space: nowrap;
  }

  &_size_s &__oldPrice {
    font-size: $font-size-xs;
  }

  &__currency {
    font-family: $font-basic;
  }
}
