.ModalFirstView {
  width: 100%;

  &__top {
    margin-top: 24px
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__big-input {
    flex-grow: 1;
    margin-right: 16px;
  }

  &__text-city {
    padding-left: 30px
    border-radius: 35px;
    width: 100%;
    height: 56px;
    appearance: none;
    font-size 16px
    color black

    &_big {
      width: 100%;
      transition: .2s ease-in-out;
    }
  }

  &__change-city-button {
    padding: 0;
    width: 55px;
    min-width 55px
    height: 56px;
    min-height 56px
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: centr;
    transition .2s ease-in-out;

    &_close {
      transform: scale(0);
      display: none;
    }

    svg {
      width: 15px;
      height: 15px;
    }
  }

  &__text-street {
    padding-left: 30px
    margin-top: 16px
    border-radius: 35px;
    width: 100%
    height: 56px
    font-size 16px
    font-weight 100;
    color black

    &_not-margin {
      margin-top: 0
      width: 100%;
    }
  }

  &__button-block {
    margin-top: 44px
    width: 100%;
    display: flex;
    justify-content: space-between;

    &-reverse {
      flex-direction: row-reverse
    }
  }

  &__button {
    width: 47%;
    height: 56px
    border-radius 35px

    &.gray {
      background: #BFB7B6;
    }
  }
}
