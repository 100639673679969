.DeliveryAddressesContainer {
  width: 100%;
  min-height: 100vh;
  background-color: white;
}

.DeliveryAddresses {
  margin-bottom: 20px;
  background: #FFFFFF;

  &:last-child {
    margin-bottom: 0;
  }

  &__item {
    padding: 16px 62px 16px 16px;
    position: relative;
    display: block;
  }

  &__item-actions {
    display: block;
    position: absolute;
    top: 15px;
    right: 4px;
  }

  &__item-action {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__item-icon {
    color: $color-grey;

    svg {
      display: block;
    }
  }
}
