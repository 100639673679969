.BackButton {
  height: 100%;
  padding-left: $phone-margin-y;
  display: flex;
  align-items: center;
  color: $color-grey;

  svg {
    transform: translateY(3px);
  }

  &__label {
    padding-top: 1px;
    margin-left: 8px;
    font-weight: 500;
    color: $color-text;
  }
}
