.AppLink {
  display: inline-block;
  text-decoration: none;
  color: $color-brand-green;
  transition: color $duration;

  &:hover {
    color: darken($color-brand-green, 30%);
    transition-duration: $halfduration;
  }
}
