.LangSwitcher {
  position: relative;

  &__dropdown {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    background-color: white;
    box-shadow: $box-shadow;
    border-radius: 5px;
    transition: opacity $duration;
    opacity: 0;

    &_entering, &_entered {
      opacity: 1;
    }

    &_exiting, &_exited {
      opacity: 0;
    }
  }
}
