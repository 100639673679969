.GreetingBlock {
  background var(--papa-color-1)

  @media $phone {
    background-image url("../../assets/bg_small.png")
    padding 0
    background-repeat no-repeat
    background-size cover
    background-position center center
  }

  &__container {
    min-height: calc(100vh - 80px);
    display grid
    grid-template-columns 1fr 535px
    //grid-template-rows 1fr auto
    align-items end
    padding-top 36px
    padding-bottom 36px


    @media $tablet {
      gap 36px
      grid-template-columns 1fr 1fr
      grid-template-rows auto auto 1fr 1fr
      padding-top 20px
      padding-bottom 20px
    }

    @media $phone {
      min-height calc(100vh - 48px)
      grid-template-columns 1fr
      grid-template-rows auto auto 1fr
      row-gap 16px
      column-gap 0
      padding 20px 0 36px
    }
  }

  &__grid {

  }


  &__bg {
    position relative
    grid-column 1 / 3
    align-self flex-start

    @media $phone {
      position static
      align-self unset
      grid-column: 1
      grid-row 3
    }

    img {
      width 100%
      border-radius 24px
      @media $phone {
        display none
      }
    }

  }

  &__header {
    position absolute
    padding 24px 47px
    left 0
    right 0
    top 0

    @media $tablet {
      padding 16px
    }

    @media $phone {
      padding-inline 0
    }
  }


  &__title {
    padding-top 20px
    font-size: 52px;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: 0.02em;
    text-align: left;
    color white

    @media $tablet {
      grid-column 1 / 3
      font-size: 40px;
      line-height: 52px;
      text-align: center;
    }

    @media $phone {
      grid-row 2
      grid-column 1
      font-size: 26px;
      line-height: 32px;
    }
  }

  &__link {
    align-self center
    grid-column 1
    @media $tablet {
      align-self flex-start
      grid-column 1 / 3

    }
    @media $phone {
      align-self unset
      grid-column 1
      grid-row 4
    }
  }

  &__logos {
    display grid
    grid-template-columns repeat(2, 1fr)
    align-self center
    align-items center
    gap 56px


    @media $tablet {
      grid-column 1
      grid-row 2
      grid-template-columns 1fr
      max-width 220px
      align-self flex-start
      margin-inline auto
    }

    @media $phone {
      grid-column 1
      grid-row 1
      max-width unset
      grid-template-columns 1fr 1fr
      margin-top 30px
    }


    &_papa, &_super {
      width 100%
    }

  }

  &__steps {
    position absolute
    width 565px
    right 0
    bottom 0
    border 30px solid var(--papa-color-1)
    border-right 0
    border-bottom 0
    border-radius 24px
    background var(--papa-color-1)
    transform translateY(68px)

    @media $tablet {
      width 353px

      grid-column 2
      grid-row 3
      transform translateY(76%)
    }

    @media $phone {
      position static
      width 100%
      grid-column 1
      grid-row 2
      height auto
      border 0
      transform none

    }

    &:after {
      position absolute
      content ''
      width 30px
      height 30px
      top 0
      right 0
      background var(--papa-color-1)
      transform translateY(-100%)
      @media $phone {
        display none
      }
    }

    &_block {
      @media $tablet {
        height 100%

      }
    }

    &_corner_1 {
      left: -54px;
      top: 46%;
      @media $tablet {
        top: 12px;
      }
      @media $phone {
        display none
      }
    }

    &_corner_2 {
      right -1px
      top -0px
      transform translateY(-62px)
      @media $phone {
        display none
      }
    }
  }

  &__arrow {
    padding-top 20px
    grid-column 1 / 3
    justify-self center
    width 60px
    @media $phone {
      width 44px
    }
  }
}


.Steps {
  display grid
  grid-template-columns repeat(4, 1fr)
  gap: 30px

  padding 24px 16px

  background var(--papa-color-4);
  color white
  border-radius 24px
  border 40px var(--papa-color-1)

  @media $tablet {
    grid-template-columns repeat(2, 1fr)
    gap 20px
    padding 12px 24px
  }

  @media $phone {
    padding 16px 12px
  }

  &__step {
    display flex
    flex-direction column

    @media $phone {
      flex-direction row
      align-items center
      gap 8px
    }

    &_title {
      font-family: Roboto;
      font-size: 68px;
      font-weight: 900;
      line-height: 72px;

      @media $tablet {
        font-size: 56px;
        line-height: 69px;
      }

      @media $phone {
        font-size: 44px;
        line-height: 56px;

      }
    }

    &_text {

    }
  }


}


.Header {
  display grid
  grid-template-columns auto 1fr 26%
  align-items flex-start
  gap 114px
  color white

  @media $tablet {
    grid-template-columns 1fr 26% auto
    gap 20px

  }

  @media $phone {
    grid-template-columns 1fr auto
  }


  &__back {
    height fit-content !important
    cursor pointer

    * {
      color white !important
    }
  }

  &__list {
    position relative
    margin-left auto
    display flex
    flex-direction row-reverse
    gap 20px
    transition all .3s
    @media $tablet {
      justify-content center
      position fixed
      background var(--papa-color-4)
      left 0
      right 0
      top 0
      transform translateY(-100%)
      padding 16px
    }

    @media $phone {
      justify-content flex-end
    }

    &_open {
      transform translateY(0%)

      @media $tablet {
        top 81px
      }

      @media $phone {
        top 49px
      }
    }


    &_cross {
      display none
      position absolute
      right 16px
      cursor pointer
      @media $tablet {
        display block
      }
    }
  }

  &__burger {
    cursor pointer
    display none
    @media $tablet {
      display block
    }
  }

  &__link {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color white

    &:hover {
      color var(--papa-color-3)
    }

    @media $phone {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__text {
    font-family: Roboto;
    font-size: 32px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0.02em;
    text-align: left;

    @media (max-width 1600px) {
      font-size: 24px;
      line-height: 28px;
    }

    @media $tablet {
      grid-column 2
      font-size: 16px;
      line-height: 20px;
    }

    @media $phone {
      display none
    }
  }
}
