.HalfSelect {
  & {
    position relative
    padding 12px 45px 12px 16px
    border-radius 4px
    background-color white
  }

  &:before {
    content ''
    position absolute
    top 0
    left 0
    width 4px
    height 100%
    border-top-left-radius 4px
    border-bottom-left-radius @border-top-left-radius
    background-color $color-brand-green
    opacity 0
  }

  &__name {
    text-overflow()
    margin-bottom 2px
    color $color-brand-green
  }

  &__arrow {
    position absolute
    top 50%
    right 16px
    margin-top -8px
    color $color-grey

    svg {
      display block
      transform rotate(180deg)
    }
  }

  &_selected:before {
    opacity 1
  }

  &_selected &__name {
    color $color-brand-brown
  }
}

