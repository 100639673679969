.Bonus {
  $button-height = 46px;
  paper();
  padding: $checkout-desktop-margin-40;
  margin-bottom: $checkout-desktop-margin-20;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &_disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &__heading {
    margin-bottom: $checkout-desktop-margin-25;

    &_green {
      color: $color-brand-green;
    }
  }

  &__actions {
    margin-bottom: $checkout-desktop-margin-20;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__submit {
    height: $button-height;
    padding: $checkout-desktop-button-padding;
    border-radius: 20px;
  }

  &__field {
    flex-grow: 1;
    margin-right: $checkout-desktop-margin-20;
    position: relative;
    color: $color-grey;

    &_active {
      color: $color-brand-green;
    }
  }

  &__clear {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    margin-left: 10px;
    &:hover svg {
      fill: $color-brand-green;
    }
  }

  &__input {
    width: 107px;
    height: $button-height;
    padding: 0 28px 0 16px;
    border-radius: 20px;
    background-color: $color-bg;
    text-align: right;
    border: none;
    color: inherit;
    font-family: $font-secondary;
    font-size: $font-size-s;
    font-weight: 500;
    line-height: 1;
    placeholder($color-grey);
  }

  &__sufix {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    color: inherit;
    font-family: $font-secondary;
    font-size: $font-size-s;
    font-weight: 500;
    line-height: 1;
  }

  &__messages {
    max-width: 228px;
    list-style: disc inside;
    color: $color-grey;
    font-family: $font-primary;
    font-size: $font-size-xs;
    font-weight: 400;
    line-height: 1.33;
  }

  &__message {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}
