.Actions {
  &__selector {
    margin-bottom 32px
  }

  &__total {
    display flex
    justify-content space-between
    align-items center

    @media $phone {
      padding-left 20px
      flex-direction row-reverse
    }
  }
}
