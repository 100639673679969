.Error404__new-year {
  .Error404 {

    &__header {
      position absolute;
      top: 0;
      left: 0;
      right: 0;
      height: calc(70px + 25 * ((100vw - 1500px) / 920));
      z-index 10;
      background-image: url($colorNY-slider-header);
      background-repeat: no-repeat;
      background-size: cover;

      @media $phone {
        height: calc(100px + 25 * ((100vw - 1500px) / 920));
        background-image: url($colorNY-slider-header_mobile);
        background-size: contain;
      }
    }
  }
}

.Error404 {
  width: 100%;
  background: #50A684;
  margin: 0;
  display: flex;
  justify-content center

  &__wrapper {
    padding: 24px 130px 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: #FFFFFF;
    border-radius: 0 0 25px 25px;

    @media $tablet {
      height: auto;
      padding: 41px 20px 20px;
    }

    @media $phone {
      height: auto;
      padding-top: 28px;
    }
  }

  &__wrapper-another {
    @media $tablet {
      width: 100%;
    }
  }

  &__content {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    @media $phone {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    @media $tablet {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 23px;
    color: #70544F;

    @media $tablet{
      font-size: 18px;
      line-height: 22px;
    }

    @media $tablet {
      width: 99%;
    }
  }

  &__button {
    margin-top: 26px;

    @media $tablet{
      margin-top: 30px;
      width: 100%;
    }
  }

  &__button-back {
    z-index: 100;
    background: url("./assets/arrow-green.svg") no-repeat center center / cover;
    width: 10px;
    height: 16px;
    position: absolute;
    left: 0;
    top: 0;
  }

  &__wrapper_banner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 695px;
    aspect-ratio 2.66
    flex-shrink: 0;
    margin-left: 65px;

    @media $tablet {
      margin-left: 0;
      margin-top: 16px;
      width: 100%;
    }
    @media $phone {
      margin-left: 0;
      margin-top: 12px;
    }
  }

  &__spinner {
    spinner(48px, 3px, $color-brand-green);
    align-self: center;
    margin-left: 12px;

    &_loadingLayout{
      display: block;
      margin: 50px auto;
    }
  }

  &__image {
    width 100%
    height 100%


    &_banner {
      cursor: pointer;
      border-radius: 12px;
    }
  }

  &__text {
    margin-top: 26px;
    text-align left;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #70544F;

    @media $phone {
      text-align: center;
    }
    @media $tablet {
      text-align: center;
    }
  }

  &__footer {
    padding: 50px 0 55px;
    background: #50A684;
    display: flex;
    justify-content: center;

    @media $tablet{
      padding: 27px 0 27px;
    }
  }

  &__footer-title {
    text-align: center;
    margin-bottom: 33px;
    font-weight: 700;
    font-size: 18px;
    line-height: 17px;
    color: #FFFFFF;
  }

  &__footer-wrapper {
    width: 1200px;

    @media $tablet {
      width: 898px;
    }
  }

  &__footer-slider-block {
    display: flex;
    justify-content: center;

    @media $tablet{
      height: auto;
      flex-direction: column;
    }
  }
}
