.PayTypeSwitcher {
  margin-bottom: $checkout-mobile-margin-15;
  display: flex;
  flex-direction: column;

  &__title {
    margin-bottom: $checkout-mobile-margin-10;
  }

  &__row {
    width: 100%;
    display: flex;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  &__button {
    &:not(:last-child) {
      margin-right: $checkout-mobile-margin-10;
    }
  }

  &__change {
    width: 50%;
    max-width: 150px;
    margin-top: $checkout-mobile-margin-15;

    &_input {
      background: white;
    }
  }

  &__modal {
    padding: $checkout-mobile-block-padding;
    display: flex;
    flex-direction: column;
    background white
    border-radius: 25px 25px 0px 0px;
  }

  &__subtitle {
    margin-bottom: $checkout-mobile-margin-15;
    text-align: center;
  }

  &__cards {
    margin-bottom: $checkout-mobile-margin-15;
  }

  &__accept {
    align-self: center;
  }
}
