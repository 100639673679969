:global {
  .swiper {
    position: relative;
  }

  .swiper-pagination-outside {
    width: 100%;
    top: calc(100% + 15px);
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    overflow: hidden;
    line-height: 0;
  }

  .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    margin: 0 3px;
    flex-shrink: 0;

    &-active {
      background: #009471!important;
    }
  }

  .swiper_newest {
    .swiper-pagination-bullet {
      width: 20px;
      height: 5px;
      margin: 0 10px;
      border-radius: 5px;
      background-color: white;
      opacity: 0.8;

      &-active {
        opacity: 1;
        background: $color-brand-red-light;
      }
    }
  }

  .swiper-button-prev, .swiper-button-next {
    width: 40px!important;
    height: 40px!important;
    margin-top: -24px;
    border-radius: 50%;
    background-color: white;
    transition: transform 0.35s;
    color: #009471 !important;
    user-select: none;
    @media $phone{
      opacity: 0.5;
    }
    @media $tablet {
      opacity: 0.5;
    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      padding: 4px;
      display: inline-block;
      border: solid currentColor;
      border-width: 0 3px 3px 0;
    }

    &:after {
      display: none;
    }

    &:hover, &:active {
      transform: scale(1.1);
    }
  }

  .swiper-button-prev {
    left: 32px;

    &:before {
      transform: translate(calc(-50% + 1px), -50%) rotate(135deg);
    }
  }

  .swiper-button-next {
    right: 32px;

    &:before {
      transform: translate(calc(-50% - 1px), -50%) rotate(-45deg);
    }
  }

  .swiper-button-prev-outside, .swiper-button-next-outside {
    width: 40px;
    min-width: 40px;
    height: 40px;
    margin-top: -20px;
    background-color: #e1faf4!important;
    color: #009471!important;

    &::before {
      border-width: 0 2px 2px 0;
    }

    &_disabled {
      background-color: #dadada!important;
      color: white!important;
      pointer-events: none;
    }
  }

  .swiper-button-prev-outside {
    left: -50px!important;
  }

  .swiper-button-next-outside {
    right: -50px!important;
  }
}
