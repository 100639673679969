.Header {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  background-color: white;
  box-shadow: 0 2px 12px 0 rgba(41, 44, 51, 0.1);
  opacity: 0;
  transition: opacity 100ms;

  &_entered {
    opacity: 1;
  }

  &__list {
    height: $phone-navbar-height;
    display: flex;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  &__item {
    height: 100%;
    padding: 0 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: $color-brand-brown;
    font-family: $font-secondary;
    font-size: $font-size-xs;
    font-weight: bold;
    text-transform: uppercase;
    transition: color $duration;

    &:first-child {
      padding-left: $tablet-margin-x;
    }

    &:last-child {
      padding-right: $tablet-margin-x;
    }

    &_current {
      color: $color-brand-green;
      transition-duration: $halfduration;
    }
  }

  &__promocode {
    width: 100%;
    padding: 14px 20px 12px;
    background-color: white;
    border-top: 2px solid $color-bg;
    box-shadow: 0 2px 12px 0 rgba(41, 44, 51, 0.1);

    &-info {
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $color-brand-green;
      font-family: $font-secondary;
      font-size: $font-size-xs;
      font-weight: bold;
    }

    &-progress {
      position: relative;
      width: 100%;
      height: 6px;
      border-radius: 3px;
      background-color: #ededed;
      overflow: hidden;
    }

    &-progress-scale {
      fullsize();
      background-color: $color-brand-green;
      border-radius: 3px;
      transform-origin: 0 0;
      transform: scaleX(0.5);
      transition: transform $duration;
    }

    &-reward {
      display: block;
      padding-right: 35px;
      margin-bottom: 5px;
      position: relative;
      color: $color-brand-brown;
      font-family: $font-secondary;
      font-size: $font-size-xs;
      font-weight: bold;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-reward-done {
      position: absolute;
      top: 0;
      right: 0;
      color: $color-brand-green;

      svg {
        width: 16px;
        height: auto;
      }
    }
  }
}
