.Tasks {
  padding-bottom: 60px;

  @media $tablet {
    padding-bottom: 30px;
  }

  &_task {
    display: flex
    gap: 36px;
    align-items: center;
    @media $tablet {
      gap: 20px;
    }
    @media $phone {
      flex-direction: column;
    }

    &_img {
      position: relative;
      align-self: flex-start;
      width: 268px;
      flex-shrink: 0;
      aspect-ratio: 1;

      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      @media $tablet {
        width: 187px;
      }
      @media $phone {
        width: 100%;
      }

      &_Overlay {
        pointer-events: none;
        cursor: default;
      }
    }

    &_container {
      width: 100%;
    }

    &_heading {

    }

    &_description {
      padding 12px 0 32px
      font-family: Manrope;
      font-size: 24px;
      font-weight: 400;
      line-height: 1.35;
      @media $tablet {
        padding 12px 0 12px
        font-size: 18px;
      }
      @media $phone {
        padding 8px 0 16px
        font-size: 16px;
      }
    }

    &_btn {
      @media $phone {
        width: 100%
      }
    }
  }

  &_slider {
    padding-top: 52px;
    padding-bottom: 20px;
    padding-inline: 15px;
    margin-bottom: -20px;
    margin-inline: -15px;
    @media $tablet {
      padding-top: 32px;
    }
    @media $phone {
      padding-top: 20px;
    }

    &_nav {
      background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
      content: "";
      position: absolute;
      top: 52px;
      bottom 20px;
      left: 0;
      width: 50px;
      z-index: 2;

      @media $tablet {
        top: 32px;
      }
      @media $phone {
        top: 20px;
      }

      &_next {
        transform: rotateZ(180deg);
        left unset
        right 0
      }

      &_disabled {
        display none
      }
    }

    &_item {
      position relative;
      width: 140px;
      height: 140px;
      aspect-ratio: 1;
      background-size cover;
      background-position: center center;
      background-repeat: no-repeat;

      &_Overlay {
        cursor: pointer;
      }

      &_active {
        &:before {
          position: absolute;
          left: -9px;
          top: -9px;
          content: '';
          width: calc(100% + 18px);
          aspect-ratio: 1;
          border: 6px solid #4FAE32;
          border-radius: 14px;
        }
      }
    }
  }
}
