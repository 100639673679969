
.Winner {
  position relative
  color black

  &__label {
    width fit-content
    padding: 2px 12px ;
    gap: 10px;
    border-radius: 60px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
  }

  &__grid {
    display grid
    grid-template-columns 1fr 1fr
    gap 20px

    padding-top 24px

    @media $tablet {
      grid-template-columns 0.8fr 1fr
      gap 16px
      padding-top 16px
    }
    @media $phone {
      grid-template-columns 1fr
      gap 12px
      padding-top 12px
    }
  }

  &__photo {
    position sticky
    top 0
    max-height 40svh
    max-width 100%
    margin-inline auto
    border-radius 12px
    @media $phone {
      position static
      top unset
    }
  }

  &__title {
    padding-top 8px

    font-family: Roboto;
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    @media $phone {
      font-family: Roboto;
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
      text-align: center;
    }
  }

  &__name {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;

    @media $tablet {
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
    }
    @media $phone {
      text-align: center;
    }
  }

  &__text {
    margin-top 12px

    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
    @media $tablet {
      margin-top 8px

      font-size: 14px;
      text-align: left;
    }

    @media $phone {
      text-align: center;
    }
  }
}
