.Logo__new_year {
  width: 112px;
}

.Logo__cosmos {
  width: 104px;
}

.Logo {
  position: relative;

  &__hw {
    transform: translateY(-50%);
    top: 50%;

    @media $phone {
      transform: none
      top unset
    }

    &-decor {
      position: absolute;
      right 0
      bottom 2px
      width 41px
      height 15px
      background: url("/src/assets/Halloween_quest/icons/hw_5.png")

      @media $phone {
        right -2px
        bottom -8px
      }
    }
  }

}
