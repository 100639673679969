.FootballCategoryDecor {
  z-index 100
  left: 40px
  bottom 40px

  width 100px
  height 100px
  border-radius 50%
  cursor pointer

  transition $duration;

  @media $tablet {
    left: 20px
    bottom 4%

    width 70px
    height 70px

  }
  @media $phone {
    left: 20px
    bottom 20px

  }


  &_image {
    width 100%
    height: 100%;
    object-fit contain
  }

  &__animation {
    animation: slice 5s ease-in-out;

    img {
      animation rotation 5s ease-in-out;
    }
  }

  @keyframes slice {
    0% { transform: translateY(0); }
    //10% { transform: translateY(-50px) translateX(50px); }
    //20% { transform: translateY(0) translateX(100px); }
    //30% { transform: translateY(-50px)  translateX(150px); }
    //40% { transform: translateY(0) translateX(200px); }
    50% { transform: translateY(0) translateX(500%); }
    60% { transform: translateY(0) translateX(500%); }
    //70% { transform: translateY(0px) translateX(200px); }
    //80% { transform: translateY(0)  translateX(150px); }
    //90% { transform: translateY(0px)  translateX(50px); }
    100% { transform: translateY(0) translateX(0px); }
  }

  @keyframes rotation  {
    0% {transform: rotate(0);}
    60% {transform: rotate(950deg);}
    100% {transform: rotate(360deg);}
  }


}
