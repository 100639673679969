.Contacts {
  padding-top: 30px;
  display: flex;
  flex-wrap: wrap;

  &__headline-wrapper {
    width: 42%;
    padding-left: 50px;
    @media $tablet {
      margin-top: 30px;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-left: 0px;
    }
  }

  &__delivery {
    margin-bottom: 10px;
    display: flex;
  }

  &__feedback-form {
    @media $tablet {
      max-width: 376px;
    }
  }

  &__title {
    width: 100%;
    margin-bottom: 30px;
    text-align: center;
  }

  &__headline {
    display: flex;
    flex-direction: column;
    margin-bottom: 36px;
    @media $tablet {
      padding: 0 30px 0px;
    }
  }

  &__wrapper {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
  }

  &__contact {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
  }

  &__text {
    color: #70544F;
    font-size: 14px;

    h3 {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 18px;
    }

    p {
      margin-bottom: 14px;

      a {
        margin-top: 14px;
      }
    }

    ul > li {
      background: var(--redBon);
      padding: 1px 0px 10px 28px;
    }
  }

  &__map-wrapper {
    width: 58%;
    border-radius: 10px;
    overflow: hidden;
    @media $tablet {
      width: 100%;
    }
  }

  &__link {
    margin-bottom: 4px;
    font-family: $font-secondary;
    font-weight: 500;
    color: $color-brand-brown;
    transition: color $duration;

    &:hover {
      color: $color-brand-green;
      transition-duration: $halfduration;
    }
  }
}

