.RightPanel {
  &_cancel {
    transition: background-color $halfduration;

    &:hover {
      background-color: $color-bg;
    }
  }

  &__timer {
    width: 20px;
    height: 20px;
    margin: 0px 15px 2px 0px;
  }

  &__spinner {
    spinner(10px, 1px, $color-brand-green);
    margin-left: 5px;
  }
}