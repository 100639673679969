.RestaurantsMap {
  &__preloader {
    spinner(48px, 3px, $color-brand-green);
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -24px;
    margin-left: -24px;
    z-index: 1;
  }

  &__mapLayout {
    &, & > div {
      fullsize();
      @media $phone {
        position: relative;
        height: 90vh;
        min-height: 280px;
      }

      @media $verticalPhone {
        height: 410px;
      }
    }
  }
}
