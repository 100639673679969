.Blog {
  overflow-x clip

  background var(--papa-color-3)
  padding 74px 0

  @media $tablet {
    padding 36px 0
  }

  @media $phone {
    padding 28px 0
  }

  &__container {
    //padding-right 0

  }

  &__title {
    color white
    margin-bottom 48px

    @media $tablet {
      margin-bottom 20px
      text-align center
    }

    @media $phone {
      margin-bottom 48px

    }
  }


  &__slider {
    overflow visible
  }

  &__controls {
    display grid
    grid-template-columns auto 1fr auto
    margin-top 48px
    gap 32px
    @media $tablet {
      margin-top 28px
      grid-template-columns 1fr 1fr
    }

    @media $phone {
      gap 24px
    }

    &_prev {


    }

    &_next {
      @media $tablet {
        grid-row 1
        grid-column 2
      }
    }

    &_link {
      justify-self center
      @media $tablet {
        grid-column 1 / 3
      }
    }
  }

  &__item {
    display grid
    grid-template-columns 1fr 2fr
    gap 10px

    @media $phone {

      display flex
      flex-direction column
      gap 16px
      border-radius 24px
      background white
      color black
    }

    &_left {
      position relative
      height fit-content


      @media $phone {
        align-self: center;
        margin-top: -20px;
        width: 50%;
        min-width 180px
      }
    }

    &_image {
      border-radius 24px
      object-fit fill
      width 100%
    }

    &_container {
      position absolute
      bottom 20px
      right 20px
      left 20px
      display flex
      flex-direction column
      gap 10px

      color white


      @media $tablet {
        position static
        margin-top 16px

      }

      @media $phone {
        position absolute
        right 10px
        left 10px
      }
    }

    &_name {


    }

    &_content {
      display flex
      flex-direction column
      justify-content space-between
      padding 30px
      gap 20px
      border-radius 24px
      background white
      color black
      @media $tablet {
        padding 16px

      }
    }

    &_heading {
      font-family: Roboto;
      font-size: 48px;
      font-weight: 700;
      line-height: 56px;
      @media $tablet {
        font-size: 24px;
        line-height: 28px;

      }

      @media $phone {
        font-size: 20px;
        line-height: 20px;

      }
    }

    &_text {
      font-family: Roboto;
      font-size: 20px;
      font-weight: 400;
      line-height: 28px;
      white-space break-spaces;
      @media $tablet {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  &__black {
    color black
  }

  &__white {
    color white
  }

}
