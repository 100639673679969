.Profile {
  padding: 100px 0 50px 0;

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__sidebar {
    width: 350px;
    padding: 28px;
  }

  &__content {
    margin-left: 35px;
    width: calc(100% - 280px - 32px);
    overflow: hidden;
  }

  &__paper {
    padding: 32px 64px 30px;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__Referral {
    margin-bottom: 8px;
  }

  &__title {
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    font-weight: bold;

    &_bonus {
      color: #50A684;
    }


    &:last-child {
      margin-bottom: 14px;
    }
  }

  &__subtitle {
    margin-bottom: 10px;
    font-size: $font-size-xs;
  }

  &__headline {
    margin-bottom: 32px;
    border-bottom: 1px solid #e6e2e1;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__orderHistory {
    margin-bottom: 0;
  }

  &__gifts {
    margin-bottom: 10px;
  }

  &__modal {
    paper();
    width: 700px;
    padding: $checkout-desktop-margin-40;
  }

  &__bonuses-history {
    height: auto;
    margin-left: auto;
    font-family: $font-primary;
    font-weight: 400;
    font-size: $font-size-m;
    line-height: 1;
    color: $color-grey;
  }
}
