.Tasks {
  position relative

  display grid
  grid-template-columns repeat(3, minmax(40px, auto))
  column-gap 12px
  row-gap 8px
  justify-items center
  justify-content center

  padding 64px 12px 12px

  border-radius: 12px;
  background-color: #3B5471;


  &__decor {
    width 122px
    height auto
    position absolute
    left 50%
    top -9px
    transform translateX(-50%)
  }

  &__container {



  }
}

.Task {
  width: 100%;
  aspect-ratio 1
  display flex
  justify-content center
  align-items center

  padding 5px

  border-radius: 50%;
  //opacity: 0px;

  font-size: clamp(18px, 32px, 32px);
  font-weight: 800;
  line-height: 1.25;
  letter-spacing: 0.02em;

  color white

  cursor pointer
}
