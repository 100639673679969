.ProductCard {
  padding: 24px $phone-margin-x 32px;
  background-color: white;

  &__menu {
    position: relative;
    margin-bottom: 16px;
  }

  &__picture {
    overflow: hidden;
    margin-bottom: 0;

    img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
    }
  }

  &__header {
    display: flex;
    margin-bottom: 10px;
  }

  &__heading {
    flex-grow: 1;
    margin-right: 8px;
  }

  &__meta {
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__meta-options {
    display: flex;
    align-items: center;
    min-height: 20px;
  }

  &__meta-option {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }

    &_person-count {
      display: flex;
      align-items: center;

      svg {
        margin-right: 5px;
      }
    }

    svg {
      display: block;
    }
  }

  &__data {
      padding-bottom: 1rem;
  }

  &__characteristics {
    margin-bottom: 24px;
  }

  &__actions {
    width: 100%;
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__badge {
    position: relative;
    display: inline-block;
    height: 20px;
    margin-bottom: 10px;
    margin-left: -($phone-margin-x);
    padding-left: 12px;
    padding-right: @padding-left;
    background-color: $color-brand-red;
    color: white;
    font: bold $font-size-xs / 21px $font-secondary;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 4px 10px 0;
      border-color: transparent white transparent transparent;
    }

    &_vegan {
      background-color: $color-brand-green;
    }
  }
}
