.ProductList {
  position relative
  &:last-of-type {
    padding-bottom: 60px;
  }

  &__section{
    padding-top: 60px
    z-index: 10;
  }

  section {
    padding-top: 60px

    &:nth-child(2) {
      padding-top: 0
    }
  }

  &__heading {
    margin-bottom: 16px;
  }

  &__list {
    position: relative;
    margin: 0 -20px;
    display: flex;
    flex-direction: column;
  }

  &__item {
    width: 100%;
    margin-bottom: 2px;
    position relative
    &:last-child {
      margin-bottom: 0;
    }

    &_constructor, &_halves {
      margin: 12px 0;
    }
  }

  &__promoGame-artefact {
    right: 30%;
    top: -35px;
  }

  &__PromoArtefact {
    left: 100px;
    top: -60px;
    height: 60px;
  }

  &__FootballDecor {
    position absolute
    top 40px
    left 60px!important
    width 55px!important
    height 55px!important

    @media $tablet {
      top 35px
    }
  }

  &__MovieDecor {
    &_constructor {
      width: 98px;
      left: 0;
      top: 50%;
      transform: translate(100%, -75%);
    }
    &_halves {
      width: 140px;
      left: 50%;
      top: 50%;
      transform: translate(0%, -85%);
    }

    &_filter {
      width: 165px;
      top: 70px;
      left: -49px;
    }
  }

  &__PromoBorder {
    &_constructor,&_halves {
      width 50%
      min-width 190px
      top 80px
      left 50%
      transform translateX(-50%)
    }
  }

  &__PaddingtonDecor {
    width 122px
    left 0
    top 0
  }
}
