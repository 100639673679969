.DeliveryTypeSwitcher {
  width: 100%;
  height: $checkout-mobile-button-height;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-radius: $checkout-mobile-button-radius;
  background: $color-bg;

  &__selector {
    width: 50%;
    height: 100%;
    padding: $checkout-mobile-button-padding;
    background: $color-brand-green;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: inherit;
    transition: transform $duration;
    transform: translateX(0);

    &_pickup {
      transform: translateX(100%);
    }
  }

  &__item {
    width: 50%;
    height: 100%;
    padding: $checkout-mobile-button-padding;
    position: relative;
    color: $color-brand-brown;

    &_active {
      color: white;
    }
  }

  &__subtitle {
    font-family: $font-primary;
    font-weight: 500;
    font-size: $font-size-s;
    line-height: 1.33;
    color: inherit;
  }

  &__promo {
    width: 27px;
    height: 27px;
    box-sizing: content-box;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -16px;
    top: -16px;
    background: $color-brand-red-light;
    border: 3px solid white;
    border-radius: 50%;
    color: white;
    font-family: $font-primary;
    font-weight: 500;
    font-size: 10px;
    line-height: 1;
    text-align: center;

    svg {
      width: 17px;
      height: auto;
    }

    &_grey {
      background: $color-grey;
    }
  }
}
