.Tomato {
  aspect-ratio 295.4 / 266.83
  img {
    height auto
  }

  &__container {
    position relative
    width 100%
    height 100%
  }

  &__0 {
    position absolute
    width 100%
    height 100%
  }

  &__1 {
    position absolute
    width 83%
    right 0
    top -7%
  }

  &__2 {
    position absolute
    left: 20px;
    bottom: 33px;
    width 48%
    height auto
  }

  &__3 {
    position absolute
    width 24.4%
    left 27%
    top 35%

  }

}

.Candy {
  aspect-ratio 2.04
  background url("../assets/candy.png")
  background-repeat no-repeat
  background-size cover
}

.Pepper {
  aspect-ratio 0.8158
  background url("../assets/pepper.png")
  background-repeat no-repeat
  background-size cover
}

.Pizza {
  aspect-ratio 0.8016
  background url("../assets/pizza.png")
  background-repeat no-repeat
  background-size cover
}

.Star {
  aspect-ratio 1.01
  background url("../assets/star.png")
  background-repeat no-repeat
  background-size cover
  //transform-origin '58.6% 11%'
}

.Logo {
  cursor pointer
  aspect-ratio 1.1853
  background url("../assets/logo.png")
  background-repeat no-repeat
  background-size cover
  transform-origin: 50% 9%;
  will-change transform
}

.SVG {
  * {
    transform-box: fill-box !important
    transform-origin center center !important
  }
}
