.Chat {


  &__navBar {
    box-shadow: 0px 0px 5px rgba(32, 38, 55, 0.08);
    border-radius: 0px 0px 20px 20px;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 50px;
  }

  &__send-button {
    margin-right: 20px;


    &_active {
      & path {
        fill: rgba(80, 166, 132, 1)
      }
    }
  }

  &__spinner-container {
    position: absolute;
    height: calc(100vh - 50px - 72px);
    width: 100%;
    top: 50px;
    display: flex
    justify-content: center;
    align-items: center;
  }

  &__spinner {
    spinner(48px, 3px, $color-brand-green);
  }

  &__messages {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    position: fixed;
    top: 50px;
    margin-bottom: 72px;
    width: 100%;
    height: calc(100vh - 50px - 72px);

    &_hidden {
      overflow-y: scroll;
      opacity: 0;
    }
    & > :first-child {
      margin-top:auto;
    }
  }

  &__error {
    height: calc(100vh - 50px);
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__input-container {
    position: fixed;
    bottom: 20px;
    left: 0px;
    right: 0px;
    width: 100%;
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    width: calc(100% - 15px - 24px - 20px);
    height: 42px;
    background-color: rgba(255, 255, 255, 1);
    margin: 15px;
    padding-right: 15px;
    padding-left: 20px;
    position: relative;

    & input {
      height: 100%;
      width: calc(100% - 35px);
      border: none;
      background: transparent;

      &::placeholder {
        color: rgba(191, 183, 182, 1);
        font-weight: 500;
      }
    }
  }

  &__file-input {

    & input {
    display: none;
    }
  }

  &__image-viewer {
    background-color: rgba(0, 0, 0, 0.400);
    width: 100%;
    height: 100%;
    overflow: auto;
    margin: auto;
    position: fixed;
    top: 0; left: 0; bottom: 0; right: 0;
    z-index: 10010;
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
      max-width: 95%;
      max-height: 95%;
    }
  }

}
