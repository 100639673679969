.CheckboxField {
  position: relative;
  display: inline-block;
  font-size: $font-size-s;
  font-weight: 500;
  font-family: $font-secondary;
  line-height: 1.33;
  color: $color-brand-green;
  opacity:0.8;
  transition: color $halfduration;
  cursor: pointer;
  user-select: none;

  &:hover {
    opacity:1
    .CheckboxField__check {
      border-color: $color-brand-green;
    }
  }

  &__input {
    position: absolute;
    visibility: hidden;
  }

  &__check {
    width: 22px;
    height: 22px;
    margin-right: 10px;
    flex-shrink: 0;
    display: inline-block;
    vertical-align: middle;
    border-radius: 2px;
    background: #F4F4F8;
  }

  &__icon {
    width: 100%;
    height: 100%;
  }

  &__label {
    display: inline-block;
    vertical-align: middle;
  }

  &_checked {
    .CheckboxField__check {
      background-color: rgba(189, 189, 189, 1);
      padding:4px;
    }
  }

  &_disabled {
    pointer-events: none;
    opacity: 0.75;
  }
}
