.ProductCard {
  position: relative;
  display: flex;
  background-color: white;

  &__menu {
    position: relative;
    width: 244px;
    flex-shrink: 0;

    @media $tablet {
      width: 250px;
    }
  }

  &__picture {
    overflow: hidden;
    margin-left: -25px;
    margin-right: @margin-left;
    margin-bottom: 16px;

    @media $tablet {
      margin-left: 0;
      margin-right: 0;
    }

    img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
    }
  }

  &__content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    padding: 0 40px 0 68px;

    @media $tablet {
      padding: 0 $paper-pad-x;
    }
  }

  &__header, &__characteristics {
    flex-shrink: 0;
  }

  &__header {
    display: flex;
    margin-bottom: $paper-pad-x;

    @media $tablet {
      margin-bottom: 20px;
    }
  }

  &__heading {
    margin-right: 8px;
  }

  &__meta {
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__meta-options {
    display: flex;
    align-items: center;
    min-height: 20px;
  }

  &__meta-option {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }

    &_person-count {
      display: flex;
      align-items: center;

      svg {
        margin-right: 5px;
      }
    }

    svg {
      display: block;
    }
  }

  &__data {
    flex: 1 1 auto;
    min-height: 116px;
    margin-bottom: $paper-pad-x;

    @media $tablet {
      margin-bottom: $paper-pad-y;
    }
  }

  &__characteristics {
    padding-bottom: 20px;

    @media $tablet {
      padding-bottom: 28px;
    }
  }

  &__actions {
    width: 100%;
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__badge {
    position: absolute;
    top: -32px;
    right: 0;
    height: 20px;
    padding-left: 8px;
    padding-right: @padding-left;
    background-color: $color-brand-red;
    color: white;
    font: normal $font-size-xs / 21px $font-primary;
    z-index: 1;

    @media $tablet {
      top: -25px;
    }

    &_vegan {
      background-color: $color-brand-green;
    }
  }
}
