.ModalNoEnough {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #FFFFFF

  &__modal-icon {
    margin: 0 auto;
    height: 6px;
    border-radius: 3px;
    background: #fff;
    width: 70px;
    position: relative;
    top: -15px;
  }
  &__picture {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  &__header {
    display: block;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    color: #50A684;
    padding-bottom: 12px;
  }
  &__text {
    display: block;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    margin: 0 40px;
    color: #70544F;
  }
  &__order-button {
    margin: 51px;
    width: 343px;
  }
}
