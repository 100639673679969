.PapaBonus {
  display: flex;
  flex-direction: column;
  background-color: $color-brand-green;

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__white {
    padding: 30px 0 55px 0;
    display: flex;
    flex-direction: column;
    background-color: white;

    &_borders {
      border-bottom-left-radius: 25px;
      border-bottom-right-radius: 25px;
    }
  }

  &__title_1 {
    font-family: Manrope;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 23px;
    color: #70544F;
    text-align: center;
    margin-bottom: 10px;
  }

  &__title_2 {
    margin-bottom: 22px;
  }

  &__title_3 {
    margin-bottom: 25px;
    color: white;
  }

  &__main {
    display: flex;
    justify-content: space-between;

    @media $tablet {
      flex-direction: column-reverse;
      align-items: center;
    }
  }

  &__left {
    flex: 1;
    padding: 40px 0 0 0;
    margin: 0 20px 0 0;
    display: flex;
    flex-direction: column;

    @media $tablet {
      width: 100%;
      margin: 0;
    }
  }

  &__subtitle {
    width: 587px;
    margin-bottom: 45px;
    font-family: $font-secondary;
    font-weight: 400;
    font-size: $font-size-m;
    line-height: 1.33;
    color: $color-brand-brown;
    opacity: 0.8;

    &_greenTitle {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #50A684;
      opacity: 0.9;
    }

    &_container2 {
      margin-top: 30px;
    }
  }

  &__articles {
    display: flex;
    flex-direction: column;
  }

  &__article {
    display: flex;
    align-items: flex-start;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &-img {
      width: 24px;
      height: 1.33em;
      margin-right: 12px;
    }

    &-right {
      display: flex;
      flex-direction: column;
    }

    &-title {
      margin-bottom: 10px;
      font-family: $font-primary;
      font-weight: 500;
      font-size: $font-size-m;
      line-height: 1.33;
      color: $color-brand-brown;
    }

    &-description {
      max-width: 60%;
      font-family: $font-primary;
      font-weight: 400;
      font-size: $font-size-s;
      line-height: 1.33;
      color: $color-brand-brown;
      opacity: 0.8;
    }
  }

  &__details {
    margin-top: 25px;
    height: auto;
    align-self: flex-start;
  }

  &__right {
    margin-left: 70px;
    margin-top: 40px;
    width: 518px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__basket {
    width: 467px;
    height: 264px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__login {
    padding: 20px;
    display: flex;
    background: white;
    box-shadow: 0 0 10px rgba(69, 69, 69, 0.25);
    border-radius: 25px;

    &-avatar {
      width: 85px;
      height: 85px;
      margin-right: 20px;
    }

    &-right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &-description {
      margin-bottom: 5px;
      font-family: $font-secondary;
      font-weight: 400;
      font-size: $font-size-s;
      line-height: 1.33;
      color: $color-brand-brown;
      opacity: 0.8;
    }

    &-link {
      height: auto;
    }
  }

  &__gifts {
    padding: 50px 0;
  }

  &__workContainer {
    display: flex;
    flex-direction: column;
    margin-top: -25px;
    width: 619px;
    padding: 35px 40px 40px 30px;
    background: #f8f8f8;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  &__itemContainer {
    display: flex;
    margin-top: 20px;
  }

  &__itemContainer2 {
    display: flex;
    flex-direction: column;
  }
  &__itemContainer3 {
    display: flex;
    align-items: center;
  }

  &__greenText {
    margin-left: 15px;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #50A684;
  }

  &__greenText2 {
    margin-top: 20px;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #50A684;
  }

  &__greenText3 {
    margin-left: 15px;
    margin-top: 4px;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #50A684;
  }

  &__itemText {
    margin-top: 10px;
    margin-left: 39px;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #70544F;
    opacity: 0.8;
  }

  &__itemText2 {
    margin-top: 10px;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #70544F;
    opacity: 0.8;
  }

  &__loyaltyButton {
    font-family: 'Roboto';
    margin-top: 24px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 343px;
    height: 44px;
    background: #50A684;
    color: rgba(255, 255, 255, 1)
    box-shadow: 0 0 15px rgba(53, 53, 53, 0.06);
    border-radius: 25px;

    &_gradient {
      background: url('../../../icons/backgrounds/gradientForButtonProfile.png') center center / cover
    }
  }

  &__lollipop {
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 15px
  }

  &__programmContainer {
    margin-top: 34px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #70544F;
    opacity: 0.8;

    &_text {
      margin-top: 30px;
    }
  }
}
