.ToggleArrow {
  width: 30px;
  height: 30px;
  position: relative;
  display: inline-block;
  color: currentColor;
  transition: color $duration;

  &__triangle {
    width: 0;
    height: 0;
    margin: -2px 0 0 -5px;
    position: absolute;
    top: 50%;
    left: 50%;
    border-style: solid;
    border-width: 6px 5px 0 5px;
    border-color: currentColor transparent transparent transparent;
  }

  &_opened &__triangle {
    margin-top: -3px;
    transform: rotate(180deg);
  }
}
