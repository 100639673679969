.Form {
  $pad = 20px

  & {
    position: relative
  }

  &__group {
    margin-bottom 40px

    @media $phone {
      margin-bottom 24px
    }
  }

  &__group-title {
    margin-bottom 24px

    @media $phone {
      margin-bottom 16px
    }
  }

  &__row {
    display: flex
    margin-left: -($pad)
    margin-right: -($pad)
    margin-bottom 20px

    @media $phone {
      margin-bottom 16px
    }
    &_last {
      margin-bottom 40px
    }

    @media $phone {
      display block
    }
  }

  &__group > &__row:last-child {
    margin-bottom 0
  }

  &__field {
    width 100%
    margin-bottom: 20px

    &_one-half {
      width: 50%
    }

    &_one-third {
      width: 33.3%
    }

    &_one-quarter {
      width 25%
    }

    @media $phone {
      width 100%
    }
  }

  &__row > &__field {
    padding-left: $pad
    padding-right: $pad
    margin-bottom: 0

    @media $phone {
      &:not(:last-child) {
        margin-bottom 16px
      }
    }
  }

  &__errors {
    padding-top 8px
    color $color-brand-red
  }

  &__success {
    padding-top 8px
    color $color-brand-green
    font-weight bold
  }
}
