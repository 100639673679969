.Contacts {
  padding: 30px 0 57px 0;

  &__title {
    margin-bottom: 40px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
  }

  &__headline {
    display: flex;
    flex-wrap: wrap;
  }

  &__wrapper {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
  }

  &__contact {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
  }

  &__text {
    margin-bottom: 14px;
    color: #70544F;
    font-size: 14px;

    h3 {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 18px;
    }

    p {
      margin-bottom: 14px;

      a {
        margin-top: 14px;
      }
    }
  }

  &__link {
    margin-bottom: 4px;
    font-family: $font-secondary;
    font-weight: 500;
    color: $color-brand-brown;
    transition: color $duration;

    &:hover {
      color: $color-brand-green;
      transition-duration: $halfduration;
    }
  }

  &__feedback-form {
    max-width: 376px;
    margin: 0 auto;
  }
}
