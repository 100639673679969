.Map {
  display: flex;
  flex-direction: column;

  &__title {
    margin-bottom: 15px;
    text-align: center;
  }

  &__order {
    width: 100%;
    height: 250px;
    margin-bottom: 30px;
  }

  &__right {
    height: 42px;
    margin-bottom: 30px;
    align-self: center;
    padding: 0 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-bg;
    border-radius: 25px;
    font-family: $font-primary;
    font-weight: 500;
    font-size: $font-size-s;
    color: $color-grey;
    line-height: 1;
    white-space: nowrap;
  }

  &__left {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $font-secondary;
    font-weight: 500;
    font-size: $font-size-s;
    color: $color-grey;
    line-height: 1;
    white-space: nowrap;
  }
}
