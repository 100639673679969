.IngredientsButton {
  & {
    position absolute
    top $paper-pad-y
    right $paper-pad-y
    cursor pointer
    user-select none
  }

  &:after {
    content ''
    position absolute
    top -16px
    right -16px
    bottom -16px
    left -16px
  }

  &__toggle {
    width 32px
    height 32px
    border 1px solid rgba($color-brand-green, 0.2)
    border-radius 50%
    background-color white
    color $color-brand-green
    transition border-color $duration
    z-index 1

    svg {
      position absolute
      top 50%
      left 50%
      margin-top -6px
      margin-left -8px
    }
  }

  &__counter {
    position absolute
    top -2px
    right -4px
    width 20px
    height 20px
    border-radius 50%
    background-color $color-brand-red
    border 2px solid white
    color white
    font-size 10px
    line-height 15px
    text-align center
  }

  &:hover &__toggle {
    border-color rgba($color-brand-green, 0.6)
    transition-duration: $halfduration;
  }
}
