.PizzaListModal {
  & {
    background-color $color-bg
  }

  &__navbar {
    position sticky
    top 0
    height 60px
    text-align center
    z-index 1
  }

  &__navbar-subtitle {
    font-size $font-size-xs
  }
}
