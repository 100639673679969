.Halves {
  //height: 100%;

  &__header {
    padding-left $phone-margin-x
    padding-right $phone-margin-x
    margin-bottom 24px
    &_match {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &__content {
    padding-top 24px
  }

  &__body {
    padding 0 8px 8px
    &_vk {
      display: flex;
      justify-content: center;
      margin-top: 12px;
    }
  }

  &__half-selectors {
    margin-bottom 24px
  }

  &__half-selector {
    margin-bottom 2px

    &:last-child {
      margin-bottom 0
    }
  }

  &__pizza {
    padding 24px 12px
    border-radius 4px
    background-color white
  }

  &__image {
    margin-bottom 32px

    &:last-child {
      margin-bottom 0
    }
  }
}
