.Stock {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;

  &__navbar {
    position: absolute;
    left: 0;
    top: 0;
    background-color: transparent !important;
  }
  &__caption {
    position: absolute;
    left: 0;
    top: 80px;
    margin: 15px;
    border-radius: 15px;
    padding: 6px 10px;
    background-color: rgba(255, 255, 255, 0.5);
    font-size: 10px;
    color: $color-brand-brown;
  }
  &__image {
    width: 100%;
    height: auto;
    flex-shrink: 0;
  }

  &__white {
    flex: 1;
    padding: 25px 16px;
    margin-top: -21px;
    display: flex;
    flex-direction: column;
    border-radius: 20px 20px 0px 0px;
    background-color: white;
  }

  &__title {
    margin-bottom: 20px;
    text-align: center;
    font-family: $font-secondary;
    font-weight: 500;
    font-size: $font-size-m;
    line-height: 1.33;
    color: $color-brand-brown;
  }

  &__description {
    font-family: $font-secondary;
    font-weight: 400;
    font-size: $font-size-s;
    line-height: 1.33;
    color: $color-brand-brown;
    opacity: 0.8;
  }

  &__button {
    margin-top: 20px;
    align-self: center;
  }

  &__spinner {
    spinner(48px, 3px, $color-brand-green);
    align-self: center;
    margin: 40% 0 12px;
  }
}
