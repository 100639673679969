.LoginModalKy {
  position relative
  overflow unset
  padding-top 75px
  display flex
  flex-direction column
  justify-content center
  align-items center

  background: linear-gradient(104.04deg, rgba(83, 41, 30, 1) 10%, rgba(149, 97, 86, 1) 90%);
  color: white

  &:before {
    position absolute
    content ''
    top 0
    left 50%
    width 80px
    height 80px
    background url("../assets/KY_quest/1.png") no-repeat center center
    transform translate(-50%, -50%)
  }

  &__text {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
  }

  &__subtext {
    padding-top 12px
    font-size: 16px;
    font-weight: 600;
    text-align: center;

  }
  &__Login {
    margin-top 24px
    background rgba(235, 87, 87, 1)
  }
}
