.RepeatOrderConflict {
  width 340px
  padding $paper-pad-x

  @media $phone {
    width 100%
  }

  &__closeButton {
    $color = #b1b7b6;

    position relative
    width 16px
    height @width
    margin-left auto
    margin-bottom 20px
    color rgba($color, 0.6)
    cursor pointer
    transition color $duration

    &:after {
      content ''
      position absolute
      top -(@width/2)
      right @top
      bottom @top
      left @top
    }

    svg {
      display block
    }

    &:hover {
      color $color
      transition-duration: $halfduration;
    }
  }

  &__text {
    margin-bottom $paper-pad-x
    text-align center
    color $color-brand-brown
  }

  &__actions {
    display flex
    align-items center
    justify-content center
    margin-left -10px
    margin-right -10px

    @media $phone {
      flex-direction column
      align-items flex-start
      justify-content center
      margin-left 0
      margin-right 0
    }

    &__item {
      margin 0 10px
      flex 1 1 100%

      @media $phone {
        display block
        width 100%
        margin-left 0
        margin-right 0
        margin-bottom 10px

        &:last-child {
          margin-bottom 0
        }
      }
    }
  }
}
