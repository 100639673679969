.Filter {
  position relative
  display flex
  align-items center
  min-height 48px

  &__categories {
    flex 1 1 auto;
    padding-right 32px

    @media $tablet {
      padding-right 0
    }
  }

  &__search {
    flex 0 0 $main-sidebar-width

    @media $tablet {
      position absolute
      right 0
      bottom 100%
      width 270px
      margin-bottom $paper-pad-y
    }
  }

  &__category {
    display inline-block

    @media $tablet {
      margin-bottom 0px
    }

    &:last-child {
      margin-right 0
    }
  }

 &__categoryButton {
    display flex
    justify-content center
    align-items center
    height 36px
    padding 8px 16px
    margin 4px
    border solid 1px $color-border
    border-radius 100px
    line-height 1.2
    color $color-text
    text-transform capitalize
    cursor pointer
    user-select none

    &_active {
      background-color $color-border
      color $color-brand-brown
      cursor default
    }

    &:not(&_active):hover {
      color $color-brand-green
      transition-duration: $halfduration;
    }
  }

  &__searchField {
    position relative
    height 48px
  }

  &__searchIcon {
    position absolute
    top 15px
    left 15px
    z-index 1
  }

  &__searchInputField {
    input  {
      height 48px
      padding 7px 16px 7px 45px
      border 1px solid #fff
      font-size $font-size-s
      background-color #fff;
      placeholder($color-text)
      transition border-color $duration, background-color $duration

      &:focus {
        background-color #fff;
        border-color $color-brand-green
        transition-duration: $halfduration;
      }
    }
  }
}
