.BorderToggle__halloween {
  .BorderToggle {

    &__toggle {
      color: $colorHW-border-color;
      background-color  $colorHW-border-bg;

      &_active {
        color: $colorHW-border-color-active;
        background-color: $colorHW-border-bg-active;
      }
    }
  }
}

.BorderToggle__new-year {
  .BorderToggle {
    &__toggle {
      color: $colorNY-border-color;
      background-color $colorNY-border-bg;

      &_active {
        color: $colorNY-border-color-active;
        background-color: $colorNY-border-bg-active;
      }
    }
  }
}

.BorderToggle {
  width: 76px;
  height: 36px;
  position: relative;
  flex-shrink: 0;

  &_bottom {
    .BorderToggle__body {
      margin-top: 8px;
      top: 100%;

      &:after {
        bottom: calc(100% + 2px);
        border-width: 0 6px 6px 6px;
        border-color: transparent transparent $color-brand-brown transparent;
      }
    }
  }

  &:not(&_bottom) {
    .BorderToggle__body {
      margin-bottom: 8px;
      bottom: 100%;

      &:after {
        top: calc(100% + 2px);
        border-width: 6px 6px 0 6px;
        border-color: $color-brand-brown transparent transparent transparent;
      }
    }
  }

  &__toggle {
    width: 100%;
    height: 100%;
    padding-left: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 100px;
    border: solid 1px $color-border;
    color: $color-brand-brown;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
    transition-duration: $duration;

    &:not(&_active):hover {
      border-color: darken($color-border, 15%);
      transition-duration: $halfduration;
    }

    &_active {
      background-color: #ededed;
    }
  }

  &__toggle-arrow {
    margin-left: -6px;
  }

  &__body {
    width: 220px;
    min-height: 70px;
    padding: 8px;
    position: absolute;
    right: 0;
    z-index: 1000;
    background-color: $color-brand-brown;
    border: 2px solid $color-brand-brown;
    border-radius: $border-radius;
    opacity: 0;
    transition: opacity $duration;

    &_entered {
      opacity: 1;
    }

    &:after {
      width: 0;
      height: 0;
      content: '';
      position: absolute;
      right: 15px;
      border-style: solid;
    }
  }

  &__item {
    width: 100%;
    height: 64px;
    padding: 4px 0;
    display: flex;
    align-items: center;
    position: relative;

    &:not(:last-child):after {
      content: '';
      width: calc(100% - 72px);
      height: 1px;
      display: block;
      position: absolute;
      bottom: -1px;
      right: 0;
      background-color: #8f6f68;
    }
  }

  &__img {
    width: 56px;
    height: 56px;
    flex-shrink: 0;
    border-radius: 28px;
  }

  &__text {
    padding: 0 16px;
    display: flex;
    text-align: left;
    color: #fff;
    font-weight: bold;
    font-size: 12px;
    transition: background-color $duration / 2, color $duration / 2;

    ^[0]__item_active & {
      color: #ffd8a6;
    }
  }

  &__icon-wrapper {
    width: 16px;
    height: 16px;
    margin-left: auto;
    flex-shrink: 0;
    position: relative;
    border-radius: 8px;
    background-color: #fff;
    opacity: 0;

    ^[0]__item_active & {
      opacity: 1;
      background-color: #ffd8a6;
      transition: opacity $duration / 2, background-color $duration / 2;
    }

    ^[0]__item:not(^[0]__item_active):hover & {
      opacity: 0.8;
      transition: opacity $duration;
    }
  }

  &__icon {
    height: inherit;
    width: inherit;

    path {
      fill: $color-brand-brown;
    }
  }
}
