.HatikoBanner {
  position: fixed;

  left: 12px;
  bottom: 32px;

  z-index: 1000;
  height: 275px;
  width: 275px;
  transition: all 1s ease-in-out;


  @media $tablet {
    height: 200px;
    width: 200px;
    left: 50%;
    transform: translateX(-50%)
  }

  @media $phone {
    left: 40%;
    transform: translateX(-50%)
  }

  &_hidden {
    transform: translateY(150%)

    @media $tablet {

      transform: translate(-50%, 150%)
    }

    @media $phone {
      transform: translate(-40%, 150%)
    }
  }

  &__image {
    height: 100%;
    width: 100%;
    background-image: url(./assets/hatico.png);
    background-size: contain;
  }

  &__quote {
    position: absolute;
    width: max-content;
    left: 60%;
    top: -23px;
    border: 2px solid;
    border-color: rgba(228, 228, 228, 0.5)
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(8px);
    box-shadow: 0px 0px 10px rgba(111, 78, 78, 0.1);
    @media $tablet {
      left: 57%;
      top: -33px;
    }

    &::after, &::before {
      content: '';
      display: block;
      bottom: -14px;
      left: 15px;
      border: 6px solid;
      border-color: transparent;
      border-left-color: rgba(228, 228, 228, 0.5);
      border-top-color: rgba(228, 228, 228, 0.5);
      position: absolute;
    }

    &::before {
      bottom: -17px;
      left: 14px;
      border: 8px solid;
      border-color: transparent;
      border-left-color: rgba(255, 255, 255, 0.3);
      border-top-color: rgba(255, 255, 255, 0.3);
    }

    &-phrase {
      padding: 16px 26px;
      font-family: $font-primary;
      font-size: $font-size-xl;
      color: $color-brand-brown;
      font-weight: 500;

    }
  }

  &__close {
    position: absolute;
    left: 0;
    top: 0;
    padding: 10px;
    margin: -10px;
    cursor: pointer;

    svg > path {
      fill: $color-brand-brown;
    }
  }

  &__mobile {
    position: fixed;
    bottom: 4%;
    left: -300px;
    width: 54px;
    height: 54px;
    background-color: #FFCE00;
    border-radius: 50%;
    z-index: 1000;
    visibility: hidden;
    cursor: pointer;
    transition: all 1s ease-in-out;
    @media $tablet {
      left: 20px;
      visibility: visible;
    }
    @media $phone {
      left: 12px;
      bottom: 100px;
    }

    &::before, &::after {
      content: '';
      position: absolute;
      display: block;
      width 16px;
      height: 16px;
      top: 50%;
      left: 50%;

      background-image: url(./assets/paw.svg);
      background-size: contain;
      background-repeat no-repeat
    }

    &::before {
      transform: translate(-29%, -95%) rotate(40deg);
    }

    &::after {
      transform: translate(-78%, 12%) rotate(67deg);
    }

    &_hidden {
      bottom: -20vh;
    }
  }
}
