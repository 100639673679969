.InputField {

  &__standart{
    direction: ltr!important;
    text-align: left!important;
  }

  &_disabled {
    pointer-events: none;
    opacity: 0.75;
  }

  &_error {
    .InputField__label {
      color: $color-brand-red;
    }

    .InputField__field {
      color: $color-brand-red;
    }
  }

  &__wrapper {
    position: relative;
    display: flex;
  }

  &__label {
    margin-bottom: 5px;
    display: block;
  }

  &__field {
    width: 100%;
    height: $inputHeight;
    padding: 12px 16px;
    border-radius: $input-border-radius;
    border: none;
    background-color: $color-bg;
    font-size: $font-size-m;
    color: $color-brand-brown;
    placeholder($color-grey);
    transition: background-color $duration;

    &:focus {
      background-color: $color-bg-hover;
    }
  }

  &__mask {
    position: absolute;
    font-size 16px
    left 30px
    top 50%
    transform translate(0, -50%)
  }
}
