.ItemContainer {
  margin-top: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: -webkit-fill-available;

  &__link {
    width: -webkit-fill-available;
  }

  &__item {
    padding: 0 22px 0 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 55px;
    background: #FFFFFF;
    box-shadow: 0 0 10px rgba(32, 38, 55, 0.08);
    border-radius: 15px;
    font-family: $font-secondary;
    font-weight: bold;
    font-size: 16px;
    line-height: 15px;
    color: #70544F;
  }
}
