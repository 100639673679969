.Profile {

  &__itemContainer {
    margin-top: 15px;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    div:first-child {
      margin-top:0;
    }
  }

  &__bonusesContainer {
    padding: 12px 24px 12px 22px;
    margin-top: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0 0 10px rgba(32, 38, 55, 0.08);
    border-radius: 15px;
  }

  &__bonuses {
    margin-top: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: rgba(112, 84, 79, 0.9);
  }

  &__edit {
    width: 56px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-grey;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  &__gifts {
    margin-bottom: 10px;
  }

  &__bonuses-history {
    margin-top: 15px;
    display: flex;
    align-items: center;
  }

  &__back-icon {
    width: auto;
    height: 11px;
    margin-left: 4px;
    transform: scale(-1, 1);
  }

  &__exit {
    margin-top: 25px;
    margin-bottom: 25px;
    width: 100%;
    display: flex;
    justify-content: center;
    font-family: $font-secondary;
    font-size: 16px;
    line-height: 18px;
    color: #70544F;
    opacity: 0.8;
  }
}

.ModalNotifications {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 191px;
  background-color: #FFFFFF

  &__modal-icon {
    margin: 0 auto;
    height: 6px;
    border-radius: 3px;
    background: #fff;
    width: 70px;
    position: relative;
    top: -15px;
  }

  &__title {
    font-family: $font-secondary;
    font-weight: 400;
    font-size: 18px;
    line-height: 17px;
    color: #70544F;
    margin-top: 30px;
    margin-bottom: 24px;
  }
}


