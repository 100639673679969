.OrderTracking {
  padding: 20px 0 40px;
  display: flex;
  flex-direction: column;

  &__main {
    paper();
    padding: 40px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
  }

  &__title {
    margin-bottom: 40px;
    text-align: center;
  }

  &__spinner {
    spinner(48px, 3px, $color-brand-green);
    align-self: center;
  }

  &__button {
    margin-top: 40px;
    align-self: center;
  }
}
