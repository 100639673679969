.Bonus {
  position: relative
  width: 217px;
  height: 46px;
  background: #F8F8F8;
  border-radius: 20px;
  display: flex;
  align-items: center;

  &__input {
    width: 100%;
    height: 100%;
    padding: 8px 25px 8px 25px;
    border-radius: 20px;
    background-color: $color-bg;
    border: 1px solid $color-bg;
    color: $color-brand-brown;
    font-family: $font-primary;
    font-size: $font-size-s;
    font-weight: 500;
    line-height: 1;
    placeholder($color-grey);
    transition: border-color $halfduration, background-color $halfduration;

  }

  &__applied {
    padding-left: 25px;
    font-weight: 500;

    &_green {
      color: $color-brand-green;
    }
  }

  &_disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &__title {
    padding-left: 30px;
    font-weight: 500;
  }

  &__button {
    position: absolute;
    top: 23px;
    right: 4px;
    width: 32px;
    height: 32px;
    transform: translateY(-50%);
    background-color: $color-grey;
    border-radius: 50%;
    color: white;
    transition: background-color $halfduration, border-color $halfduration, color $halfduration;

    &_active:hover {
      background-color: $color-brand-green;
    }

    &_applied {
      background: none;
      color: $color-brand-green;

      &:hover {
        background: none;
      }
    }
  }

  &__button-arrow {
    chevron-right();
  }

  &__button-spinner {
    spinner(20px, 2px, $color-brand-green);
    $spinnerSize = 20px;
    $spinnerWidth = 2px;
    margin-top: -10px;
    margin-left: -10px;
    position: absolute;
    top: 50%;
    left: 50%;
  }

  &__button-clear {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
    color: $color-grey;
    transition: color $halfduration;

    svg {
      width: 24px;
      height: auto;
      display: block;
    }

    &:hover {
      color: darken(#bfb7bf, 15%);
    }
  }

}

.ModalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 310px;
  height: 323px;
  background-color: #FFFFFF;
  border-radius: 25px;

  &__Header {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: Manrope;
    font-weight: 500;
  }

  &__title {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #70544F;

    &_bonus {
      color: #50A684;
    }
  }

  &__actions {
    margin-top: $checkout-desktop-margin-25;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  &__submit {
    margin-top: 15px;
    height: $button-height;
    width: 230px;
    padding: $checkout-desktop-button-padding;
    border-radius: 20px;
  }

  &__field {
    flex-grow: 1;
    position: relative;
    color: $color-grey;

    &_active {
      color: $color-brand-green;
    }
  }

  &__clear {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    margin-left: 10px;

    &:hover svg {
      fill: $color-brand-green;
    }
  }


  &__sufix {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    color: inherit;
    font-family: $font-secondary;
    font-size: $font-size-s;
    font-weight: 500;
    line-height: 1;
  }

  &__messages {
    margin-top: 34px;
    max-width: 201px;
    text-align: center;
    list-style: disc inside;
    color: $color-grey;
    font-family: $font-primary;
    font-size: $font-size-xs;
    font-weight: 400;
    line-height: 1.33;

    &_notMargin {
      margin-top: 0;
    }
  }


}
