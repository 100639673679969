.Nutrition {
  &__title {
    margin-bottom 26px

    @media $tablet {
      padding-top 16px
      padding-right 290px
      font-size $paper-pad-x
    }
  }

  &__filter {
    margin-bottom 26px
  }
}