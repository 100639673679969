.NotAuthModal {

  &__content {
    max-width 300px
    display flex
    flex-direction column
    gap 12px

    @media $phone {
      max-width unset
    }
  }

}
