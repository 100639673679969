.SuperpapaLink {
  z-index 100
  position: fixed;
  bottom: 40px;
  left: 40px;
  width: 120px;


  img {
    width 100%
  }
}
