.ProductCard {
  &__halloween {
    .ProductCard {
      &__container {
        background-image: url($colorHW-card-background);
      }
      &__meta, &__header, &__heading, &__subheading {
        color: $colorHW-text-halloween
      }
      &__button {
        background-color: $colorHW-bg-btn;
        color: $colorHW-text-btn!important;
      }
    }
  }

  &__new-year {
    .ProductCard {
      &__container {
        background-image: url($colorNY-card-background);
      }
      &__meta, &__header, &__heading, &__subheading {
        color: $colorNY-text-halloween
      }
      &__button {
        background-color: $colorNY-bg-btn;
        color: $colorNY-text-btn!important;
      }
    }
  }

  &__picture {
    width: 100%;
    padding-bottom: 78.7671232877%; // 146/115 - размер изображения
    margin-bottom: 12px;
    position: relative;
    overflow: hidden;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;

    &_clickable {
      cursor: pointer;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  &__container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-size: cover;
    padding-top: 20px;

    &_promoGame {
      position: relative;
    }
  }


  &__promoGame {
    &_side {
      z-index: 1
      top: 150px;
      right: 0;
    }

    &_sideLeft {
      z-index: 1

      top: 20%;
      left: 0;
    }

    &_top {
      z-index: 1
      top: 0
      left: 20%;
    }
    &_bottom {
      z-index: 10
      bottom : 0;
      right: 20%;
    }


    &_button {
      left: 15px;
      top: -45px;

      &_mm{
        top: -20px;
        left: 0;
      }
    }

    &_top_outside {
      top: -45px;
      left : 10px;
    }
  }


  &__meta {
    height: 20px;
    margin-bottom: 12px;
    padding-right: $paper-pad-x;
    display: flex;
    align-items: center;
  }

  &__badge {
    height: 100%;
    padding: 0 12px;
    position: relative;
    color: white;
    font: bold $font-size-xs / 21px $font-secondary;
    background-color: $color-brand-red;

    &:after {
      width: 0;
      height: 0;
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      border-style: solid;
      border-width: 10px 4px 10px 0;
      border-color: transparent white transparent transparent;
    }

    &_vegan {
      background-color: $color-brand-green;
    }
    &_sale {
      background-color: $color-brand-green;
    }
  }

  &__option {
    &:first-of-type {
      margin-left: auto;
    }
  }

  &__header {
    padding: 0 $paper-pad-x;
    margin-bottom: 6px;
    position: relative;
    display: flex;
    justify-content: space-between;
  }

  &__heading {
    margin-right: 12px;
    flex-wrap: nowrap;
    font-family: $font-secondary;
    font-weight: bold;
    color: $color-brand-brown;
    line-height: 1.33;
    font-size: $font-size-l;
  }

  &__subheading {
    padding: 0 $paper-pad-x;
    margin-bottom: $paper-pad-x;
    font-family: $font-primary;
    font-size: $font-size-s;
    font-weight: 400;
    line-height: 1.33;
  }

  &__actions {
    margin-top: auto;
    padding: 12px $paper-pad-x $paper-pad-x $paper-pad-x;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    &_compact {
      position: sticky;
      bottom: 0;
      left: 0;
      background-color: white;
      box-shadow: rgb(230, 230, 230) 0px -25px 25px -25px;
    }
  }

  &__selector {
    width: 100%;
    margin-bottom: $paper-pad-x;
    order: 1;
  }

  &__button-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    order: 2;
    &_compact {
      order: 3;
    }
  }

  &__button {
    width: 110px;
    @media $phone {
      width: 174px;
    }
  }

  &__points {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 65px;
    height: 45px;
    font-family: Roboto;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
    order: 2;
    color: #FFFFFF;
    border-radius: 25px;
    cursor: pointer;
    background: url('../../icons/backgrounds/BG.png') no-repeat center center / cover
    @media $phone {
      order: 2;
      width: 78px;
    }

    &_disabled {
      background: #BFB7B6;
    }
  }

  &__price {
    order: 3;

    &_compact {
      order: 1;
    }
  }

  &__gift {
    width: auto;
    height: 32px;
    color: $color-grey;
  }

  &__backside {
    width: 100%;
    height: 100%;
    padding: $paper-pad-x;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &_adaptive {
      padding-top 25px
      padding-bottom 25px
    }

    &-icon {
      width: 120px;
      height: 100px;
      margin: auto 0 15px;

      &_adaptive {
        margin-bottom 35px
      }
    }

    &-description {
      margin-bottom: 15px;
      font-size: $font-size-m;

      &_adaptive {
        font-family: Manrope;
        font-style: normal;
        font-size: 16px;
        line-height: 15px;
        letter-spacing: 0.02em;
        color: #70544F;
        margin-bottom 25px
        font-weight 500
      }
    }

    &-buttons {
      width: 100%;
      margin-top: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &_adaptive {
        justify-content center
      }
    }

    &-buttons-yes, &-buttons-no {
      width: 47%;

      &_adaptive {
        padding 13px 25px
        max-width 80px
        margin auto 20px
      }
    }
  }

  &__hidden {
    display none !important;
  }

  &__spinner {
    spinner(24px, 2px, currentColor);
    margin-top: -12px;
    margin-left: -12px;
    position: absolute;
    top: 50%;
    left: 50%;
  }

  &__cosmo_decor {
    position absolute
    top -18px;
    right 8px
    width: 40px;
  }


  &__PromoBorder {

    &_pic {
      left: 0;
      bottom 0;
      min-width 135px
      max-width 50%
    }

    &_button {
      width 56px
      bottom 0
      right  0
      transform translate(50%, 20%)
    }

  }

  &__PaddingtonDecor {
    width 74px
    top 75px
    right 0
  }
}
