.SuccessMessage {
& {
    position relative
    width fit-content
    text-align center
    margin-left auto
    margin-right auto
    padding 56px 118px 32px
  }

  @media $phone {
    display flex
    align-items center
    justify-content center
    width auto
    min-height 100vh
    padding 0 $phone-margin-x
  }

  &__icon {
    margin-bottom 16px
    color $color-brand-red
  }

  &__title {
    margin-bottom 32px
  }

  &__icon,
  &__title {

    &:last-child {
      margin-bottom 0
    }
  }

  &__actions {
    display flex
    justify-content center
    margin-bottom 56px

    @media $phone {
      flex-direction column
    }

    &:last-child {
      margin-bottom 24px
    }
  }

  &__button {
    margin-right 16px

    @media $phone {
      width 100%
      margin-right 0
      margin-bottom 20px

      &:last-child {
        margin-bottom 0
      }
    }

    &:last-child {
      margin-right 0
    }
  }
}
