.IngredientCard {
  position: relative;
  width: 122px;
  min-height: 132px;
  margin: 5px;
  border-radius: $border-radius;
  background-color: $color-bg;
  text-align: center;
  cursor: pointer;
  user-select: none;
  &__halal {
    position absolute;
    bottom: 2px;
    right 4px;
  }
  &__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 12px;
  }

  &__count {
    position: absolute;
    top: 6px;
    right: 6px;
    line-height: 1;

    @media $phone {
      right: auto;
      left: 6px;
    }
  }

  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50px;
    margin-bottom: 10px;

    img {
      max-width: 100%;
    }
  }

  &__name {
    margin-bottom: 10px;
    line-height: 1.3;

    &:first-letter {
      text-transform: capitalize;
    }
  }

  &__remove {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 6px;
    color: $color-brand-red;
    opacity: 0;
    transition: opacity $duration;

    @media $phone {
      display: none;
    }

    &:before {
      content: none;
    }

    svg {
      display: block;
    }
  }

  &__action {
    position: absolute;
    top: 20px;
    left: 50%;
    width: 46px;
    height: @width;
    margin-left: -23px;
    border-radius: 50%;
    opacity: 0;
    transition: opacity $duration, transform $duration;

    @media $phone {
      width: 42px;
      height: @width;
    }

    &_add {
      background-color: $color-brand-green;

      @media $phone {
        display: none;
      }

      &:before, &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: white;
      }

      &:before {
        width: 4px;
        height: 16px;
        margin-top: -8px;
        margin-left: -2px;
      }

      &:after {
        width: 16px;
        height: 4px;
        margin-top: -2px;
        margin-left: -8px;
      }
    }

    &_remove {
      background-color: $color-brand-red;
      color: white;

      svg {
        display: none;
      }

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: white;
        width: 16px;
        height: 4px;
        margin-top: -2px;
        margin-left: -8px;
      }

      @media $phone {
        opacity: 1;
        left: auto;
        top: -6px;
        right: -6px;

        &:before {
          display: none;
        }

        svg {
          display: block;
          margin-top: 8px;
          margin-left: 9px;
        }
      }
    }
  }

  &_included {
    background-color: #ffd8a6;
  }

  &:not(&_included):hover {
    background-color: #ededed;
    transition-duration: $halfduration;
  }

  &:hover &__action, &:hover &__remove {
    opacity: 1;
    transition-duration: $halfduration;
  }
}
