.Suggestions {
  $padding-left = 48px;
  $box-shadow = 0 2px 8px 0 rgba(41, 44, 51, 0.08);


  &_focused {
    background-color: $color-bg;
    transition-duration: $halfduration;

    &:before {
      transition-duration: $halfduration;
      opacity: 1;
    }
  }

  &__label{
    margin-bottom: 5px;
    display: block;
  }

  &__standart {
    direction: ltr!important;
    text-align: left!important;
  }

  input {
    width: 100%;
    height: 62px;
    display: block;
    border: none;
    background-color: $color-bg;
    font-size: $font-size-m;
    color: $color-brand-brown;
    transition: background-color $duration;
    placeholder($color-grey);

    &:focus {
      background-color: $color-bg-hover;
    }
  }

  &__fieldEnd{
    direction: ltl;
    text-align: end;

    &::focus{
      direction: rtr;
    }
  }

  ul {
    width: 100%;
    max-height: 200px;
    scrollable();
    custom-scrollbar(2px, #e6e2e1);
    box-sizing: border-box;
    position: absolute;
    top: 100%;
    padding-right: 5px;
    border-right: 4px solid transparent;
    border-radius: 5px;
    background-color: #FFF;
    box-shadow: $box-shadow;
    z-index: 1000;
  }

  li {
    max-width: 100%;
    padding-left: $padding-left;
    padding-right: 15px;
    cursor: pointer;
    line-height: 1.15;
    color: $color;
    height: 40px;
    font: $font-size-s / 40px $font-primary;
    color: $color-brand-brown;
    background-color: #fff;
    transition: background-color $duration;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 24px;
      top: 50%;
      opacity: 0;
      transform: translateY(-50%);
      background: url('../../assets/check.svg') no-repeat 50% 50% / contain;
      width: 16px;
      height: @width;
      transition: opacity $duration;
    }

    @media $phone {
      height: auto;
      padding-top: 8px;
      padding-bottom: 8px;
      line-height: 1.66;
      font-size: $font-size-xs;

      &:before {
        display: none;
      }
    }
  }
}
