.PawGroup {
  position: absolute;
  background-image: url("./assets/paw_group.png")
  width: 58px;
  height: 117px;
  background-repeat no-repeat;
  top: -8px;
  left: -64px;
  background-size: contain;

  @media $tablet {
    top: 24px;
    left: 130px;
    transform: rotate(-69deg);
    width: 59px;
    height: 106px;
  }
  @media $phone {
    top: 32px;
    left: 105px;
    transform: rotate(-69deg);
    width: 36px;
    height: 86px;
  }
}


