.PhoneField {
  position: relative;

  &__wrapper {
    position: relative;
  }

  &__flag {
    width: 16px;
    height: 16px;
    position: absolute;
    left: 16px;
    top: calc(50% - 8px);
  }

  &__label {
    mobileinputlabel();
  }

  &__input {
    mobileinputfield();
    padding-left: 40px;

    &_plain {
      mobileinputplainfield();
    }
  }

  &_disabled {
    pointer-events: none;
    opacity: 0.75;
  }
}