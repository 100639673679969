.ReplacementSlider {
  paper();
  margin-bottom: $checkout-desktop-margin-20;

  &__slider {
    margin: 0 55px;

    @media $tablet {
      margin: 0;
    }
  }

  &__slide {
    width: 330px;
    min-height: 150px;
    height: auto;
    display: flex;
  }

  &__wrapper {
    width: 100%;
    padding: $checkout-desktop-margin-20;
    position: relative;
    display: flex;
    border: 4px solid $color-bg;
    border-radius: 15px;
  }

  &__image {
    width: 85px;
    height: auto;
    margin-right: 16px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
    }
  }

  &__group {
    flex: 1;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }

  &__name {
    margin-bottom: 6px;
    word-break: break-word;
  }

  &__description {
    margin-bottom: 7px;
    word-break: break-word;
    font-size: $font-size-s;
    color: $color-grey;
    font-family: $font-primary;
    line-height: 1.33;
    font-weight: 400;
  }

  &__footer {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__amount {
    font-family: $font-secondary;
    font-weight: 500;
    font-size: $font-size-m;
    line-height: 1;
  }

  &__price {
    display: inline-block;
    color: $color-brand-green-dark;
  }

  &__oldprice {
    display: inline-block;
    color: $color-brand-red-light;
    text-decoration: line-through;
  }

  &__button {
    width: 36px;
    height: 36px;
    padding: 1px;
    flex-shrink: 0;
    border-radius: 50%;
  }

  &__plus {
    width: 14px;
    height: auto;
    vertical-align: middle;
    fill: white;
  }
}
