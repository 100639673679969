.DownloadApp {

  background-color: $color-border;


  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 44px 0 44px
    @media $tablet {
      padding: 28px 0 36px
    }

    @media $phone {
      padding: 20px 0 24px
    }
  }

  &__container {
    display: grid;
    grid-template-columns: 4fr 2fr;
    column-gap: 70px

    @media $phone {
      column-gap: 26px
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-self: center;
  }

  &__description {
    font-family: Manrope;
    font-weight: 700;
    font-size: 28px;
    line-height: 1.28;
    padding-bottom: 48px;
    letter-spacing: 0em;
    color: $color-brand-brown;
    @media $tablet {
      font-size: 16px;
      line-height: 1.5;
      padding-bottom: 22px;
    }

    @media $phone {
      padding-bottom: 8px;
    }
  }

  &__heading {
    padding-bottom: 32px
    @media $tablet {
      padding-bottom: 20px
    }

    &_desktop {
      display: block
      @media $tablet {
        display: none
      }

      &-less {
        text-align: center;
        display: none
        @media $tablet {
          display: block
        }
      }
    }
  }

  &__qr {
    width: 224px;
    aspect-ratio: 1 / 1;
    border-radius: 16px;
    @media $tablet {
      width: 160px;
      border-radius: 12px;
    }

    @media $phone {
      width: 120px;
      border-radius: 8px;
    }
  }

  &__phone {
    width: 100%;
  }

}

