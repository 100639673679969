.Characteristics {
    display block

  &__group {
    &_weight {
      position relative
      width 65px
      margin-right 8px
      padding-right 6px


      &:after {
        content ''
        position absolute
        right 0
        top 3%
        height 94%
        border-right 2px solid $color-border
      }
    }
  }
  &__weight {
    font-size $font-size-xs
    color $color-brand-brown
    margin-bottom 12px
  }
  &__groupName {
    font-size $font-size-xs
    margin-bottom 8px
  }
  &__items {
    display flex
    flex-wrap wrap
  }
  &__item {
    min-width 110px
    padding-right 15px

    @media $tablet {
      min-width 75px
      padding-right 10px
    }

    @media $phone {
      margin-bottom 8px
    }

    @media $verticalPhone {
      width 110px

      &:nth-child(odd) {
        flex-basis 40%
      }

      &:nth-child(even) {
        flex-basis 60%
      }
    }

    &:last-child {
      padding-right 0

      @media $verticalPhone {
        width auto
      }
    }
  }
  &__value {
    margin-bottom 2px
    line-height 1.33
    font-weight 500
    color $color-brand-green
    white-space nowrap
    font-size $font-size-l
  }
  &__valueUnit {
    font-size 10px
  }
  &__name {
    font-size $font-size-xs
    color $color-brand-brown
  }
}
