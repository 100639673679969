.container {
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer
}

.icon {
  height: 16px
}

.text {
  font-family: sans-serif Manrope;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
