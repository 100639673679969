.SignUpForm {

  &__field-group {
    display: flex
    justify-content: space-between
    margin-top: 20px

    & input {
      border-radius: 12px

      @media $verticalTablet {
        width: 200px
      }
    }
  }

  &__field {
    margin-top: 10px

    & input {
      border-radius: 12px
    }
  }

  &__checkbox-group {
    padding-top: 10px
  }

  &__checkbox {
    margin-top: 20px
    display: flex
  }

  &__footer {
    margin-top: 20px
    display: flex

    &_text {
      font-size: 12px
      padding-left: 40px
      color: rgba(189, 189, 189, 1);

      a {
        text-decoration underline
        color: rgba(189, 189, 189, 1);
        opacity 0.8

        &:hover {
          opacity 1
        }
      }
    }
  }

  &__error {
    font-size: $font-size-xs;
    line-height: 1.33;
    font-weight: 400;
    color: $color-brand-red;

    &_hidden {
      visibility: hidden
    }
  }
}
