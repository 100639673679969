.FreeSaucesPanel {
  background-color: $color-bg;

  &__navbar {
    position: sticky;
    top: 0;
    z-index: 10;
  }

  &__list {
    display: flex;
    flex-direction: column;
  }
}

.Sauce {
  display: flex;
  min-height: 80px;
  margin-bottom: 2px;
  padding: 8px 16px 8px 8px;
  border-radius: 5px;
  background-color: #fff;

  &:last-child {
    margin-bottom: 0;
  }

  &__image {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    width: 105px;
    padding-left: 10px;
    padding-right: 10px;

    img {
      max-width: 100%;
    }
  }

  &__info {
    flex-grow: 1;
    flex-basis: 100%;
    padding-top: 8px;
  }

  &__name {
    margin-bottom: 8px;
  }

  &__description {
    font-size: $font-size-xs;
  }

  &__button {
    flex-shrink: 0;
    align-self: center;

    svg {
      width: auto;
      height: 20px;
    }
  }
}
