.Ingredients {
  height: auto;
  background-color: $color-border;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 0 60px;
    @media $tablet {
      padding: 24px 0 28px;
    }

    @media $phone {
      padding: 24px 0 20px;
    }
  }

  &__container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 26px;
    column-gap: 10px

    @media $tablet {
      row-gap: 12px;
      column-gap: 6px
    }

    @media $phone {
      grid-template-columns: repeat(2, 1fr);
      row-gap: 12px;
      column-gap: 12px
    }
  }

  &__grid {

    &_1 {
      grid-column: 1 / 3;
      grid-row: 1 / 3;

      @media $phone {
        grid-column: 1 / 3;
        grid-row: 2 ;
        margin 0 -20px;
        border-radius 0 !important;
      }
    }

    &_2 {
      grid-column: 3 / 5;
      grid-row: 1 / 3;
      padding-left: 48px;
      align-self center
      @media $tablet {
        padding-left: 20px;
      }
      @media $phone {
        grid-column: 1 / 3;
        grid-row: 1 ;
        padding-left: 0px;
      }

    }

    &_3 {
    }

  }


  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__heading {
    padding-bottom: 36px
    @media $tablet {
      padding-bottom: 28px
    }
    @media $phone {
      padding-bottom: 12px
    }

    &_desktop {
      display: block

      @media $tablet {
        display: none
      }

      &-less {
        display: none

        @media $tablet {
          display: block
        }
      }
    }
  }

  &__description {
    font-weight: 400;

  }

}

.sprite {
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url(./assets/sprite.png);
  background-size: 305% 300%;
  aspect-ratio: 1.4;

  border-radius 1vw;

  @media $tablet {
    border-radius 2vw;
  }

  &__ingredients_1 {
    background-position: 0 0
    background-size: 154% 151%;
  }

  &__ingredients_2 {
    background-position: 97% 0
  }

  &__ingredients_3 {
    background-position: 0 100%
  }

  &__ingredients_4 {
    background-position: 50% 100%
  }

  &__ingredients_5 {
    background-position: 100% 100%
  }
}
