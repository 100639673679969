.Profile {
  $pad-x = 16px;
  position: relative;
  cursor: pointer;

  &__body:after, &__logout:after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 10px;
    z-index: 1;
  }

  &__logout:after {
    background-color: white;
  }

  &__body {
    padding: 10px $pad-x;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    transition: box-shadow $duration, background-color $duration;

    &:after {
      top: 100%;
      height: 4px;
    }
  }

  &__logout {
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    box-shadow: $box-shadow;
    opacity: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    visibility: hidden;
    text-align: right;
    transition: opacity $duration, visibility $duration;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: $pad-x;
      width: 'calc(100% - %s)' % ($pad-x * 2);
      height: 1px;
      background-color: #ededed;
    }

    &:after {
      bottom: 100%;
    }
  }

  &__main {
    display: flex;
    text-align: right;
  }

  &__avatar {
    width: 52px;
    height: 52px;
  }

  &__avatar-person {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 48px;
    height: 48px;
    margin-top: -(@height / 2);
    margin-left: -(@width / 2);
    overflow: hidden;
  }

  &__avatar-award {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 17px;
    height: 24px;

    img {
      display: block;
      width: 100%;
    }
  }

  &__bonus {
    margin-right: 10px;
    padding-top: 10px;
    color: $color-grey;
  }

  &__bonus-count {
    font-size: $font-size-s;
    color: $color-brand-brown;
    line-height: 1.1;
  }

  &__bonus-label {
    font-size: $font-size-xs;
  }

  &__logout-link {
    position: relative;
    display: inline-block;
    padding: 10px $pad-x 10px 28px;
    font-size: $font-size-xs;
    cursor: pointer;
    user-select: none;
    transition: color $duration;

    &:hover {
      color: $color-brand-green;
      transition-duration: $halfduration;
    }
  }

  &__logout-icon {
    position: absolute;
    top: 7px;
    left: 0;
  }

  &:hover &__body {
    box-shadow: $box-shadow;
    background-color: white;
    transition-duration: $halfduration;
  }

  &:hover &__logout {
    opacity: 1;
    visibility: visible;
    transition-duration: $halfduration;
  }
}
