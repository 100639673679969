
.AlcoholWarning {
  width: 100%;
  height: 100%;
  padding: 16px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: white;

  @media $verticalPhone {
    flex-direction: column;
    justify-content: space-around;
    padding: 16px 30px;
  }

  &__title {
    max-width: 400px;
  }

  &__button {
    margin-left: 10%;

    @media $verticalPhone {
      margin-left: 0;
    }
  }
}
