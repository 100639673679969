.ProductCardCompact {
  & {
    position: relative;
    flex-grow: 1;
    flex-basis: 100%;
    display: flex;
    min-height: 115px;
    background-color: white;
  }

  &__addContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__image {
    $width = 175px;
    $smallWidth = 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: $width;
    margin-left: -25px;
    padding-left: 2px;
    padding-right: 4px;
    flex-shrink: 0;

    @media $phoneSmall {
      width: $smallWidth;
    }

    img {
      max-width: 100%;
    }

    ^[0]_pizza & {
      width: 290px;
      margin-left: 'calc(-%s - 25px)' % (@width - $width);

      @media $phoneSmall {
        margin-left: 'calc(-%s - 25px)' % (@width - $smallWidth);
      }
    }
  }

  &__badge {
    position: relative;
    align-self: flex-end;
    height: 20px;
    margin-bottom: -(@height / 2);
    margin-right: 12px;
    padding-left: 8px;
    padding-right: @padding-left;
    background-color: $color-brand-red;
    color: white;
    font: normal 10px / 21px $font-primary;
    z-index: 1;

    &_vegan {
      background-color: $color-brand-green;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    padding: 16px 16px 16px 0;
  }

  &__name {
    margin-bottom: 8px;
  }

  &__description {
    margin-bottom: 16px;
    user-select: none;
  }

  &__meta-options {
    display: flex;
    align-items: center;
    min-height: 20px;
    margin-bottom: 10px;
  }

  &__meta-option {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }

    svg {
      display: block;
    }
  }

  &__sizes {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__price {
    flex-basis: 100%;
    flex-grow: 1;
    user-select: none;
  }

  &__button {
    flex-shrink: 0;
  }
}
