.HowToAddModal {
  padding: $phone-margin-y $phone-margin-x 32px;

  @media (min-width: 768px) and (max-width: 1279px) {
    top: auto;
    max-width: none;
    height: 256px;
    background-color: white;
    padding-top: 36px;
    padding-left: 250px;
  }

  &__header {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
  }

  &__close {
    padding-left: 20px;
    flex-shrink: 0;
  }

  &__text {
    margin-bottom: 25px;
    color: $color-brand-brown;

    @media (min-width: 768px) and (max-width: 1279px) {
      width: 270px;
    }
  }

  &__image {
    margin-bottom: 25px;
    text-align: center;

    img {
      max-width: 100%;

      @media (min-width: 768px) and (max-width: 1279px) {
        display: block;
        width: 100%;
        height: @width;
      }
    }

    @media (min-width: 768px) and (max-width: 1279px) {
      position: absolute;
      top: 32px;
      left: 88px;
      width: 123px;
      height: @width;
    }
  }

  &__button {
    width: 100%;

    @media (min-width: 768px) and (max-width: 1279px) {
      width: 280px;
    }
  }
}
