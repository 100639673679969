.EntryBlock {
  max-height: 90vh;
  min-height: 95vh;
  overflow: hidden;
  display: grid;
  grid-template-rows: 1fr 1.3fr 1fr;
  padding-top: 30px;
  gap: 15px;

  &_wrapper {
    position: relative;

  }

  &_row {
    position: absolute;
    top: 0;
    right: 0;

    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    gap: 15px;
  }

  &_image {
    flex-shrink: 0
  }
}

.sprite {
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url(../assets/sprite.png);
  height: 100%;
  border-radius 1vw;

  &__logo {
    aspect-ratio: 3.04;
    background-size: 160% 255%
    background-position: 0 100%
  }

  &__photo_1 {
    aspect-ratio: 1.84;
    background-size: 342% 330%
    background-position: -0px -0px
  }


  &__photo_2 {
    aspect-ratio: 1.62;
    background-size: 409% 330%
    background-position: 40% 0
  }

  &__photo_3 {
    aspect-ratio: 1.42;
    background-size: 443% 330%
    background-position: 71% 0
  }

  &__photo_4 {
    aspect-ratio: 0.9;
    background-size: 733% 330%
    background-position: 91% 0
  }

  &__photo_5 {
    aspect-ratio: 0.912;
    background-size: 532% 253%
    background-position: 77% 100%
  }

  &__photo_6 {
    aspect-ratio: 0.912;
    background-size: 540% 255%
    background-position: 100% 100%
  }

  &__photo_7 {
    aspect-ratio: 1.84;
    background-size: 342% 330%
    background-position: 0 43.5%
  }

  &__photo_8 {
    aspect-ratio: 1.62;
    background-size: 409% 330%
    background-position: 40% 43.5%
  }

  &__photo_9 {
    aspect-ratio: 0.9;
    background-size: 733% 330%
    background-position: 64% 43.5%
  }

  &__photo_10 {
    aspect-ratio: 1.42;
    background-size: 443% 330%
    background-position: 89.5% 43.5%
  }
}
