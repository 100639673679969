.Footer {
  padding-top: 40px;
  padding-bottom: 15px;
  background-color: #FFFFFF;

  &__body {
    margin-bottom: 35px;
  }

  &__phone {
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid $color-border;
  }

  &__row {
    width: 100%;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
      padding-top: 24px;
      border-top: solid 2px rgba(244, 244, 248, 1)
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

  }

  &__nav {
    width: 620px;
  }

  &__apps {
    display: flex;
    align-items: center

    @media $tablet {
      flex-direction: column;
    }

    &_promoGameActive {
      position: relative;
    }

  }

  &__app {
    margin-right: 15px;
    margin-bottom: 10px;
    width: 150px;
    height: 45px;
    z-index: 1;

    @media $tablet {
      margin-right: 0px

      img {
        width: 100%;
      }
    }

    &:last-child {
      margin-right: 0px;
    }

    &_promoGameActive {
      z-index: 1;
    }

  }

  &__promoGame {
    top: -45px;
    right : 15px;
  }

  &__socials {
    display: flex;
    flex-shrink: 0;
    margin: 0 40px;
    align-items: center;
    justify-content: center;
  }

  &__social {
    flex-shrink: 0;

    &:last-child {
      margin-right: 0px;
    }
  }

  &__copyright {
    flex: 1;
    font-family: $font-secondary;
    font-size: $font-size-xs;
    font-weight: 500;
    color: $color-brand-brown;

    &-line {
      display: block;
    }
  }

  &__legal {
    text-align: right;
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
  }

  &__legal-link {
    display: inline-block;
    padding: 5px 0;
    font-family: $font-secondary;
    font-size: $font-size-xs;
    font-weight: 500;
    color: $color-brand-brown;
    transition: color $duration;

    &:hover {
      color: $color-brand-green;
      transition-duration: $halfduration;
    }
  }

}
