.Warning {
  padding: 18px;
  background: #FAF0E1;
  border-radius: 15px;
  font-family: $font-primary;
  font-weight: 400;
  font-size: $font-size-s;
  line-height: 1.33;
  color: $color-brand-brown;
}
