.Error {
  width 100%

  &__image {
    height: auto;
    width: 100%;
    object-fit: cover;
  }


  &__content {
    margin-top: 25px;

  }


  &__list {
    padding-top 20px
    column-count: 4;
    column-gap: 80px;

    @media $tablet{
      column-count: 3;
      grid-column-gap: 40px;
      column-gap: 40px;
    }

    @media $phone{
      column-count: 2;
      grid-column-gap: 40px;
      column-gap: 40px;
    }

  }

  &__letterGroup {
    overflow hidden

    &-title {
      color $color-brand-green
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight 500;

      &:after {
        padding-left 5px
        content: "- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - "
      }
    }

    &-list {
      padding 5px 0 15px
    }

  }

  &__city {
    display flex
    flex-direction column
    gap 2px
    padding-bottom 10px

    &-name {
      font-weight 500
      color $color-brand-brown
    }

    &-phone {
      color $color-brand-brown

      &:hover {
        color $color-brand-green
      }
    }
  }

  &__mainCities {
    display flex
    gap 40px
    padding-top 20px

    &_city {
      position: relative;
      font-family: 'Manrope', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      font-weight: bold;
      color: $color-brand-green;
      line-height: 1.33;
      font-size: 18px;
      white-space: pre-wrap;
    }
  }

  &__other {
    padding-top 20px
  }
}


