.FootballProductCardMobile {
  position relative
  height 100%
  z-index 10

  &__content {
    height 100%
    padding 0 12px 12px
    position relative
    display flex

    flex-direction column
  }

  &__flag {
    position absolute
    top 53px
    left 0
    right 0
    bottom 0
  }

  &__score {
    flex 1
    display flex
    flex-direction column
    gap 5px
    color: white;

    &_title {
      font-family: Manrope;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
    }

    &_content {
      display flex
      align-items center
      justify-content center
      gap 8px
      font-family: Manrope;
      font-size: 40px;
      font-weight: 500;
      line-height: 48px;
      letter-spacing: 0.02em;
      text-align: center;

    }
  }

  &__cart {
    height 100%
    width 100%
    margin-top 8px
    z-index 10
    border-radius 8px

    picture {
      display none
    }

    & > div > div {
      width 100%
      padding 12px
    }
  }

  &__top {
    width 100%
    display flex
    justify-content space-between
    align-items flex-end
    z-index 10

    &_reversed {
      flex-direction row-reverse
    }
  }

  &__image{
    display flex
    border-radius 12px
    padding 7px
    background white
    max-width 50%
    min-height 130px
  }

  &__picture{
    border-radius 8px
    width 100%
    object-fit contain
  }
}

