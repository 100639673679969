.Selector {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  border-radius: 100px;
  border: solid 1px $color-border;

  &__item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    cursor: pointer;
    user-select: none;
    transition: color $duration;
    white-space: nowrap;
    color: $color-brand-brown;
    font-family: Roboto;
    font-size: 1rem;
    font-weight: 500;
    line-height: 24px;


    &_selected {
      cursor: default;
      color: white;
    }

    &:not(&_selected):hover {
      color: $color-brand-green;
      transition-duration: $halfduration;
    }
  }

  &__overlay {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $color-brand-green;
    border-radius: 100px;
    transition: transform ($duration / 1.5) $easeOutCubic;
  }
}
