.Container {
  max-width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;

  &_size {
    &_s {
      width: $container-width-s;

      @media $verticalTablet {
        width: auto;
        margin: 0 $tablet-margin-x;
      }
    }

    &_m {
      width: $container-width-m;

      @media $tablet {
        width: $container-width-s;
      }

      @media $verticalTablet {
        width: auto;
        margin: 0 $tablet-margin-x;
      }
    }

    &_l {
      width: 1640px;
      padding-inline 20px

      @media $tablet {
        width: auto;
        padding-inline 0;
        margin: 0 $tablet-margin-x;
      }


    }
  }
}
