.SelectField {
  $height = $inputHeight;
  $input-border-radius = 2px;
  $color = $color-brand-brown;
  position: relative;

  &_disabled {
    pointer-events: none;
    opacity: 0.75;
  }

  &__label {
    display: block;
    max-width: 100%;
    min-height: 19px;
    margin-bottom: 5px;
  }

  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  &__field {
    flex: 1 1 100%;
  }

  &__arrow {
    position: absolute;
    height: 0;
    width: 0;
    right: 22px;
    top: 50%;
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 2.5px;
    border-color: #333 transparent transparent;
    display: inline-block;
  }

  &__control {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: $height;
    padding: 0 16px;
    border: none;
    border-radius: $input-border-radius;
    background-color: $color-bg;
    font-size: $font-size-m;
    color: $color;
    placeholder($color-grey);
    transition: background-color $duration;
  }

  &__placeholder {
    placeholder($color-grey);
    font-family: $font-primary;
    font-size: $font-size-s;
    font-weight: 400;
    line-height: 1.33;
  }
}
