.Advent {
  padding-top: 60px;

  &_demo {
    filter: blur(10px)
  }

  &_pageTitle {
    padding-bottom: 25px;
    text-align: center;
  }

  &_mainGrid {
    display: grid;
    grid-template-columns 7fr 3fr;
    @media $tablet {
      display: block;
    }
  }

  &_Selector {
    margin-bottom: 50px;
    background-color: #fff;
  }

  &_desktop_decor {
    margin: auto;
    transform: translateY(10px);
    @media $tablet {
      display: none;
    }
    &_hidden {
      display: none;
    }
  }

  &_Modal {
    padding 20px 24px 32px
    background-color white
    border-radius 15px
    @media $phone {
      border-bottom-left-radius: 0
      border-bottom-right-radius: 0
    }
  }
}
