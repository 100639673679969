.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: left;
  position: relative;
}

.box {
  width: 480px;
  height: 100px;
  padding: 10px 45.5px 18px 227.5px;
  gap: 0;
  border-radius: 16px;
  background: #281E3F80;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
}

.box_mobile {
  width: 100%;
  height: 100px;
  padding: 12px 0 12px 180px;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.02em;
}

.box_tablet {
  width: 100%;
  height: 100px;
  padding: 24px 0 12px 260px;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.02em;
}

.img {
  position: absolute;
  top: -20px;
  left: -60px;
}

.img_mobile {
  width: 180px;
  height: 100px;
  top: 0;
  left: -20px;
}

.img_tablet {
  width: 240px;
  height: 160px;
  top: -20px;
  left: 0;
}

.green {
  color: #66F8BE;
}

.add {
  width: 480px;
  padding-left: 130px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
}

.add_mobile {
  width: 100%
  padding: 0;
  padding-top: 12px;
  padding-bottom: 24px;
}


.add_tablet {
  width: 100%
  padding: 0;
  padding-top: 12px;
  padding-bottom: 24px;
}
@media (max-width: 360px) {
  .img_mobile {
    width: 140px;  /* Меньшая ширина для изображения */
  }

  .box_mobile {
    font-size: 10px;
    padding: 12px 0 12px 140px; /* Измененный padding */
  }
}
