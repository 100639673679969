.MainSlider {
  width: 100%;
  padding: 20px 0;
  margin-bottom: 20px;
  position: relative;

  &__item {
    cursor: pointer;
    //position: relative;
    &-wrapper {
      position : relative;
    }

    &-img {
      width: 100%;
      height: 100%;
      opacity: 0.5;
      transition: opacity $duration;

      &_active {
        opacity: 1;
      }
    }

    &-caption {
      position: absolute;
      left : 0;
      bottom : 0;
      margin : 15px;
      border-radius : 15px;
      padding : 6px 10px;
      background-color: rgba(255, 255, 255, 0.5);
      font-size: 10px;
    }
  }

  &__preloader {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__spinner {
    spinner(48px, 3px, $color-brand-green);
  }

  &_phone {
    .MainSlider__item {
      height: calc((100vw * 455 / 750));
    }

    .MainSlider__preloader {
      height: calc((100vw * 455 / 750));
    }
  }

  &_tablet {
    .MainSlider__item {
      height: calc((100vw * 540 / 1440));
    }

    .MainSlider__preloader {
      height: calc((100vw * 540 / 1440));
    }
  }

  &_desktop {
    .MainSlider__item {
      height: calc((100vw * 540 / 1440 / 1.75));
      min-height: 'calc(%s * 540 / 1440 / 1.75)' % $min-desktop-width;
    }

    .MainSlider__item-img {
      border-radius: $border-radius;
    }

    .MainSlider__preloader {
      height: calc((100vw * 540 / 1440 / 1.75));
      min-height: 'calc(%s * 540 / 1440 / 1.75)' % $min-desktop-width;
    }
  }

  &__MovieDecor_filter {
    top: -81px;
    left: 23%;
    transform: scaleX(-1)
    width: 192px;
    clip-path: polygon(0 0, 100% 0%, 100% 50%, 0 50%)
    animation: decor 8s ease-in-out;
    z-index 2000 !important;

    @keyframes decor {
      0% { opacity:0; }
      99% { opacity:0; }
      100% { opacity:1; }
    }

    @media $tablet {
      animation: none
      width 121px
      top: -43px;
    }

    @media $phone {
      transform: unset
      left unset
      right: 15%;
    }

  }

  &__PaddingtonDecor {
    top: 0;
    transform: translateY(-70%);
    z-index: 1005;
    left: 30%;
    height: 100px;
    clip-path: polygon(0% 0, 100% 0%, 100% 91%, 0 91%);
    @media $tablet {
      height: 62px;
      top: -36px;
      transform: unset;
      left: 15%;
    }

    @media $phone {
      height: 43px;
      top -19px
      left 50px
    }
  }
}
