.Wallet {
  position relative

  display flex
  flex-direction column
  justify-content center

  padding 16px
  border-radius: 12px;
  background-color: #A9DEE8;
  background-image url("../../assets/wallet_bg.png")

  @media $phone{
    padding 16px 16px 12px
  }

  &__title {

    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: center;
    color: $color-brand-brown;
  }

  &__wrapper {
    display flex
    flex-direction column
    align-items center
    gap 24px

    margin-top 24px

    @media $phone{
      flex-direction row
    }
  }

  &__collected {
    display flex
    justify-content space-between
    align-items center
    gap 8px


    &_decor {
      width 35px

    }

    &_x {

    }

    &_value {
      font-size: 40px;
      font-weight: 800;
      line-height: 44px;
      letter-spacing: 0.02em;
      color: #D03B3B;
    }
  }

  &__link {
    width 100%
    transition opacity $duration

    &_hidden {
      pointer-events none
      opacity 0
    }

    &_button {
      width 100%

      &:hover {
        &:before {
          transform none!important
        }
      }
    }
  }
}
