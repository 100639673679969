.AddCardFieldGroup {
  display: flex;
  flex-direction: column;

  &__row {
    display: flex;
  }

  &__field {
    flex: 1;

    &:first-child {
      margin-bottom: 15px;
    }

    &_left {
      margin-right: 15px;
    }
  }

  &__error {
    margin-top: 5px;
    color: $color-brand-red;
  }
}
