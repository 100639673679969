.InputField {
  &__textarea{
    overflow-x: hidden;
    white-space: nowrap;
  }

  &_disabled {
    pointer-events: none;
    opacity: 0.75;
  }

  &__label {
    desktopinputlabel();
  }

  &__field {
    desktopinputfield();
  }

  &_error {
    .InputField__label {
      color: $color-brand-red;
    }
  }

  &__textBlock{
    justify-content: flex-start;
  }

  &__textInsideBlock{
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    justify-content: flex-end;

    &_placeholder{
      color: #bfb7b6;
    }
  }
}
