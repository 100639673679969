.UnAuthorized {

  display flex
  flex-direction column
  align-items center
  justify-content center
  gap 16px
  &__logo {
    transform translateY(-50%)
    width 268px
  }
  &__text {}
  &__login {}
}
