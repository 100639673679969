.HalvesPage {
  padding: 60px 0 50px;
  //background: url("../../icons/vk/vkBg2.png") repeat center center / cover;

  @media $phone {
    padding: 0;
  }

  //@media $verticalTablet {
  //  padding: 0;
  //  background: url("../../icons/vk/vkBgTablet3.png") repeat center center / cover;
  //}
  //@media $phone {
  //  padding: 0;
  //  background: url("../../icons/vk/vkBgMobile.png") repeat center center / cover;
  //}
}
