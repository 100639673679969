.OrderCart {
  padding: $checkout-mobile-block-padding;
  padding-top: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 0px 0px 25px 25px;

  &__list {
    margin-bottom: $checkout-mobile-margin-15;
    display: flex;
    flex-direction: column;
  }

  &__item {
    padding: $checkout-mobile-margin-15 0;

    &:not(:last-child) {
      border-bottom: 3px solid $color-bg;
    }

    &:last-child {
      padding-bottom: 0px;
    }
  }

  &__promocode {
    width: 220px;
    margin-top: $checkout-mobile-margin-15;
    align-self: center;
  }

  &__sale {
    text-align: center;
    font-family: $font-secondary;
    color: $color-brand-brown;
    font-weight: 500;
    line-height: 1.33;
    font-size: $font-size-xs;
  }

  &__preloader {
    width: 100%;
    padding: $checkout-mobile-block-padding;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__spinner {
    spinner(48px, 3px, $color-brand-green);
  }
}
