.InputField {

  &__standart{
    direction: ltr!important;
    text-align: left!important;
  }


  &_disabled {
    pointer-events: none;
    opacity: 0.75;
  }

  &__label {
    desktopinputlabel();
  }

  &__field {
    desktopinputfield();
  }

  &_error {
    .InputField__label {
      color: $color-brand-red;
    }
  }
}
