.Sidebar {
  &__section {
    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      padding-bottom: 0;
    }
  }

  &__header {
    @media $verticalTablet {
      margin-bottom: 32px;
      display: flex;
      align-items: flex-start;
    }

    &-group {
      @media $verticalTablet {
        flex-grow: 1;
        flex-basis: 100%;
      }
    }
  }

  &__avatar {
    margin: -98px auto 28px;

    @media $tablet {
      margin: -69px auto 20px;
    }

    @media $verticalTablet {
      margin: 0 32px 0 16px;
      flex-shrink: 0;
    }
  }

  &__field > label, &__field-label {
    font-size: $font-size-xs;
  }

  &__field {
    &-content, & input, & textarea, & :global(.react-tel-input input[type='tel']), & :global(.react-date-picker__button), & :global(.Select-placeholder), & :global(.Select-control) {
      font-family: $font-secondary;
      font-size: $font-size-s;
      font-weight: 500;
    }

    & input:not(:global(.Select-input)), & textarea, & :global(.react-tel-input input[type='tel']), & :global(.react-date-picker), & :global(.Select) {
      width: calc(100% + 16px * 2);
      margin: 0 -16px;

      & + :global(.flag-dropdown) {
        display: none;
      }
    }

    & :global(.react-tel-input .flag-dropdown) {
      display: none;
    }

    & :global(.react-tel-input input[type='tel']) {
      padding-left: 16px;
    }

    &-name {
      & input {
        height: 48px;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;

        @media $verticalTablet {
          height: 34px;
        }
      }

      &-content {
        width: 100%;
        height: auto;
        min-height: 48px;
        justify-content: center;
        align-items: center;
        position: static;
        line-height: 1.4;
        word-wrap: break-word;
        white-space: normal;

        @media $verticalTablet {
          min-height: 34px;
        }
      }

      &-content, & input {
        justify-content: center;
        font-family: $font-secondary;
        font-size: $font-size-l;
        font-weight: 500;
        text-align: center;

        @media $verticalTablet {
          justify-content: flex-start;
          font-size: $font-size-l;
          text-align: left;
        }
      }
    }
  }

  &__status {
    padding: 24px 0;
    border-top: 1px solid #e6e2e1;
    border-bottom: @border-top;
    text-align: center;

    @media $verticalTablet {
      padding: 0;
      border: none;
      text-align: left;
    }

    &-caption {
      margin-bottom: 8px;
      font-size: $font-size-xs;
    }

    &-value, &-tooltip {
      display: inline-block;
      color: $color-brand-green;
    }

    &-value {
      margin-left: 4px;
    }

    &-tooltip {
      margin-left: 6px;
      vertical-align: text-bottom;
    }

    &-cashback {
      font-size: $font-size-xs;
    }
  }

  &__toggle-button {
    padding: 16px 0 20px;
    text-align: center;
    transition: color $duration;

    @media $verticalTablet {
      border-top: 1px solid #e6e2e1;
    }

    &_active {
      color: $color-brand-green;
    }

    svg {
      margin-left: 8px;
      vertical-align: middle;
    }
  }
}

.ExpireInformation {
  display: flex;
  width: 280px;
  height: 32px;
  margin-top: 16px;

  &__icon {
    margin-left: 17px;
    margin-top: 5px;
  }

  &__text {
    margin-left: 17px;
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: rgba(112, 84, 79, 0.9);

    &_superbonuses {
      color: #F2994A;
    }

    &_papabonuses {
      color: #50A684;
    }

    &_expire {
      color: red;
    }

    &_date {
      color: red;
      text-decoration-line: underline;
    }
  }
}

.StatusesInfoItem {
  padding: 15px 0;
  display: flex;
  border-top: 2px solid #EBEEF5;

  &:last-child {
    padding-bottom: 0;
  }

  &__list {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }

  &__award {
    margin-right: 15px;
    flex-shrink: 0;
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__name {
    padding-top: 5px;
    margin-bottom: 10px;
    font-family: $font-secondary;
    font-weight: 500;
    font-size: $font-size-l;
    line-height: 1;
  }

  &__description {
    font-family: $font-primary;
    font-weight: 400;
    font-size: $font-size-m;
    line-height: 1.33;
    color: $color-brand-brown;
    opacity: 0.8;
  }

  &__item-description {
    position: relative;
  }
}

.Loyalty__name, .StatusesInfo__name, .StatusesInfoItem__name {
  &_silver {
    color: #B5BAC6;
  }

  &_gold {
    color: #FDB515;
  }

  &_platinum {
    color: #9EA8BA;
  }

  &_ultimate {
    color: #B9A5FF;
  }
}
