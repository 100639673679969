.CovidField {
  display: flex;
  flex-direction: column;

  &__title {
    margin-bottom: $checkout-desktop-margin-25;
  }

  &__wrapper {
    width: 307px;
    padding: 22px 16px 22px 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FAF0E1;
    border-radius: $border-radius;
  }

  &__button {
    position: relative;
    color: $color-grey;
    transition: color $duration;

    svg {
      width: 18px;
      height: auto;
      vertical-align: middle;
    }

    &:hover {
      color: #807573;
    }
  }

  &__tooltip {
    paper();
    width: 361px;
    padding: 20px;
    margin-bottom: $checkout-desktop-margin-20;
    position: absolute;
    bottom: 100%;
    left: -13px;
    opacity: 0;
    transition: opacity $duration;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    color: $color-brand-brown;
    font-family: $font-primary;
    font-weight: 400;
    line-height: 1.33;
    text-align: left;

    &:after {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      top: 100%;
      left: 16px;
      border-style: solid;
      border-width: 6px 6px 0 6px;
      border-color: white transparent transparent transparent;
    }

    &_entered {
      opacity: 1;
    }
  }
}
