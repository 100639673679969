.Characteristics__halloween {
  .Characteristics {
    &__icon {
      color : $colorHW-characteristics-color;
      background-color :$colorHW-characteristics-bg;
    }
  }
}

.Characteristics__new-year {
  .Characteristics {
    &__icon {
      color : $colorNY-characteristics-color;
      background-color :$colorNY-characteristics-bg;
    }
  }
}

.Characteristics {
  width: 18px;
  height: 18px;
  position: relative;
  flex-shrink: 0;

  &__icon {
    width: 100%;
    height: 100%;
    color: $color-grey;
    border-radius : 50%;
    cursor: pointer;
    user-select: none;
    transition: color $duration;

    &:after {
      content: '';
      position: absolute;
      top: -9px;
      right: @top;
      bottom: @top;
      left: @top;
    }

    &:hover {
      color: #807573;
      transition-duration: $halfduration;
    }

    svg {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  &__tooltip {
    width: 248px;
    padding: 16px;
    margin-bottom: 13px;
    position: absolute;
    bottom: 100%;
    left: -13px;
    border-radius: $border-radius;
    background-color: $color-brand-brown;
    color: white;
    z-index: 1000;
    opacity: 0;
    transition: opacity ($duration / 2);

    @media $tablet {
      right: @left;
      left: auto;
    }

    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 16px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 6px 0 6px;
      border-color: $color-brand-brown transparent transparent transparent;

      @media $tablet {
        right: @left;
        left: auto;
      }
    }

    &_entered {
      opacity: 1;
    }

    &-title {
      margin-bottom: 10px;
      color: white;
    }
  }
}
