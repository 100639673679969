.Preloader {
  & {
    fullsize()
    background-color: rgba(255, 255, 255, 0.8)
  }

  &__spinner {
    spinner(48px, 3px, $color-brand-green);
    position: absolute
    top: 50%
    left: 50%
    margin-top: -24px
    margin-left: -24px
  }
}
