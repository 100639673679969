.Games {
  height: auto;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 0 148px;
    @media $tablet {
      padding: 28px 0 36px;
    }

    @media $phone {
      padding: 20px 0 28px;
    }
  }

  &__description {
    white-space: break-spaces;
    text-align center;
    padding-top: 32px;
    @media $tablet {
      padding-top: 16px;
    }

    @media $phone {
      padding-top: 8px;
    }
  }

  &__links {
    display: flex;
    justify-content center
    width 100%
    padding-top: 52px;
    //gap 92px
    gap 20px
    flex-wrap wrap
    @media $tablet {
      //justify-content space-between
      padding-top: 20px;
      //gap 47px
    }
    @media $phone {
      //gap 28px
    }


    &_item {
      position relative
      overflow hidden
      display flex
      align-items center
      justify-content center
      width 18%
      max-width 29%
      aspect-ratio 1
      background-color $color-brand-green-dark
      border-radius 50%

      @media $tablet {
        width 24%
      }

      @media $phone {
        width 30%
      }


      &:hover > a {
        z-index 5
      }

      &:hover > .overlay {
        background-color: rgba(46, 44, 44, 0.8)
      }


      &-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0);
        transition: all .5s

      }

      &-image {
        width 100%
        height 100%
        object-fit scale-down
      }

      &-button {
        z-index -5
        position absolute
      }
    }

  }

}
