
.FeedbackForm {
  margin: 0;
  width: 100%;

  &__title {
    margin-bottom: 20px;
    font-size: 28px;
    text-align: center;

    @media $phone {
      margin-bottom: 16px;
      font-size: $font-size-l;
    }
  }

  &__step {
    width: 100%;
    display: none;
    flex-direction: column;

    &_visible {
      display: flex;
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  &__column {
    width: 100%;
  }

  &__row {
    width: 100%;
    margin-bottom: 20px;
  }

  &__error {
    font-family: $font-primary;
    font-size: $font-size-xs;
    line-height: 1.33;
    font-weight: 400;
    color: $color-brand-red;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media $phone {
      flex-direction: column;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
  }

  &__back {
    margin-right: 40px;
  }

  &__image-loader {
    @media $phone {
      margin: 20px 0;
      align-self: flex-start;
    }
  }

  &__privacy {
    @media $phone {
      margin-bottom: 20px;
      text-align: center;
    }
  }

  &__custom-field {

    div:last-child {
      border-radius: 12px;
      overflow: hidden;
      @media $phone {
        border-radius: 0;
      }
    }

    label {
      margin-bottom: 15px;
      @media $phone{
        margin-bottom: 5px;
      }
    }

    &--textarea {
      textarea:last-child {
        border-radius: 12px;
        overflow: hidden;
        @media $phone {
          border-radius: 0;
        }
      }

      label {
        margin-bottom: 15px;
        @media $phone{
          margin-bottom: 5px;
        }
      }
    }

  }
}
