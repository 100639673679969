.CityPoints {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 21px;

  &__text {
    font-family: $font-secondary;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: rgba(112, 84, 79, 0.9);

    &_bonuses {
      color: #50A684;
    }
  }

  &__activate {
    font-family: $font-secondary;
    margin-top: 3px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-decoration-line: underline;
    color: #50A684;
    cursor: pointer;
  }
}

.ModalCityPoints {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 333px;
  background-color: white
  border-radius: 25px;

  &__title {
    font-family: $font-secondary;
    font-weight: 700;
    font-size: 18px;
    line-height: 17px;
    text-align: center;
    color: #70544F;
    margin-top: 40px;
    margin-bottom: 30px;
  }

  &__button {
    margin: 22px 40px 40px 40px;
    width: 253px;
    height: 51px;
    background: #50A684;
    border: 1px solid #50A684;
    box-sizing: border-box;
    border-radius: 25px;
    font-family: $font-primary;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #FFFFFF;

    &_disabled {
      background: #BFB7B6;
      border: 1px solid #BFB7B6;
    }
  }
}

