.BankCardList {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #FFFFFF;
  &__sms {
    margin-top: 15px;
  }
}
