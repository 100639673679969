.CalendarDescription {
  position: relative;

  &_text {
    display: block;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #70544F;
    padding-bottom: 16px;

    &:last-child {
      padding-bottom: 60px;

    }
  }

  &_bg_1 {
    position: absolute;
    bottom: -30px;
    left: -230px
    margin-right: 50px;
    margin-bottom: 10px;
    width: 200px
    height: 120px;
    float: left;
    background-image: url('../../assets/common/pizza_2.svg')
    background-size: cover;
    background-repeat: no-repeat;

    @media $tablet {
      position: relative;
      margin-right: 30px;
      bottom: unset;
      left: unset
    }

    @media $phone {
      width: 106px
      height: 64px;
    }

  }

  &_bg_2 {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-left: 30px;
    margin-top: 10px;
    width: 320px
    height: 150px;
    float: right;
    background-image: url("../../assets/common/pizza.svg")
    background-size: cover;
    background-repeat: no-repeat;

    @media $tablet {
      position: relative;
      width: 250px
      height: 160px;
    }

    @media $phone {
      width: 175px
      height: 114px;
    }
  }

}
