.Composition {
  &__info {
    border-bottom 1px solid #f5f5f5

    @media $phone {
      padding 24px $phone-margin-x $phone-margin-y
    }

    .Composition_tablet & {
      background-color white
      padding 32px
      margin-bottom 48px
    }
  }

  &__title {
    margin-bottom 15px

    .Composition_tablet & {
      font-size $font-size-xxl
      line-height 32px
      text-align center
      margin-bottom 40px
    }
  }

  &__variation {
    display flex
    justify-content space-between
    align-items flex-end
    padding 8px 0
    font-size $font-size-xs
    color $color-brand-brown

    @media $phone {
      flex-direction column
      justify-content flex-start
      align-items flex-start
      padding 0
    }

    .Composition_tablet & {
      padding 0
    }
  }

  &__variation-info {
    margin-right 20px

    @media $phone {
      margin-right 0
      margin-bottom 10px
      font-size $font-size-m
    }

    .Composition_tablet & {
      font-size $font-size-m
      line-height 24px
    }
  }

  &__variation-price {
    flex-shrink 0

    .Composition_tablet & {
      font-size $font-size-l
      line-height 24px
    }
  }

  &__ingredients-title {
    display none

    @media $phone {
      display block
      padding 30px $phone-margin-x 5px
    }

    &_tablet {
      font-size $font-size-l
      line-height 24px
      padding-left 32px
      margin-bottom 16px

      @media $tablet {
        display block
      }
    }
  }

}
