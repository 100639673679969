.Chat {
  width: 350px;
  height: 500px;
  position: fixed;
  bottom: 40px;
  right: 40px;
  box-shadow: $box-shadow;
  border-radius: 20px;
  background-color: rgba(244, 244, 248, 1);
  display: flex;
  flex-direction: column;
  z-index: 9000;

  &_hidden {
    display: none;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    color: rgba(255, 255, 255, 1)
    padding-left: 25px
    padding-right: 15px
    height: 46px;
    background-color: rgba(80, 166, 132, 1);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  &__buttons {
    cursor: pointer;
  }

  &__spinner-container {
    position: absolute;
    height: calc(100% - 46px - 65px);
    width: 100%;
    top: 46px;
    display: flex
    justify-content: center;
    align-items: center;
  }

  &__spinner {
    spinner(48px, 3px, $color-brand-green);
  }

  &__messages {
    overflow-y: scroll;
    height: calc(100% - 46px - 65px);

    &_hidden {
      overflow-y: scroll;
      opacity: 0;
      height: calc(100% - 46px - 65px);
    }

    scrollbar-width: thin;
    scrollbar-color: rgba(244, 244, 248, 1);

    &::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(244, 244, 248, 1);
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 1);
      border-radius: 2px;
    }
  }

  &__input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    width: 320px
    height: 50px;
    background-color: rgba(255, 255, 255, 1);
    margin: 15px;
    padding-right: 15px;
    padding-left: 20px;
    position: relative;

    input {
      height: 100%;
      width: calc(100% - 65px);
      border: none;
      background: transparent;
    }
  }

  &__file-input {

    & input {
      display: none;
    }
  }

  &__image-viewer {
    background-color: rgba(0, 0, 0, 0.400);
    width: 100%;
    height: 100%;
    overflow: auto;
    margin: auto;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10010;
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
      max-width: 95%;
      max-height: 95%;
    }
  }

  &__preloader {
    margin-left: 15px
  }
}
