.CityPointsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.CityPoints {
  &__item {
    padding: 0 16px;
  }

  &__text {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: rgba(112, 84, 79, 0.9);

    &_bonus {
      color: #50A684;
    }
  }
}

.ModalCity {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #FFFFFF

  &__shadow {
    position: absolute;
    left: 0;
    right: 0;
    top: 91.4%;
    bottom: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 36.64%);
    z-index: 3000;
  }

  &__modal-icon {
    margin: 0 auto;
    height: 6px;
    border-radius: 3px;
    background: #fff;
    width: 70px;
    position: relative;
    top: -15px;
  }

  &__header {
    margin-top: 30px;
    margin-bottom: 24px;
    display: block;
    text-align: center;
    font-family: $font-secondary;
    font-weight: 400;
    font-size: 18px;
    line-height: 17px;
    color: #70544F;
  }


}
