.FirstScene {
  background-color: white;

  &__body {
    padding: 24px $phone-margin-x 0;
  }

  &__title {
    margin-bottom: 16px;
    text-align: center;
  }

  &__navbar {
    .FirstScene_tablet & {
      display: none;

      @media $phone {
        display: flex;
      }
    }
  }

  &__pizza {
    max-width: 335px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }

  &__footer {
    padding: 24px $phone-margin-x;
    text-align: center;
  }

  &__button {
    width: 100%;
    max-width: 335px;
  }
}
