.CheckLink {
  position: relative;
  display: flex;
  padding: 5px 15px 5px 30px;
  border-radius: 1px;
  color: $color-brand-brown;
  line-height: 1.33;
  user-select: none;
  transition: background-color $duration, color $duration;

  @media $phone {
    padding: 10px 20px 10px 40px;
  }

  &__icon {
    top: 5px;
    left: 10px;
    opacity: 0;
    transition: opacity $duration;

    @media $phone {
      top: 7px;
      right: 20px;
      left: auto;
    }

    svg {
      width: 16px;
      height: 16px;
      display: block;

      @media $phone {
        width: 24px;
        height: 24px;
      }
    }

    &_selected {
      opacity: 1;
    }
  }

  &:not(&_selected):not(&_removed) {
    cursor: pointer;

    &:hover {
      background-color: $color-bg;
      transition-duration: $halfduration;
    }
  }

  &:not(&_selected):not(&_removed):hover &__icon {
    opacity: 1;
    transition-duration: $halfduration;
  }

  &_selected {
    color: $color-brand-green;

    @media $phone {
      background-color: $color-brand-green-translucent;
    }
  }

  &_removed {
    text-decoration: line-through;
    color: #e6e2e1;
    cursor: default;
  }
}
