.BorderedFrame {
  backdrop-filter: blur(40px)
  border: 6px solid #FFFFFF
  border-radius 20px
  background #FEBFD7

  color #620D2F !important

  * {
    color #620D2F !important
  }
}

.CardsGrid {
}

.Valentine25Link {
  width 70px
  height 70px
  background url("../assets/link.png") no-repeat center center
}

.Heart {
  width 100%
  aspect-ratio 1
  display flex
  justify-content center
  align-items center
  background: linear-gradient(294.34deg, #4430FB 39.47%, #5CB6FF 83.95%);
  border: 6px solid #7cb2de
  border-radius 20px

  @media $tablet {
    max-width 285px
  }

  @media $phone {
    aspect-ratio unset
    height 200px
  }

  &__inner {
    height 70%
    position relative
    aspect-ratio 1.17

    //@media $tablet {
    //  height 90%
    //}


    &:before {
      position absolute
      left 0
      top 0
      bottom 0
      right 50%
      content ''
      background var(--left)
      background-size: contain;
      background-repeat: no-repeat;
      transform translateX(11%)
    }

    &:after {
      position: absolute;
      right: 0;
      top: 0;
      bottom 0
      left 50%
      content ''
      background: var(--right);
      background-size: contain;
      background-repeat: no-repeat;
      transform translateX(-11%)
    }

    &_full {
      &:before {
        right 0
        transform unset
        background: var(--full);
        background-size: contain;
        background-repeat: no-repeat;
      }

      &:after {
        content none
      }
    }
  }
}
