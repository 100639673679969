.Advent {
  padding 32px 0 160px
  min-height calc(100vh - 80px)

  &_hidden {
    filter blur(10px)
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 32.68px;
    text-align: center;
    color white
  }

  &__grid {
    margin-top 14px
    display grid
    grid-template-columns 1fr 192px
    gap 12px
  }

  &__container {
    display flex
    flex-direction column
    gap 12px
  }

  &__Collage {
    margin-right -12px
  }

  &__floor {
    width 100vw
    height 100%
    background-repeat no-repeat
    background-position top center
    position absolute
    background url("../components/Collage/assets/floor.png")
    background-position center
    left : var(--offset)
  }

}
