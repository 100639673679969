.Advent {
  padding 20px 0 24px
  display flex
  flex-direction column
  gap 12px

  &_hidden {
    filter blur(10px)
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color white
  }

  &__Rules {
    margin-top 6px
  }

  &__Tasks {
    grid-template-columns repeat(5, minmax(40px, 48px)) !important
  }

}
