.ModalPhone {
  padding: 0 20px;
  position: fixed;
  z-index: 1000;
  bottom: 0;
  width: 100%;
  height: 105px;
  display: flex;
  align-items: center;
}
