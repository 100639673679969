.ModalMapView {
  width: 100%;
  height: auto;
  background white;

  &__text-address {
    position relative
    padding-left: 30px
    padding-top:20px!important;
    padding-bottom: 19px!important;
    border-radius: 35px;
    width: 100%;
    height: 56px;
    font-size 16px;
    font-weight 400;
    font-size 16px
    color black


    &_big {
      width: 100%;
      transition: .2s ease-in-out;
    }

    &_not-margin {
      margin-top: 16px;
      z-index: 5000;
    }
  }

  &__address-block {
    width: 100%;
  }

  &__map {
    display: block;
    margin: 24px auto 0;
    width: calc(100% - 32px);
    height: 300px;
    border-radius: 35px;
    filter: drop-shadow(0px 0px 15px rgba(32, 38, 55, 0.15));

    & > div > ymaps {
      border-radius 35px;
      overflow hidden
    }
  }

  &__address-mini-block {
    margin-top: 16px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
  }

  &__text-mini {
    width: 47%;
    height: 56px

    &_padding-left {
      padding-left: 30px;
      border-radius: 35px;
      height: 56px
      font-size 16px
      color black
    }

    &_padding-flat{
      padding-left:57px
    }

    &_padding-floor{
      padding-left:48px
    }

    &_padding-intercom{
      padding-left:82px
    }

    &_padding-porch{
      padding-left:67px
    }


  }

  &__address-button {
    font-family: Manrope
    margin-top: 16px;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #50A684;

    &-icon {
      margin-left: 10px

      svg {
        width: 10px;
        height: 8px;
      }
    }

    &-icon {
      margin-left: 10px;
      transition: .2s ease-in-out;

      svg {
        width: 10px;
        height: 8px;
      }

      &_open {
        transform: rotate(-90deg);
      }
    }
  }

  &__button {
    margin: 20px 0 16px;
    width: 100%;
    height: 56px;
    max-height 56px;
    background: #BFB7B6;
    border-radius: 35px;

    &_open {
      background: #50A684;
    }

    &-wrapper {
      background: white
      position: -webkit-sticky;
      z-index 3000
      width: 100%;
      bottom: 0;
      border-radius: 25px 25px 0 0
      box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.05);
    }
  }

  &__padding {
    padding: 0 16px;
    position: sticky;
  }

  &__hidden-block {
    height: 100px;
  }

}

@media (max-height: 650px) {
  .ModalMapView {
    &__map {
      display: none;
    }
  }
}
