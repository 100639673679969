.ProductCardModal {
  paper();
  width: 700px;
  display: flex;
  justify-content: space-between;

  &__left {
    width: 45%;
    display: flex;
    flex-direction: column;
    border-right: 3px solid $color-bg;
  }

  &__top {
    flex: 1;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    img {
      width: 100%;
      height: auto;
      border-radius: $border-radius;
    }
  }

  &__badge {
    position: absolute;
    left: 21px;
    top: 21px;
    text-transform: uppercase;
    background: #F3D03E;
    padding: 3px 9px;
    box-shadow: 0px 0px 15px rgba(32, 38, 55, 0.15);
    border-radius: 20px;
    color: white;
    font-family: $font-primary;
    font-weight: 500;
    font-size: $font-size-xs;
    line-height: 1;
  }

  &__options {
    position: absolute;
    right: 21px;
    top: 24px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    & > * {
      margin-bottom: 10px;
    }
  }

  &__bottom {
    padding: 25px 40px;
    border-top: 3px solid $color-bg;
    flex-shrink: 0;
  }

  &__100g {
    margin-bottom: 10px;
    font-family: $font-secondary;
    font-weight: 500;
    font-size: $font-size-s;
    line-height: 1.33;
    color: $color-brand-brown;
    opacity: 0.7;
  }

  &__p {
    font-family: $font-secondary;
    font-weight: 400;
    font-size: $font-size-s;
    line-height: 1.33;
    color: $color-brand-brown;
    opacity: 0.7;

    &_bold {
      font-weight: 500;
    }

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  //

  &__right {
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__heading {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  &__subheading {
    font-family: $font-secondary;
    font-weight: 400;
    font-size: $font-size-m;
    line-height: 1.33;
    color: $color-brand-brown;
    opacity: 0.8;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__selector {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__section {
    max-height: 500px;
    padding: 40px 40px 25px 40px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__footer {
    padding: 25px 40px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px -3px 10px rgba(53, 53, 53, 0.08);
  }

  &__add {
    height: auto;
    align-self: flex-start;
    font-weight: 500;
    font-family: $font-secondary;
    color: $color-brand-green;
    transition: $halfduration;

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    &:hover {
      color: $color-brand-green-hover;
    }

    &-icon {
      width: 16px;
      height: 16px;
      margin-right: 10px;
      position: relative;
      border-radius: 50%;
      background-color: currentColor;

      &:before, &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: white;
      }

      &:before {
        width: 8px;
        height: 2px;
        margin-top: -(@height / 2);
        margin-left: -(@width / 2);
      }

      &:after {
        width: 2px;
        height: 8px;
        margin-top: -(@height / 2);
        margin-left: -(@width / 2);
      }
    }
  }

  &__removed {

    display: flex;
    flex-wrap: wrap;
    margin: -2% 0 0 -2%;


    &-heading {
      margin-bottom: 15px;
      align-self: flex-start;
      display: flex;
      align-items: center;
      font-family: $font-secondary;
      font-weight: 500;
      font-size: $font-size-xs;
      line-height: 1;
      color: $color-brand-green;
    }

    &-ingredient {
      position: relative;
      width: 31%;
      margin: 2% 0 0 2%;
      flex-shrink: 0;
      padding: 10px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      background: $color-grey;
      box-shadow: 0px 0px 5px rgba(32, 38, 55, 0.08);
      border-radius: 15px;
      transition: background-color $halfduration;

      &_halal {
        position: absolute;
        right: 4px;
        top: 4px;
      }

      &_removed {
        background-color: $color-brand-red;
      }

      &_required {
        background-color: $color-brand-green;
      }

    }

    &-img {
      margin-bottom: 10px;
      padding: 5px 0;
      background-color: white;
      box-shadow: 0px 0px 10px rgba(32, 38, 55, 0.1);
      border-radius: 15px;

      img {
        width: 60px;
        height: 60px;
        border-radius: inherit;
      }
    }

    &-name {
      margin-bottom: 8px;
      font-family: $font-secondary;
      font-weight: 700;
      font-size: $font-size-xs;
      line-height: 1;
      text-align: center;
      color: white;

      &:first-letter {
        text-transform: capitalize;
      }
    }
  }
}
