.Form {
  display flex

  flex-direction column
  max-width 90vw
  width 828px

  @media $tablet {
    max-width 100%
    width auto
  }

  &__title {
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    color black
    margin-bottom 8px

    @media $phone {
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
    }
  }

  &__container {
    display flex
    flex-direction column
    gap 16px
  }


  &__buttons {
    display grid
    gap 30px
    grid-template-columns min-contentr 1fr 1fr
    align-items flex-start
  }

  &__upload {
    display flex
    align-items center
    justify-content center
    gap 16px
  }

  &__select {

  }

  &__phone {
    &_error {
      input {
        background rgba(194, 19, 19, 0.1)

      }
    }
  }

  &__submit {
    align-self center
    width 50%

    @media $tablet {
      width 100%
    }
  }

  &__ref {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: center;
    color black
    @media $tablet {
      font-family: Roboto;
      font-size: 12px;
      line-height: 16px;
    }
  }
}
