.GiftsSlider {
  width: 100%;

  &_disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &__slide {
    width: 290px;
    min-height: 150px;
    height: auto;
    padding: $checkout-desktop-margin-20;
    display: flex;
    border: 4px solid $color-bg;
    border-radius: 15px;
    background-color: white;

    &-image {
      width: 85px;
      height: auto;
      margin-right: 16px;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
      }
    }

    &-group {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
    }

    &-name {
      margin-bottom: 6px;
      word-break: break-word;
    }

    &-description {
      margin-bottom: 15px;
      word-break: break-word;
      font-size: $font-size-s;
      color: $color-grey;
      font-family: $font-primary;
      line-height: 1.33;
      font-weight: 400;
    }

    &-footer {
      margin-top: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-amount {
      color: $color-brand-green-dark;
    }

    &-button {
      width: 36px;
      height: 36px;
      padding: 1px;
      flex-shrink: 0;
      border-radius: 50%;
    }

    &-plus {
      width: 14px;
      height: auto;
      vertical-align: middle;
      fill: white;
    }
  }
}
