.Avatar {
  width: 52px;
  height: 52px;
  flex-shrink: 0;
  padding: 2px;
  position: relative;
  border-radius: 50%;
  background: no-repeat 50% 50% / cover;

  &__person {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: inherit;
    background: inherit;
  }

  &__award {
    width: 18px;
    height: 24px;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
