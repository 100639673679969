.BlurredBordered {
  border: 6px solid #3E6B8D
  border-radius 20px
  backdrop-filter: blur(20px)
}


.Rewards {
  position relative
  display flex
  gap 12px
  justify-content space-between

  @media $tablet {
    flex-direction column-reverse
  }

  @media $phone {
    flex-direction row
  }

  &__container {
    display flex
    flex-direction column
    gap 4px
    padding 22px 30px
    z-index 1


    @media $tablet {
      justify-content center
      padding 16px

    }
    @media $phone {
      justify-content start
      padding 22px 30px
    }
  }


  &__text {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.02em;
    white-space: pre-line;
    //text-align: left;
    //
    //@media $tablet {
    //  text-align: center;
    //}
    //
    //@media $phone {
    //  text-align: left;
    //}

  }

  &__collected {
    display flex
    align-items center
    gap 8px


    font-size: 40px;
    font-weight: 600;
    line-height: 1;
    @media $tablet {
      font-size: 28px;
    }
    @media $phone {
      font-size: 24px;
    }
  }


  &__BonusIcon {
    width 30px
    @media $tablet {
      width 24px
    }
    @media $phone {
      width 30px
    }
  }


  &__decor {
    position relative

    @media $tablet {
      height 34px
    }
    @media $phone {
      height auto
    }


    img {
      position absolute;
      right: 0;
      top: 50%;
      width 150px
      transform: translate(25px, -50%);

      @media $tablet {
        width: 85px;
        left: 50%;
        transform: translate(-50%, -40px);
      }

      @media $phone {
        width 150px
        left: unset
        transform: translate(25px, -50%);
      }
    }
  }


}

.Common {
  &_opacity_0 {
    opacity 0
  }

  &__preloader {
    position absolute;
    left 50%
    top 50%
    transform translate(-50%, -50%)
    padding 0

  }
}

.BonusIcon {
  display flex
  justify-content center
  align-items center
  border-radius 50%
  background url("../../../icons/backgrounds/BG.png") no-repeat center center
  background-size cover
  aspect-ratio 1

  svg {
    width 80%
    height 80%
  }
}

.CurrentTask {
  position relative

  &__content {
    height 100%
    display grid
    grid-template-columns 1fr
    gap 16px
    justify-items center
    padding 16px

    text-align center

    @media $tablet {
      //grid-template-columns 1fr auto
      padding 10px 150px 10px 24px
    }
    @media $phone {
      grid-template-columns 1fr
      padding 16px
    }
  }


  &__title {
    position relative
    align-self end
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    @media $tablet {
    }
    @media $phone {
    }
  }

  &__checked {
    position absolute
    right -5px
    top 50%
    transform translate(100%, -60%)

    width 25px
    height 25px
  }

  &__subtitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    white-space: pre-line;
    @media $tablet {
    }
    @media $phone {
    }
  }

  &__artefact {
    transition all
    transition-duration: $halfduration;
    max-height 170px

    @media $tablet {
      position absolute
      right 24px
      max-height 100%
      height: 86px;
      max-width 130px
      top: 50%;
      transform: translateY( -50%);
    }
    @media $phone {
      height auto
      width auto
      position static
      top unset
      right unset
      transform unset

    }

    &_hidden {
      filter brightness(0)
    }
  }
}


.HalloweenLink {
  &_img {
    width 100%
    height 100%
  }
}

.ModalContent {

  display flex
  flex-direction column
  gap 12px
  align-items center

  padding 36px 24px 24px
  background: #365B76 !important;
  border: 5px solid #3E6B8D
  border-radius 20px
  color white


  @media $phone {
    border none
  }

  &__image {
    max-height 170px
  }

  &__title {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
  }

  &__text {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    white-space: pre-line
  }

  &__button {
  }
}

.CollageBlock {
  &__Modal {
    position relative
  }

  &__modalClose {
    position absolute
    top 12px
    right 12px
    width 48px
    aspect-ratio 1
    cursor pointer
    transition all $halfduration
    &:hover {
      transform scale(1.05)
    }
  }

  &__Collage {
    width: min(90vw, 90vh, 1100px)
    height width
    max-width 100%
    @media $tablet {
      width: min(90vw, 90vh, 700px)
    }
  }
}

.Collage {
  position relative
  aspect-ratio 1
  overflow hidden
  background-image url("../assets/collage_bg.png")
  background-color #365B76
  background-position center center
  background-repeat no-repeat
  backdrop-filter unset
  background-size cover

  &__item {
    position absolute
    object-fit cover

    &_0 {
      width 54%
      bottom 0
      right -16%
    }

    &_1 {
      width 26%
      left -3%
      bottom -5%
    }

    &_2 {
      width 35%
      top 9%
      left -6%
    }

    &_3 {
      width 20%
      top 25%
      left 36%
    }

    &_4 {
      width 19%
      top 2%
      left 30%
    }

    &_5 {
      left 40%
      width 20%;
      bottom 1%
    }

    &_6 {
      width 40%
      top -6%
      right -7%
      transform rotate(180deg)
    }

    &_darken {
      filter brightness(0)
    }
  }


}

