.RewardItem {
  display: flex;
  color: $color-brand-brown;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  a& {
    color: $color-brand-green;
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__message {
    display: flex;
    color: currentColor;
    font-family: $font-primary;
    font-size: $font-size-s;
    font-weight: 500;
    line-height: 1.33;
  }

  &__icon {
    width: auto;
    height: 1.33em;
    margin-right: 16px;
    flex-shrink: 0;
    color: #e6e2e1;
  }

  &__replace {
    margin-top: 5px;
    font-size: $font-size-xs;
    margin-left: calc(1.5em + 16px);
  }

  &_avaivable &__icon {
    color: $color-brand-green;
  }

  &_applied &__icon {
    color: $color-brand-red;
  }
}
