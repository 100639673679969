.ClosedView {
  $padding-y = 16px
  $padding-x = 24px
  display flex
  align-items center
  padding $padding-y $padding-x

  @media $phone {
    padding-left $phone-margin-y
    padding-right $phone-margin-y
  }

  &__icon {
    flex-shrink 0
    height 28px
    width @height
    display flex
    align-items center
    justify-content center
    margin-right 10px
    border-radius 50%
    background-color $color-brand-green
    color white
  }

  &__title {
    flex-basis 100%
    flex-grow 1
    position: relative
    font-size: $font-size-m
    line-height: 24px;
  }

  &__button {
    flex-shrink 0
    margin-top -($padding-y)
    margin-bottom -($padding-y)
    margin-right -($padding-x)
    padding inherit
    cursor pointer
    transition color $duration

    &:hover {
      transition-duration: $halfduration;
      color $color-brand-green-hover
    }

    @media $phone {
      margin-right: -($phone-margin-y)
    }
  }
}
