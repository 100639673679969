.ImageLoader {
  &__workarea {
    margin-right: -40px;
    display: flex;
    align-items: center;
    flex-wrap wrap

    @media $phone {
      margin-right: -34px;
      width calc(100% - 40px)
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__dropzone {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.04em;
    color: #BDBDBD;
    margin-bottom 10px
    cursor: pointer;

    &:hover {
      border-color: darken(#ccc, 10%);
    }

    &_active {
      border-color: $color-brand-green;
    }

    &_rejected {
      border-color: $color-brand-red;
    }
  }

  &__caption {
    position: absolute;
    top: 114%;
    line-height: 1.2;
    font-size: 10px;
    white-space: nowrap;
  }

  &__error {
    margin-bottom: 10px;
    font-size: $font-size-xs;
    color: $color-brand-red;

    &:last-child {
      margin-bottom: 0;
    }
  }

  // Image box
  &__image {
    margin-right: 15px;
    padding 5px 5px
    border-radius 10px
    background: #F8F8F8;
    margin-bottom: 10px;

    &:last-child {
      margin-right: 0;
    }

    &-preview {
      width: 73px;
      height: 33px;
      margin 5px 5px 13px 5px
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
    }

    &-caption {
      display: flex;
      justify-content: flex-end;
      font-size: 10px;
      flex-wrap wrap
    }

    &-delete {

      margin 5px
      margin-bottom 0px
      height 8px

      &:hover {
        transform scale(1.1)
        transition-duration 0.5s
      }
    }
  }

  &__plus {
    width 20px
    height 20px
    background: rgba(191, 183, 182, 1);
    display flex
    justify-content center
    align-items center
    border-radius 10px
    align-self flex-end
  }
}
