.Sitemap {
  display: flex;
  flex-direction: column;
  padding-top: 32px;

  @media $tablet {
    padding-top: 20px;
  }

    &__spinner {
    spinner(48px, 3px, $color-brand-green);
    align-self: center;
    margin-left: 12px;
          margin-top: 10vh;
  }

  &__back-btn {
    margin-bottom: 20px;
    padding-left: 0 !important;
    cursor: pointer;
    width: max-content;

    &:hover > * {
      color: $color-brand-green;
      transition: color .2s;
    }

    @media $phone {
      margin-bottom: 16px;
    }

  }

  &__title {
    align-self: center

    @media $tablet {
      align-self: flex-start;
    }

  }

  &__list {
    column-count: 4
    column-gap: 40px
    column-rule: 1px dashed $color-brand-brown
    margin-top: 25px

    @media $tablet {
      margin-top: 16px
          column-gap: 60px
      column-count: 2
    }

    @media $phone {
      column-count: 1
    }

    &-item a {
      font-family: $font-primary;
      margin-top: 10px;
      margin-bottom: 10px;
      font-weight: normal;

      font-size: $font-size-s;
      color: $color-brand-brown;

      &:hover {


        color: $color-brand-green;
        transition: color .2s;
      }

      @media $tablet {
        margin-top: 6px;
        margin-bottom: 6px;
      }

    }
  }
}
