.CookiePolicyPopup {
  width: 308px;
  padding: 20px 0px;
  transform: translateY(100%);
  z-index: 10001;
  background: white;
  border-radius: 25px;
  transition: transform 500ms;
  margin: 0px -4px;


  &-background {
    width: 100%;
    position: fixed;
    height: 100vh;
    background: rgba(53, 53, 53, 0.35);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000;
  }

  &_entered {
    transform: translateY(0);
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-title {
      color: $color-brand-brown;
      font-weight: bold;
      font-size: 18px;
      line-height: 17px;
      font-family: $font-secondary;
      margin-top: 20px;
      text-align: center
    }

    &-text {
      font-family: $font-primary;
      color: $color-brand-brown;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      opacity: 0.8;
      margin-top: 10px;
    }

    &-checkbox {
      color: $color-brand-green;
      margin-top: 22px;
      align-self: flex-start;
    }

    &-checkbox-description {
      padding-left: 30px;
      margin-top: 10px;
      align-self: flex-start;
    }

    &-checkboxs-group {
      margin-bottom: 20px;
    }

    &-button {
      background-color: $color-brand-green;
      color: white;
      height: 42px;
      padding:0px 30px;
      border-radius: 20px;
      font-size: 14px;
      line-height: 16px;
    }

    &-footer {
      margin-top:15px;
      display flex
      flex-direction column
      align-items center
      & a {
        font-family: $font-primary;
        font-size: 10px;
        line-height: 16px;
        color: rgba(189, 189, 189, 1);
        display: block;
        opacity 0.8

        &:hover {
          opacity 1
        }
      }
    }
  }

  &__button {
    align-self: center
  }
}
