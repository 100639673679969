.Calendar {
  &_task_overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: contain;

    &_disabled {
      background-color: #f3bdbda6
      pointer-events: none;
      cursor: default;
    }

    &_completed {
      background-color: #ffffffa6
    }
  }

  &_mobile {
    display: none;
    position: relative;
    width: calc(100% + 38px);
    transform: translateX(-20px);
    padding-top: 24px;
    background-image url("../../assets/common/calendar_bg.png");
    background-size cover;
    background-repeat no-repeat;
    background-position center center;

    &:before {
      position absolute;
      bottom: 54px;
      content: ''
      height 12px;
      width 100%;
      background-color: $color-bg
    }
    @media $tablet {
      display: block;
    }

    &_car {
      position: absolute;
      top -38px;
      width: 56px;
      height: 40px;
      background-image url("../../assets/common/car.png");
      background-size cover;
      background-repeat no-repeat;
      background-position center center;
      transition all 1s ease-in-out;
    }

    &_tree {
      position: absolute;
      z-index: 100;
      bottom: 41px;
      left: 50%;
      transform: translateX(-50%);
      pointer-events none;
      width: 50px;
      height: 70px;
      background-image url("../../assets/common/decor_tree.svg");
      background-size cover;
      background-repeat no-repeat;
      background-position center center;
      transition all 1s ease-in-out;
    }

    &_swiper {
      position: relative
      padding-bottom: 20px;

      &_slide {
        position: relative;
        overflow: hidden;
        width: 100%;
        aspect-ratio: 1;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        margin-bottom: 20px;
        border-radius: 8px;
      }

      &_nav {
        background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
        content: "";
        position: absolute;
        top: 0;
        bottom 20px;
        left: 0;
        width: 20px;
        z-index: 2;

        &_next {
          transform: rotateZ(180deg);
          left unset
          right 0
        }

        &_disabled {
          display none
        }
      }

      &:after {
        position absolute
        content: ''
        bottom: 20px;
        width 100%;
        height: 34px;
        box-shadow: 0 0 6px 0 #0000001A, 0px 4px 8px 0px #0000001F;
      }

      &::before {
        position absolute
        content: ''
        bottom: 0;
        width 100%;
        height: 20px;
        background-color $color-bg
      }

      .caption {
        position relative
        color: transparent
        text-align: center;
        padding: 8px
        background-color: white

        &::before, &::after {
          position absolute
          content: '';
          height: 100%;
          width 15px;
          left -12px;
          bottom 0
          background-color: white
        }

        &::after {
          right: -12px
        }

        &_active {
          background-color: $color-brand-green-translucent
          color: #EB5757;
        }
      }
    }
  }

  &_desktop {
    margin-bottom: 40px;
    padding: 35px;
    display: grid;
    grid-template-columns: repeat(10, 104px);
    grid-template-rows: repeat(4, 104px);
    gap: 10px
    background-image: url("../../assets/common/calendar_bg.png");
    background-size: cover;
    border-radius: 12px;

    @media $tablet {
      display none
    }

    &_tile {
      position: relative;
      overflow: hidden;
      background-size: cover;
      background-position: center center;
      border-radius: 8px;
    }
  }
}
