.GiftsSlider {
  margin-top: 38px;

  &::before,
  &::after {
    background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
    content: "";
    height: 100%;
    position: absolute;
    width: 20px;
    z-index: 2;
  }

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  &_item {
    width: 128px;
    height: 128px;
    aspect-ratio: 1;
    background-size cover;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 12px;
    overflow: hidden;
  }
}
