.AdventShop{
  position relative
  padding 32px 0 160px

  @media $tablet {
    padding 20px 0 40px
  }
  @media $phone {
    padding 18px 0 28px
  }

  &__BackButton {
    position absolute
    left 0
    top 50%
    transform translateY(-50%)
    &:hover {
      * {
        color $color-brand-green
      }
    }
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
    color $color-brand-brown

    @media $phone {
      font-size: 20px;
    }
  }
  &__grid {
    display grid
    grid-template-columns repeat(4, 1fr)
    grid-auto-rows minmax(252px,1fr)
    column-gap 10px
    row-gap 12px

    margin-top 24px
    @media $tablet {
      grid-template-columns 1fr
      margin-top 16px
    }
  }

  &__cart1 {
    grid-column 1/3
  }
  &__cart2 {
    grid-column 3/5
  }
  &__cart3 {
    //grid-column 2
  }
  &__cart4 {
    grid-column 2/4
  }
  &__cart5 {
    grid-column 4
  }

  &__cart1,
  &__cart2,
  &__cart3,
  &__cart4,
  &__cart5 {
    @media $tablet {
      grid-column 1
    }
  }


  &__wallet {
    z-index 5
    display block
    width min-content
    margin-top 20px
    margin-left auto

    @media $tablet {
      position fixed
      right  40px
      bottom 110px
    }

    @media $phone {
      bottom 40px
    }
  }

}
