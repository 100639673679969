.Map {
  position: relative;

  &__left, &__right {
    height: 48px;
    padding: 16px 20px 14px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 5;
    background-color: white;
    border-radius: 25px;
    box-shadow: 0px 0px 15px rgba(46, 65, 83, 0.25);
    font-family: $font-secondary;
    font-weight: 500;
    font-size: $font-size-xs;
    color: $color-brand-brown;
    white-space: nowrap;
  }

  &__left {
    top: 12%;
    right: 55%;
  }

  &__right {
    top: 12%;
    left: 55%;
  }

  &__order {
    width: 100%;
    height: 420px;
  }
}
