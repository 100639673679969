.Bonus {
  display: flex;
  flex-direction: column;
  margin-bottom: $checkout-mobile-margin-15;

  &_disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &__title {
    margin-bottom: $checkout-mobile-margin-10;
  }

  &__row {
    display: flex;
  }

  &__button {
    &:not(:last-child) {
      margin-right: $checkout-mobile-margin-10;
    }
  }

  &__clear {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px;

    &:hover svg {
      fill: $color-brand-green;
    }
  }

  &__modal {
    display: flex;
    flex-direction: column;
    background: $color-bg;
  }

  &__header {
    padding: $checkout-mobile-block-padding;
    padding-top: 30px;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    background: white;
    box-shadow: 0px 0px 15px rgba(32, 38, 55, 0.15);
    border-radius: 25px;
  }

  &__heading1 {
    margin-bottom: $checkout-mobile-margin-20;
    text-align: center;
  }

  &__bonuses {
    height: 51px;
    padding: 12px 28px;
    background: $color-bg;
    border-radius: 30px;
    align-self: center;
    font-size: $font-size-m;

    svg {
      margin-right: 14px;
    }
  }

  &__body {
    padding: $checkout-mobile-block-padding;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    background: $color-bg;
  }

  &__heading2 {
    margin-bottom: $checkout-mobile-margin-15;
    text-align: center;
  }

  &__description {
    margin-bottom: $checkout-mobile-margin-20;
    font-family: $font-secondary;
    font-weight: 400;
    font-size: $font-size-m;
    line-height: 1.33;
    text-align: center;
    color: $color-brand-brown;
    opacity: 0.8;

    &-bottom {
      margin-top: $checkout-mobile-margin-10;
    }
  }

  &__slider {
    padding: 0 25px;
    margin-bottom: $checkout-mobile-margin-25;
    display: flex;
    flex-direction: column;
  }

  &__input {
    margin-bottom: 9px;
  }

  &__edges {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__from {
    font-family: $font-primary;
    font-weight: 500;
    font-size: $font-size-m;
    line-height: 1;
    color: $color-brand-green;
    opacity: 0.8;
  }

  &__to {
    font-family: $font-primary;
    font-weight: 500;
    font-size: $font-size-m;
    line-height: 1;
    color: $color-brand-brown;
    opacity: 0.8;
  }

  &__accept {
    align-self: center;
  }
}
