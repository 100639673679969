// Remove input[type=number] arrows
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.B2bForm {
  background-color: #fff;
  position: relative;

  &__success {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    @media $tablet {
      top: unset;
      bottom: 10%;
    }

    &_active {
      opacity: 1
    }
  }

  &__input {
    input {
      background-color: $color-bg;
    }
  }

  &__body {
    padding: 42px 0 60px;
    @media $phone{
      padding: 28px 0 60px;
    }

    &_disactive {
      opacity: 0;
    }
  }

  &__title {
    font-size: 22px;
    @media $phone{
      $font-size-l
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    align-items: end;
    column-gap: 30px;
    row-gap: 32px;
    padding-top: 34px;
    @media $phone{
      padding-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 24px;
    }
  }

  &__name {
    grid-column: 1 / 3;
  }

  &__company {
    grid-column: 3 / 5;
  }

  &__phone {
    grid-column: 5 / 7;
  }

  &__email {
    grid-column: 1 / 3;
  }

  &__date {
    position: relative;
    grid-column: 3 / 4;

    &-arrow {
      position: absolute;
      height: 0;
      width: 0;
      right: 22px;
      bottom: 26%;
      border-color: $color-grey transparent transparent;
      border-style: solid;
      border-width: 5px 5px 2.5px;
      display: inline-block;
    }

  }

  &__time {
    grid-column: 4 / 5
  }

  &__quantity {
    grid-column: 5 / 7
  }

  &__error {
    position: absolute;
    font-family: $font-primary;
    font-size: $font-size-xs;
    line-height: 1.33;
    font-weight: 400;
    color: $color-brand-red;
  }

  &__recaptcha {
    grid-column: 1 / 8;

    * {
      margin: auto;
    }
    @media $phone{
      * {
        margin: 0;
      }
    }
  }

  &__submit {
    grid-column: 3 / 5;
    color: white;
    background-color: $color-brand-green;

    &:disabled::before {
      background-color: $color-grey!important;
    }
  }

  .react-date-picker, .react-date-picker :global(.react-date-picker__calendar-button) {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  .react-date-picker :global(.react-date-picker__button) {
    opacity: 0;

    * {
      opacity: 0;
    }
  }
}
