.Characteristics {
  display flex

  @media $tablet {
    display block
  }

  &__group {
    &_weight {
      position relative
      width 98px
      padding-right 15px
      margin-right 24px

      &:after {
        content ''
        position absolute
        right 0
        top 3%
        height 94%
        border-right 2px solid $color-border
      }
    }
  }
  &__weight {
    margin-bottom 16px
    font-size $font-size-xs
    color $color-brand-brown
  }
  &__groupName {
    font-size $font-size-xs
    margin-bottom 8px
  }
  &__items {
    display flex
  }
  &__item {
    min-width 110px
    padding-right 15px

    @media $tablet {
      min-width 75px
      padding-right 10px
    }

    &:last-child {
      padding-right 0
    }
  }
  &__value {
    margin-bottom 2px
    line-height 1.33
    font-size 22px
    font-weight 500
    color $color-brand-green
    white-space nowrap
  }
  &__valueUnit {
    font-size 10px
  }
  &__name {
    font-size $font-size-xs
    color $color-brand-brown
  }
}
