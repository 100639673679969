.ChatButton {
  z-index: 9000;
  height: 60px;
  width 60px;
  border-radius: 50%;
  position: fixed;
  bottom: 40px;
  right: 40px;
  background-color: rgba(80, 166, 132, 1);
  box-shadow: $box-shadow;

  &_offset {
      bottom: 100px;
  }

  &_hidden {
    display: none;
  }

  &__badge {
    position: fixed;
    bottom: 78px;
    right: 35px;
    z-index: 9001
    height: 20px;
    width: 20px;
    border: 3px solid #F8F8F8;
    border-radius: 50%;
    background-color: #EB5757;
    font-size: 12px;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    box-shadow: $box-shadow;
  }
}
