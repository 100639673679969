.Accordion {
  position: relative;

  &__item {
    border-bottom: 8px solid $color-bg;
    background-color: white;

    @media $phone {
      border-bottom-width: 4px;
    }
  }

  &__toggle {
    position: relative;
    padding: 24px 55px 24px 32px;

    ^[0]__divider {
      height: 1px;
      margin-bottom: 24px;
      background-color: #e6e2e1;
    }

    @media $phone {
      padding: 14px 55px 14px $phone-margin-x;

      ^[0]__divider {
        margin-bottom: 14px;
      }
    }
  }

  &__toggle-title, &__toggle-arrow {
    transition: color $duration;
  }

  &__toggle-arrow {
    margin-top: -15px;
    position: absolute;
    top: 50%;
    right: 14px;
    color: $color-brand-brown;
  }

  &__toggle-subtitle {
    padding-top: 6px;
  }

  &__content {
    padding: 0 32px 24px;

    @media $phone {
      padding-left: $phone-margin-x;
      padding-right: $phone-margin-x;
    }
  }

  &__item_selected &__toggle-title, &__item_selected &__toggle-arrow {
    color: $color-brand-green;
  }

  &__item_disabled {
    pointer-events: none;
  }
}
