@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./fonts/Roboto-Regular.woff2') format('woff2'), url('./fonts/Roboto-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('./fonts/Roboto-Medium.woff2') format('woff2'), url('./fonts/Roboto-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./fonts/Manrope-Medium.woff2') format('woff2'), url('./fonts/Manrope-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('./fonts/Manrope-Bold.woff2') format('woff2'), url('./fonts/Manrope-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:  url('./fonts/Manrope-ExtraBold.woff') format('woff');
}

html, body, :global(#root) {
  width: 100%;
  min-height: 100vh;
  background-color: $color-bg;
  color: $color-text;
  font-size: $font-size-s;
  line-height: 1.33;
  font-family: $font-primary;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;

}

:global {
  .desktop {
    min-width: $min-desktop-width;
  }
}

a {
  display: inline-block;
  text-decoration: none;
  color: $color-brand-green;
  transition: color $duration;
}
