.AvatarInput {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &:hover, &_active {
    .AvatarInput__icon {
      opacity: 1;
    }
  }

  &__inner {
    width: 141px;
    height: 141px;
    padding: 3px;
    position: relative;
    border-radius: 50%;
    cursor: pointer;
    background: no-repeat 50% 50% / contain;
    box-shadow: 0 0 10px 0 transparent inset;
    transition: box-shadow 0.5s;

    @media $tablet {
      width: 82px;
      height: 82px;
    }

    @media $tablet {
      width: 68px;
      height: 68px;
    }
  }

  &__preview {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: inherit;
    background-color: #ededed;
    background-image: url('./assets/no-avatar.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  &__icon {
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #fff;
    opacity: 0;
    background-color: rgba(112 84 79 0.5);
    transition: opacity $duration;
  }

  &__award {
    position: absolute;
    width: 30px;
    right: 8%;
    bottom: -6%;
    pointer-events: none;

    @media $tablet {
      width: 20px;
    }
  }

  &__text {
    margin-left: 13px;
    color: $color-brand-green;
  }

  &_active {
    box-shadow: 0 0 10px 0 $color-brand-green inset;
  }

  &[aria-disabled='true'] {
    pointer-events: none;
  }
}
