.Constructor {
  &__scene {
    width 584px
    margin-left auto
    margin-right @margin-left
  }

  &__steps {
    position relative
    top -50px
  }

  &__title {
    margin-bottom 10px
    text-align center
  }

}
