.EditProfile {
  width: 100%;
  min-height: 100vh;
  background-color: $color-bg;

  &__field {
    display: block;
    margin-bottom: 8px;
  }

  &__avatar {
    width: fit-content;
    margin-top: 18px;
    margin-bottom: 18px;
    margin-left: 18px;
  }

  &__footer {
    padding: $phone-margin-x;
    background-color: white;
  }

  &__submit {
    width: 100%;
  }
}
