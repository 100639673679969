.StockList {
  min-height: 100vh;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;

  &__white {
    margin-bottom: 20px;
    padding: 20px 16px;
    border-radius: 0px 0px 20px 20px;
    background-color: white;
    box-shadow: 0px 0px -10px rgba(32, 38, 55, 0.08);
  }

  &__table {
    width: 100%;
    display: flex;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__stock {
    position: relative;
    flex-shrink: 0;
  }

  &__caption {
    position: absolute;
    left: 0;
    bottom: 0;
    margin: 15px;
    border-radius: 15px;
    padding: 6px 10px;
    background-color: rgba(255, 255, 255, 0.5);
    font-size: 10px;
    color: $color-brand-brown;
  }

  &__image {
    width: auto;
    height: 120px;
    border-radius: 20px;
    margin-right: 20px;


    &_first {
      margin-left: 16px;
    }

    &_last {
      margin-right: 16px;
    }
  }

  &__spinner {
    spinner(48px, 3px, $color-brand-green);
    align-self: center;
    margin-left: 12px;
  }
}
