.Tabs {
    position: relative;
    font-family: $font-secondary;
    font-weight: 500;
}

.Tabs__list {
    display: flex;
    justify-content: flex-start;
    padding: 0;
    color: rgb(112, 84, 79);
    box-shadow: inset 0px -3px 0px 0px rgb(239, 238, 238);
}

.Tabs__item {
    box-sizing: border-box;
    margin: 0 30px 0 0;
    height: 30px;
    font-size: $font-size-xs;
    font-weight: bold;
    border-bottom: 3px solid transparent;
    list-style: none;
    cursor: pointer;
    user-select: none;
    transition: border-color $duration;
}

.Tabs__item-disabled {
    pointer-events: none;
    color: $color-text;
}

.Tabs__item-selected {
    color: $color-brand-green;
    border-color: #008965;
    cursor: default;
}

.Tabs__item:not(.Tabs__item-selected):hover {
    border-color: $color-brand-green;
    transition-duration: 0.175s;
}

.Tabs__item:last-child {
    margin: 0;
}

.Tabs__content {
    box-sizing: border-box;
    min-height: 200px;
    padding: 24px 0;
}

.Tabs__content p {
    margin-top: 0;
}
