.OrderItemDescription {
  padding: 8px 0;

  &__text {
    font-size: $font-size-xs;
    padding-top: 4px;

    &_combobox {
      text-overflow();
    }
  }

  &__AddedSauces {
    padding-top: 8px;
  }

  &__combobox {
    padding-top: 6px;
    cursor: pointer;
  }

  &__comboboxItem {
    padding: 6px 0;

    &:last-child {
      padding-bottom: 0;
    }
  }

  &__comboboxVariationInfo {
    font-size: $font-size-xs;
  }

  // 
  &__composition {
    padding-top: 6px;
    cursor: pointer;
  }

  &__compositionToggleWrapper {
    padding-top: 8px;
    display: flex;
  }

  &__compositionToggle {
    padding-right: 30px;
    position: relative;
    font-size: $font-size-xs;
    user-select: none;

    &_disabled {
      color: #BFB7B6;

      &:focus {
        color: #BFB7B6;
      }

      &:hover {
        color: #BFB7B6;
      }
    }
  }

  &__compositionToggleArrow {
    position: absolute;
    top: -8px;
    right: 0px;
    transition: none;
  }
}
