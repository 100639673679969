.Map {
  width: 100%;
  height: 220px;
  background-color: $color-bg;
  position: relative;

  &__preloader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
