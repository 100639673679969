.ProductList {
  position relative
  margin-bottom 45px

  &:last-child {
    margin-bottom 0
  }

  &__heading {
    margin-bottom 16px
  }

  &__list {
  }

  &__item {
    position relative
  }

  &__card {
    position relative
    padding-top 32px
    padding-left 64px
    padding-right @padding-left
    padding-bottom 40px

    &:after {
      content ''
      position absolute
      display block
      left @padding-left
      right @padding-right
      bottom 0
      border-top 1px solid $color-border

      ^[0]__item:last-child & {
        display none
      }
    }

     @media $tablet {
      padding-top 25px
      padding-left 14px
      padding-right @padding-left
      padding-bottom @padding-top

      &:after {
        left @padding-left
        right @padding-right
      }
    }
  }
}
