.ArrowButton {
  position relative
  width 100%
  background #C21313
  border-radius 50%
  aspect-ratio 1
  display flex
  justify-content center
  align-items center

  transition: transform $halfduration

  &:hover {
    transform scale(1.1)
  }

  svg {
    position: absolute;
    inset: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 31%;
  }
}
