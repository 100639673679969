.RestTile {
  &__restaurant {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media $tablet {
      gap: 16px;
    }

    &-wrapper {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    &-title {
      font-weight: 500;

      &:hover {
        color: $color-brand-green;
      }
    }

    &-container {
      display: flex;
      gap: 15px;
    }

    &-icon {
      width: auto;
      flex-shrink: 0;
      height: 17px;
      filter: brightness(0) saturate(100%) invert(60%) sepia(26%) saturate(635%) hue-rotate(105deg) brightness(90%) contrast(92%);
    }

    &-description {
      font-family: $font-primary;
      font-weight: 400;
      font-size: $font-size-s;
      line-height: 1.33;
      color: $color-brand-brown;
    }
  }
}
