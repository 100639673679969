.InputField {

  &__standart{
    direction: ltr!important;
    text-align: left!important;
  }

  &_disabled {
    pointer-events: none;
    opacity: 0.75;
  }

  &__wrapper {
    position: relative;
    display: flex;
  }

  &__label {
    mobileinputlabel();
  }

  &__field {
    mobileinputfield();

    &_plain {
      mobileinputplainfield();
    }
  }

  &_error {
    .InputField__label {
      color: $color-brand-red;
    }
  }
}
