.FloatingCart {
  opacity: 0;
  display: flex;
  align-items: center;
  position: fixed;
  height: 56px;
  bottom: 4%;
  right: 64px;
  border-radius: 100px;
  background-color: $color-brand-red;
  box-shadow: 0 4px 5px 0 rgba(41, 44, 51, 0.2);
  color: white;
  transform: scale(0.85);
  pointer-events: none;
  z-index: 999;
  transition: transform $duration, opacity $duration;

  @media $verticalTablet {
    right: $tablet-margin-x;
  }

  @media $phone {
    bottom: $phone-margin-x;
    right: $phone-margin-x;
  }

  &_visible {
    opacity: 1;
    transform: scale(1);
    pointer-events: auto;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0px;
    right: -20px;
    bottom: -20px;
    left: 0px;
  }

  &:active {
    transform: scale(0.95);
    transition-duration: $halfduration;
  }

  &__icon {
    position: relative;
    width: 56px;
    height: 100%;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -12px;
      margin-left: -12px;
    }
  }

  &__counter {
    padding-right: 15px;
    color: white;
  }
}

.FloatingCartLayer {
  margin-bottom: 96px;
}
