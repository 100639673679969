.DeliveryAddress {
  width: 310px;
  padding-bottom: 12px;
  margin-right: 42px;
  display: flex;
  justify-content: space-between;

  @media $verticalTablet {
    width: calc(100% - 20px);
    margin-right: 0;
  }

  &__name {
    font-weight: 500;

    &:first-letter {
      text-transform: capitalize;
    }
  }

  &__actions {
    height: 0;
    margin: -3px -24px 0 8px;
    flex-shrink: 0;
    vertical-align: middle;
    white-space: nowrap;
  }

  &__actions-button {
    margin-right: 8px;
    color: #ededed;
    cursor: pointer;
    transition: color $duration;

    @media $tablet {
      height: 24px;
      position: relative;
      display: inline-block;

      &:after {
        content: '';
        width: 32px;
        height: @width;
        margin-top: (- @height / 2);
        margin-left: (- @width / 2);
        position: absolute;
        top: 50%;
        left: @top;
        display: inline-block;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      color: $color-text;
    }

    &_delete {
      &:hover {
        color: $color-brand-red;
      }
    }
  }
}
