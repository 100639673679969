.IncludeIngredient {
  & {
    display: table
    width: 100%
    min-height: 40px

    @media $tablet {
      position relative
      display flex
      align-items center
      min-height 80px
      background-color white
      padding 0 32px
    }

    &:not(:last-child) {
      @media $tablet {
        margin-bottom 8px
      }
    }
  }

  &__amount {
    @media $tablet {
      font-size $font-size-l
      line-height 24px
    }
  }

  &__cell {
    display: table-cell
    vertical-align: middle
    border-bottom: 1px solid #f5f5f5

    @media $tablet {
      border-bottom none
    }

    &_name {
      width: 60%
      padding-right: 10px

      @media $tablet {
        padding-left 25px
      }
    }

    &_portion {
      width: 56px

      @media $tablet {
        width 74px
        order -1
      }
    }

    &_price, &_remove {
      text-align: right
    }

    &_price {
      padding-left: 5px

      @media $tablet {
        margin-left auto
      }
    }

    &_remove {
      width: 20px
      cursor: pointer
      border-bottom-color: transparent

      @media $tablet {
        position absolute
        top 50%
        right 0
        transform translate(100%, -50%)
        padding-left 13px
      }
    }
    &_empty {
      width: 20px;
      border-bottom-color: transparent;
      cursor: auto;
    }
  }

  &__name {
    color: $color-brand-brown
    font-size: $font-size-xs

    &:first-letter {
      text-transform: capitalize
    }

    @media $tablet {
      font-size $font-size-m
      line-height 32px
    }
  }

  &__remove {
    color: $color-grey
    transition: color $duration

    @media $tablet {
      display block
      width 14px
      height @width
    }
  }

  &__cell_remove:hover &__remove {
    color: $color-brand-red

    transition-duration: $halfduration;
  }
}

