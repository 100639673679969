.QuantityControl {
  display: flex;

  &__display {
    width: 40px;
    font-family: $font-secondary;
    font-weight: 700;
    font-size: $font-size-m;
    text-align: center;
    color: $color-brand-brown;
    opacity: 0.8;
  }

  &__button {
    width: 20px;
    height: 20px;
    padding: 1px;
    flex-shrink: 0;

    svg {
      width: 8px;
      height: auto;
      fill: white;
    }
  }
}
