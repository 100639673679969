.SuccessMessageModal {

  paper();
  width: 375px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 25px;
  
  &__img {
    width: 45px;
    height: 45px;
    margin-bottom: 20px;
  }
  
  &__header {
    font-size: 24px
    font-weight: 700
    font-family: $font-secondary
    color: rgba(112, 84, 79, 1)
    text-align: center
    margin-top: 37px
  }

  &__subtitle {
    margin-bottom: 12px;
  }

  &__button {
    margin-top: 28px;
  }
}
