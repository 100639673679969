.ModalChooseCity {
  width: 100%;
  height: auto;

  &__top {
    margin-top: 24px
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__text-city {
    padding-left: 30px
    border-radius: 25px;
    width: 272px;
    height: 56px;
    animation: animate .2s ease-in-out forwards;
    font-size 16px
    color black


    &_big {
      width: 100%;
      transition: .2s ease-in-out;
    }
  }

  &__change-city-button {
    padding: 0;
    width: 55px;
    height: 56px;
    border: none;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: centr;
    transition .2s ease-in-out;

    &_close {
      transform: scale(0);
      display: none;
    }

    svg {
      width: 15px;
      height: 15px;
    }
  }

  &__list {
    width: 100%;

    &-item {
      width: 100%;
      border-bottom: 1px solid #F4F4F8;
      ont-weight: 400;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.02em;

      button {
        padding: 16px 30px;
        width: 100%;
        height: 100%;
        text-align: left;
        color: #353535;
      }
    }
  }
}

@keyframes animate {
  0% {
    width: 272px;
  }

  100% {
    width: 100%;
  }
}
