placeholder($color) {
  &::placeholder {
    color: $color;
    opacity: 1;
  }
}

paper() {
  position: relative;
  border-radius: $border-radius;
  background-color: white;
}

fullsize() {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

text-overflow() {
  overflow: hidden;
  white-space: nowrap;
}

scrollable() {
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

custom-scrollbar($width = 2px, $color = $color-brand-red, $border-radius = 1px) {
  &::-webkit-scrollbar {
    width: $width;
    background: transparent;
  }

  &::-webkit-scrollbar-track {
    border-radius: $border-radius;
  }

  &::-webkit-scrollbar-track-piece {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: $border-radius;
    background: $color;
  }

  &::-webkit-resizer, &::-webkit-scrollbar-button, &::-webkit-scrollbar-corner {
    display: none;
  }
}

scrollGradient($bgColor = $color-bg){
  background:
    linear-gradient($bgColor 33%, $bgColor 0%),
    linear-gradient($bgColor 0%, $bgColor 66%) 0 100%,
    radial-gradient(farthest-side at 50% 0, rgba(34,34,34, 0.5), rgba(0,0,0,0)),
    radial-gradient(farthest-side at 50% 100%, rgba(34,34,34, 0.5), rgba(0,0,0,0)) 0 100%;
  background-color: $bgColor;
  background-repeat: no-repeat;
  background-attachment: local, local, scroll, scroll;
  background-size: 100% 21px, 100% 21px, 100% 7px, 100% 7px;
}

chevron-right() {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -4px;
  margin-left: -5px;
  border: solid currentColor;
  border-width: 0 2px 2px 0;
  padding: 3px;
  transform: rotate(-45deg);
}

spinner($spinnerSize, $spinnerWidth, $color) {
  width: $spinnerSize;
  height: $spinnerSize;
  display: inline-block;
  color: $color;
  border: $spinnerWidth solid currentColor;
  border-radius: 50%;
  box-sizing: border-box;
  animation: spin 0.75s infinite linear;

  &:before, &:after {
    content: '';
    width: inherit;
    height: inherit;
    position: absolute;
    left: -($spinnerWidth);
    top: -($spinnerWidth);
    border: inherit;
    border-radius: inherit;
    box-sizing: border-box;
  }

  &, &:before {
    border-color: currentColor transparent transparent transparent;
  }

  &:before {
    display: inline-block;
    animation: spin 1.5s infinite ease;
  }

  @keyframes spin {
    from {
      transform: rotate(0);
    }

    to {
      transform: rotate(360deg);
    }
  }
}

mobileinputlabel() {
  margin-bottom: 10px;
  display: block;
  color: $color-grey;
  font-size: $font-size-s;
  line-height: 1.33;
  font-family: $font-primary;
  font-weight: 500;
}

mobileinputfield() {
  width: 100%;
  height: 42px;
  padding: 12px 16px;
  border-radius: 20px;
  border: none;
  background-color: $color-bg;
  transition: background-color $duration;
  color: $color-brand-brown;
  font-size: $font-size-s;
  line-height: 1;
  font-family: $font-primary;
  font-weight: 500;
  placeholder($color-grey);

  &:focus {
    background-color: $color-bg-hover;
    //direction: ltr;
    //text-align: left;
  }
}

mobileinputplainfield() {
  width: 100%;
  height: auto;
  padding: 0px;
  border-radius: 0px;
  border: none;
  background-color: transparent;
  transition: background-color $duration;
  color: $color-brand-brown;
  font-size: $font-size-m;
  line-height: 1;
  font-family: $font-primary;
  font-weight: 500;
  placeholder($color-brand-red-light);

  &:focus {
    background-color: transparent;
  }
}

desktopinputlabel() {
  margin-bottom: 15px;
  display: block;
  color: $color-grey;
  font-size: $font-size-m;
  line-height: 1.33;
  font-family: $font-primary;
  font-weight: 400;
}

desktopinputfield() {
  width: 100%;
  height: 62px;
  padding: 15px 30px;
  border-radius: 12px;
  border: none;
  background-color: $color-bg;
  transition: background-color $duration;
  color: $color-brand-brown;
  font-size: $font-size-m;
  line-height: 1;
  font-family: $font-primary;
  font-weight: 400;
  placeholder($color-grey);

  &:focus {
    background-color: $color-bg-hover;
  }
}
