.ConstructorPage {
  width: 1000px;
  margin: 0 auto;
  padding: 50px 0 50px;

  @media $tablet {
    width: auto;
  }

  @media $phone {
    padding: 0;
    background-color: white;
  }
}
