.StockGoods {
  padding-top: 80px;

  &:last-of-type {
    padding-bottom: 70px;
  }

  &__heading {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  &__list {
    margin: -($card-margin);
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
  }

  &__item {
    width: 'calc(33.3% - %s)' % ($card-margin * 2);
    margin: $card-margin;

    // при ресайзе окна на десктопе
    @media $tablet {
      width: 'calc(50% - %s)' % ($card-margin * 2);
    }

    @media $verticalTablet {
      width: 100%;
    }
  }
}
