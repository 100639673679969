.BonusesButtons {
  background-color: #F8F8F6;

  &__containerForButtons {
    padding: 20px 16px 0 16px;
    display: grid;
    grid-template-columns repeat(2, 155px)
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    overflow scroll

    &_column3 {
      grid-template-columns repeat(3, minmax(31%, 155px))
    }
  }
}

.SuperBonuses {
  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    //width: 155px;
    height: 122px;
    box-shadow: 0 0 10px rgba(32, 38, 55, 0.08);
    border-radius: 20px;
    background: url('../../../../icons/backgrounds/bgSuperButton_big.png') center center;
    font-weight: 500;
    font-size: 14px
    color: #FFFFFF;
    position: relative;
  }

  &__buttonBlack {
    display: flex;
    align-items: center;
    justify-content: center;
    //width: 155px;
    height: 122px;
    box-shadow: 0 0 10px rgba(32, 38, 55, 0.08);
    border-radius: 20px;
    background: url('./blackButtonMobile.png') center center;
    background-size cover
    font-weight: 500;
    font-size: 14px
    color: #FFFFFF;
    position: relative;
  }

  //&__noExpire {
  //  height: 80px;
  //  background: url('../../../../icons/backgrounds/bgSuperButton_big.png') center center;
  //}

  &__text {
    flex-direction: column;
    font-family: $font-secondary;
    font-size: 12px;
    line-height: 22px;
    justify-content: center;
    display: flex;

    &_bonus {
      font-size: 16px;
    }
  }

  &__line {
    width: 131px;
    height: 18px;
    border-bottom: 1px solid #FFFFFF;
    opacity: 0.8;
  }

  &__expireInfo {
    display: flex;
    margin-top: 10px;
    align-items: center;

    &_text {
      margin-left: 8px;
      font-family: $font-secondary;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 11px;
      color: #FFFFFF;
    }
  }

  &__icon {
    position: absolute;
    top: 6px;
    right: 10px;
  }

}

.PapaBonuses {
  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    //width: 155px;
    height: 122px;
    box-shadow: 0 0 10px rgba(32, 38, 55, 0.08);
    border-radius: 20px;
    background-color: #50A684;
    font-weight: 500;
    font-size: 14px
    color: #FFFFFF;
    position: relative;
  }

  //&__noExpire {
  //  height: 80px;
  //}

  &__text {
    flex-direction: column;
    font-family: $font-secondary;
    font-size: 12px;
    line-height: 22px;
    display: flex;
    justify-content: center;

    &_bonus {
      font-size: 16px;
    }
  }

  &__line {
    width: 131px;
    height: 18px;
    border-bottom: 1px solid #FFFFFF;
    opacity: 0.8;
  }

  &__expireInfo {
    display: flex;
    margin-top: 10px;
    align-items: center;

    &_text {
      margin-left: 8px;
      font-family: $font-secondary;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 11px;
      color: #FFFFFF;
    }
  }

  &__icon {
    position: absolute;
    top: 6px;
    right: 10px;
  }

}

.ModalPapaBonuses {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #F4F4F8

  &__modal-icon {
    margin: 0 auto;
    height: 6px;
    border-radius: 3px;
    background: #fff;
    width: 70px;
    position: relative;
    top: -15px;
  }

  &__container-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background-color: #FFFFFF;
    height: 153px;
    border-radius: 25px;
    width: 100%;
    position: relative;
    top: -6px;
  }

  &__title {
    font-family: $font-secondary;
    font-style: normal;
    font-size: 18px;
    font-weight: 500;
    color: #70544F;
  }

  &__container-value {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 164px;
    height: 51px;
    background-color: #50A684;
    border-radius: 25px;
  }

  &__value {
    font-family: $font-primary;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin-left: 14px;
  }

  &__header {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #70544F;
    margin-top: 30px;
  }

  &__information {
    font-family: $font-secondary;
    width: 265px;
    font-style: normal;
    font-size: 16px;
    display: block;
    text-align: center;
    font-weight: 500;
    color: #70544F;
    opacity: 0.8;
    margin-top: 12px;
  }

  &__understand-button {
    font-family: $font-primary;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 40px 30px 40px
    width: 91%;
    height: 51px;
    background-color: #50A684;
    border-radius: 25px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    cursor: pointer;
  }
}

.ModalSuperBonuses {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #F4F4F8

  &__modal-icon {
    margin: 0 auto;
    height: 6px;
    border-radius: 3px;
    background: #fff;
    width: 70px;
    position: relative;
    top: -15px;
  }

  &__container-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background-color: #FFFFFF;
    height: 153px;
    border-radius: 25px;
    width: 100%;
    position: relative;
    top: -6px;
  }

  &__title {
    font-family: $font-secondary;
    font-style: normal;
    font-size: 18px;
    font-weight: 500;
    color: #70544F;
  }

  &__container-value {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 164px;
    height: 51px;
    background: url('../../../../icons/backgrounds/bgSuperButtonModal_small.png') center center;
    border-radius: 25px;
  }
  &__container-black {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 164px;
    height: 51px;
    background: url('./blackButtonMobile.png') center center;
    border-radius: 25px;
  }

  &__value {
    font-family: $font-primary;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin-left: 14px;
  }

  &__header {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #70544F;
    margin-top: 30px;
  }

  &__information {
    font-family: $font-secondary;
    width: 265px;
    font-style: normal;
    font-size: 16px;
    display: block;
    text-align: center;
    font-weight: 500;
    color: #70544F;
    opacity: 0.8;
    margin-top: 12px;
  }

  &__understand-button {
    font-family: $font-primary;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 40px 30px 40px
    width: 91%;
    height: 51px;
    background: url('../../../../icons/backgrounds/bgSuperButtonModal_big.png') center center no-repeat;
    border-radius: 25px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    cursor: pointer;
  }
  &__understand-buttonBlack {
    font-family: $font-primary;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 40px 30px 40px
    width: 91%;
    height: 51px;
    background-color: #50A684;
    border-radius: 25px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    cursor: pointer;
  }
}
