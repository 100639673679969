.Cart {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__preview {
    margin-bottom: 30px;
  }

  &__amount {
    font-family: $font-secondary;
    font-weight: 500;
    font-size: $font-size-l;
    line-height: 1;
    color: $color-brand-green-dark;
  }
}
