.DeliveryTimeSwitcher {
  display: flex;
  flex-direction: column;
  margin-bottom: $checkout-mobile-margin-15;

  &__title {
    margin-bottom: $checkout-mobile-margin-10;
  }

  &__row {
    display: flex;
  }

  &__button {
    &:not(:last-child) {
      margin-right: $checkout-mobile-margin-10;
    }
  }

  &__modal {
    padding: $checkout-mobile-block-padding;
    display: flex;
    flex-direction: column;
    align-items: center;
    background white
    border-radius: 25px 25px 0px 0px;
  }

  &__subtitle {
    margin-bottom: $checkout-mobile-margin-15;
  }

  &__times {
    margin-bottom: $checkout-mobile-margin-15;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }

  &__time {
    flex: 1;

    &:first-child {
      margin-right: $checkout-mobile-margin-10;
    }
  }
}
