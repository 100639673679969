.RestRegion {
  display flex
  flex-direction column
  gap 20px
  padding 20px 0

  &__title {
    align-self center
  }

  &__list {
    display grid
    grid-template-columns repeat(3,1fr)
    gap 10px
  }

  &__listItem {
    font-weight bold
    font-size $font-size-l

    &:hover {
      color $color-brand-green
    }
  }
}
