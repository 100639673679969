.SearchBar {
  display flex
  margin 20px 0px

  &__field {
    width 100%
  }

  & &__input {
    height: 42px;
    padding: 8px 50px 10px 25px;
    display: block;
    border: none;
    border-radius: 20px;
    background-color: #f8f8f8;
    transition: background-color 0.35s;
    font-family: Roboto;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #BFB7B6;
    width 100%

    &::placeholder {
      font-family: Roboto;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #BFB7B6;
    }
  }

}
