.CategoryIcons {
  position: relative;
  width: 100%;
  padding: 20px ;
  display: flex;
  justify-content: space-between;
  gap: 10px
  border-radius: 25px;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(69, 69, 69, 0.1);


  &__link {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    color: $color-brand-brown;
    transition: color $duration;

    &:hover {
      color: $color-brand-green;
      transition-duration: $halfduration;
    }

    &_vegan {
      color: #ACCB7D;
    }
  }

  &__name {
    flex-grow: 1;
    margin-top: 20px;
    font-family: $font-secondary;
    font-weight: 700;
    font-size: $font-size-s;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;

    @media $verticalTablet {
      display: none;
    }
  }

  &__icon {
    width: auto;
    height: 50px;

    &_vegan {
      margin-right: 14px;
    }
  }
  &__promoGame-decor {
    position: absolute;
    width: 75px;
    left: -(@width/2);
    top: -(@width/2);

    @media $tablet {
      width: 45px;
      left: -(@width/ 2);
      top: -(@width/ 2);
    }

    @media $phone {
      width: 38px;
      left: -(@width/ 2);
      top: -(@width/ 2);
    }

    img {
      max-width: 100%;
    }
  }
  &__promoBorder {
    position: absolute;
    width: 170px;
    z-index 1;
    top: 0;
    left: (@width/2);
    transform translateY(-100%);
  }

  &__PaddingtonDecor {
    top: -50px;
    left: -16px;
    width: 110px;
  }
}
