.Tickets {
  position relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 35px;
  font-family: Manrope;
  padding-bottom: 60px;


  @media $tablet {
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;

  }

  &_part {
    max-width: 580px;
    //  flex : 1 1 0;

  }

  &_left {
    @media $tablet {
      //position: absolute;
      max-width: 100%;
    }
  }

  &_banner {
    display: flex
    background-color: #50A684;
    border-radius: 12px;

    color: white;
    flex-grow: 1;
    background-image: url("../../assets/common/decor_tickets.svg")
    background-repeat no-repeat
    background-size contain;
    background-position-x: right;
    //@media $phone {
    //  background-size cover;
    //}

    &_container {
      padding: 46px;
      @media $phone {
        padding: 27px;
      }
    }

    &_heading {
      font-size: 18px;
      line-height: 32px;
      @media $phone {
        font-size: 12px;
      }

    }

    &_points {
      position relative
      font-size: 48px;
      line-height: 52px;
      font-weight: 700;
      padding-left 65px;
      @media $phone {
        font-size: 28px;
      }

      &:before {
        position absolute
        left: 0;
        top: -4px;
        width 55px
        height 33px
        content: url("../../assets/common/ticket.svg")
        transform: rotate(-30deg)

        @media $phone {
          width 33px
          height 20px
          top: 10px;
        }
      }
    }

    &_decor {
      //flex-grow: 1;
      //background-image: url("../../assets/common/decor_tickets.svg")
      //background-repeat no-repeat
      //background-size contain;
      //background-position-x: right;
      //@media $phone {
      //  background-size cover;
      //}
    }


  }

  &_text {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: $color-brand-brown;
    padding-top: 16px;
    @media $phone {
      font-size: 14px;
    }
  }

  &_slider {
    padding-top: 38px;

    &_item {
      width: 128px;
      height: 128px;
      aspect-ratio: 1;
      background-size cover;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }

  &_table {
    position: relative;
    @media $tablet {
      max-width: unset;
      width: 100%;
      padding-bottom 88px;
    }

    &_heading {
      font-size: 22px;
      font-weight: 400;
      line-height: 28px;
      color: $color-brand-brown;

      @media $phone {
        font-size: 20px;
        line-height: 24px;
      }
    }

    &_container {
      position: absolute;
      top: 48px;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: auto;

      custom-scrollbar(6px, $color-brand-green, 6px);
      scrollGradient()

      @media $tablet {
        position: relative;
        max-height: 500px;
      }
      @media $phone {
        max-height: 360px;
      }
    }

    &_row {
      display: grid;
      grid-template-columns: auto 55px 106px 1fr;
      align-items: center;
      @media $phone {
        grid-template-columns: auto 30px 75px 1fr;
      }

      color: $color-brand-brown;
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      @media $phone {
        font-size: 14px;
        line-height: 20px;
      }

      &:not(:first-child) {
        padding-top: 20px;
      }

      &_ticket {
        padding-right: 20px;

      }

      &_points {
        color: $color-brand-green;
        padding-right: 50px;
        @media $phone {
          padding-right: 12px;
        }
      }

      &_date {
        padding-right: 50px;
        @media $phone {
          padding-right: 16px;
        }
      }
    }
  }
}
