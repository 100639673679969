.Modal {
  padding: 34px;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0;
  will-change: opacity;
  transition: opacity $duration;
  -webkit-overflow-scrolling: touch;

  &__content {
    max-width: 1100px;
    margin: auto;

    @media $tablet {
      max-width: 700px;
    }
  }

  &_entered {
    opacity: 1;
  }
}

.ModalPhone {
  max-height: 100%;
  // отступ с сейфзоны для айфонов
  padding-bottom: 20px;
  padding-bottom: constant(safe-area-inset-bottom, 0px);
  padding-bottom: env(safe-area-inset-bottom, 0px);
  position: fixed;
  left: 0;
  right: 0;
  z-index: 2000;
  background: white;
  overflow-x: hidden;
  overflow-y: scroll;
  transition: transform 700ms;
  will-change: transform;
  transition-timing-function: $easeInCubic;
  -webkit-overflow-scrolling: touch;

  &_transparent {
    background: transparent;
  }

  &__overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2000;
    background-color: black;
    opacity: 0;
    transition: opacity 700ms;

    &_entered {
      opacity: 0.5;
    }
  }

  &_left {
    top: 0;
    bottom: 0;
    transform: translateX(100%);
  }

  &_top {
    top: auto;
    bottom: 0;
    transform: translateY(100%);
  }

  &_entered {
    transition-timing-function: $easeOutCubic;
  }

  &_entered.ModalPhone_left {
    transform: translateX(0%);
  }

  &_entered.ModalPhone_top {
    transform: translateY(0%);
  }
}
