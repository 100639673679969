.ThanksForOrderingLink {
  width: auto;
  height: 55px;
  display: flex;
  align-items: center;
  position: fixed;
  left: 50%;
  bottom: 4%;
  z-index: 1001;
  transform: translateX(-50%);
  transition: opacity $halfduration;
  opacity: 0;
  pointer-events: none;

  &_entered {
    opacity: 1;
    pointer-events: auto;
  }

  &__link {
    width: auto;
    height: 55px;
    margin-right: 15px;
    padding-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 35px;
    background-color: $color-brand-green;
    transition: background-color $halfduration;
    cursor: pointer;

    &:hover {
      background-color: $color-brand-green-dark;
    }

    &-icon {
      width: 55px;
      height: 55px;
      margin-right: 5px;
    }

    &-description {
      font-family: $font-primary;
      font-weight: 500;
      color: white;
      text-transform: uppercase;
      white-space: nowrap;
    }
  }

  &__cancel {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: $color-grey;
    transition: background-color $halfduration;
    cursor: pointer;

    &:hover {
      background-color: $color-brand-red-light;
    }

    &-icon {
      width: 14px;
      height: 14px;

      path {
        fill: white;
      }
    }
  }
}
