.PayFieldGroup {
  paper();
  padding: $checkout-desktop-margin-40;
  margin-bottom: $checkout-desktop-margin-20;
  display: flex;
  flex-direction: column;

  &_disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.Notifications {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}