.GiftsContainer {
  height: 100%;
  background-color: #FFFFFF;
}

.Gifts {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    margin: 15px;
  }
  &__list {
    display: flex;
    justify-content: center;
  }
}
