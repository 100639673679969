.ModalVpn {
  &__popupMobileGeneral {
    margin-top: 15px;
    padding: 0 20px;
    position: fixed;
    z-index: 1000;
    top: 0;
    width: 100%;
    height: 78px;

    &_desktop {
      padding: 0 20px;
      position: fixed;
      z-index: 1000;
      bottom: 30px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 361px;
      height: 84px;
    }

    &_icon {
      min-width: 40px;
      min-height: 40px;
      heigth: 40px;
      width: 40px;
    }
  }
}
