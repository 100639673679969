.OkkoLink {
  overflow hidden
  position relative

  border-radius 24px
  background: var(--gradient-1);

  @media $phone {
    border-radius 20px

  }

  &__content {
    height 100%
    display flex
    flex-direction column
    justify-content space-between
    gap 30px
    padding 24px 20% 24px 24px;
    @media $tablet {
      padding 24px;
    }
    @media $phone {
      gap 16px

      padding 12px;
    }
  }

  &__title {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    color white
    z-index 1
    @media $tablet {
      font-size: 16px;
      line-height: 20px;
    }

    @media $phone {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__logo {
    filter invert(1) ;
    max-width 80%
  }

  &__button {
    width 100%
  }


  &__bg {
    position absolute
    inset 0
    display flex

    &:before {
      content: "";
      display: block;
      position absolute
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      right 0
      bottom 0

      background: var(--gradient-2);

    }


  }

  &__bgImage{
    margin-top auto
    width 100%
    object-fit fill
  }
}
