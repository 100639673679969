.Bonuses {
  height: 50px;
  padding: 10px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $font-primary;
  font-weight: 500;
  font-size: inherit;
  line-height: 1;
  color: $color-brand-green;

  svg {
    width: auto;
    height: 100%;
    margin-right: 10px;
  }
}