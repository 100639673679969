.OrderHistory {
  width: 100%;
  position: relative;

  &__vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__empty {
    text-align: center;
  }

  &__slide {
    width: 245px;
    padding: 40px 16px 0 4px;
    margin-top: 12px;
  }
}
