.StockList {
  padding: 30px 0 50px 0;
  display: flex;
  flex-direction: column;

  &__title {
    margin-bottom: 25px;
    text-align: center;
  }

  &__table {
    display: flex;
    flex-direction: column;
  }
}

.Stock {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 50px;
  }

  &__image {
    position: relative;
    width: 60%;
    margin-right: 50px;
    flex-shrink: 0;
    border-radius: 25px;

    img {
      width: 100%;
      height: auto;
      border-radius: 25px;
    }

    &-caption {
      position: absolute;
      left : 0;
      bottom : 0;
      margin : 15px;
      border-radius : 15px;
      padding : 6px 10px;
      background-color: rgba(255, 255, 255, 0.5);
      font-size: 10px;
      color: $color-brand-brown;
    }
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__title {
    margin-bottom: 15px;
    font-family: $font-secondary;
    font-weight: 500;
    font-size: $font-size-xl;
    line-height: 1.33;
    color: $color-brand-brown;
  }

  &__description {
    font-family: $font-secondary;
    font-weight: 400;
    font-size: $font-size-m;
    line-height: 1.33;
    color: $color-brand-brown;
    opacity: 0.8;
  }

  &__link {
    margin-top: 10px;
    align-self: flex-start;
    font-family: $font-primary;
    font-weight: 400;
    font-size: $font-size-s;
    line-height: 1.33;
  }

  &__button {
    height: 60px;
    margin-top: 40px;
    align-self: flex-start;
  }

  &__spinner {
    spinner(48px, 3px, $color-brand-green);
    align-self: center;
    margin-left: 12px;
  }
}
