.OrderTracking {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  min-height: 100vh;
  padding: 0 0 25px 0;

  &__navbar {
    margin-bottom: 25px;
  }

  &__spinner {
    spinner(48px, 3px, $color-brand-green);
    align-self: center;
  }
}
