.DeliveryTimeSwitcher {
  margin-bottom: $checkout-desktop-margin-40;
  display: flex;
  flex-direction: column;

  &__title {
    margin-bottom: $checkout-desktop-margin-25;
  }

  &__row {
    display: flex;

    &:not(:last-child) {
      margin-bottom: $checkout-desktop-margin-25;
    }
  }

  &__button {
    &:not(:last-child) {
      margin-right: 15px;
    }
  }

  &__times {
    display: flex;
  }

  &__time {
    width: 200px;

    &:not(:last-child) {
      margin-right: 15px;
    }
  }
}