.PromocodeWidget {
  paper();
  display: flex;
  flex-direction: column;

  &__progress {
    width: 100%;
    height: 4px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: $border-radius;
    background-color: #ededed;

    @media $phone {
      display: none;
    }

    &-scale {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: $border-radius;
      background-color: $color-brand-green;
      transform-origin: 0 0;
      transition: transform $duration;
    }
  }

  &__field {
    width: 100%;
    height: 46px;
    position: relative;
  }

  &__input {
    width: 100%;
    height: 100%;
    padding: 8px 50px 8px 20px;
    border-radius: 20px;
    background-color: $color-bg;
    border: 1px solid $color-bg;
    color: $color-brand-brown;
    font-family: $font-primary;
    font-size: $font-size-s;
    font-weight: 500;
    line-height: 1;
    placeholder($color-grey);
    transition: border-color $halfduration, background-color $halfduration;

    &_applied {
      color: $color-brand-green;
    }

    &_error {
      border: 1px solid $color-brand-red;
    }

    &:not(&_applied):not(&_error):focus {
      background-color: transparent;
      border-color: $color-brand-green;
    }

    &_withoutFocus:focus {
      background-color: $color-bg !important;
    }
  }

  &__button {
    width: 32px;
    height: 32px;
    position: absolute;
    top: 50%;
    right: 4px;
    transform: translateY(-50%);
    background-color: $color-grey;
    border-radius: 50%;
    color: white;
    transition: background-color $halfduration, border-color $halfduration, color $halfduration;

    &_applied {
      background: none;
      color: $color-brand-green;
    }

    &:not(&_applied):hover {
      background-color: $color-brand-green;
    }
  }

  &__button-arrow {
    chevron-right();
  }

  &__button-clear {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
    color: $color-grey;
    transition: color $halfduration;

    svg {
      width: 24px;
      height: auto;
      display: block;
    }

    &:hover {
      color: darken(#bfb7bf, 15%);
    }
  }

  &__spinner {
    spinner(20px, 2px, $color-brand-green);
    $spinnerSize = 20px;
    $spinnerWidth = 2px;
    margin-top: -10px;
    margin-left: -10px;
    position: absolute;
    top: 50%;
    left: 50%;
  }

  &__error {
    margin: 16px 0;
    color: $color-brand-red;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  &__conditions {
    margin-top: 16px;
  }


  &__promoGame {
    top: 40% px;
    left: 0;
    transform: translate(20%, -30%);
  }

  &__promoGame-decor {
    top: unset !important;
    bottom: 0px;
    transform: translateY(100%);
  }

  &__PromoBorder {
    width: 150px;
    top: 0;
    transform: translate(10%, -100%);
    z-index: 10;

    @media $phone {
      width: 120px;
      transform: translate(-10%, -100%);
    }
  }

  &__PaddingtonDecor {
    display none

    @media $tablet {
      display block
      width: 80px;
      top: 0;
      left: 0;
      transform: translate(-36%, -48%);
    }
    @media $phone {
      width: 65px;
    }
  }
}

.ModalConfirm {
  &__list {
    padding-top: 6px;
    display: inline-block;
    max-width: 100%;
    text-align: center;
  }

  &__item {
    display: block;
    margin-bottom: 4px;
    text-align: left;
    font-size: $font-size-xs;
  }

  &__item-name {
    display: inline;
  }

  &__item-info {
    display: inline;
    color: $color-text;
  }
}
