.MobilePhone {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__icon {
    width: 48px;
    height: @width;
    margin-left: 10px;
    flex-shrink: 0;
    display: block;
    position: relative;
    border-radius: 50%;
    border: solid 1px #cccccc;
    color: $color-brand-brown;

    svg {
      margin-top: -12px;
      margin-left: -12px;
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }
}
