.AddressSelector {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  &__mobile {
    height: 42px !important;
    border-radius: 20px !important;
    font-size: 14px !important;
    padding: 12px 16px;
    font-weight: 500;
  }

  &__title {
    margin-bottom: $checkout-mobile-margin-15;
    text-align: center;
  }

  &__hint {
    color: $color-text-gray;
    margin-bottom: $checkout-mobile-margin-10

    &_error {
      color: $color-brand-red
    }
  }

  &__map {
    width: calc(100% + 32px);
    height: 280px;
    margin-left: -16px;
    margin-bottom: $checkout-mobile-margin-15;
  }

  &__addresses {
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &__address {
    margin: 0 10px 10px 0;
    font-family: $font-secondary;
    font-weight: 500;
    line-height: 1;
    font-size: $font-size-s;
    color: $color-brand-brown;
    text-align: left;

    &_active {
      color: $color-brand-green;
    }
  }

  &__addresser {
    margin-bottom: $checkout-mobile-margin-15;
  }

  &__row {
    margin-bottom: $checkout-mobile-margin-15;
    display: flex;

    & > * {
      flex: 1;

      &:first-child {
        margin-right: 20px;
      }
    }
  }

  &__toggle {
    margin-bottom: $checkout-mobile-margin-15;
    display: flex;
    align-items: center;
    align-self: flex-start;
    color: $color-brand-green;
    font-family: $font-secondary;
    font-weight: 500;
    font-size: $font-size-s;
    line-height: 1.33;
  }

  &__arrow {
    height: auto;
  }

  &__accept {
    z-index: 0;
    align-self: center;
  }
}
