.ModalInCompetition {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 48px 42px;

  &__img {
    width: 40px;
    height: 40px;
    margin-bottom: 25px;
  }

  &__title {
    margin-bottom: 12px;
  }
  &__subtitle {
    font-family: $font-primary;
    font-weight: 500;
    font-size: $font-size-m;
    line-height: 1.33;
    text-align: center;
    color: $color-brand-brown;
    opacity: 0.8;
    margin-bottom: 20px;
  }

}
