.B2b {
  &__Benefits {
    padding: 60px 0 100px;
    color: $color-brand-brown;
    background-color: white;

    @media $phone{
      padding: 20px 0 32px;
    }
  }

  &__title {
    line-height: 1.1;
  }

  &__caption {
    font-size: $font-size-m;
    margin-top: 24px;
    @media $phone{
      margin-top: 14px;
    }
  }
}

.react-date-picker__calendar-button {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0
}
